import { CHARTCONFIG } from './../../../charts/charts.config';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Routes, RouterModule, Router, ActivatedRoute } from '@angular/router';
import { JobsService } from '../../../services/jobs.service';
import { RunService } from '../../../services/run.service';
import { ProjectService } from '../../../services/project.service';
import { Base } from '../../../models/base.model';
import { Run } from '../../../models/run.model';
import { VERSION } from '@angular/material/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Handler } from '../../dialogs/handler/handler';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DatePipe } from '@angular/common';
import { GlobalProjectDetails } from "../../../shared/shared.data";
@Component({
  selector: 'app-run-history',
  templateUrl: './run-history.component.html',
  styleUrls: ['./run-history.component.scss'],
  providers: [JobsService, RunService, ProjectService]
})
export class RunHistoryComponent implements OnInit {
  run: Run = new Run();
  list;
  suites;
  id;
  suiteName = '';
  projectId: string = '';
  jobId: string = '';
  project: Base = new Base();
  job: Base = new Base();
  showSpinner: boolean = false;
  config = CHARTCONFIG;
  // chart: Chart = []; // This will hold our chart info
  // chart2: Chart = []; // This will hold our chart info
  // chart3: Chart = []; // This will hold our chart info
  graph1Options: any;
  graph2Options: any;
  graph3Options: any;
  displayedColumns: string[] = [
    'runNo',
    'totalFailed',
    'region',
    'totalPassed',
    'requestStartTime',
    'requestTime',
    'totalBytes'
  ];
  dataSource = null;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(
    private jobsService: JobsService,
    private runService: RunService,
    private projectService: ProjectService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private handler: Handler,
    private datePipe: DatePipe
  ) { }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      if (params['jobId']) {
        this.jobId = params['jobId'];
        this.loadJob(this.jobId);
      }
      if (params['suiteId']) {
        this.suiteName = params['suiteId'];
        this.getTestSuiteResponseHistoryByName();
      }
      if (params['projectId']) {
        this.projectId = params['projectId'];
        this.loadProject(this.projectId);
      }
      if (params['id']) {
        this.id = params['id'];
        this.getRunById();
      }
    });
  }
  globalProjectDetails = GlobalProjectDetails;
  loadProject(id: string) {
    this.projectService.getById(id).subscribe((results) => {
      if (this.handler.handle(results)) {
        return;
      }
      this.project = results['data'];
      this.globalProjectDetails.name = this.project.name;
    });
  }

  getRunById() {
    this.handler.activateLoader();
    this.runService.getDetails(this.id).subscribe(
      (results) => {
        this.handler.hideLoader();
        if (this.handler.handle(results)) {
          return;
        }
        this.run = results['data'];
      },
      (error) => {
        this.handler.hideLoader();
        this.handler.error(error);
      }
    );
  }

  loadJob(id: string) {
    this.jobsService.getById(id).subscribe((results) => {
      if (this.handler.handle(results)) {
        return;
      }
      this.job = results['data'];
    });
  }

  getTestSuiteResponseHistoryByName() {
    this.handler.activateLoader();
    this.runService
      .getTestSuiteResponseHistoryByName(
        this.jobId,
        this.suiteName,
        this.page,
        this.pageSize
      )
      .subscribe(
        (results) => {
          this.handler.hideLoader();
          if (this.handler.handle(results)) {
            return;
          }
          this.suites = results['data'];
          this.length = results['totalElements'];
          this.dataSource = new MatTableDataSource(this.suites);
          this.dataSource.sort = this.sort;

          // Get data - Start
          const totalData = results['data'];
          const tp: any = [];
          const tf: any = [];
          const tb: any = [];
          const rt: any = [];
          const runno: any = [];
          const success: any = [];
          const crDate: any = [];
          const crDateConvert: any = [];
          const temp: any = [];
          for (let i = totalData.length - 1; i >= 0; i--) {
            tp[i] = totalData[i]['totalPassed'];
            tf[i] = totalData[i]['totalFailed'];
            tb[i] = totalData[i]['totalBytes'] / 1024;
            rt[i] = totalData[i]['requestTime'] / 1000;
            crDate[i] = totalData[i]['createdDate'];
            const dt = new Date(crDate[i]);
            crDateConvert[i] = this.datePipe.transform(dt, 'MMM dd');
            success.push(100 * (tp[i] / (tp[i] + tf[i])));
          }
          // End
          tb.reverse();
          rt.reverse();
          crDateConvert.reverse();

          for (let i = totalData.length - 1; i >= 0; i--) {
            runno.push(totalData[i].runNo);
          }

          // Graphs Start
          this.graph1Options = {

            title: {
              text: 'Success Statistics'
            },
            tooltip: {
              trigger: 'axis',
              textStyle: {
                fontWeight: 'bold',
                fontSize: 13
              },
              axisPointer: {
                type: 'cross',
                label: {
                  backgroundColor: '#6a7985'
                }
              }
            },
            legend: {
              show: 'true',
              right: 20,
              data: ['Success']
            },
            grid: {
              left: '3%',
              right: '4%',
              bottom: '3%',
              containLabel: true
            },
            toolbox: {
              show: true,
              feature: {
                saveAsImage: { show: true, title: 'save' }
              }
            },
            xAxis: [
              {
                type: 'category',
                name: 'Date',
                nameLocation: 'middle',
                boundaryGap: false,
                data: crDateConvert
              }
            ],
            yAxis: [
              {
                type: 'value',
                name: 'Success',
                nameLocation: 'middle',
                nameGap: 23,
                boundaryGap: false
              }
            ],
            series: [
              {
                name: 'Failed',
                type: 'line',
                stack: 'Success',
                // label: {
                //   normal: {
                //     show: true,
                //     position: 'top'
                //   }
                // },
                areaStyle: { normal: {} },
                data: success,
                markPoint: {
                  data: [{ type: 'max', name: '' }, { type: 'min', name: '' }]
                }
              }
            ]
          };

          this.graph2Options = {
            title: {
              text: 'Data (in Bytes)'
            },
            tooltip: {
              trigger: 'axis',
              textStyle: {
                fontWeight: 'bold',
                fontSize: 13
              },
              axisPointer: {
                type: 'cross',
                label: {
                  backgroundColor: '#6a7985'
                }
              }
            },
            legend: {
              show: 'true',
              right: 20,
              data: ['Success']
            },
            grid: {
              left: '3%',
              right: '4%',
              bottom: '3%',
              containLabel: true
            },
            toolbox: {
              show: true,
              feature: {
                saveAsImage: { show: true, title: 'save' }
              }
            },
            xAxis: [
              {
                type: 'category',
                name: 'Runs',
                nameLocation: 'middle',
                boundaryGap: false,
                data: runno
              }
            ],
            yAxis: [
              {
                type: 'value',
                name: 'Data',
                nameLocation: 'middle',
                nameGap: 23,
                boundaryGap: false
              }
            ],
            series: [
              {
                name: 'Failed',
                type: 'line',
                stack: 'Success',
                // label: {
                //   normal: {
                //     show: true,
                //     position: 'top'
                //   }
                // },
                areaStyle: { normal: {} },
                data: tb,
                markPoint: {
                  data: [{ type: 'max', name: '' }, { type: 'min', name: '' }]
                }
              }
            ]
          };

          this.graph3Options = {
            title: {
              text: 'Time (Seconds)'
            },
            tooltip: {
              trigger: 'axis',
              textStyle: {
                fontWeight: 'bold',
                fontSize: 13
              },
              axisPointer: {
                type: 'cross',
                label: {
                  backgroundColor: '#6a7985'
                }
              }
            },
            legend: {
              show: 'true',
              right: 20,
              data: ['Success']
            },
            grid: {
              left: '3%',
              right: '4%',
              bottom: '3%',
              containLabel: true
            },
            toolbox: {
              show: true,
              feature: {
                saveAsImage: { show: true, title: 'save' }
              }
            },
            xAxis: [
              {
                type: 'category',
                name: 'Runs',
                nameLocation: 'middle',
                boundaryGap: false,
                data: runno
              }
            ],
            yAxis: [
              {
                type: 'value',
                name: 'Time',
                nameLocation: 'middle',
                nameGap: 23,
                boundaryGap: false
              }
            ],
            series: [
              {
                name: 'Failed',
                type: 'line',
                stack: 'Success',
                // label: {
                //   normal: {
                //     show: true,
                //     position: 'top'
                //   }
                // },
                areaStyle: { normal: {} },
                data: rt,
                markPoint: {
                  data: [{ type: 'max', name: '' }, { type: 'min', name: '' }]
                }
              }
            ]
          };
        },
        (error) => {
          this.handler.hideLoader();
          this.handler.error(error);
        }
      );
  }

  length = 0;
  page = 0;
  pageSize = 25;
  pageSizeOptions: number[] = [25, 50, 100];
  change(evt) {
    this.page = evt['pageIndex'];
    this.list();
    this.pageSize = evt.pageSize;
    this.getTestSuiteResponseHistoryByName();
  }
}
