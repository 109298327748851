export class Project {
  id: string;
  public name: string;
  public description: string;
  public url: string;
  public branch: string;
  public credsRequired: boolean = false;
  public account: Account = new Account();
  public lastCommit: string;
  public lastSync: string;
  public projectType: string;
  public org: Dto = new Dto();
  public visibility: string;
  public openAPISpec: string;
  public openText: string;
  public apiType: string;
  public isFileLoad: boolean = false;
  public issueTracker: ITDto = new ITDto();
  public notifications: Noti[] = [];
  public entitledUsers: EntitledUsers[] = [];
  public databases: any = [];
  public commandCheck: string;
  public email: string;
  public credentials: string;
  public personalizedCoverage: PersonalizedCoverage = new PersonalizedCoverage();
  public bulkUser: string;
  public timeZone: string;
  public hour: string;
  public activateScheduledReports: boolean;
  public planType: string;
  public skipGitbackup: boolean;
  public apiGateway: boolean;
  public apiGatewayAccountType: string;
  public apiGatewayId: string;
  public localOffSet: number;
  public apispecType: string;
  public region: string;
  public validation: boolean;
  public swaggerEditorOas: string;
  public noApi: boolean;
  public skipAbac: boolean = false;
  public manualEpFound: boolean = false;
  public source: string;
  public tags: any[];
  constructor() {
    var dt = new Date();
    var tz = dt.getTimezoneOffset();
    this.localOffSet = tz;
  }
}

/**
 * API Gateway Discovery Multi-Project Creation Model
 */
export class APIProjects {
  public projectsAPI: Project = new Project();
  public apiId: any;
  public apiName: any;
  public stage: any;
  public region: any;
  public isSelected: boolean;
  public planType: string;
  public isDisabled: boolean;
  public swaggerFile: {};
  public createdDate: string;
  public apiManagementServiceName: string;
  public resourceGroupName: string;
  public specContent: string;
}
export class EntitledUsers {
  public entitlementType: string;
  public projectId: string;
  public userId: string;
}
export class Account {
  public id: string;
  public name: string;
  public cloudType: string;
  public accessKey: string;
  public secretKey: string;
  public accountType: string;
  public isSSH: boolean;
}

export class Dto {
  public id: string;
  public name: string;
  public orgPlan: string;
}

export class SuggestionCounts {
  public groupBy: string;
  public count: number;
  public estimate: number;
}

export class AutoSUggest {
  public category: string;
  public createdBy: string;
  public createdDate: string;
  public endPoint: string;
  public estimates: string;
  public id: string;
  public inactive: string;
  public issueDesc: string;
  public issues;
  public method: string;
  public modifiedBy: string;
  public modifiedDate: string;
  public projectId: string;
  public region: string;
  public respStatusCode: string;
  public rbacAction: string;
  public severity: string;
  public status: string;
  public suggestion: string;
  public suggestionId: string;
  public testCaseNumber: string;
  public testSuiteId: string;
  public testSuiteName: string;
  public version: string;
}

export class Noti {
  id: string;
  name: string;
  to: string;
  channel: string;
  account: string;
}

export class ITDto {
  id: string;
  name: string;
  url: string;
  accountType: string;
  projectKey: string;
}

export class PersonalizedCoverage {
  projectId: string;
  databases: string;
  commandChecks: string;
  roles: string;
  tokenExample: string;
  timeZone: string;
  cron: string;
  email: string;
  auths: Auth[] = [];
}
export class Auth {
  name: string;
  authType: string;

  username: string;
  password: string;

  header_1: string;
  header_2: string;
  header_3: string;

  // OAuth 2.0 properties
  clientId: string;
  clientSecret: string;
  id: string;
  accessTokenUri: string;

  authorizationScheme: string;

  clientAuthenticationScheme: string;

  tokenName: string;
  scope: string;
  grantType: string;

  preEstablishedRedirectUri: string;
  useCurrentUri: string;
  userAuthorizationUri: string;

}
