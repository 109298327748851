export class ReportSetting {
    
    projectId: string;
    public account: Account = new Account();
    inactive: boolean;
    
  }

  
export class Account {
  public id: string;
  public name: string;
  public accessKey: string;
  public secretKey: string;
  public accountType: string;
  public region: string;
  public bucketName: string;
}

  