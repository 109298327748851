import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RegionsService } from '../../../services/regions.service';
import { Routes, RouterModule, Router, ActivatedRoute } from '@angular/router';
import { SnackbarService } from '../../../services/snackbar.service';

@Component({
  selector: 'app-bot-dialog',
  templateUrl: './bot-dialog.component.html',
  styleUrls: ['./bot-dialog.component.scss'],
  providers: [RegionsService, SnackbarService]
})
export class BotDialogComponent implements OnInit {

  dockerScript;
  kubernetesScript;
  dockerSwarm;
  kubernetesSwarm;
  constructor(
    private router: Router, public dialogRef: MatDialogRef<BotDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private snackbarService: SnackbarService) { }

  // tslint:disable-next-line: no-empty
  ngOnInit() {
    if (this.data != null) {
      this.dockerScript = this.data[0];
      this.dockerScript = this.dockerScript.includes('cloud.apisec.ai') ? this.dockerScript.replace('cloud.apisec.ai', 'scanner.apisec.ai') : this.dockerScript;
      this.kubernetesScript = this.data[1];
      this.dockerSwarm = this.data[2];
      this.kubernetesSwarm = this.data[3];
      this.kubernetesSwarm = this.kubernetesSwarm.includes('cloud.apisec.ai') ? this.kubernetesSwarm.replace('cloud.apisec.ai', 'scanner.apisec.ai') : this.kubernetesSwarm;
    }
  }

  copyToClipboard(element) {
    if (element == "#botScript") {
      console.log("botScript" + this.dockerScript);
    }
    if (element == "#botSwarm") {
      console.log("botSwarm" + this.dockerSwarm);
    }
    const $temp = $('<input>');
    $('body').append($temp);
    $temp.val($(element).text()).select();
    document.execCommand('copy');
    this.snackbarService.openSnackBar('Copied text to clipboard ', '');
    $temp.remove();
  }

}
