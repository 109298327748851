import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Handler } from '../components/dialogs/handler/handler';
import { TeamsUserListDialogComponent } from '../components/dialogs/teams-userslist-dialog/teams-userlist-dialog.component';
import { Team } from '../models/org.model';
import { OrgService } from '../services/org.service';
import { SnackbarService } from '../services/snackbar.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-team-edit',
  templateUrl: './team-edit.component.html',
  styleUrls: ['./team-edit.component.scss'],
  providers: [OrgService, SnackbarService]


})
export class TeamEditComponent implements OnInit {

  firstFormGroup: FormGroup;
  teamsControl = new FormControl([]);
  teamsData: Team = new Team();
  page = 0;
  pageSize = 10;
  selectedId;
  sort = 'createdDate';
  sortType = 'DESC';
  dataresult
  orgId
  newData;
  selectedUsersNames = [];
  selectedUsersArr = [];
  userId
  users: any
  data;
  newResult;
  newId;
  teamId;
  constructor(private _formBuilder: FormBuilder, private route: ActivatedRoute, private orgService: OrgService, private handler: Handler, private snackbarService: SnackbarService, private router: Router, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.firstFormGroup = this._formBuilder.group({
      name: new FormControl('', [Validators.required, Validators.maxLength(250),  Validators.pattern('[a-zA-Z0-9 ]*')]),
      description: new FormControl('', [Validators.required, Validators.maxLength(250),  Validators.pattern('[a-zA-Z0-9 ]*')]),
      users: ['', Validators.required],

    })
 
    this.route.params.subscribe(params => {
      if (params['id']) {
        this.teamId = params['id'];
        this.getTeambyId();
      }
    });
    this.getorgsId();

  }

  itemId: string;

  selectedUserIds;
  selectedUser: any;

  onToppingRemoved(topping: string) {
    const toppings = this.teamsControl.value as string[];
    this.removeFirst(toppings, topping);
    this.teamsControl.setValue(toppings); // To trigger change detection
  }
  private removeFirst<T>(array: T[], toRemove: T): void {
    const index = array.indexOf(toRemove);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }
  trackByFunction(index: number, item: string): string {
    return item; // In this case, you can use the item itself as the track identifier
  }
  itemName: any;
  getTeambyId() {
    this.handler.activateLoader();
    this.orgService.getTeamById(this.teamId).subscribe(result => {
      this.handler.hideLoader();
      this.teamsData = result['data'];
      // console.log(this.teamsData)
      for (const key in this.teamsData) {
        if (this.teamsData.hasOwnProperty(key)) {
          // console.log(key + ': ' + this.teamsData[key]);
        }
      }
      this.teamsData.name = this.teamsData.name.replace(/\s+/g, ' ');
      this.teamsData['description'] = this.teamsData['description'].replace(/\s+/g, ' ');
    }, error => {
      this.handler.hideLoader();
      this.handler.error(error);
    })

  }

  usersChange(e) {

    if (e) {
      this.selectedUsersNames = e
    }
  }

  update() {
    var tempUsers = [];
    this.selectedUsersNames.forEach(u1 => {
      this.orgUsers.map(u2 => {
        if (u1 == u2['name']) {
          tempUsers.push(u2)
        }
      })
    })
    this.teamsData.users = tempUsers;
    this.handler.activateLoader();
    this.snackbarService.openSnackBar("' saving...", "");
    this.orgService.editTeam(this.teamId, this.teamsData).subscribe(results => {
      this.handler.hideLoader();
      if (this.handler.handle(results)) {
        return;
      }
      this.data = results["data"]
      this.snackbarService.openSnackBar("' saved successfully...", "");
      this.router.navigate(['/app/teams']);
    }, error => {
      this.handler.hideLoader();
      this.handler.error(error);
    });
  }

  getorgsId() {
    this.orgService.getLoggedInUser().subscribe(item => {
      this.data = item["data"];
      this.orgId = this.data.org.id;
      this.getOrgUser();
    }, error => {
      this.handler.hideLoader();
      this.handler.error(error)
    })

  }

  orgUsers = [];
  getOrgUser() {
    this.orgService.getOrgUsersByIdByPage(this.orgId, this.page, 100).subscribe(results => {
      this.dataresult = results['data'];
      this.orgUsers = this.dataresult.map(newdt => newdt.users);
      // matching users selected
      this.teamsData.users.map(tu => {
        this.orgUsers.map(u => {
          if (tu['name'] == u.name) {
            this.selectedUsersArr.push(tu['name'])
          }
        })
      })
      this.firstFormGroup.controls['users'].setValue(this.selectedUsersArr)
      // this.firstFormGroup.controls['name'].setValue()
      // this.firstFormGroup.controls['description'].setValue()
    }, error => {
      this.handler.hideLoader();
      this.handler.error(error);
    })
  }

  // delete() {
  //   this.handler.activateLoader();
  //   this.snackbarService.openSnackBar("'" + "Deleting Team ......", "");
  //   this.orgService.deleteTeam(this.teamId).subscribe(result => {
  //     console.log(result)
  //     this.snackbarService.openSnackBar("' Team Deleted successfully...", "");
  //     this.router.navigate(['/app/api-groups']);
  //   }, error => {
  //     this.handler.hideLoader();
  //     this.handler.error(error);
  //   });
  // }
  selectedUsersData

  selectedUserIds2 = []
  openUserListDialog() {
    const dialogRef = this.dialog.open(TeamsUserListDialogComponent, {
      width: '600px',
      data: {
        name: this.selectedUsersArr,
      }
    });

    dialogRef.componentInstance.emitData.subscribe(data1 => {
      this.selectedUsersData = data1
      this.selectedUsersNames = this.selectedUsersData.map(a => a.name);

    });
  }
}
