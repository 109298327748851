import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { JobsService } from '../../../services/jobs.service';
import { AdvRunComponent } from '../adv-run/adv-run.component';
import { Handler } from '../handler/handler';

@Component({
  selector: 'app-bulk-endpoints-confirm-dialog',
  templateUrl: './bulk-endpoints-confirm-dialog.component.html',
  styleUrls: ['./bulk-endpoints-confirm-dialog.component.scss'],
  providers: [

    JobsService,

  ]
})
export class BulkEndpointsConfirmDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<BulkEndpointsConfirmDialogComponent>, private dialog: MatDialog,
    private router: Router, private jobsService: JobsService, private handler: Handler,
  ) { }
  @Output() emitData = new EventEmitter<boolean>();
  // profiles;
  // masterProfile;
  ngOnInit(): void {

    // this.getProfiles();
  }
  gotoScan() {
    this.dialogRef.close();

    this.emitData.next(true);

    // const dialogRef = this.dialog.open(AdvRunComponent, {
    //   width: '800px',
    //   data: [this.masterProfile, "", this.data.endpointsBulk]
    // });
    // dialogRef.componentInstance.emitData.subscribe(result => {
    //   this.router.navigate(['/app/projects/', this.data.projectId, 'profiles', result[0]['job']['id'], 'runs', result[0]['id']]);
    // });

    // this.router.navigate(["/app/projects/", this.data, "dashboard"]);
  }


  // getProfiles() {
  //   this.jobsService.getJobs(this.data.projectId, 0, 25).subscribe(
  //     results => {
  //       if (this.handler.handle(results)) {
  //         return;
  //       }
  //       this.profiles = results["data"];

  //       for (var i = 0; i < this.profiles.length; i++) {
  //         if (this.profiles[i].name == 'Master') {
  //           this.masterProfile = this.profiles[i];
  //           i = this.profiles.length;
  //         }

  //       }




  //     },

  //     error => {
  //       this.handler.hideLoader();
  //       this.handler.error(error);
  //     }
  //   );

  // }

}
