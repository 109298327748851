import { Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ProjectService } from "../../../services/project.service";
import { Handler } from "../handler/handler";
import { Project } from "../../../models/project.model";
import { ELASTICSEARCHVALIDATOR } from './../../../shared/shared.data';

@Component({
    selector: 'app-manage-skippath-dialog',
    templateUrl: './manage-skippath-dialog.component.html',
    styleUrls: ['./manage-skippath-dialog.component.scss'],
    providers: [ProjectService]

})
export class ManageSkippathDialogComponent implements OnInit {
    project: Project = new Project();
    projectId: string;
    keyword: string;
    displayedColumns: string[] = ['resourceName', 'endpoint', 'skipFlag'];
    endPointDataSource = null;
    endpointsData: any;
    endpointList: any;

    resourceSamples;

    allSelectedFlag: boolean = false;
    fewSelectedFlag: boolean = false;
    fewSelectedData = [];
    searchStringValidator = ELASTICSEARCHVALIDATOR;

    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @Output() emitData = new EventEmitter<string>();
    totalEndpoints: number;

    constructor(private dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any, private handler: Handler,
        public dialogRef: MatDialogRef<ManageSkippathDialogComponent>, private projectService: ProjectService) {
    }

    ngOnInit() {

        this.projectId = this.data.projectId;
        if (this.data.pathPatterns != null)
            this.resourceSamples = this.data.pathPatterns.split("\n");
        else
            this.resourceSamples = [];

        this.endpointsData = this.data.endpointsData;
        if (this.endpointsData.length != 0)
            this.processEndpointData();
        else
            this.getEndpointList();

    }

    processEndpointData() {

        for (let element of this.endpointsData) {
            var index = this.resourceSamples.findIndex(r1 => element.method == r1.split(':')[0] && element.endpoint == r1.split(':')[1])
            if (index >= 0) {
                element['skipFlag'] = true
            }
            else
                element['skipFlag'] = false

        }

        this.endPointDataSource = this.endpointsData;

        this.totalEndpoints = this.endpointsData.length;
        this.endPointDataSource = new MatTableDataSource(this.endPointDataSource);
        this.endPointDataSource.sort = this.sort;
        this.selectedEndPoint();
    }

    getEndpointList() {
        this.handler.activateLoader();
        this.projectService.getEndpoints(this.projectId).subscribe(results => {
            this.handler.hideLoader();
            if (this.handler.handle(results)) {
                return;
            }
            this.endpointsData = results['data'];

            this.processEndpointData();


        }, error => {
            this.handler.hideLoader();
            this.handler.error(error);
        });
    }


    save() {
        let modifiedData = '';
        for (let element of this.endpointsData) {
            if (element.skipFlag == true) {
                modifiedData = modifiedData + element.method + ':' + element.endpoint + '\n';
            }
        }

        modifiedData = modifiedData.replace(/\n$/, "");
        this.emitData.next(modifiedData);
        this.dialogRef.close();
    }


    selectedEndPoint() {

        let totalSelected = this.endpointsData.filter(i => i.skipFlag).length;

        if (totalSelected == 0) {
            this.allSelectedFlag = false;
            this.fewSelectedFlag = false;
        }
        else
            if (totalSelected > 0 && totalSelected < this.endpointsData.length) {
                this.allSelectedFlag = false;
                this.fewSelectedFlag = true;
            }
            else
                if (totalSelected > 0 && totalSelected == this.endpointsData.length) {
                    this.allSelectedFlag = true;
                    this.fewSelectedFlag = false;
                }

    }

    applyFilter(filterValue: string) {
        this.endPointDataSource.filter = filterValue.trim().toLowerCase();
        this.fewSelectedData = this.endPointDataSource.filteredData
    }



    selectedAllEndPoints(event) {
        //All checked
        if (event.checked) {

            // for select few endpoints with search
            if (this.fewSelectedData.length > 0) {

                this.endpointsData = this.fewSelectedData;
            }
            //ends
            for (let element of this.endpointsData) {
                element['skipFlag'] = true
            }
        }

        //All unchecked
        else {

            for (let element of this.endpointsData) {
                element['skipFlag'] = false
            }
        }

    }

}
