import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiGroupModel } from '../models/org.model';
import { Handler } from '../components/dialogs/handler/handler';
import { SnackbarService } from '../services/snackbar.service';
import { ApiGroups } from '../services/api-groups.service';
import { data } from 'jquery';
import { ProjectService } from "../services/project.service";
import { OrgService } from '../services/org.service';
import { ApiListDialogComponent } from '../components/dialogs/api-list-dialog/api-list-dialog.component';

import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TeamsUserListDialogComponent } from '../components/dialogs/teams-userslist-dialog/teams-userlist-dialog.component';
import { TeamsListDialogComponent } from '../components/dialogs/teams-list-dialog/teams-list-dialog.component';
import { ApiGroupListDialogComponent } from '../components/dialogs/api-groups-list-dialog/api-group-list-dialog.component';


@Component({
  selector: 'app-api-group-edit',
  templateUrl: './api-group-edit.component.html',
  styleUrls: ['./api-group-edit.component.scss'],
  providers: [SnackbarService, ApiGroups, ProjectService, OrgService]
})
export class ApiGroupEditComponent implements OnInit {
  EditFormGroup: FormGroup;
  groupsData: ApiGroupModel = new ApiGroupModel();
  groupId
  id;
  groupgroupListData;
  page = 0;
  pageSize = 10;
  length = 0;
  keyword: string = '';
  sort = 'createdDate';
  defaultChecked: boolean = false;
  data
  groupListData;
  testId;
  selectedProjects;
  selectedTeams;
  selectedProjectsNames
  projectListData;
  sortString: string = 'createdDate';
  sortType: string = 'DESC';
  tag: string = "";
  constructor(private _formBuilder: FormBuilder, private route: ActivatedRoute, private apiGroups: ApiGroups, private handler: Handler, private snackbarService: SnackbarService, private projectService: ProjectService, private orgService: OrgService, private router: Router, private dialog: MatDialog) { }

  ngOnInit(): void {

    this.EditFormGroup = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.maxLength(250),   Validators.pattern('[a-zA-Z0-9\. ]*')]),
      description: new FormControl('', [Validators.required, Validators.maxLength(250),   Validators.pattern('[a-zA-Z0-9\. ]*')]),
      projects: new FormControl('', Validators.required),
      subApiGroups: new FormControl(''),
      teams: new FormControl('')
    });
    this.route.params.subscribe(params => {
      if (params['id']) {
        this.groupId = params['id'];
        this.getGroupbyId();
      }
    });


    this.teamList();
    // this.getGroupbyId();
    this.getAllProjects();
  }

  getAllProjects() {
    this.projectService.getProjects(0, 100, this.sortString, this.sortType, this.tag).subscribe(results => {
      this.projectListData = results["data"];

    },
      error => {
        this.handler.hideLoader();
        this.handler.error(error);
      }
    )

  }
  teamListData;
  teamList() {
    this.handler.activateLoader();
    this.orgService.getTeam(0, 100, this.sort, this.sortType).subscribe(result => {
      this.handler.hideLoader();
      if (this.handler.handle(result)) {
        return;
      }
      this.teamListData = result['data'].content;


    }, error => {
      this.handler.hideLoader();
      this.handler.error(error);
    })

  }
  projectNew
  groupIdsC
  selectedProjectsNames1 = [];
  selectedTeamsNamesArr = [];
  selectedAPIGroupArr = [];
  selectedAPIGroupNames = [];
  getGroupbyId() {
    this.apiGroups.getGroupById(this.groupId).subscribe(result => {
      this.groupsData = result['data'];
      this.groupsData.name = this.groupsData.name.replace(/\s+/g, ' ');
      this.groupsData.description = this.groupsData.description.replace(/\s+/g, ' ');
      this.groupsData['teams'].map(t => {
        this.selectedTeamsArr.push(t['id']);
        this.selectedTeamsNamesArr.push(t['name']);

      })
      
       this.selectedTeams = this.groupsData['teams'];
      // this.groupsData['projects'].map(t => {
      //   this.selectedProjectsArr.push(t['id']);
      // })
      this.groupsData['projects'].map(t => {
        this.selectedProjectsArr.push(t['id']);     
        this.selectedProjectsNames1.push(t['name']);
      });
      this.groupsData['subApiGroups'].map(t => {
        this.selectedAPIGroupArr.push(t['id']);
        this.selectedAPIGroupNames.push(t['name']);
      });

      // this.groupIdsC = this.groupsData['projects'].map(t => {
      //   this.selectedProjectsArr.push(t['id']);
      // })
      this.EditFormGroup.controls['teams'].setValue(this.selectedTeamsNamesArr)
      this.EditFormGroup.controls['projects'].setValue(this.selectedProjectsNames1)
      this.EditFormGroup.controls['subApiGroups'].setValue(this.selectedAPIGroupNames)
    }, error => {
      this.handler.hideLoader();
      this.handler.error(error)
    })
    // this.apiGroupList();
  }
  teamsList = [];
  selectedTeamsArr = [];
  projects
  projectList = []
  selectedProjectsArr = [];
  newdt;

  selectedGroupArr = [];
  teamsChange(e) {
    if (e) {
      this.selectedTeamsArr = e;
    }
  }
  projectChange(e) {
    if (e) {
      this.selectedProjectsArr = e;
    }
  }
  subgroupChange(e) {
    if (e) {
      this.selectedGroupArr = e;
    }
  }
  selectedAPIGroupsData
  selectedAPIGroupsName: string;

  openApiGroupListGroupDialog() {

    const dialogRef = this.dialog.open(ApiGroupListDialogComponent, {
      width: '600px',
      data: {
        subGroupId: this.selectedAPIGroupArr,
        subGroupName: this.selectedAPIGroupNames
      }
    });

    dialogRef.componentInstance.emitData.subscribe(selectedGroups => {
      this.selectedAPIGroupsData = selectedGroups
      this.selectedAPIGroupNames = this.selectedAPIGroupsData.map(a => a.name)

    });
  }

  openApiListGroupDialog() {
    const dialogRef = this.dialog.open(ApiListDialogComponent, {
      width: '600px',
      data: {
        groupId: this.groupId,
        name: this.selectedProjectsNames
      }
    });
    dialogRef.componentInstance.emitData.subscribe(data1 => {
      this.selectedProjects = data1
      // this.defaultChecked = true;
      this.selectedProjectsNames = this.selectedProjects.map(a => a.name)
    });
  }

  apiGroupUpdate() {
    var tempTeams = [];
    var tempproject = [];
    var tempGroup = [];
    
    // this.selectedTeamsArr.forEach(u1 => {
    //   this.teamListData.map(u2 => {
    //     if (u1 == u2['id']) {
    //       tempTeams.push(u2)
    //     }
    //   })
    // })
    

    this.selectedTeams.forEach(u1 => {
      this.teamListData.map(u2 => {
        if (u1.id == u2['id']) {
          tempTeams.push(u2)
        }
      })
    })
    if (this.selectedProjects && Array.isArray(this.selectedProjects)) {
      this.selectedProjects.map(a => tempproject.push(a));
    } else {
      this.selectedProjects = this.groupsData.projects;
    }
    if (this.selectedAPIGroupsData && Array.isArray(this.selectedAPIGroupsData)) {
      this.selectedAPIGroupsData.map(a => tempGroup.push(a));
    }
    this.groupsData.teams = tempTeams;
    this.groupsData.projects = tempproject;
    this.groupsData.projects = this.selectedProjects;
    this.groupsData.subApiGroups = tempGroup;
    this.handler.activateLoader();
    this.snackbarService.openSnackBar("' saving...", "");
    this.apiGroups.update(this.groupId, this.groupsData).subscribe(result => {
      this.handler.hideLoader();
      this.groupsData = result['data'];
      this.snackbarService.openSnackBar("' saved successfully...", "");
      this.router.navigate(['/app/api-groups']);
    }, error => {
      this.handler.hideLoader();
      this.handler.error(error)
    })
  }
  selectedData
  selectedTeamNames
  // openUserListDialog() {
  //   const dialogRef = this.dialog.open(TeamsUserListDialogComponent, {
  //     width: '600px',
  //     data: {
  //       userId: this.selectedTeamsArr,
  //     }
  //   });

  //   dialogRef.componentInstance.emitData.subscribe(data1 => {
  //     this.selectedData = data1
  //     this.selectedTeamNames = this.selectedTeamsArr.map(a => a.name);
  //   });
  // }
  teamListDataUpdate :any;
  teamListDataUpdateNames : any;
  openTeamsListDialog() {

    const dialogRef = this.dialog.open(TeamsListDialogComponent, {
      width: '600px',
      data: {
        groupId: this.groupId,
        userSelectedTeamId: this.selectedTeams
                // userSelectedTeamId:this.groupsData.teams 

      },
      
    });
      dialogRef.componentInstance.emitData.subscribe(data1 => {
        this.selectedTeams = data1
        this.selectedTeamsNamesArr = this.selectedTeams.map(a => a.name)
        
      });
  }
}