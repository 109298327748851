import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Categories } from "../models/project-autocode-config.model";

@Injectable({
  providedIn: "root"
})
export class AutocodeGeneratorService {
  private serviceUrl = "/api/v1/autocode-generator";
  constructor(private http: HttpClient) {}

  saveAutoCodeGenerator(autoCodeGenerator: Categories) {
    return this.http.post(this.serviceUrl, autoCodeGenerator);
  }
}
