import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class DataTransferService {
  private subject = new Subject<any>();
  private keywordSubject = new BehaviorSubject<string>("");
  public keyword: string = "";
  public page: any = 0;

  sendMessage(message: string) {
    this.subject.next({ text: message });
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }

  updateKeyword(keyword: string) {
    this.keywordSubject.next(keyword);
  }

  getKeyword(): Observable<string> {
    return this.keywordSubject.asObservable();
  }
}
