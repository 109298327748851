import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Handler } from "./../handler/handler";
import { SnackbarService } from '../../../services/snackbar.service';

@Component({
  selector: 'app-scanner-delete-dialog',
  templateUrl: './scanner-delete-dialog.component.html',
  styleUrls: ['./scanner-delete-dialog.component.scss'],
  providers: [SnackbarService]
})
export class ScannerDeleteDialogComponent implements OnInit {

  constructor(
    // private dialog: MatDialog,
    public dialogRef: MatDialogRef<ScannerDeleteDialogComponent>,
    private snackbarService: SnackbarService,
    private handler: Handler,
    private dialog: MatDialog, @Inject(MAT_DIALOG_DATA)
    public data: any
  ) { }
  scannerArray = ["kubectl delete pod", "docker service rm -f", "docker rm -f"];

  ngOnInit() {}

  copyToClipboard(element) {
    var $temp = $("<input>");
    $("body").append($temp);
    $temp.val($(element).text()).select();
    document.execCommand("copy");
    this.snackbarService.openSnackBar("Copied text to clipboard ", "");
    $temp.remove();
  }
}
