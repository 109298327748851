import { Component, OnInit, Inject, ViewChild, Output, EventEmitter } from '@angular/core';
// import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// import { RegionsService } from '../../../services/regions.service';
import { Routes, RouterModule, Router, ActivatedRoute } from '@angular/router';
import { SnackbarService } from '../../../services/snackbar.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ProjectService } from '../../../services/project.service';
import { Handler } from '../handler/handler';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { ApiGroups } from '../../../services/api-groups.service';

@Component({
  selector: 'api-list-dialog',
  templateUrl: './api-list-dialog.component.html',
  styleUrls: ['./api-list-dialog.component.scss'],
  providers: [SnackbarService, ProjectService, ApiGroups]
})
export class ApiListDialogComponent implements OnInit {


  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<ApiListDialogComponent>, private handler: Handler, private snackbarService: SnackbarService, private projectService: ProjectService, private apiGroups: ApiGroups) { }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Output() emitData = new EventEmitter<string>();
  page = 0;
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 25];
  sortString: string = 'createdDate';
  sortType: string = 'DESC';
  tag: string = "";
  projectListData;
  // dataSource = null;
  projectId;
  projectLength1;
  apiData
  testId
  newId = [];
  selectedName: any = [];
  test123
  NewArray = [];
  filteredData;
  length:number = 0;
  displayedColumns: string[] = ['add', 'name'];
  ngOnInit() {
    this.getAllProjects()
  }
  change(evt) {
    this.page = evt['pageIndex'];
    this.pageSize = evt.pageSize;
    this.getAllProjects();
  }
  close() {
    this.dialogRef.close();
  }
  addApis() {
  }
  keyword: any = "";
  // getObjectById(arr, id) {
  //   return arr.find(obj => obj.id === id);
  // }
  selected212
  getAllProjects() {
    this.apiGroups.getAllProjects().subscribe(results => {
      this.projectLength1 = results['totalElements'];
      this.projectListData = results["data"];
      // if (Array.isArray(this.data['projectId']) && this.data['projectId'].length > 0) {
      this.projectListData.forEach(obj1 => {

          if (this.data['groupId'] != null && this.data['name'].includes(obj1.name)) {
            obj1['isCheck'] = true;
          }
          else obj1['isCheck'] = false;
        });
      // }
      this.selectedName = this.projectListData.filter((selected) => selected.isCheck == true)
      this.filteredData = this.projectListData.filter(item =>
        item.apiGroupId === null || item.apiGroupId === this.data['groupId']);

      this.filteredData = this.filteredData.sort((p1, p2) => (p1.isCheck < p2.isCheck) ? 1 : (p1.isCheck > p2.isCheck) ? -1 : 0);

      this.apiData = new MatTableDataSource(this.filteredData);
      this.apiData.paginator = this.paginator;
      this.length = this.filteredData.length;
    },
      error => {
        this.handler.hideLoader();
        this.handler.error(error);
      }
    )

  }


  applyFilter(filterValue: string) {

    filterValue = filterValue.trim().toLowerCase();
    const escapedFilterValue = filterValue.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&');
    const regex = new RegExp(escapedFilterValue, 'i');
    this.apiData.filterPredicate = (data: any, filter: string) => {
      const matchColumns = [
       data['name']
      ];
      return matchColumns.some(column => regex.test(column));

    };
    this.apiData.filter = filterValue;
    if (this.apiData.paginator) {
      this.apiData.paginator.firstPage();
    }

  }



  onSelectRow(item, event: any) {
    if (event.checked) {
      item.isCheck = event.checked

      this.selectedName.push(item);
      this.projectListData.forEach(element => {
        if (item.id == element.id) {
          element.isCheck = true;
        }
      })
    } else {
      this.selectedName = this.selectedName.filter((selected) => selected.isCheck == true);
      this.projectListData.forEach(element => {
        if (item.id == element.id) {
          element.isCheck = false;
        }
      })
      const index = this.selectedName.indexOf(item);
      if (index !== -1) {
        this.selectedName.splice(index, 1);
      }
    }
  }
  onAddClick() {
    this.emitData.next(this.selectedName);
    this.dialogRef.close(this.selectedName);

  }

}


