import { Component, OnInit, Inject, inject, EventEmitter, Output } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Handler } from '../../dialogs/handler/handler';
import { SnackbarService } from '../../../services/snackbar.service';
import { ResourceService } from '../../../services/resource.service';
import { Project } from '../../../models/project.model';
import { ProjectService } from '../../../services/project.service';
import { ActivityService } from '../../../services/activity.service';
@Component({
  selector: 'app-bulk-endpoints',
  templateUrl: './bulk-endpoints.component.html',
  styleUrls: ['./bulk-endpoints.component.scss'],
  providers: [SnackbarService, ProjectService, ActivityService]
})
export class BulkEndpointsComponent implements OnInit {
  bulkEndPoints = "";
  project: Project = new Project();
  disableBtn = false;
  status: string;
  maxEndpoints = 5;
  minEndpoints = 1;
  autoTrigger = true;
  methodTypes = ['POST', 'GET', 'PUT','DELETE', 'PATCH'];
  @Output() emitData = new EventEmitter<Object>();
  toggle: boolean = false;
  constructor(private handler: Handler,
    private snackbarService: SnackbarService,
    private resource: ResourceService,
    public dialogRef: MatDialogRef<BulkEndpointsComponent>,
    private dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
  }

  saveEndPoint() {
    const tempAuth = this.endpointsBulk;
    this.handler.activateLoader();
    this.snackbarService.openSnackBar("Adding endpoints..", "");
    this.resource.addEndPoints(this.data.projectId, tempAuth, this.autoTrigger).subscribe(
      results => {
        this.handler.hideLoader();
        if (this.handler.handle(results)) {
          return;
        }
        this.snackbarService.openBackgroundMsgBar("Adding endpoints in progress. This process will take couple of seconds.");

        var obj = {
          "bulkEndpoints": this.endpointsBulk,
          "autoTrigger": this.autoTrigger
        }
        this.emitData.next(obj);
        this.dialogRef.close();

      },
      error => {
        this.handler.hideLoader();
        this.handler.error(error);
      }
    )
  }

  public endpointsBulk: any[] = [{
    endpoint: '',
    method: '',
    pathDependency: '',
    queryDependency: '',
    payload: ''
  }];

  addEndpoinForm() {
    this.endpointsBulk.push({
      endpoint: '',
      method: '',
      pathDependency: '',
      queryDependency: '',
      payload: ''
    });
  }

  removeEndpointForm(i: number) {
    this.endpointsBulk.splice(i, 1);
  }

  checkFormValidity() {
    return !(this.endpointsBulk.length && this.endpointsBulk.find(x => !x.endpoint || !x.method) === undefined)
  }

  onSelectMethod(value: any){
    if(value == "POST" || value == "PUT") {
      this.toggle = true;
    } else {
      this.toggle = false;
    }
  }
}
