export class Account {
    id: string;
    name: string;
    region: string;
    accessKey: string;
    secretKey: string;
    accountType: string;
    cloudType: string;
    org: Org = new Org();
    prop1: string;
    prop2: string;
    prop3: string;
    allowedRegions: string[]
    bucketName: string;
    isDefaultStore: boolean = false;
    isdefault: boolean = false;
    autoOnboard: boolean = false;    
    isSSH: boolean = false;
}

export class Org {
    id: string;
    name: string;
}
