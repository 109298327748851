import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mfa-check-dialog',
  templateUrl: './mfa-check-dialog.component.html',
  styleUrls: ['./mfa-check-dialog.component.scss']
})
export class MfaCheckDialogComponent implements OnInit {

  d1 = new Date(this.data[0]);
  mfaEndDate;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<MfaCheckDialogComponent>, private dialog: MatDialog,
    private router: Router,
  ) { }

  ngOnInit(): void {

    var m = this.d1.getMonth() + 1
    this.mfaEndDate = m + "-" + this.d1.getDate() + "-" + this.d1.getFullYear()
  }

  gotoProfile() {

    this.dialogRef.close();
    this.router.navigate(["/app/profile/myProfile"]);

  }
}
