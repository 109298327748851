import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class DashboardService {

  private serviceUrl = '/api/v1/dashboard';
  private securityServiceUrl = '/api/v1/securitycenter/';
  private testsuiteUrl = '/api/v1/test-suites';
  private runUrl = '/api/v1/runs/totalScansByOrg';

  constructor(private http: HttpClient) { }

  getStat(name: string) {
    return this.http.get(this.serviceUrl + "/" + name);
  }

  getStatById(name: string,id:any) {
    return this.http.get(this.serviceUrl + "/" + name+ "?apiGroupId=" + id);
  }

  getStatByMonth(name: string, fromDate: string, toDate: string) {
    return this.http.get(this.serviceUrl + "/" + name + "?fromDate=" + fromDate + "&toDate=" + toDate);
  }

  botSavings(botid: string) {
    return this.http.get(this.serviceUrl + "/bots/" + botid + "/savings");
  }

  getOpenCloseVulnerabilities() {
    return this.http.get(this.serviceUrl + "/count-bugs/open-close");
  }

  getTotalUsers() {
    return this.http.get(this.serviceUrl + "/count-users");
  }

  getTotalEndpoints() {
    return this.http.get(this.serviceUrl + "/count-endpoints");
  }

  getOrgStats() {
    return this.http.get(this.serviceUrl + "/org-stats");
  }
  getOrgStatsById(id) {
    return this.http.get(this.serviceUrl + "/org-stats?apiGroupId="+id);
  }
  getOrgMonthlyStats() {
    return this.http.get(this.serviceUrl + "/org-stats-v2");
  }
  getSeveritybyOrgLevel() {
    return this.http.get(this.securityServiceUrl + "autosuggestion-by-severity")
  }
  getSeveritybyOrgLevelById(id) {
    return this.http.get(this.securityServiceUrl + "autosuggestion-by-severity?apiGroupId=" +id)
  }
  getSeveritybyMonthly() {
    return this.http.get(this.securityServiceUrl + "monthly-vulcount")
  }
  getOrgVulbyMonthly() {
    return this.http.get(this.securityServiceUrl + "org-monthly-vulcount")
  }
  getOrgVulbyMonthlyById(id) {
    return this.http.get(this.securityServiceUrl + "org-monthly-vulcount?apiGroupId="+id)
  }
  getRiskMonthly() {
    return this.http.get(this.securityServiceUrl + "monthly-severity-vulcount")
  }
  getVulbyAge() {
    return this.http.get(this.securityServiceUrl + "vulcount-by-age")
  }
  getVulbyAgeById(id) {
    return this.http.get(this.securityServiceUrl + "vulcount-by-age?apiGroupId="+id)
  }

  getCurrentPrevStats() {
    return this.http.get(this.securityServiceUrl + "current-prev-month-stats")
  }

  getOwaspVulnerabilities() {
    return this.http.get(this.securityServiceUrl + "org-owasp-stats")
  }
  getOwaspVulnerabilitiesById(id) {
    return this.http.get(this.securityServiceUrl + "org-owasp-stats?apiGroupId=" +id)
  }

  endpointCoverageByTier() {
    return this.http.get(this.testsuiteUrl + "/org-group-by-tier")
  }

  getPlaybookScannedCount() {
    return this.http.get(this.runUrl)
  }
  getPlaybookScannedCountById(id) {
    return this.http.get(this.runUrl+ "?apiGroupId=" + id)
  }
  getOrgOpenStats() {
    return this.http.get(this.securityServiceUrl + "org-stats");
  }
  getOrgOpenStatsById(id) {
    return this.http.get(this.securityServiceUrl + "org-stats?apiGroupId=" + id);
  }
  getListOfPendingAPI() {
    return this.http.get(this.securityServiceUrl + "org-pending-api");
  }

  getListOfPendingAPIById(id) {
    return this.http.get(this.securityServiceUrl + "org-pending-api?apiGroupId="+ id);
  }

  getUniqueEndpoints() {
    return this.http.get(this.securityServiceUrl + "unique-endpoints-count");
  }
  getUniqueEndpointsById(id) {
    return this.http.get(this.securityServiceUrl + "unique-endpoints-count?apiGroupId=" +id);
  }

}
