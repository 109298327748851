import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import {ManageAbacType3DialogComponent} from "../manage-abac-type3-dialog/manage-abac-type3-dialog.component";
import {map, startWith} from "rxjs/operators";
import {Observable} from "rxjs/Observable";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {ManageAbacType2ResponseDialogComponent} from "../manage-abac-type2-response-dialog/manage-abac-type2-response-dialog.component";

@Component({
    selector: 'app-nested-resource',
    templateUrl: './nested-resource.component.html',
    styleUrls: ['./nested-resource.component.scss']
})
export class NestedResourceComponent implements OnInit {

    constructor(private dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any,
                public dialogRef: MatDialogRef<ManageAbacType3DialogComponent>, private _formBuilder: FormBuilder) {
    }

    scriptName = '';
    createEndpoint='';
    body = '';
    deleteEndpoint = '';
    createAuth = '';
    nestedResourceObj;
    resourceData;
    nestedRes;
    parentRes = '';
    genType = '';
    resourceList: string[]=[];
    endpointList: string[]=[];
    scriptType:boolean;

    filteredOptions: Observable<string[]>;
    firstFormGroup:FormGroup;
    selectedResourceName='';
    endpointData:any;
    filteredOptionsEndpoint: Observable<string[]>;
    filteredOptionsEndpointD: Observable<string[]>;

    @Output() emitData = new EventEmitter<string[]>();
    enumValuesArray = [];
    selectedEnum = '';
    selectedEnumType = '';
    enumList = '';
    enumObject = [];
    enumTypeInScriptName = [];
    enumTypeTemplateName='';
    bodyKeys = [];
    selectedNestedKey = '';
    nestedKeyScriptName='';

    ngOnInit() {


        this.firstFormGroup = new FormGroup({
            myControlResource: new FormControl(),
            myControlEndpoint: new FormControl(),
            myControlEndpointR: new FormControl(),
            myControlEndpointD: new FormControl(),
            creatorCtrl: new FormControl(),
            useridRCtrl: new FormControl(),
            methodCtrl: new FormControl(),
            methodRCtrl: new FormControl(),
            methodDCtrl: new FormControl(),
            bodyCtrl: new FormControl(),
            bodyRCtrl: new FormControl(),
            enumValues: new FormControl()

        });


        this.resourceData = this.data.resourceData;
        this.endpointList = this.data.endpointList;
        this.createAuth = this.data.createAuth;
        this.nestedRes = this.data.nestedRes;
        this.scriptType = this.data.scriptType;
        this.parentRes = this.data.parentRes;
        this.genType = this.data.genType;
        this.endpointData = this.data.endpointData;
        this.bodyKeys = this.data.bodyKeys;


        if(this.nestedRes != null){
            this.scriptName = this.nestedRes.scriptName;
            this.body  = this.nestedRes.body;
            this.deleteEndpoint = this.nestedRes.deleteEndPoint;
            this.createEndpoint = this.nestedRes.endpoint;
            this.selectedResourceName = this.nestedRes.resourceName;
            this.createAuth = this.nestedRes.userAuth;
            this.enumTypeTemplateName = this.nestedRes.scriptName.split(this.nestedRes.resourceName)[1].split('Create')[0];
        }

        // this.filteredOptions = this.myControlResource.valueChanges
        this.filteredOptions = this.firstFormGroup.controls['myControlResource'].valueChanges.pipe(
            startWith(''),
            map(value => value? this._filter(value) : this.resourceData.slice())
        );


        this.filteredOptionsEndpoint = this.firstFormGroup.controls['myControlEndpoint'].valueChanges
            .pipe(
                startWith(''),
                map(value => this._filterEndpoint(value))
            );


        this.filteredOptionsEndpointD = this.firstFormGroup.controls['myControlEndpointD'].valueChanges
            .pipe(
                startWith(''),
                map(value => this._filterEndpointD(value))
            );


    }

    private _filter(value: string): string[] {
        // this.selectedResource = value;
        const filterValue = value.toLowerCase();

        return this.resourceData.filter(option => option.type.toLowerCase().indexOf(filterValue) === 0);
    }


    private _filterEndpoint(value: string): string[] {
        const filterValue = value.toLowerCase();

        return this.endpointList.filter(option => option.toLowerCase().includes(filterValue));
    }


    private _filterEndpointD(value: string): string[] {
        const filterValue = value.toLowerCase();

        return this.endpointList.filter(option => option.toLowerCase().includes(filterValue));
    }

    addNestedResource(action) {

        this.scriptName = this.selectedResourceName + this.enumTypeTemplateName+ "Create" + this.createAuth +"Init" + this.genType;

        this.nestedResourceObj = {
            body: this.body,
            deleteEndPoint: this.deleteEndpoint,
            endpoint: this.createEndpoint,
            resourceName: this.selectedResourceName,
            scriptName: this.scriptName,
            scriptType: null,
            sequence: 0,
            userAuth: this.createAuth,
            validationScript: this.scriptType,
            selectedNestedKey:this.selectedNestedKey,
            nestedKeyScriptName:this.nestedKeyScriptName

        };

        this.emitData.next([this.nestedResourceObj,action,this.selectedNestedKey]);

        this.dialogRef.close();
    }

    loadBody(event,selectedResource){
        if(event.source.selected) {
            this.nestedKeyScriptName = '';
            this.scriptName =  selectedResource.type + "Create" + this.createAuth + "Init" + this.genType;
            this.body = selectedResource.body;
            this.createEndpoint = selectedResource.method +':' + selectedResource.endpoint;

            if(selectedResource.enums != null)
                this.loadEnumValuesArray(selectedResource.enums);

            this.loadNestedScriptName();
        }
        }


    loadEnumValuesArray(tempEnum) {
        this.enumTypeTemplateName = '';
        this.enumTypeInScriptName = [];
        this.selectedEnum = '';
        this.selectedEnumType = '';
        this.enumValuesArray = [];
        this.enumList = '';
        this.enumObject = [];
        var enumArray1 = tempEnum.split('\n');
        if (enumArray1 != "")
            for (var i = 0; i < enumArray1.length; i++) {
                this.enumList = enumArray1[i].split('[')[0];
                var tmpEnum = enumArray1[i].split('[')[1].split(']')[0].split('__');
                this.enumObject.push({
                    enum: this.enumList,
                    enumType: tmpEnum
                })
            }
    }

    AddEnum() {
        var tempBody= JSON.parse(this.body);
        tempBody[this.selectedEnum] = this.selectedEnumType;
        this.enumTypeInScriptName[this.selectedEnum] = this.selectedEnumType ;
        var enumTypesTemp='';
        for(var key in this.enumTypeInScriptName) {
            enumTypesTemp = enumTypesTemp + key + this.enumTypeInScriptName[key];
        }
        this.enumTypeTemplateName = enumTypesTemp;
        this.body = JSON.stringify(tempBody,null,2);
        this.body = JSON.stringify(tempBody,null,2);
        // this.bodyR = JSON.stringify(tempBody,null,2);
        this.scriptName = this.selectedResourceName + this.enumTypeTemplateName + "Create" + this.createAuth + this.genType;
    }

    EnumSelectedMethod() {
        this.enumValuesArray = [];
        this.selectedEnumType = '';
        for (var i = 0; i < this.enumObject.length; i++) {
            if (this.enumObject[i].enum == this.selectedEnum)
                this.enumValuesArray = this.enumObject[i].enumType
        }

    }

    loadNestedScriptName(){
        this.nestedKeyScriptName = "{{@" + this.scriptName + "_Response}}";
    }

    resources;
    viewEndPointResponses(){

        if(this.endpointData && this.endpointData.length>0){
                this.resources = '';
                for (let obj of this.endpointData) {
                    if (obj['method'] + ":" + obj['endpoint'] === this.createEndpoint) {
                        this.resources=obj.responses;
                        break;
                    }
                }
        }

        const dialogRef = this.dialog.open(ManageAbacType2ResponseDialogComponent, {
            width: '800px',
            data: {resourceData:this.resources,endpointData: this.endpointData,createEndpoint:this.createEndpoint}

        });
    }
}

