import { Component, OnInit } from "@angular/core";
import { AccountService } from "../../services/account.service";
import { Handler } from "../dialogs/handler/handler";
import { JobsService } from "../../services/jobs.service";
import { RegionsService } from "../../services/regions.service";
import { FormBuilder } from "@angular/forms";
import { TestSuiteService } from "../../services/test-suite.service";
import { SnackbarService } from "../../services/snackbar.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ProjectService } from "../../services/project.service";
import { MatDialog } from "@angular/material/dialog";
import { Project } from "../../models/project.model";
import { Jobs } from "../../models/jobs.model";
import { GradleIntegrationComponent } from "../dialogs/gradle-integration/gradle-integration.component";
import { BambooDialogComponent } from "../dialogs/bamboo-dialog/bamboo-dialog.component";
import { TeamcityDialogComponent } from "../dialogs/teamcity-dialog/teamcity-dialog.component";
import { HudsonDialogComponent } from "../dialogs/hudson-dialog/hudson-dialog.component";
import { AwsCodePipelineIntegrationComponent } from "../dialogs/aws-code-pipeline-integration/aws-code-pipeline-integration.component";
import { TfsIntegrationComponent } from "../dialogs/tfs-integration/tfs-integration.component";
import { JenkinsIntegrationComponent } from "../dialogs/jenkins-integration/jenkins-integration.component";
import { MavenIntegrationComponent } from "../dialogs/maven-integration/maven-integration.component";
import { GitlabIntegrationComponent } from "../dialogs/gitlab-integration/gitlab-integration.component";
import { MsAzureIntegrationComponent } from "./../dialogs/ms-azure-integration/ms-azure-integration.component";
import { CircleCicdIntegrationComponent } from "./../dialogs/circle-cicd-integration/circle-cicd-integration.component";
import { UpgradeComponent } from "../dialogs/upgrade/upgrade.component";
import { GlobalProjectDetails, GlobalSelectedEnv } from "../../shared/shared.data";
import { Account } from "../../models/account.model";
import { GithubActionsIntegrationComponent } from "../dialogs/github-actions-integration/github-actions-integration.component";
import { MixpanelService } from "../../services/mixpanel.service";
// import { DroneIntegrationComponent } from "../dialogs/drone-integration/drone-integration.component";

@Component({
  selector: "app-ci-cd-integration",
  templateUrl: "./ci-cd-integration.component.html",
  styleUrls: ["./ci-cd-integration.component.scss"],
  providers: [
    ProjectService,
    SnackbarService,
    JobsService,
    AccountService,
    RegionsService,
    TestSuiteService,
  ],
})
export class CiCdIntegrationComponent implements OnInit {
  id: string;
  jobId: string;
  project: Project = new Project();
  projects;
  loggedInUserRole;
  job: Jobs = new Jobs();
  jobs = [];
  length = 0;
  page = 0;
  pageSize = 100;
  names: any = [];
  itAccounts: Array<Account> = [];
  name: any = "Master";
  issueTracker: any = "jenkins";
  jenkinData: any = null;
  cicdTypes: Type[] = [
    { label: "Jenkins", value: "jenkins" },
    { label: "GitHub Actions", value: "github-actions" },
    { label: "Bamboo", value: "bamboo" },
    { label: "Hudson", value: "hudson" },
    { label: "TeamCity", value: "teamCity" },
    { label: "AWS", value: "aws" },
    { label: "Gitlab", value: "gitlab" },
    { label: "TFS", value: "tfs" },
    { label: "Circle", value: "circleCicd" },
    { label: "Microsoft Azure Pipelines", value: "ms-azure" },
    { label: "Drone", value: "drone" }
  ];
  nextAccountFlag: boolean = false;
  prevAccountFlag: boolean = true;
  nextRegionFlag: boolean = false;
  prevRegionFlag: boolean = true;
  cicdTypes1 = [];
  i: number = 0;
  r: number = 0;
  hostname = location.origin;
  integration: string =
    //"wget https://raw.githubusercontent.com/intesar/FX-Scripts/master/fx_job_invoke_script.sh?token=AHILVH7YY7DVETVRLYXBYJK6FK7TC -O fx_job_invoke_script.sh;bash fx_job_invoke_script.sh {Username} {Password}";
    "wget https://raw.githubusercontent.com/apisec-inc/apisec-scripts/master/apisec_job_invoke_script.sh?token=AVOLEQ4FVVPJJ3DPAME4JELBS56SI -O apisec_job_invoke_script.sh;bash apisec_job_invoke_script.sh --host " + this.hostname + " --username {Username} --password {Password}";
  integration2: string = "--emailReport true --reportType RUN_SUMMARY";
  pShell: string =
    "curl https://raw.githubusercontent.com/apisec-inc/apisec-scripts/master/apisec_job_invoke_script.ps?token=AVOLEQ4FVVPJJ3DPAME4JELBS56SI -o apisec_job_invoke_script.ps";
  pShell2: string =
    "powershell -File apisec_job_invoke_script.ps -url " + this.hostname + " -username {Username} -password {Password} ";
  pShell3: string = "-emailReport true -reportType RUN_SUMMARY";
  radioSelected = "shell";
  globalSelectedEnv = GlobalSelectedEnv;
  value: string = "";
  projectNameTemp: string;

  inputParams = `
  <ul>
  <li>
      <p><b>username</b> <span> &nbsp;**Required** &nbsp;The APIsec
              username with which the scans will be
              executed. </span>
          <br>
          Note:- Change Username and Password values. Optionally, you can
          create a new user on APIsec with
          'ROLE_USER' privilege and add him as a collaborator to the
          project.
      </p>
  </li>

  <li>
      <p><b>password</b> <span> &nbsp;**Required** &nbsp;The Password of
              the APIsec user with which the
              scans will be executed.</span>
      </p>
  </li>

  <li>
      <p><b>project</b><span> &nbsp;**Required** &nbsp;The Name of the
              project for security scan.</span>
      </p>
  </li>

  <li>
      <p><b>profile</b> <span> &nbsp;**Optional** &nbsp;The Name of the
              scan profile.</span>
          <br>
          Default value "Master".
      </p>
  </li>

  <li>
      <p><b>scanner</b><span>&nbsp; **Optional** &nbsp; The Name of the
              scanner .</span>
          <br>
          Default value "The scanner configured in profile".
      </p>
  </li>

  <li>
      <p><b>emailReport</b> <span>&nbsp; **Optional**&nbsp; Email report
              after scan completion.</span>
          <br>
          Note:- Possible values are true | false.
          <br>
          Default value "false".
      </p>
  </li>

  <li>
      <p><b>reportType</b> <span> &nbsp; **Optional**&nbsp; APIsec report
              type.</span>
          <br>
          Note:- Possible values are RUN_SUMMARY | RUN_DETAIL |
          PROJECT_SUMMARY | PROJECT_DETAIL |
          PROJECT_PEN_TEST_REPORT |
          DEVELOPER_REPORT | COMPLIANCE | PROJECT_NIGHTLY.
          <br>
          Default value "RUN_SUMMARY".
      </p>
  </li>
</ul>`
  droneData: string;

  constructor(
    private jobsService: JobsService,
    private accountService: AccountService,
    private route: ActivatedRoute,
    private regionService: RegionsService,
    private router: Router,
    private handler: Handler,
    private snackbarService: SnackbarService,
    private _formBuilder: FormBuilder,
    private projectService: ProjectService,
    private testSuiteService: TestSuiteService,
    public dialog: MatDialog,
    private mixpanelService: MixpanelService,

  ) { }

  ngOnInit() {

    this.route.params.subscribe((params) => {
      this.id = params["id"];
      this.jobId = params["jobId"];
      if (this.id) {
        this.loadProject();
        this.loggedInUserRole = localStorage.getItem('orgRole');
        if(!this.loggedInUserRole)  this.loggedInUserRole = localStorage.getItem('userRole'); 
        if (this.loggedInUserRole == 'BASIC') {
          this.upgradeSubscription()
        }
        else {
          this.getJobs();
        }
      }
    });
    this.getcicdTypes();
  }
  globalProjectDetails = GlobalProjectDetails;

  loadProject() {
    this.handler.activateLoader();
    this.projectService.getById(this.id).subscribe(
      (results) => {
        this.handler.hideLoader();
        if (this.handler.handle(results)) {
          return;
        }
        this.project = results["data"];
        this.globalProjectDetails.name = this.project.name;
      },
      (error) => {
        this.handler.hideLoader();
        this.handler.error(error);
      }
    );
  }

  profileData: any = [];
  getJobs() {
    this.jobsService.getJobs(this.id, this.page, this.pageSize).subscribe(
      (results) => {
        // this.handler.hideLoader();
        if (this.handler.handle(results)) {
          return;
        }
        this.jobs = results["data"];
        if (this.jobs.length > 0) {
          this.profileData = this.jobs.map((element) => {
            return {
              name: element.name,
              projectId: element.project.id,
              projectName: element.project.name,
              jobId: element.id,
              envId: element.environment.id,
              regions: element.regions,
            };
          });
          for (let p of this.profileData) {
            if (p.name == this.name) {
              this.jenkinData = p;
            }
          }
          this.projectNameTemp = "\"" + this.jenkinData.projectName + "\""
          // "\""+ data.url+  "\""
          this.integration = this.integration + " --project " + this.projectNameTemp + " --profile " + this.jenkinData.name + " --scanner " + this.jenkinData.regions + " " + this.integration2;
          //this.pShell2 =this.pShell2 + this.jenkinData.jobId +" "+ this.jenkinData.regions+" "+this.jenkinData.envId+" "+this.jenkinData.projectId;
          this.pShell2 = this.pShell2 + " -project " + this.projectNameTemp + " -profile " + this.jenkinData.name + " -scanner " + this.jenkinData.regions + " " + this.pShell3;
        }

        //showing selected env profile name
        // this.jobs.map((j) => {
        //   if (j.environment.id == this.globalSelectedEnv.envId) {
        //     this.name = j.name;
        //   }
        // })

      },
      (error) => {
        this.handler.hideLoader();
        this.handler.error(error);
      }
    );
  }

  showMsg() {
    console.log(this.droneData)
    this.snackbarService.openSnackBar('Copied text to clipboard ', '');
  }

  onChangeProfile(e) {
    if (this.profileData.length > 0) {
      for (let p of this.profileData) {
        if (p.name == this.name) {
          this.jenkinData = p;
        }
      }
      this.changeIssueTracker(this.issueTracker);
    }
  }

  openJenkins() {
    const dialogRef = this.dialog.open(JenkinsIntegrationComponent, {
      width: "800px",
      data: this.jenkinData,
    });
    dialogRef.afterClosed().subscribe((result) => { });
  }

  openBamboo() {
    const dialogRef = this.dialog.open(BambooDialogComponent, {
      width: "800px",
      data: this.jenkinData,
    });
    dialogRef.afterClosed().subscribe((result) => { });
  }

  openHudson() {
    const dialogRef = this.dialog.open(HudsonDialogComponent, {
      width: "800px",
      data: this.jenkinData,
    });
    dialogRef.afterClosed().subscribe((result) => { });
  }

  openTeam() {
    const dialogRef = this.dialog.open(TeamcityDialogComponent, {
      width: "800px",
      data: this.jenkinData,
    });
    dialogRef.afterClosed().subscribe((result) => { });
  }

  openAws() {
    const dialogRef = this.dialog.open(AwsCodePipelineIntegrationComponent, {
      width: "800px",
      data: this.jenkinData,
    });
    dialogRef.afterClosed().subscribe((result) => { });
  }

  openGitlab() {
    const dialogRef = this.dialog.open(GitlabIntegrationComponent, {
      width: "800px",
      data: this.jenkinData,
    });
    dialogRef.afterClosed().subscribe((result) => { });
  }

  // openGithubActions() {
  //   const dialogRef = this.dialog.open(GithubActionsIntegrationComponent, {
  //     width: "800px",
  //     data: this.jenkinData,
  //   });
  //   dialogRef.afterClosed().subscribe((result) => { });
  // }

  openTfs() {
    const dialogRef = this.dialog.open(TfsIntegrationComponent, {
      width: "800px",
      data: this.jenkinData,
    });
    dialogRef.afterClosed().subscribe((result) => { });
  }

  openCircle() {
    const dialogRef = this.dialog.open(CircleCicdIntegrationComponent, {
      width: "800px",
      data: this.jenkinData,
    });
    dialogRef.afterClosed().subscribe((result) => { });
  }

  openMsAzure() {
    const dialogRef = this.dialog.open(MsAzureIntegrationComponent, {
      width: "800px",
      data: this.jenkinData,
    });
    dialogRef.afterClosed().subscribe((result) => { });
  }

  // openDrone() {
  //   const dialogRef = this.dialog.open(DroneIntegrationComponent, {
  //     width: "800px",
  //     data: this.jenkinData,
  //   });
  //   dialogRef.afterClosed().subscribe((result) => { });
  // }

  changeIssueTracker(type) {

    //mixpanel code starts
    var obj1 = {};
    if (this.issueTracker == 'jenkins') {
      obj1 = {
        "projectName": this.project.name,
        "CicdType": this.issueTracker,
        "scriptType": this.radioSelected
      }
      this.mixpanelService.trackMixpanel("CICDSelection", obj1);
    }
    else {
      obj1 = {
        "projectName": this.project.name,
        "CicdType": this.issueTracker
      }
      this.mixpanelService.trackMixpanel("CICDSelection", obj1);
    }

    //mixpanel code ends



    if (this.issueTracker === "github-actions") {
      // this.openGithubActions();
      this.integration = this.integration + " --project " + this.projectNameTemp + " --profile " + this.jenkinData.name + " --scanner " + this.jenkinData.regions + " " + this.integration2;
      this.value =
        `  - name: Trigger APIsec scan \n` +
        `    id: scan \n` +
        `    uses: apisec-inc/apisec-run-scan@v1.0.3 \n` +
        `    with: \n` +
        `     apisec-username: $❴❴ secrets.apisec_username ❵❵ \n` +
        `     apisec-password: $❴❴ secrets.apisec_password ❵❵ \n` +
        `     apisec-project:  ` + this.globalProjectDetails.name + `\n` +
        `     apisec-profile:  ` + this.jenkinData.name + `\n` +
        `     apisec-region:   ` + this.jenkinData.regions + `\n`

      this.integration = 'wget https://raw.githubusercontent.com/intesar/FX-Scripts/master/fx_job_invoke_script.sh?token=AHILVH7YY7DVETVRLYXBYJK6FK7TC -O fx_job_invoke_script.sh;bash fx_job_invoke_script.sh {Username} {Password}';
    }
    else if (this.issueTracker === "drone") {
      this.integration2 = "";
      this.value =
        ` kind: pipeline  \n` +
        ` type: docker \n` +
        ` name: default \n` +
        ` \n` +
        ` steps: \n` +
        ` - name: APIsec scan trigger \n` +
        `   image: apisec/drone-scan:latest \n` +
        `   environment: \n` +
        `      scanner: ` + this.jenkinData.regions + `\n` +
        `      email: true \n` +
        `      hostUrl: ` + this.hostname + `\n` +
        `      projectName: ` + this.globalProjectDetails.name + `\n` +
        `      profile: ` + this.jenkinData.name + `\n` +
        `      report: RUN_SUMMARY \n` +
        `      fail-on-high-vulns: true \n` +
        `      password: \n` +
        `         from_secret: password \n` +
        `      username: \n` +
        `         from_secret: username \n`;
    } else
      this.integration =
        "wget https://raw.githubusercontent.com/apisec-inc/apisec-scripts/master/apisec_job_invoke_script.sh?token=AVOLEQ4FVVPJJ3DPAME4JELBS56SI -O apisec_job_invoke_script.sh;bash apisec_job_invoke_script.sh --host " + this.hostname + " --username {Username} --password {Password}";
    this.integration = this.integration + " --project " + this.projectNameTemp + " --profile " + this.jenkinData.name + " --scanner " + this.jenkinData.regions + " " + this.integration2;

    // }
    // else



    // if (this.issueTracker === "bamboo") {
    //   // this.openBamboo();
    //   this.integration = ' wget https://raw.githubusercontent.com/apisec-inc/apisec-scripts/master/apisec_job_invoke_script.sh?token=AElo1orWSWln6w6y5uX0-1ylzPH8TX_3ks5bqJBywA%3D%3D -O apisec_job_invoke_script.sh;bash apisec_job_invoke_script.sh {Username} {Password}';
    //   this.integration = this.integration + " " + this.jenkinData.jobId + " " + this.jenkinData.regions + " " + this.jenkinData.envId + " " + this.jenkinData.projectId;

    // }
    // else if (this.issueTracker === "hudson") {
    //   this.integration = 'wget https://raw.githubusercontent.com/apisec-inc/apisec-scripts/master/apisec_job_invoke_script.sh?token=AElo1orWSWln6w6y5uX0-1ylzPH8TX_3ks5bqJBywA%3D%3D -O apisec_job_invoke_script.sh;bash apisec_job_invoke_script.sh {Username} {Password}';
    //   this.integration = this.integration + " " + this.jenkinData.jobId + " " + this.jenkinData.regions + " " + this.jenkinData.envId + " " + this.jenkinData.projectId;

    //   // this.openHudson();
    // } else if (this.issueTracker === "teamCity") {
    //   // this.openTeam();
    //   this.integration = " wget https://raw.githubusercontent.com/apisec-inc/apisec-scripts/master/apisec_job_invoke_script.sh?token=AElo1orWSWln6w6y5uX0-1ylzPH8TX_3ks5bqJBywA%3D%3D -O apisec_job_invoke_script.sh;bash apisec_job_invoke_script.sh {Username} {Password}";
    //   this.integration = this.integration + " " + this.jenkinData.jobId + " " + this.jenkinData.regions + " " + this.jenkinData.envId + " " + this.jenkinData.projectId;

    // } else if (this.issueTracker === "gitlab") {
    //   this.integration = 'wget https://raw.githubusercontent.com/apisec-inc/apisec-scripts/master/apisec_job_invoke_script.sh?token=AElo1orWSWln6w6y5uX0-1ylzPH8TX_3ks5bqJBywA%3D%3D -O apisec_job_invoke_script.sh;bash apisec_job_invoke_script.sh {Username} {Password}';
    //   this.integration = this.integration + " " + this.jenkinData.jobId + " " + this.jenkinData.regions + " " + this.jenkinData.envId + " " + this.jenkinData.projectId;

    //   // this.openGitlab();
    // }
    // else if (this.issueTracker === "tfs") {
    //   this.integration = "wget https://raw.githubusercontent.com/apisec-inc/apisec-scripts/master/apisec_job_invoke_script.sh?token=AElo1orWSWln6w6y5uX0-1ylzPH8TX_3ks5bqJBywA%3D%3D -O apisec_job_invoke_script.sh;bash apisec_job_invoke_script.sh {Username} {Password}";
    //   this.integration = this.integration + " " + this.jenkinData.jobId + " " + this.jenkinData.regions + " " + this.jenkinData.envId + " " + this.jenkinData.projectId;

    //   // this.openTfs();
    // } else if (this.issueTracker === "circleCicd") {
    //   this.integration =
    //     " wget https://raw.githubusercontent.com/apisec-inc/apisec-scripts/master/apisec_job_invoke_script.sh?token=AElo1orWSWln6w6y5uX0-1ylzPH8TX_3ks5bqJBywA%3D%3D -O apisec_job_invoke_script.sh;bash apisec_job_invoke_script.sh {Username} {Password}";

    //   this.integration = this.integration + " " + this.jenkinData.jobId + " " + this.jenkinData.regions + " " + this.jenkinData.envId + " " + this.jenkinData.projectId;

    //   // this.openCircle();
    // } else if (this.issueTracker === "ms-azure") {
    //   this.integration =
    //     "wget https://raw.githubusercontent.com/apisec-inc/apisec-scripts/master/apisec_job_invoke_script.sh?token=AElo1orWSWln6w6y5uX0-1ylzPH8TX_3ks5bqJBywA%3D%3D -O apisec_job_invoke_script.sh;bash apisec_job_invoke_script.sh {Username} {Password}";
    //   this.integration = this.integration + " " + this.jenkinData.jobId + " " + this.jenkinData.regions + " " + this.jenkinData.envId + " " + this.jenkinData.projectId;

    //   // this.openMsAzure();
    // } else if (this.issueTracker === "aws") {
    //   // this.openAws();
    //   this.integration = 'wget https://raw.githubusercontent.com/apisec-inc/apisec-scripts/master/apisec_job_invoke_script.sh?token=AElo1orWSWln6w6y5uX0-1ylzPH8TX_3ks5bqJBywA%3D%3D -O apisec_job_invoke_script.sh;bash apisec_job_invoke_script.sh {Username} {Password}';
    //   this.integration = this.integration + " " + this.jenkinData.jobId + " " + this.jenkinData.regions + " " + this.jenkinData.envId + " " + this.jenkinData.projectId;
    // }

  }

  getcicdTypes() {
    for (this.i = 0; this.i && this.i < this.cicdTypes.length; this.i++) {
      this.cicdTypes1[this.i] = this.cicdTypes[this.i]
    }

  }

  nextAccount() {
    this.cicdTypes1 = [];
    if (this.i < 0) {
      for (this.i = 0; this.i < 5 && this.i < this.cicdTypes.length;) {
        this.i++
      }
    }
    if (this.cicdTypes.length > this.i) {
      for (var k = 0; k < 5 && k < this.cicdTypes.length && this.i < this.cicdTypes.length; k++) {
        this.cicdTypes1[k] = this.cicdTypes[this.i]
        this.i++
      }
    }
    if (this.cicdTypes.length == this.i) {
      this.nextAccountFlag = true;

    }
    if (this.i > 5)
      this.prevAccountFlag = false;
  }

  previousAccount() {
    this.i = this.i - this.cicdTypes1.length
    this.i--;
    this.cicdTypes1 = [];
    for (var k = 4; k >= 0 && k < this.cicdTypes.length && this.i >= 0; k--) {
      this.cicdTypes1[k] = this.cicdTypes[this.i]
      this.i--
    }
    if (this.cicdTypes.length == this.i - 1)
      this.prevAccountFlag = true;
    if (this.i <= 0)
      this.prevAccountFlag = true;
    if (this.cicdTypes.length > 5)
      this.nextAccountFlag = false;
    // this.i = this.i + 3
  }

  upgradeSubscription(): void {
    const dialogRef = this.dialog.open(UpgradeComponent, {
      width: '350px',
      disableClose: true

    });
  }

}
export interface Type {
  value: string;
  label: string;
}
