import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProjectService } from "../../../services/project.service";
import { Handler } from "../../dialogs/handler/handler";
import { SnackbarService } from '../../../services/snackbar.service';

@Component({
  selector: 'app-notabug-comments',
  templateUrl: './notabug-comments.component.html',
  styleUrls: ['./notabug-comments.component.scss'],
  providers: [ProjectService,SnackbarService]

})


export class NotabugCommentsComponent implements OnInit {

  autoSuggestion: any;
  id: string;
  result: Array<boolean> = new Array();
  activateChecked: boolean;
  togglevalue;
  comments;

  constructor(public dialogRef: MatDialogRef<NotabugCommentsComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    private projectService: ProjectService, private handler: Handler,private snackbarService: SnackbarService) {
    this.autoSuggestion = data[0];
    // this.falsePositiveChecked = data[1];
    this.id = data[1];
    this.togglevalue = data[2];
  }

  ngOnInit() {
    this.comments = this.autoSuggestion.comment;
  }

  saveAndClose() {
    this.autoSuggestion.comment = this.comments ;
    this.saveAutoSuggestion();
    this.result[0] = true;
    this.result[1] = this.activateChecked;
    this.snackbarService.openSnackBar('Deactivating Vulnerablility', '');
    this.dialogRef.close(this.result);
  }

  saveAutoSuggestion() {
    this.handler.activateLoader();
    this.projectService.updateAutoSuggestion(this.id, this.autoSuggestion).subscribe(results => {
      this.handler.hideLoader();
      if (this.handler.handle(results)) {
        return;
      }
          this.snackbarService.openSnackBar('Successfully Deactivated', '');

      this.autoSuggestion = results['data'];
    }, error => {
      this.handler.hideLoader();
      this.handler.error(error);
    });
  }

  closeWithoutSave() {
    this.result[0] = false;
    this.dialogRef.close(this.result);
  }

}
