import {
  Component,
  OnInit,
  ViewChild,
  Inject,
  EventEmitter,
  Output,
} from "@angular/core";
import { Handler } from "../components/dialogs/handler/handler";
import { MatSort } from "@angular/material/sort";

import { OrgService } from "../services/org.service";
import { DatePipe } from "@angular/common";
import { SnackbarService } from "../services/snackbar.service";
import { AutoCodeConfig } from "../models/project-autocode-config.model";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
// import { ManageSkippathDialogComponent } from '../components/dialogs/manage-skippath-dialog/manage-skippath-dialog.component';
import { ManageGlobalSkipEndpointComponent } from "../components/dialogs/manage-global-skip-endpoint/manage-global-skip-endpoint.component";
import { ProjectService } from "../services/project.service";
import { ResourceService } from "../services/resource.service";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import downloadCsv from "download-csv";
import { Subject, Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { ELASTICSEARCHVALIDATOR } from "../shared/shared.data";
@Component({
  selector: "global-skip-endpoint",
  templateUrl: "./global-skip-endpoint.component.html",
  styleUrls: ["./global-skip-endpoint.component.scss"],
  providers: [SnackbarService, ProjectService, DatePipe],
})
export class GlobalSkipEndpointComponent {
  @ViewChild(MatSort) sort: MatSort;
  @Output() emitData = new EventEmitter<string[]>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  skipEndpointList: any;
  skippedEndpointList: any=[];
  projectId: string;
  id = [];
  newEndpointskippedList;
  searchStringValidator = ELASTICSEARCHVALIDATOR;
  displayedColumns: string[] = [
    "RemoveSkipEndpoint",
    "API/Project",
    "Method",
    "Endpoint",
    "Category",
    "Since",
  ];
  selectedId = [];
  endpointID;
  NewArray = [];
  selectedRowID: any;
  fewSelectedFlag: boolean = false;
  selectAll: boolean = false;
  ats: boolean = false;
  endpointId;
  searchQuery: string = "";
  pageSize = 5;
  objEndpoint = {
    "API Name": "",
    Method: "",
    Endpoint: "",
    Category: "",
    Since: "",
  };
  newLIst: any;
  newItemVar: any;
  selectedRowIndex: number;
  subjectKeyUp: Subject<string> = new Subject<string>();

  constructor(
    private snackbarService: SnackbarService,
    public dialog: MatDialog,
    private projectService: ProjectService,
    private datepipe: DatePipe,
    private route: ActivatedRoute,
    private resourcesService: ResourceService,
    private router: Router,
    private handler: Handler
  ) {}

  getInitialData() {
    // Fetch or initialize your data here
    return [];
  }

  ngOnInit() {
    this.newEndpointskippedList = new MatTableDataSource(this.getInitialData());
    this.filteredEndpointList = this.newEndpointskippedList.data;

    this.subjectKeyUp
      .pipe(debounceTime(700), distinctUntilChanged())
      .subscribe((keyword) => {
        this.applyFilter(keyword);
      });
    this.getSkippedEndpointList();
  }

  getSkippedEndpointList() {
    // this.resultOfVul=true;
// if(this.clearFlag) this.resultOfVul=false;
// else this.resultOfVul=true;
    this.handler.activateLoader();
    this.resourcesService.skippedEdnpointList(this.projectId).subscribe(
      (results) => {
        this.handler.hideLoader();
        if(results['data'] && results['data'].length>0) {
        // this.clearFlag=false;
        // if(!this.clearFlag) this.resultOfVul=true;
        this.skippedEndpointList = results['data'];
        this.fewSelectedFlag = false;
        this.selectAll = false;
        // this.resultOfVul=true;
        this.skippedEndpointList.forEach((element) => {
          element.isCheck = false;
        });
        this.newEndpointskippedList = new MatTableDataSource(
          this.skippedEndpointList
        );
        this.newEndpointskippedList.paginator = this.paginator;
      }
      },
      (error) => {
        this.handler.hideLoader();
        this.handler.error(error);
      }
    );
  }

  openDialogSkipPath() {
    const dialogRef = this.dialog.open(ManageGlobalSkipEndpointComponent, {
      width: "1200px",
      data: [],
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.getSkippedEndpointList();
    });
  }

  onSelectRow(skippedData, event) {
    if (event.checked) {
      this.NewArray.push(skippedData);
      this.skippedEndpointList.forEach((element) => {
        if (skippedData == element.id) element.isCheck = true;
      });
      this.fewSelectedFlag = true;
      if (this.NewArray.length == this.skippedEndpointList.length) {
        this.fewSelectedFlag = false;
        this.selectAll = true;
      }
    } else {
      let index = this.NewArray.indexOf(skippedData);
      if (index !== -1) {
        this.NewArray.splice(index, 1);
      }
      this.skippedEndpointList.forEach((element) => {
        if (skippedData == element.id) element.isCheck = false;
      });
      this.fewSelectedFlag = true;
      if (this.NewArray.length == 0) {
        this.fewSelectedFlag = false;
      }
    }
  }

  removedSkippedEndPoint(id: any) {
    this.handler.activateLoader();
    this.resourcesService.removeSkippedEndpoint(this.NewArray).subscribe(
      (results) => {
        this.skippedEndpointList = results["data"];
        this.handler.hideLoader();
        this.getSkippedEndpointList();
      },
      (error) => {
        this.handler.hideLoader();
        this.handler.error(error);
      }
    );
  }

  private searchSubject = new Subject<string>();
  searchBy(event: any) {
    const key = event.keyCode;
    if (this.searchStringValidator.isValidSearchKeyCode(key)) {
      this.keyword = (event.target as HTMLInputElement).value;
      this.subjectKeyUp.next(this.keyword);
    }
  }

  clearSearch() {
    this.keyword = '';
    this.searchBy({ target: { value: '' } } as any);
  }
  clearFlag: boolean=false;

  // applyFilter(keyword: string) {
  //   this.keyword = keyword;
  //   const escapedFilterValue = this.keyword.replace(
  //     /[.*+\-?^${}()|[\]\\]/g,
  //     "\\$&"
  //   );
  //   const regex = new RegExp(escapedFilterValue, "i");

  //   this.filteredEndpointList = this.newEndpointskippedList.data.filter(
  //     (data: any) => {
  //       const matchColumns = [
  //         data["name"],
  //         data["method"],
  //         data["endpoint"],
  //         data["generatorTypes"],
  //         data["createdDate"],
  //       ];
  //       return matchColumns.some((column) => regex.test(column));
  //     }
  //   );
  //   this.newEndpointskippedList.data = this.filteredEndpointList;

  //   if (this.newEndpointskippedList.paginator) {
  //     this.newEndpointskippedList.paginator.firstPage();
  //   }
  // }

  length: any;

  applyFilter(filterValue: string) {
    // this.clearFlag=true;
    this.resultOfVul=false
    filterValue = filterValue.trim().toLowerCase();
    // Escape special characters in the filter value to prevent regex issues
    const escapedFilterValue = filterValue.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&');
    const regex = new RegExp(escapedFilterValue, 'i');
    // Create a filter predicate that applies the filter to all columns
    this.newEndpointskippedList.filterPredicate = (data: any, filter: string) => {
      const matchColumns = [
        data['name'],
        data['method'],
        data['endpoint'],
        data['generatorTypes'],
        data['createdDate']
      ];
     
      return matchColumns.some(column => regex.test(column));

    };
    this.newEndpointskippedList.filter = filterValue;
    this.length = this.newEndpointskippedList.length;
    if (this.newEndpointskippedList.paginator) {
      this.newEndpointskippedList.paginator.firstPage();
    }
    setTimeout(() => {
      this.resultOfVul=true;
    }, 1000);

  }

  resultOfVul: boolean = true;
  keyword: any = "";
  filteredEndpointList;
  private searchSubscription: Subscription;
  // applyFilter() {
  //   // if (!this.keyword) {
  //   //   this.filteredEndpointList = this.newEndpointskippedList.data; // Show all data if keyword is empty
  //   //   return;
  //   // }

  //   // this.resultOfVul = false;
  //   // Escape special characters in the filter value to prevent regex issues
  //   const escapedFilterValue = this.keyword.replace(
  //     /[.*+\-?^${}()|[\]\\]/g,
  //     "\\$&"
  //   );
  //   const regex = new RegExp(escapedFilterValue, "i");

  //   // Create a filter predicate that applies the filter to all columns
  //   this.newEndpointskippedList.filterPredicate = (
  //     data: any,
  //     filter: string
  //   ) => {
  //     const matchColumns = [
  //       data["name"],
  //       data["method"],
  //       data["endpoint"],
  //       data["generatorTypes"],
  //       data["createdDate"],
  //     ];
  //     // this.resultOfVul = true;
  //     return matchColumns.some((column) => regex.test(column));
  //   };

  //   // Apply the filter
  //   this.newEndpointskippedList.filter = this.keyword;
  //   this.filteredEndpointList = this.newEndpointskippedList.filteredData;

  //   if (this.newEndpointskippedList.paginator) {
  //     this.newEndpointskippedList.paginator.firstPage();
  //   }
  //   // this.keyword = filterValue.trim().toLowerCase();
  //   // filterValue = filterValue.trim().toLowerCase();
  //   // Escape special characters in the filter value to prevent regex issues
  //   // const escapedFilterValue = this.keyword.replace(
  //   //   /[.*+\-?^${}()|[\]\\]/g,
  //   //   "\\$&"
  //   // );
  //   // const regex = new RegExp(escapedFilterValue, "i");
  //   // // Create a filter predicate that applies the filter to all columns
  //   // this.newEndpointskippedList.filterPredicate = (
  //   //   data: any,
  //   //   filter: string
  //   // ) => {
  //   //   const matchColumns = [
  //   //     data["name"],
  //   //     data["method"],
  //   //     data["endpoint"],
  //   //     data["generatorTypes"],
  //   //     data["createdDate"],
  //   //   ];
  //   //   this.resultOfVul = true;
  //   //   return matchColumns.some((column) => regex.test(column));
  //   // };
  //   // this.newEndpointskippedList.filter = this.keyword;
  //   // if (this.newEndpointskippedList.paginator) {
  //   //   this.newEndpointskippedList.paginator.firstPage();
  //   // }
  // }

  ngOnDestroy() {
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
  }


  xlData = [];
  arrSelectedValuesEndpoint = [];
  selectAllEndPoints(event) {
    // this.selectAll = event.target.checked;

    if (event.checked) {
      this.selectAll = true;
      this.ats = true;
      // If "Select All" is checked, push all items' ids to the NewArray
      this.skippedEndpointList.forEach((element) => {
        element.isCheck = true;
      });
      this.NewArray = this.skippedEndpointList.map((item) => item.id);
    } else {
      // If "Select All" is unchecked, clear the NewArray
      this.skippedEndpointList.forEach((element) => {
        element.isCheck = false;
      });
      this.NewArray = [];
      this.ats = false;
    }
  }

  exportAsCsv() {
    this.getSkippedEndpointList();
    let API = "";
    var skippedMethod = "";
    var skippedEdnpoint = "";
    var skippedCategory = "";
    const skippedEndpointsData = [];
    var Date = "";
    this.arrSelectedValuesEndpoint;
    for (let i = 0; i < this.skippedEndpointList.length; i++) {
      this.newItemVar = this.skippedEndpointList[i];
      // for (let j = 0; j < this.newItemVar.length; j++) {
      // this.newLIst = this.newItemVar[j]
      skippedMethod = this.newItemVar.method;
      API = this.newItemVar.name;
      skippedEdnpoint = this.newItemVar.endpoint;
      skippedCategory = this.newItemVar.generatortypelabels;
      Date = this.datepipe.transform(
        this.newItemVar.modified_date,
        "mediumDate"
      );
      // }
      this.objEndpoint = {
        "API Name": API,
        Method: skippedMethod,
        Endpoint: skippedEdnpoint,
        Category: `"${skippedCategory}"`,
        Since: `"${Date}"`,
      };
      skippedEndpointsData.push(this.objEndpoint);
    }
    setTimeout(() => {
      this.handler.hideLoader();
      this.snackbarService.openSnackBar("' Successfully downloaded.", "");
    }, 1500);
    downloadCsv(skippedEndpointsData, "", "" + "Skipped_Endpoints.csv");
  }
}