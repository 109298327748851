import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";


@Injectable()
export class ApiGroups {

    constructor(private http: HttpClient) { }
    private apiGroupsCtrl = '/api/v1/api-groups'
    private projectListCtrl = 'api/v1/projects/projects-list'

    createAPIGroup(data) {
        return this.http.post(this.apiGroupsCtrl, data)
    }
    getAllProjects() {
        return this.http.get(this.projectListCtrl)
    }
    getGroups(page, pageSize, sort, sortType) {
        let params = new HttpParams();
        params = params.append('page', page);
        params = params.append('pageSize', pageSize)
        params = params.append('sort', sort)
        params = params.append('sortType', sortType)
        return this.http.get(this.apiGroupsCtrl ,{ params })
    }
    getGroupsById(id,page, pageSize, sort, sortType) {
        let params = new HttpParams();
        params = params.append('page', page);
        params = params.append('pageSize', pageSize)
        params = params.append('sort', sort)
        params = params.append('sortType', sortType)
        return this.http.get(this.apiGroupsCtrl+"/"+id ,{ params })
    }
    getGroupSearch(keyword, page, pageSize, sort, sortType) {
        let params = new HttpParams();
        params = params.append("keyword", keyword)
        params = params.append("page", page);
        params = params.append("pageSize", pageSize);
        params = params.append("sort", sort);
        params = params.append("sortTyps", sortType);
        return this.http.get(this.apiGroupsCtrl + "/search", { params })

    }
    subAPIGroups() {

    }
    getGroupById(id: string) {
        return this.http.get(this.apiGroupsCtrl + "/" + id)
    }
    update(id, data) {
        return this.http.put(this.apiGroupsCtrl + "/" + id, data)
    }
}
