import { Component, OnInit, ViewChild } from "@angular/core";
import { CHARTCONFIG } from "../charts/charts.config";
import { Handler } from "../components/dialogs/handler/handler";
import { DashboardService } from "../services/dashboard.service";
import { FormGroup, FormBuilder, FormArray } from "@angular/forms";
import { ProjectService } from "../services/project.service";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { NavigationExtras, Router } from "@angular/router";
import { OrgService } from "../services/org.service";
import { OrgUser } from "../models/org.model";
import * as XLSX from "xlsx";
import downloadCsv from "download-csv";
import * as moment from "moment";
import { SnackbarService } from "../services/snackbar.service";
import { color, graphic } from "echarts";
import { J } from "@angular/cdk/keycodes";
import { backgroundClip } from "html2canvas/dist/types/css/property-descriptors/background-clip";
import { viaProjectList, loggedInUserDetail } from "../shared/shared.data";
import { MixpanelService } from "../services/mixpanel.service";
import { DatePipe } from "@angular/common";
import { ApiGroups } from "../services/api-groups.service";

// import { PDFOptions } from '@progress/kendo-drawing/dist/es/pdf';
@Component({
  selector: "app-security-center-dashboard",
  template: "<p>{{ formattedDate }}</p>",
  templateUrl: "./security-center-dashboard.component.html",
  styleUrls: ["./security-center-dashboard.component.scss"],
  providers: [
    DashboardService,
    ProjectService,
    OrgService,
    SnackbarService,
    ApiGroups,
  ],
})
export class SecurityCenterDashboardComponent {
  formattedDate: string;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  users: any = "-";
  config = CHARTCONFIG;
  showSpinner: boolean = false;
  showData: boolean = false;
  showDataseverity: boolean = false;
  projects;
  projectsCount = 0;
  jobs = "-";
  envs = "-";
  runs = "-";
  playbooksScanned: any;
  time = "-";
  bytes = "-";
  iBots = "-";
  iTracker = "-";
  eBots = "-";
  suites = "-";
  channels = "-";
  severityCount = 0;
  severityHigh = 0;
  severityCritical = 0;
  severityMedium = 0;
  severityLow = 0;
  totalEndpoints = 0;
  // recommendations ="-";
  datePicker = { startDate: "", endDate: "" };
  selectedMonth = "";
  today = new Date();
  mm;
  yy;

  viaProjectList = viaProjectList;
  nextMonth = false;
  prevMonth = true;
  datePickerForm: FormGroup;
  PlaybookChart: any;
  ScansChart: any;
  pendingScanDataSource = null;
  dataSource = null;
  dataSourceNew= null;
  displayedColumns = ["val1", "val2", "val3"];
  displayedColumns1 = ["name", "scan", "date"];
  // 'closedVulPrevMonth', 'closedVulThisMonth'
  months = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];
  enterpriseLicence = "-";
  totalEnterpriseLicence = "-";
  freeLicence = "-";
  totalFreeLicence = "-";
  openVul = 0;
  closedVul = 0;
  openClosedVul = 0;
  openListVul = [];
  endpointCount = [];
  closedListVul = [];
  xAxisData = [];
  xAxisScanData: any[];
  scanList: any[];
  totalPlaybooks = 0;
  totalScans = 0;
  orgname: string;
  entry: OrgUser = new OrgUser();
  totalPages = 1;
  sortString: string = "createdDate";
  sortType: string = "DESC";
  currentPrevStats = [];
  constructor(
    private datePipe: DatePipe,
    private dashboardService: DashboardService,
    private formBuilder: FormBuilder,
    private snackbarService: SnackbarService,
    private handler: Handler,
    private projectService: ProjectService,
    private orgService: OrgService,
    private router: Router,
    private mixpanelService: MixpanelService,
    private apiGroups: ApiGroups
  ) {
    this.datePickerForm = this.formBuilder.group({
      startDate: [this.datePicker.startDate],
      endDate: [this.datePicker.endDate],
    });
  }
  // ExportTOExcel(){
  // TableUtil.exportTableToExcel("ExampleMaterialTable");
  // }
  loggedInUserDetail = loggedInUserDetail;
  ngOnInit() {
    this.getOrgOpenStatus();
    this.getOwaspVul();
    this.getOrgMonthlyStatus();
    this.getOrgStatus();
    // this.getRiskTrends();
    this.getPlaybooksScannedCount();
    // this.getEndpointCoverageByTier();
    this.getListOfPendingAPI();
    this.getVulnByAge();
    this.getUniqueEndpoints();
    this.getProjectCount("count-projects", "projects");
    this.getSeveritybyOrgLevel();
    this.apiGroupList();
    // this.getCurrentAndPrevMonthStats();
    this.orgname = this.loggedInUserDetail.orgName;
  }

  spDate1 = ["Dec", "Jan", "Feb", "Mar"];
  spCount1 = [];
  vulCount = [];
  VulChart = null;
  VulChart1 = null;
  endpontsTierData = [];
  // endpointCoverageByTierData: any = null
  // getEndpointCoverageByTier() {
  //   this.dashboardService.endpointCoverageByTier().subscribe(results => {
  //     this.handler.hideLoader();
  //     if (this.handler.handle(results)) {
  //       return;
  //     }
  //     this.endpointCoverageByTierData = results['data'];
  //     var data = results['data'];

  //     this.endpontsTierData.push(data['t0']);
  //     this.endpontsTierData.push(data['t1']);
  //     this.endpontsTierData.push(data['t2']);
  //     this.endpontsTierData.push(data['t3']);

  //     // this.endpointsCoverage();

  //   },
  //     error => {
  //       this.handler.hideLoader();
  //       this.handler.error(error);
  //     })
  // }

  // getOrgOpenStatus() {
  //   this.dashboardService.getOrgOpenStats().subscribe(
  //     (results) => {
  //       this.handler.hideLoader();
  //       if (this.handler.handle(results)) {
  //         return;
  //       }
  //       this.openVul = results["data"]["activeVulnerabilities"];
  //     },
  //     (error) => {
  //       this.handler.hideLoader();
  //       this.handler.error(error);
  //     }
  //   );
  // }
  getOrgOpenStatus(id?: string) {
    this.handler.activateLoader();
  
    const request = id 
      ? this.dashboardService.getOrgOpenStatsById(id) 
      : this.dashboardService.getOrgOpenStats();
  
    request.subscribe(
      (results) => {
        this.handler.hideLoader();
  
        if (this.handler.handle(results)) {
          return;
        }
  
        this.openVul = results?.["data"]?.["activeVulnerabilities"] || 0;;
      },
      (error) => {
        this.handler.hideLoader();
        this.handler.error(error);
      }
    );
  }

  uniqueEndpointsCount: any = null;
  // getUniqueEndpoints() {
  //   this.dashboardService.getUniqueEndpoints().subscribe(
  //     (results) => {
  //       this.handler.hideLoader();
  //       if (results != null) {
  //         if (this.handler.handle(results)) {
  //           return;
  //         }
  //         this.uniqueEndpointsCount = results;
  //         this.uniqueEndpointsCount = this.numFormatter(
  //           this.uniqueEndpointsCount
  //         );
  //       }
  //     },
  //     (error) => {
  //       this.handler.hideLoader();
  //       this.handler.error(error);
  //     }
  //   );
  // }
  getUniqueEndpoints(id?: string) {
    const serviceCall = id ? this.dashboardService.getUniqueEndpointsById(id) : this.dashboardService.getUniqueEndpoints();
  
    serviceCall.subscribe(
      (results) => {
        this.handler.hideLoader();
        if (results != null) {
          if (this.handler.handle(results)) {
            return;
          }
          this.uniqueEndpointsCount = results;
          this.uniqueEndpointsCount = this.numFormatter(this.uniqueEndpointsCount);
        }
      },
      (error) => {
        this.handler.hideLoader();
        this.handler.error(error);
      }
    );
  }

  getOrgStatus(id?: string) {
    this.handler.activateLoader();
  
    const request = id
      ? this.dashboardService.getOrgStatsById(id)
      : this.dashboardService.getOrgStats();
  
    request.subscribe(
      (results) => {
        this.handler.hideLoader();
  
        if (this.handler.handle(results)) {
          return;
        }
  
        this.closedVul = results["data"]["closedVulnerabilities"];
        this.totalEndpoints = this.numFormatter(results["data"]["totalEndpoints"]);
        this.totalPlaybooks = this.numFormatter(results["data"]["totalPlaybooks"]);
  
        this.spCount1.push(0);
        this.spCount1.push(this.closedVul);
        this.vulCount.push(this.openVul);
  
        this.openClosedVul = this.openVul + this.closedVul;
      },
      (error) => {
        this.handler.hideLoader();
        this.handler.error(error);
      }
    );
  }

  // getOrgStatus() {
  //   this.dashboardService.getOrgStats().subscribe(
  //     (results) => {
  //       this.handler.hideLoader();
  //       if (this.handler.handle(results)) {
  //         return;
  //       }

  //       // this.openVul = results['data']['activeVulnerabilities']
  //       this.closedVul = results["data"]["closedVulnerabilities"];
  //       this.totalEndpoints = results["data"]["totalEndpoints"];
  //       this.totalEndpoints = this.numFormatter(this.totalEndpoints);

  //       this.totalPlaybooks = results["data"]["totalPlaybooks"];
  //       this.totalPlaybooks = this.numFormatter(this.totalPlaybooks);

  //       // this.playbooksScanned = results['data']['playbooksScanned'];
  //       // this.playbooksScanned = this.numFormatter(this.playbooksScanned);

  //       this.spCount1.push(0);
  //       this.spCount1.push(this.closedVul);
  //       this.vulCount.push(this.openVul);

  //       this.openClosedVul = this.openVul + this.closedVul;
  //     },
  //     (error) => {
  //       this.handler.hideLoader();
  //       this.handler.error(error);
  //     }
  //   );
  // }

  severityStatus;
  severityData:any=[];
  owasptop10Data;
  severityData1: any = [];
  severityTotalCount=0;
  // getSeveritybyOrgLevel() {
  //   this.severityChart = null;
  //   this.dashboardService.getSeveritybyOrgLevel().subscribe(
  //     (results) => {
  //       this.handler.hideLoader();
  //       if (this.handler.handle(results)) {
  //         return;
  //       }
  //       this.severityData = results["data"];

  //       this.severityData.map((s) => {
  //         if (s.severity == "Critical") this.severityCritical = s.count;
  //         else if (s.severity == "High") this.severityHigh = s.count;
  //         else if (s.severity == "Medium") this.severityMedium = s.count;
  //         else if (s.severity == "Low") this.severityLow = s.count;
  //       });
  //       // this.severityHigh = results['data'][0].count;
  //       // this.severityCritical = results['data'][1].count;
  //       // this.severityMedium = results['data'][2].count;
  //       // this.severityLow = results['data'][3].count;
  //       this.showDataseverity = true;
  //       this.severityTotalCount =
  //         this.severityHigh +
  //         this.severityCritical +
  //         this.severityMedium +
  //         this.severityLow;

  //       if (this.severityData) {
  //         this.severityData1 = [
  //           {
  //             value: this.severityHigh,
  //             itemStyle: {
  //               color: "#ff5d02",
  //             },
  //             name: "High",
  //           },
  //           {
  //             value: this.severityCritical,
  //             color: "#dc0000",
  //             itemStyle: { color: "#dc0000" },
  //             name: "Critical",
  //           },
  //           {
  //             value: this.severityMedium,
  //             color: "#fdc500",
  //             itemStyle: {
  //               color: "#fdc500",
  //             },
  //             name: "Medium",
  //           },
  //           {
  //             value: this.severityLow,
  //             color: "#00ac46",
  //             itemStyle: { color: "#00ac46" },
  //             name: "Low",
  //           },
  //         ];
  //         this.severityData1 = this.severityData1.filter((x) => x.value > 0);
  //         this.severityChart = {
  //           tooltip: {
  //             trigger: "item",
  //             backgroundColor: "white",
  //             textStyle: {
  //               color: "#696969",
  //             },
  //           },

  //           graphic: {
  //             elements: [
  //               {
  //                 type: "text",
  //                 left: "center",
  //                 top: "middle",
  //                 z: 999,
  //                 style: {
  //                   text: this.severityTotalCount,
  //                   textAlign: "center",
  //                   fontSize: 22,
  //                   fontFamily: '"Exo 2", sans- serif',
  //                   fontWeight: 600,
  //                 },
  //               },
  //             ],
  //           },
  //           series: [
  //             {
  //               name: "Vulnerabilties",
  //               type: "pie",
  //               radius: ["50%", "70%"],
  //               avoidLabelOverlap: false,
  //               label: {
  //                 show: false,
  //                 position: "center",
  //                 color: "red",
  //               },
  //               data: this.severityData1,

  //               labelLine: {
  //                 show: false,
  //               },

  //               areaStyle: {
  //                 color: "#ff0",
  //                 opacity: 0.5,
  //               },
  //             },
  //           ],
  //         };
  //       }
  //     },
  //     (error) => {
  //       this.handler.hideLoader();
  //       this.handler.error(error);
  //     }
  //   );
  // }
  getSeveritybyOrgLevel(id?: string) {
    this.severityChart = null;
    this.severityCritical=0;
    this.severityMedium=0;
    this.severityLow=0;
    this.severityHigh =0;
    this.severityTotalCount =0;
    this.severityData=[]
    const serviceMethod = id 
      ? this.dashboardService.getSeveritybyOrgLevelById(id) 
      : this.dashboardService.getSeveritybyOrgLevel();
      
    serviceMethod.subscribe(
      (results) => {
        this.handler.hideLoader();
        if (this.handler.handle(results)) {
          return;
        }
        this.severityData = results["data"];
    if(this.severityData && this.severityData.length>0){
        this.severityData.map((s) => {
          if (s.severity == "Critical") this.severityCritical = s.count;
          else if (s.severity == "High") this.severityHigh = s.count;
          else if (s.severity == "Medium") this.severityMedium = s.count;
          else if (s.severity == "Low") this.severityLow = s.count;
        });
  
        this.showDataseverity = true;
        this.severityTotalCount =
          this.severityHigh +
          this.severityCritical +
          this.severityMedium +
          this.severityLow;

          this.severityData1 = [
            {
              value: this.severityHigh,
              itemStyle: {
                color: "#ff5d02",
              },
              name: "High",
            },
            {
              value: this.severityCritical,
              color: "#dc0000",
              itemStyle: { color: "#dc0000" },
              name: "Critical",
            },
            {
              value: this.severityMedium,
              color: "#fdc500",
              itemStyle: {
                color: "#fdc500",
              },
              name: "Medium",
            },
            {
              value: this.severityLow,
              color: "#00ac46",
              itemStyle: { color: "#00ac46" },
              name: "Low",
            },
          ];
          this.severityData1 = this.severityData1.filter((x) => x.value > 0);
          this.severityChart = {
            tooltip: {
              trigger: "item",
              backgroundColor: "white",
              textStyle: {
                color: "#696969",
              },
            },
  
            graphic: {
              elements: [
                {
                  type: "text",
                  left: "center",
                  top: "middle",
                  z: 999,
                  style: {
                    text: this.severityTotalCount,
                    textAlign: "center",
                    fontSize: 22,
                    fontFamily: '"Exo 2", sans-serif',
                    fontWeight: 600,
                  },
                },
              ],
            },
            series: [
              {
                name: "Vulnerabilities",
                type: "pie",
                radius: ["50%", "70%"],
                avoidLabelOverlap: false,
                label: {
                  show: false,
                  position: "center",
                  color: "red",
                },
                data: this.severityData1,
  
                labelLine: {
                  show: false,
                },
  
                areaStyle: {
                  color: "#ff0",
                  opacity: 0.5,
                },
              },
            ],
          };
        
      }
      },
      (error) => {
        this.handler.hideLoader();
        this.handler.error(error);
      }
    );
  }

  orgMonthlyData: any = null;
  orgMonthlyDataNew: any = null;
  orgMonthlyDataNew2: any = null;
  // getOrgMonthlyStatus() {
  //   this.dashboardService.getOrgVulbyMonthly().subscribe(
  //     (results) => {
  //       this.handler.hideLoader();
  //       this.orgMonthlyData = results["data"];
  //       var i = 0;
  //       var obj = [...this.orgMonthlyData];
  //       if (obj.length > 6) i = 1;
  //       for (i = 0; i < obj.length; i++) {
  //         var row = obj[i];
  //         let snapshotDate = row["snapshotDate"].split(".")[0];
  //         const dateObj = new Date(snapshotDate);
  //         this.formattedDate = this.datePipe.transform(dateObj, "MMM yy") || "";
  //         this.openListVul.push(row["vulnerabilityCount"]);
  //         this.endpointCount.push(row["endpointCount"]);
  //         this.xAxisData.push(this.formattedDate);
  //       }

  //       //add zero if the count is lessthan 7
  //       for (var i = this.xAxisData.length; i < 7; i++) {
  //         this.openListVul.push("");
  //         this.closedListVul.push("");
  //         this.xAxisData.push(" ");
  //       }

  //       // Monthly Activities Summary
  //       if (results["data"].length == 0) {
  //         this.orgMonthlyDataNew = "NA";
  //         this.orgMonthlyDataNew2 = "NA";
  //       } else if (results["data"].length == 1) {
  //         this.orgMonthlyDataNew = "NA";
  //         this.orgMonthlyDataNew2 = results["data"][0];
  //       } else if (results["data"].length == 2) {
  //         this.orgMonthlyDataNew = results["data"][0];
  //         this.orgMonthlyDataNew2 = results["data"][1];
  //       } else if (results["data"].length > 2) {
  //         this.orgMonthlyDataNew = results["data"][results["data"].length - 2];
  //         this.orgMonthlyDataNew2 = results["data"][results["data"].length - 1];
  //       }
  //       // this.orgMonthlyDataNew = results["data"][5];
  //       // this.orgMonthlyDataNew2 = results["data"][6];

  //       var objNew: any = {
  //         val1: "Newly Registered APIs",
  //         val2: this.orgMonthlyDataNew.projectsThisMonth,
  //         val3: this.orgMonthlyDataNew2.projectsThisMonth,
  //       };
  //       this.currentPrevStats.push(objNew);

  //       var objNew: any = {
  //         val1: "Newly Discovered Vulnerabilities",
  //         val2: this.orgMonthlyDataNew.newVulThisMonth,
  //         val3: this.orgMonthlyDataNew2.newVulThisMonth,
  //       };
  //       this.currentPrevStats.push(objNew);

  //       var objNew: any = {
  //         val1: "Newly Discovered High/Critical Vulnerabilities",
  //         val2: this.orgMonthlyDataNew.criHighThisMonth,
  //         val3: this.orgMonthlyDataNew2.criHighThisMonth,
  //       };
  //       this.currentPrevStats.push(objNew);

  //       var objNew: any = {
  //         val1: "Vulnerabilities Closed",
  //         val2: this.orgMonthlyDataNew.closedVulThisMonth,
  //         val3: this.orgMonthlyDataNew2.closedVulThisMonth,
  //       };
  //       this.currentPrevStats.push(objNew);

  //       this.dataSourceNew = new MatTableDataSource(this.currentPrevStats);
  //       //Monthly Activities Summary end

  //       this.orgMonthlyData.map((v) => {
  //         this.criticalVul.push(v.criticalCount);
  //         this.highVul.push(v.highCount);
  //         this.mediumVul.push(v.mediumCount);
  //         this.lowVul.push(v.lowCount);
  //       });
  //       this.getVulnerabilityRiskTrend();
  //       this.buildTrendChart();
  //     },
  //     (error) => {
  //       this.handler.hideLoader();
  //       this.handler.error(error);
  //     }
  //   );
  // }

  // getProjectCount(stat: string, _var: string) {
  //   this.handler.activateLoader();
  //   this.dashboardService.getStat(stat).subscribe(
  //     (results) => {
  //       this.handler.hideLoader();

  //       if (results["errors"]) {
  //         // TODO - handle errors
  //         return;
  //       }
  //       var count = results["data"];
  //       if (_var === "projects") this.projectsCount = count;

  //       // else
  //       //   if (_var === 'tests') {
  //       //     this.allScanCount = 0;
  //       //     this.tests = "" + this.numFormatter(this.allScanCount);
  //       //   }

  //       this.showData = true;
  //     },

  //     (error) => {
  //       console.log("Unable to fetch stat");
  //       this.handler.hideLoader();
  //       this.handler.error(error);
  //     }
  //   );
  // }

  clearOrgMonthlyStatus(){
    this.severityTrendChart = null;
    this.currentPrevStats = [];
    this.formattedDate = "";
    this.openListVul = [];
    this.criticalVul = [];
    this.highVul = [];
    this.mediumVul = [];
    this.lowVul = [];
    this.endpointCount = [];
    this.orgMonthlyData = null;
    this.xAxisData = [];
  
  }
  getOrgMonthlyStatus(id?: string) {
    // Activate loader and reset variables
    this.handler.activateLoader();
    this.clearOrgMonthlyStatus()
    // Determine the API call
    const request = id 
      ? this.dashboardService.getOrgVulbyMonthlyById(id) 
      : this.dashboardService.getOrgVulbyMonthly();
  
    request.subscribe(
      (results) => {
        this.handler.hideLoader();
  
        // Reset arrays
        this.currentPrevStats = [];
        this.orgMonthlyData = results["data"] || [];
        // Clear all arrays if orgMonthlyData is empty or null
        if (this.orgMonthlyData?.length === 0 || !this.orgMonthlyData) {
          this.clearOrgMonthlyStatus();
          this.orgMonthlyData = [];
          return;
        }
  else{
        // Process data if orgMonthlyData has values
        let i = 0;
        let obj = [...this.orgMonthlyData];
        if (obj.length > 6) i = 1;
        for (i = 0; i < obj.length; i++) {
          let row = obj[i];
          let snapshotDate = row["snapshotDate"].split(".")[0];
          const dateObj = new Date(snapshotDate);
          this.formattedDate = this.datePipe.transform(dateObj, "MMM yy") || "";
          this.openListVul.push(row["vulnerabilityCount"]);
          this.endpointCount.push(row["endpointCount"]);
          this.xAxisData.push(this.formattedDate);
        }
  
        // Add empty entries if the count is less than 7
        for (let i = this.xAxisData.length; i < 7; i++) {
          this.openListVul.push("");
          this.closedListVul.push("");
          this.xAxisData.push(" ");
        }
  
        // Handle Monthly Activities Summary
        if (!results["data"] || results["data"].length === 0) {
          this.orgMonthlyDataNew = "NA";
          this.orgMonthlyDataNew2 = "NA";
        } else if (results["data"].length === 1) {
          this.orgMonthlyDataNew = "NA";
          this.orgMonthlyDataNew2 = results["data"][0];
        } else if (results["data"].length === 2) {
          this.orgMonthlyDataNew = results["data"][0];
          this.orgMonthlyDataNew2 = results["data"][1];
        } else if (results["data"].length > 2) {
          this.orgMonthlyDataNew = results["data"][results["data"].length - 2];
          this.orgMonthlyDataNew2 = results["data"][results["data"].length - 1];
        }
  
        // Push data into currentPrevStats array
        this.currentPrevStats.push({
          val1: "Newly Registered APIs",
          val2: this.orgMonthlyDataNew?.projectsThisMonth || "NA",
          val3: this.orgMonthlyDataNew2?.projectsThisMonth || "NA",
        });
  
        this.currentPrevStats.push({
          val1: "Newly Discovered Vulnerabilities",
          val2: this.orgMonthlyDataNew?.newVulThisMonth || "NA",
          val3: this.orgMonthlyDataNew2?.newVulThisMonth || "NA",
        });
  
        this.currentPrevStats.push({
          val1: "Newly Discovered High/Critical Vulnerabilities",
          val2: this.orgMonthlyDataNew?.criHighThisMonth || "NA",
          val3: this.orgMonthlyDataNew2?.criHighThisMonth || "NA",
        });
  
        this.currentPrevStats.push({
          val1: "Vulnerabilities Closed",
          val2: this.orgMonthlyDataNew?.closedVulThisMonth || "NA",
          val3: this.orgMonthlyDataNew2?.closedVulThisMonth || "NA",
        });
  
        this.dataSourceNew = new MatTableDataSource(this.currentPrevStats);
  
        // Map vulnerability data to severity arrays
        if (Array.isArray(this.orgMonthlyData)) {
          this.orgMonthlyData.map((v) => {
            this.criticalVul.push(v.criticalCount);
            this.highVul.push(v.highCount);
            this.mediumVul.push(v.mediumCount);
            this.lowVul.push(v.lowCount);
          });
        }
  
        // Generate the trend charts and vulnerability risk trend
        this.getVulnerabilityRiskTrend();
        this.buildTrendChart();
      }
      },
      (error) => {
        this.handler.hideLoader();
        this.handler.error(error);
      }
    );
  }
  // getOrgMonthlyStatus(id?: string) {
  //   this.handler.activateLoader();  
  //  this.severityTrendChart=null;
  //   this.currentPrevStats=[];
  //   this.formattedDate = "";
  //   this.openListVul=[];
  //   this.endpointCount=[];this.orgMonthlyData=null;
  //   this.xAxisData=[];
  //   const request = id 
  //     ? this.dashboardService.getOrgVulbyMonthlyById(id) 
  //     : this.dashboardService.getOrgVulbyMonthly();
  
  //   request.subscribe(
  //     (results) => {
  //       this.handler.hideLoader();
  //       this.currentPrevStats=[]
  //       this.orgMonthlyData = results["data"];
  //       if(this.orgMonthlyData==null)  this.orgMonthlyData=[]
  //       if(this.orgMonthlyData && this.orgMonthlyData.length>0){
  //       let i = 0;
  //       let obj = [...this.orgMonthlyData];
  //       if (obj.length > 6) i = 1;
  //       for (i = 0; i < obj.length; i++) {
  //         let row = obj[i];
  //         let snapshotDate = row["snapshotDate"].split(".")[0];
  //         const dateObj = new Date(snapshotDate);
  //         this.formattedDate = this.datePipe.transform(dateObj, "MMM yy") || "";
  //         this.openListVul.push(row["vulnerabilityCount"]);
  //         this.endpointCount.push(row["endpointCount"]);
  //         this.xAxisData.push(this.formattedDate);
  //       }
  
  //       // Add zero if the count is less than 7
  //       for (let i = this.xAxisData.length; i < 7; i++) {
  //         this.openListVul.push("");
  //         this.closedListVul.push("");
  //         this.xAxisData.push(" ");
  //       }
  //     }
  
  //       // Monthly Activities Summary
  //       if (!results["data"] || results["data"].length === 0) {
  //         this.orgMonthlyDataNew = "NA";
  //         this.orgMonthlyDataNew2 = "NA";
  //       } else if (results["data"].length === 1) {
  //         this.orgMonthlyDataNew = "NA";
  //         this.orgMonthlyDataNew2 = results["data"][0];
  //       } else if (results["data"].length === 2) {
  //         this.orgMonthlyDataNew = results["data"][0];
  //         this.orgMonthlyDataNew2 = results["data"][1];
  //       } else if (results["data"].length > 2) {
  //         this.orgMonthlyDataNew = results["data"][results["data"].length - 2];
  //         this.orgMonthlyDataNew2 = results["data"][results["data"].length - 1];
  //       }
  
  //       // Push data into currentPrevStats array
  //       this.currentPrevStats.push({
  //         val1: "Newly Registered APIs",
  //         val2: this.orgMonthlyDataNew.projectsThisMonth,
  //         val3: this.orgMonthlyDataNew2.projectsThisMonth,
  //       });
  
  //       this.currentPrevStats.push({
  //         val1: "Newly Discovered Vulnerabilities",
  //         val2: this.orgMonthlyDataNew.newVulThisMonth,
  //         val3: this.orgMonthlyDataNew2.newVulThisMonth,
  //       });
  
  //       this.currentPrevStats.push({
  //         val1: "Newly Discovered High/Critical Vulnerabilities",
  //         val2: this.orgMonthlyDataNew.criHighThisMonth,
  //         val3: this.orgMonthlyDataNew2.criHighThisMonth,
  //       });
  
  //       this.currentPrevStats.push({
  //         val1: "Vulnerabilities Closed",
  //         val2: this.orgMonthlyDataNew.closedVulThisMonth,
  //         val3: this.orgMonthlyDataNew2.closedVulThisMonth,
  //       });
  
  //       this.dataSourceNew = new MatTableDataSource(this.currentPrevStats);
  
  //       // Map vulnerability data to arrays
  //       if (this.orgMonthlyData && Array.isArray(this.orgMonthlyData)) {
  //         this.orgMonthlyData.map((v) => {
  //           this.criticalVul.push(v.criticalCount);
  //           this.highVul.push(v.highCount);
  //           this.mediumVul.push(v.mediumCount);
  //           this.lowVul.push(v.lowCount);
  //         });
  //       }
  //       this.getVulnerabilityRiskTrend();
  //       this.buildTrendChart();
  //     },
  //     (error) => {
  //       this.handler.hideLoader();
  //       this.handler.error(error);
  //     }
  //   );
  // }

  
  allScanCount = 0;
  numFormatter(num) {
    if (num > 999 && num < 1000000) {
      return (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
    } else if (num >= 1000000) {
      return (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
    } else if (num <= 999) {
      return num; // if value < 1000, nothing to do
    }
  }

  severityChart: any=null;
  riskChart: any;
  vulRiskTrend: any=null;
  riskDate;
  risksdata;
  riskCount;
  riskseverity;
  // severityMedium1
  severityHigh1;
  severityMedium1;
  severityCritical1;
  severityLow1;
  severityTrendChart:any=null;
  riskTrendsData: any = null;
  highVul = [];
  criticalVul = [];
  mediumVul = [];
  lowVul = [];
  monthsVul: any = null;
  buildTrendChart() {
    this.severityTrendChart = {
      // title: {
      //   text: 'Stacked Line'
      // },
      tooltip: {
        trigger: "axis",
        backgroundColor: "white",
        textStyle: {
          color: "#696969",
        },
      },
      legend: {
        data: ["Critical", "High", "Medium", "Low"],
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      },
      // toolbox: {
      //   feature: {
      //     saveAsImage: {}
      //   }
      // },
      xAxis: {
        type: "category",
        boundaryGap: false,
        data: this.xAxisData,
      },
      yAxis: {
        type: "value",
      },
      series: [
        {
          name: "Low",
          type: "line",
          color: "#00ac46",
          data: this.lowVul,
        },
        {
          name: "Medium",
          type: "line",
          color: "#fdc500",
          data: this.mediumVul,
        },
        {
          name: "Critical",
          type: "line",
          color: "#dc0000",
          data: this.criticalVul,
        },
        {
          name: "High",
          type: "line",
          color: "#ff5d02",
          data: this.highVul,
        },
      ],
    };
  }

  getRiskTrends() {
    this.monthsVul = [];
    this.dashboardService.getRiskMonthly().subscribe(
      (result) => {
        this.handler.hideLoader();
        this.riskTrendsData = result["data"];
        this.riskTrendsData.map((v) => {
          // if (v.severity == "Critical") {
          this.criticalVul.push(v.criticalCount);
          // }
          // if (v.severity == "High") {
          this.highVul.push(v.highCount);
          // }
          // if (v.severity == "Medium") {
          this.mediumVul.push(v.mediumCount);
          // }
          // if (v.severity == "Low") {
          this.lowVul.push(v.lowCount);
          // }
          this.monthsVul.push(v.month);
        });
        //remove duplicates from month array
        this.monthsVul = [...new Set(this.monthsVul)];
        // this.buildTrendChart();
      },
      (error) => {
        this.handler.hideLoader();
        this.handler.error(error);
      }
    );
  }

  getVulnerabilityRiskTrend() {
    this.vulRiskTrend = {
      tooltip: {
        trigger: "axis",
        backgroundColor: "white",
        textStyle: {
          color: "#696969",
        },
      },
      legend: {
        data: ["Open Vulnerabilities", "Endpoints"],
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      },
      xAxis: {
        type: "category",
        boundaryGap: false,
        data: this.xAxisData,
      },
      yAxis: {
        type: "value",
      },
      series: [
        {
          data: this.endpointCount,
          type: "line",
          name: "Endpoints",
          color: "#2196F3",
          //stack: 'Total',
          smooth: true,
        },
        {
          data: this.openListVul,
          type: "line",
          name: "Open Vulnerabilities",
          color: "#dc0000",
          //stack: 'Total',
          smooth: true,
        },
      ],
    };
  }
  OwaspCoverageGraph: any=null;
  OwaspCoverageData: any=null;
  OwaspCoverageGraphData = [];
  // getOwaspVul() {
  //   this.OwaspCoverageGraphData = [];
  //   this.dashboardService.getOwaspVulnerabilities().subscribe(
  //     (result) => {
  //       this.handler.hideLoader();

  //       this.OwaspCoverageData = result["data"];

  //       this.OwaspCoverageData.map((d) => {
  //         var obj = {
  //           name: d.category,
  //           value: d.openVul,
  //         };
  //         this.OwaspCoverageGraphData.push(obj);
  //       });
  //       this.getOwaspAPIBreakdown();
  //     },
  //     (error) => {
  //       this.handler.hideLoader();
  //       this.handler.error(error);
  //     }
  //   );
  // }

  getOwaspVul(id?: string) {
    this.OwaspCoverageData = null;
    this.OwaspCoverageGraphData=[]
    // Determine the service method to call based on the presence of id
    const serviceCall = id ? this.dashboardService.getOwaspVulnerabilitiesById(id) : this.dashboardService.getOwaspVulnerabilities();
  
    serviceCall.subscribe(
      (result) => {
        this.handler.hideLoader();
        
        this.OwaspCoverageData = result["data"];
  
        this.OwaspCoverageData.forEach((d) => {
          const obj = {
            name: d.category,
            value: d.openVul,
          };
          this.OwaspCoverageGraphData.push(obj);
        });

        this.getOwaspAPIBreakdown();
      },
      (error) => {
        this.handler.hideLoader();
        this.handler.error(error);
      }
    );
  }

  getOwaspAPIBreakdown() {
    this.OwaspCoverageGraph = {
      tooltip: {
        position: "top",
        backgroundColor: "white",
        textStyle: {
          color: "#696969",
        },
      },
      series: [
        {
          name: "",
          type: "pie",
          radius: ["50%", "70%"],
          data: this.OwaspCoverageGraphData,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    };
  }
  vulAgeData: any = null;
  criticalVul30_60 = [];
  criticalVul30 = [];
  criticalVul60_90 = [];
  criticalVul90_above = [];

  mediumVul30_60 = [];
  mediumVul30 = [];
  mediumVul60_90 = [];
  mediumVul90_above = [];

  highVul30_60 = [];
  highVul30 = [];
  highVul60_90 = [];
  highVul90_above = [];

  lowVul30_60 = [];
  lowVul30 = [];
  lowVul60_90 = [];
  lowVul90_above = [];

  highVul12 = [];
  mediumVul12 = [];
  lowVul12 = [];
  less30days = [];
  between30to60days = [];
  between60to90days = [];


  getVulnByAge(id?: string) {
    this.vulAgeData=null;  
    this.clearVulnData();
    const serviceCall = id ? this.dashboardService.getVulbyAgeById(id) : this.dashboardService.getVulbyAge();
    
    serviceCall.subscribe(
      (result) => {
        this.handler.hideLoader();
        this.vulAgeData = result["data"];
         // If vulAgeData is null or empty
       if (!this.vulAgeData || this.vulAgeData.length === 0 ) {
        this.vulAgeData=[];
        this.clearVulnData(); // Clear data if the result is empty
        return;
      }
      else { this.vulAgeData.forEach((v) => {
          switch (v.severity) {
            case "Critical":
              if (v.between_30_to_60_days) this.criticalVul30_60.push(v.between_30_to_60_days);
              if (v.lessThan_30_days) this.criticalVul30.push(v.lessThan_30_days);
              if (v.between_60_to_90_days) this.criticalVul60_90.push(v.between_60_to_90_days);
              if (v["90_days_and_above"]) this.criticalVul90_above.push(v["90_days_and_above"]);
              break;
            case "High":
              if (v.between_30_to_60_days) this.highVul30_60.push(v.between_30_to_60_days);
              if (v.between_60_to_90_days) this.highVul60_90.push(v.between_60_to_90_days);
              if (v.lessThan_30_days) this.highVul30.push(v.lessThan_30_days);
              if (v["90_days_and_above"]) this.highVul90_above.push(v["90_days_and_above"]);
              break;
            case "Medium":
              if (v.between_30_to_60_days) this.mediumVul30_60.push(v.between_30_to_60_days);
              if (v.between_60_to_90_days) this.mediumVul60_90.push(v.between_60_to_90_days);
              if (v.lessThan_30_days) this.mediumVul30.push(v.lessThan_30_days);
              if (v["90_days_and_above"]) this.mediumVul90_above.push(v["90_days_and_above"]);
              break;
            case "Low":
              if (v.between_30_to_60_days) this.lowVul30_60.push(v.between_30_to_60_days);
              if (v.between_60_to_90_days) this.lowVul60_90.push(v.between_60_to_90_days);
              if (v.lessThan_30_days) this.lowVul30.push(v.lessThan_30_days);
              if (v["90_days_and_above"]) this.lowVul90_above.push(v["90_days_and_above"]);
              break;
            default:
              break;
          }
          this.getAgeMatrix(); // Ensure this is intended to be called for each item in vulAgeData
        });
      }
      },
      (error) => {
        this.handler.hideLoader();
        this.handler.error(error);
      }
    );
  }

// Custom method to clear arrays
clearVulnData() {
     // Initialize or reset all vulnerability arrays
    //  this.agematrix=null;
     this.criticalVul30_60 = [];
     this.criticalVul30 = [];
     this.criticalVul60_90 = [];
     this.criticalVul90_above = [];
     this.highVul30_60 = [];
     this.highVul30 = [];
     this.highVul60_90 = [];
     this.highVul90_above = [];
     this.mediumVul30_60 = [];
     this.mediumVul30 = [];
     this.mediumVul60_90 = [];
     this.mediumVul90_above = [];
     this.lowVul30_60 = [];
     this.lowVul30 = [];
     this.lowVul60_90 = [];
     this.lowVul90_above = [];
}

  agematrix: any=null;
  getAgeMatrix() {
    // prettier-ignore
    const severity = [
      'Critical', 'High', 'Medium', 'Low'
    ];

    // prettier-ignore
    const days = [
      '<30Days', '30-60Days', '60-90Days', '>90Days'
    ];
    // prettier-ignore

    const data = [
      [0, 0, this.criticalVul30, {
        itemStyle: { backgroundColor: '#49cc90 !important' }
      }], [0, 1, this.highVul30]
      , [0, 2, this.mediumVul30], [0, 3, this.lowVul30],
      [1, 0, this.criticalVul30_60], [1, 1, this.highVul30_60], [1, 2, this.mediumVul30_60], [1, 3, this.lowVul30_60],
      [2, 0, this.criticalVul60_90], [2, 1, this.highVul60_90], [2, 2, this.mediumVul60_90], [2, 3, this.lowVul60_90],
      [3, 0, this.criticalVul90_above], [3, 1, this.highVul90_above], [3, 2, this.mediumVul90_above], [3, 3, this.lowVul90_above]
    ]
      .map(function (item) {
        return [item[1], item[0], item[2] || '-'];
      });

    this.agematrix = {
      tooltip: {
        position: "top",
        backgroundColor: "white",
        textStyle: {
          color: "#696969",
        },
      },
      grid: {
        height: "50%",
        top: "10%",
        left: "15%",
      },
      xAxis: {
        type: "category",
        data: severity,
        splitArea: {
          show: true,
        },
      },
      yAxis: {
        type: "category",
        data: days,
        splitArea: {
          show: true,
        },
      },
      visualMap: {
        min: 0,
        max: 100,
        calculable: true,
        orient: "horizontal",
        left: "center",
        bottom: "15%",
        inRange: {
          itemStyle: {
            color: "#ff5d02",
          },
          color: ["#dcdc43", "#EA7843", "#cf0a0a"],
          // color: ['#10ea69', '#EA7843', '#FF0000']
          // color: ['#1df301', '#FF7200', '#ff4501']

          // color: ['green', 'orange', 'red'] //From smaller to bigger value ->
        },
      },

      series: [
        {
          name: "",
          type: "heatmap",
          data: data,
          label: {
            show: true,
          },

          emphasis: {
            // itemStyle: {
            //   shadowBlur: 10,
            //   // shadowColor: 'red'
            // }
          },
        },
      ],
    };
  }

  getPlaybooksScannedCount(id?: string) {
    // Determine the appropriate service call based on the presence of id
    const serviceCall = id ? this.dashboardService.getPlaybookScannedCountById(id) : this.dashboardService.getPlaybookScannedCount();
  
    serviceCall.subscribe(
      (results) => {
        this.handler.hideLoader();
        if (this.handler.handle(results)) {
          return;
        }
  
        this.playbooksScanned = results;
        this.playbooksScanned = this.numFormatter(this.playbooksScanned);
      },
      (error) => {
        this.handler.hideLoader();
        this.handler.error(error);
      }
    );
  }

  showVul() {
    this.viaProjectList["route"] = "";
    this.router.navigate(["/app/projects/vulnerabilities"]);
  }

  showApis() {
    this.viaProjectList["route"] = "";
    this.router.navigate(["/app/projects"]);
  }

  showVulBySeverity(severity) {
    // console.log(severity)
    // this.viaProjectList['route'] = "Project-list";

    const navigationExtras: NavigationExtras = {
      queryParams: { severity: severity },
    };
    this.router.navigate(["/app/projects/vulnerabilities"], navigationExtras);
  }

  pageIndex = 0;
  changePostman(evt) {
    this.page = evt["pageIndex"];
    this.pageIndex = this.page;

    this.pageSize = evt.pageSize;

    //check if all selected or not

    let endIndex: number = 0;
    let startIndexOfPage: number = 0;
    let totalElements = 0;
    let totalSelected = 0;

    // First check whether data source length is greater than current page index multiply by page size.
    // If yes then endIdex will be current page index multiply by page size.
    // If not then select the remaining elements in current page only.
    if (
      this.pendingScanDataSource.data.length >
      (this.pendingScanDataSource.paginator.pageIndex + 1) *
        this.pendingScanDataSource.paginator.pageSize
    ) {
      endIndex =
        (this.pendingScanDataSource.paginator.pageIndex + 1) *
        this.pendingScanDataSource.paginator.pageSize;
      startIndexOfPage =
        endIndex - this.pendingScanDataSource.paginator.pageSize;
    } else {
      // tslint:disable-next-line:max-line-length
      endIndex = this.pendingScanDataSource.data.length;
      startIndexOfPage =
        this.pendingScanDataSource.paginator.pageIndex *
        this.pendingScanDataSource.paginator.pageSize;
    }

    for (var i = startIndexOfPage; i < endIndex; i++) {
      if (
        !this.pendingScanDataSource.data[i] &&
        !this.pendingScanDataSource.data[i].isDisabled
      )
        totalElements++;

      if (this.pendingScanDataSource.data[i].isSelected) totalSelected++;
    }
  }

  listOfPendingAPI: any = [];
  page = 0;
  pageSize = 10;
  hidePaginator: boolean;
  showError: boolean = false;
  
  getListOfPendingAPI(id?: string) {
    this.listOfPendingAPI=[];
    // Determine which service method to call based on the presence of id
    const serviceCall = id ? this.dashboardService.getListOfPendingAPIById(id) : this.dashboardService.getListOfPendingAPI();
  
    serviceCall.subscribe(
      (results) => {
        this.handler.hideLoader();
        if (this.handler.handle(results)) {
          return;
        }
  
        this.listOfPendingAPI = results["data"];
        this.pendingScanDataSource = new MatTableDataSource(this.listOfPendingAPI);
        this.pendingScanDataSource.paginator = this.paginator;
  
        // Check if the total number of items is less than or equal to the page size
        this.hidePaginator = this.listOfPendingAPI && this.listOfPendingAPI.length <= this.pageSize;
  
        // Show error if the list is empty or null
        this.showError = !this.listOfPendingAPI || this.listOfPendingAPI.length < 1;
  
        // Adding filter predicate to data source
        this.pendingScanDataSource.filterPredicate = (data, filter: string): boolean => {
          return data.apiName.toLowerCase().includes(filter);
        };
      },
      (error) => {
        this.handler.hideLoader();
        this.handler.error(error);
      }
    );
  }

  arrSelectedValues = [];
  headers = {};
  ExcelData = [];
  downloadExcelData() {
    this.ExcelData = [];
    this.arrSelectedValues = [];
    this.headers = {};
    this.headers["API"] = "";
    this.headers["Profile"] = "";
    this.headers["Last Scan Date"] = "";
    this.ExcelData = this.listOfPendingAPI;
    if (this.ExcelData && this.ExcelData.length > 0) {
      for (let i = 0; i < this.ExcelData.length; i++) {
        this.ExcelData[i]["API"] = this.ExcelData[i]["projectName"];
        this.ExcelData[i]["Profile"] = this.ExcelData[i]["jobName"];
        if (this.ExcelData[i].createdDate) {
          var createdDate = "";
          createdDate = moment(this.ExcelData[i].createdDate).format(
            "MMM DD, YYYY"
          );
          this.ExcelData[i]["Last Scan Date"] = createdDate;
        }
        const element = this.ExcelData[i];
        const obj = JSON.parse(JSON.stringify(this.headers));
        Object.keys(obj).forEach(function (key) {
          Object.keys(element).forEach(function (key2) {
            if (key.toLowerCase() == key2.toLowerCase()) {
              obj[key] = element[key2];
            }
          });
        });
        this.arrSelectedValues.push(obj);
        //Sort by Category, and then Severity
      }

      // this.arrSelectedValues.sort(function (a: any, b: any) {
      //   const aa = a['Severity'].toString().split(/(\d+)/);
      //   const bb = b['Severity'].toString().split(/(\d+)/);
      //   const aaname = a['Category'].toString().split(/(\d+)/);
      //   const bbname = b['Category'].toString().split(/(\d+)/);

      //   for (let x = 0; x < Math.max(aa.length, bb.length); x++) {
      //     if (aa[x] != bb[x]) {
      //       const cmp1 = (isNaN(parseInt(aa[x], 10))) ? aa[x] : parseInt(aa[x], 10);
      //       const cmp2 = (isNaN(parseInt(bb[x], 10))) ? bb[x] : parseInt(bb[x], 10);
      //       if (cmp1 == undefined || cmp2 == undefined)
      //         return aa.length - bb.length;
      //       else
      //         return (cmp1 < cmp2) ? -1 : 1;
      //     }
      //   }

      //   for (let x = 0; x < Math.max(aaname.length, bbname.length); x++) {
      //     if (aaname[x] != bbname[x]) {
      //       const cmp1name = (isNaN(parseInt(aaname[x], 10))) ? aaname[x] : parseInt(aaname[x], 10);
      //       const cmp2name = (isNaN(parseInt(bbname[x], 10))) ? bbname[x] : parseInt(bbname[x], 10);
      //       if (cmp1name == undefined || cmp2name == undefined)
      //         return aaname.length - bbname.length;
      //       else
      //         return (cmp1name < cmp2name) ? -1 : 1;
      //     }
      //   }
      //   return 0;
      // });

      let objectMaxLength = [];
      for (let i = 0; i < this.arrSelectedValues.length; i++) {
        let value = <any>Object.values(this.arrSelectedValues[i]);
        for (let j = 0; j < value.length; j++) {
          if (typeof value[j] == "number") {
            objectMaxLength[j] = 14;
          } else {
            if (value[j] && value[j].length) {
              objectMaxLength[j] =
                objectMaxLength[j] >= value[j].length
                  ? objectMaxLength[j]
                  : value[j].length;
            }
          }
        }
      }

      var wscols = [
        { wch: objectMaxLength[0] },
        { wch: (objectMaxLength[1] = "12") }, // first column
        { wch: (objectMaxLength[2] = "10") }, // second column
        { wch: (objectMaxLength[3] = "25") },
      ];
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(
        this.arrSelectedValues
      );
      worksheet["!cols"] = wscols;
      const workBook: XLSX.WorkBook = {
        Sheets: { data: worksheet },
        SheetNames: ["data"],
      };
      var fileName;
      if (this.orgname) fileName = this.orgname + "_ListofUnscannedAPIs";
      else fileName = "ListofUnscannedAPIs";
      let xlFileName = fileName + ".csv";
      XLSX.writeFile(workBook, xlFileName, { bookType: "csv", type: "buffer" }); // initiate a file download in browser
    }
  }

  getProjectCount(stat: string, _var: string, id?: string) {
    this.handler.activateLoader();
  
    const request = id 
      ? this.dashboardService.getStatById(stat, id) 
      : this.dashboardService.getStat(stat);
  
    request.subscribe(
      (results) => {
        this.handler.hideLoader();
  
        if (results["errors"]) {
          // TODO - handle errors
          return;
        }
  
        const count = results["data"];
        if (_var === "projects") {
          this.projectsCount = count;
        }
  
        this.showData = true;
      },
      (error) => {
        this.handler.hideLoader();
        this.handler.error(error);
      }
    );
  }

  groupApiId: any = "all";
  selectionGroupApiChange(event:any) {
   let selectedGroupApiId=event.value;
   if(selectedGroupApiId!="all"){
    this.getOrgOpenStatus(selectedGroupApiId);
    this.getOwaspVul(selectedGroupApiId);
    this.getOrgMonthlyStatus(selectedGroupApiId);
    this.getOrgStatus(selectedGroupApiId);
    this.getPlaybooksScannedCount(selectedGroupApiId);
    this.getListOfPendingAPI(selectedGroupApiId);
    this.getVulnByAge(selectedGroupApiId);
    this.getUniqueEndpoints(selectedGroupApiId);
    this.getProjectCount("count-projects", "projects",selectedGroupApiId);
    this.getSeveritybyOrgLevel(selectedGroupApiId);
   }else{
    this.getOrgOpenStatus();
    this.getOwaspVul();
    this.getOrgStatus();
    this.getVulnByAge();
    this.getUniqueEndpoints();
    this.getProjectCount("count-projects", "projects");
    this.getPlaybooksScannedCount();
    this.getOrgMonthlyStatus();
    this.getListOfPendingAPI();
    this.getSeveritybyOrgLevel();
   }
  }

  groupListData: any = [];
  length: any = 0;
  apiGroupList() {
    let pageSize=100;
    this.handler.activateLoader();
    this.apiGroups
      .getGroups(this.page, pageSize, this.sortString, this.sortType)
      .subscribe(
        (result) => {
          this.handler.hideLoader();
          if (this.handler.handle(result)) {
            return;
          }
          if (result["data"]?.content?.length) 
          this.groupListData = result["data"].content;
         
        },
        (error) => {
          this.handler.hideLoader();
          this.handler.error(error);
        }
      );
    // this.searchGroups()
  }
}

// export interface Elm {
//   name: string;
//   scan: string;
//   date: string;
// }
