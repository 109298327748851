import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class ActivityService {
  private serviceUrl = 'api/v1/events';
  //private serviceUrl = '/api/v1/events/register';
  constructor(private http: HttpClient) { }

  /**
   * Get observable from endpoint
   */
  get(projectId,page, pageSize,q, sort, sortType) {
    let params = new HttpParams();

    if(q!=null)params = params.append('q', q);
    params = params.append('page', page);
    params = params.append('pageSize', pageSize);
    params = params.append('sort', sort);
    params = params.append('sortType', sortType);
    return this.http.get(this.serviceUrl + "/projevents/"+projectId, {params});
  }

  getById(id: string){
    return this.http.get(this.serviceUrl + "/orgevents/" + id)
  }

  getEventStatus(id,eventType){
      return this.http.get(this.serviceUrl + "/project/" + id + "/" + eventType)
  }
}
