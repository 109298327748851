import { BackButtonDirective } from './shared/backButton.directive';
import {
    NgModule,
    ApplicationRef,
    CUSTOM_ELEMENTS_SCHEMA
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { TimeAgoPipe } from 'time-ago-pipe';
import { AceEditorModule } from 'ng2-ace-editor';
import { DatePipe } from '@angular/common';
// import { Ng2OdometerModule } from "ng2-odometer";
// import { ChartsModule } from "ng2-charts";
// import { NgxShimmerLoadingModule } from 'ngx-shimmer-loading';
// import { Ng2SearchPipeModule } from "ng2-search-filter";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// Layout
import { LayoutComponent } from './layout/layout.component';
import { PreloaderDirective } from './layout/preloader.directive';
// Header
import { AppHeaderComponent } from './layout/header/header.component';
// Footer
import { AppFooterComponent } from './layout/footer/footer.component';
// Pages
// Sub modules
import { LayoutModule } from './layout/layout.module';
import { SharedModule } from './shared/shared.module';
// hmr
// import { removeNgStyles, createNewHosts } from '@angularclass/hmr';
import { ProjectsListComponent } from './components/projects/projects-list/projects-list.component';
import { ProjectsNewComponent } from './components/projects/projects-new/projects-new.component';
// import { ProjectsEditComponent } from "./components/projects/projects-edit/projects-edit.component";
import { RunListComponent } from './components/run-list/run-list.component';
import { RunDetailComponent } from './components/run-detail/run-detail.component';
import { DashboardService } from './services/dashboard.service';
import { TestSuiteService } from './services/test-suite.service';
import { AccountService } from './services/account.service';
import { NotificationListComponent } from './components/notify/notification-list/notification-list.component';
// import { NotificationEditComponent } from "./components/notify/notification-edit/notification-edit.component";
// import { NotificationNewComponent } from "./components/notify/notification-new/notification-new.component";
import { MsgDialogComponent } from './components/dialogs/msg-dialog/msg-dialog.component';
import { ErrorDialogComponent } from './components/dialogs/error-dialog/error-dialog.component';
import { ResponseDialogComponent } from './components/dialogs/response-dialog/response-dialog.component';
import { Handler } from './components/dialogs/handler/handler';
import { AdvRunComponent } from './components/dialogs/adv-run/adv-run.component';
import { MstoDurationPipe } from './pipes/msto-duration.pipe';
import { ByteFormatPipe } from './pipes/byte-format.pipe';
import { RunHistoryComponent } from './components/analytics/run-history/run-history.component';
// import { DeleteDialogComponent } from "./components/dialogs/delete-dialog/delete-dialog.component";
import { SuitDailogComponent } from './components/dialogs/suit-dailog/suit-dailog.component';
import { IssueTrackerRegisterComponent } from './components/dialogs/issue-tracker-register/issue-tracker-register.component';
// import { ProjectsSyncComponent } from "./components/dialogs/projects-sync/projects-sync.component";
// import { ProjectsManageComponent } from './config-details/projects-manage/projects-manage.component';
import { RegisterComponent } from './components/dialogs/register/register.component';
import {
    DialogEntryComponent,
    TestSuiteListComponent
} from './components/testsuites/test-suite-list/test-suite-list.component';
import { AutoSyncComponent } from './components/dialogs/auto-sync/auto-sync.component';
import { SlackRegisterComponent } from './components/dialogs/slack-register/slack-register.component';
import { BotCredentialsComponent } from './components/dialogs/bot-credentials/bot-credentials.component';
import { BotDialogComponent } from './components/dialogs/bot-dialog/bot-dialog.component';
// import { TestsuiteRunComponent } from "./components/dialogs/testsuite-run/testsuite-run.component";
import { BotSavingDialogComponent } from './components/dialogs/bot-saving-dialog/bot-saving-dialog.component';
import { MavenIntegrationComponent } from './components/dialogs/maven-integration/maven-integration.component';
import { GradleIntegrationComponent } from './components/dialogs/gradle-integration/gradle-integration.component';
import { JenkinsIntegrationComponent } from './components/dialogs/jenkins-integration/jenkins-integration.component';
import { TestsuitEditDialogComponent } from './components/dialogs/testsuit-edit-dialog/testsuit-edit-dialog.component';
// import { AutoSyncSaveConfigComponent } from "./components/dialogs/auto-sync-save-config/auto-sync-save-config.component";
import { TestsuitNewDialogComponent } from './components/dialogs/testsuit-new-dialog/testsuit-new-dialog.component';
import { ProjectRecommendationsComponent } from './components/projects/project-recommendations/project-recommendations.component';
import { BambooDialogComponent } from './components/dialogs/bamboo-dialog/bamboo-dialog.component';
import { TeamcityDialogComponent } from './components/dialogs/teamcity-dialog/teamcity-dialog.component';
import { HudsonDialogComponent } from './components/dialogs/hudson-dialog/hudson-dialog.component';
import { ManageRolesComponent } from './components/dialogs/manage-roles/manage-roles.component';
// import { ProjectRatingsComponent } from "./components/projects/project-ratings/project-ratings.component";
import { ManageSkippathDialogComponent } from './components/dialogs/manage-skippath-dialog/manage-skippath-dialog.component';
import { ManageAutocodeGenAuthComponent } from './components/dialogs/manage-autocode-gen-auth/manage-autocode-gen-auth.component';
import { ManageAbacDialogComponent } from './components/dialogs/manage-abac-dialog/manage-abac-dialog.component';
// import { HowToFixDialogComponent } from "./components/dialogs/how-to-fix-dialog/how-to-fix-dialog.component";
// import { RatingAssesmentComponent } from "./components/dialogs/rating-assesment/rating-assesment.component";
import { ManageE2eDialogComponent } from './components/dialogs/manage-e2e-dialog/manage-e2e-dialog.component';
import { ManageAbacType3DialogComponent } from './components/dialogs/manage-abac-type3-dialog/manage-abac-type3-dialog.component';
import { ManageAbacType2DialogComponent } from './components/dialogs/manage-abac-type2-dialog/manage-abac-type2-dialog.component';
import { ManageAbacL1PositiveComponent } from './components/dialogs/manage-abac-l1-positive/manage-abac-l1-positive.component';
import { ManageAbacL2PositiveComponent } from './components/dialogs/manage-abac-l2-positive/manage-abac-l2-positive.component';

import { AutocodeGeneratorService } from './services/autocode-generator.service';
import { ManageAbacL6DialogComponent } from './components/dialogs/manage-abac-l6-dialog/manage-abac-l6-dialog.component';
import { ManageAbacL7DialogComponent } from './components/dialogs/manage-abac-l7-dialog/manage-abac-l7-dialog.component';
import { UserVariableService } from './services/user-variable.service';
import { RecommendationsLogsComponent } from './components/dialogs/recommendations-logs/recommendations-logs.component';
import { AwsCodePipelineIntegrationComponent } from './components/dialogs/aws-code-pipeline-integration/aws-code-pipeline-integration.component';
import { GitlabIntegrationComponent } from './components/dialogs/gitlab-integration/gitlab-integration.component';
import { TfsIntegrationComponent } from './components/dialogs/tfs-integration/tfs-integration.component';
import { ManageDdosComponent } from './components/dialogs/manage-ddos/manage-ddos.component';
import { NestedResourceComponent } from './components/dialogs/nested-resource/nested-resource.component';
import { RecommendationsCommentsComponent } from './components/dialogs/recommendations-comments/recommendations-comments.component';
import { ManageHijack1Component } from './components/dialogs/manage-hijack1/manage-hijack1.component';
import { ManageAbacType2ResponseDialogComponent } from './components/dialogs/manage-abac-type2-response-dialog/manage-abac-type2-response-dialog.component';
import { RecommendationsIssuesComponent } from './components/dialogs/recommendations-issues/recommendations-issues.component';
import { XssFilterDialogComponent } from './components/dialogs/xss-filter-dialog/xss-filter-dialog.component';
import { OasDiffDialogComponent } from './components/dialogs/oas-diff-dialog/oas-diff-dialog.component';
// import { JobsSlackDialogComponent } from "./components/dialogs/jobs-slack-dialog/jobs-slack-dialog.component";
import { JobsAutovulDialogComponent } from './components/dialogs/jobs-autovul-dialog/jobs-autovul-dialog.component';
// import { VulDetailsDialogComponent } from "./components/dialogs/vul-details-dialog/vul-details-dialog.component";
import { PlaybookSampleDialogComponent } from './components/dialogs/playbook-sample-dialog/playbook-sample-dialog.component';
import { BotStatusDialogComponent } from './components/dialogs/bot-status-dialog/bot-status-dialog.component';
import { GitRepoComponent } from './components/projects/git-repo/git-repo.component';
// import { PermissionsComponent } from './config-details/permissions/permissions.component';
// import { PersonalizedCoverageDialogComponent } from "./components/dialogs/personalized-coverage-dialog/personalized-coverage-dialog.component";
import { AssertionsListDialogComponent } from './components/dialogs/assertions-list-dialog/assertions-list-dialog.component';
import { ArchivedCategoriesDialogComponent } from './components/dialogs/archived-categories-dialog/archived-categories-dialog.component';
import { ShortNumberPipe } from './pipes/short-number.pipe';
import { IssueTrackerPageRegistrationComponent } from './components/issue-tracker-page-registration/issue-tracker-page-registration.component';
import { CiCdIntegrationComponent } from './components/ci-cd-integration/ci-cd-integration.component';
import { ScannerDeleteDialogComponent } from './components/dialogs/scanner-delete-dialog/scanner-delete-dialog.component';
import { ActivityDialog } from './components/dialogs/activity-dialog/activity-dialog.component';
import { NotabugCommentsComponent } from './components/dialogs/notabug-comments/notabug-comments.component';
import { ReviewbugCommentsComponent } from './components/dialogs/reviewbug-comments/reviewbug-comments.component';
import { CircleCicdIntegrationComponent } from './components/dialogs/circle-cicd-integration/circle-cicd-integration.component';
import { MsAzureIntegrationComponent } from './components/dialogs/ms-azure-integration/ms-azure-integration.component';
import { SelectEndpointsDialogComponent } from './components/dialogs/select-endpoints-dialog/select-endpoints-dialog.component';
import { TokenInterceptor } from './services/auth/token.interceptor';
import { AgreementDialogComponent } from './components/dialogs/agreement-dialog/agreement-dialog.component';
import { CustomScrollDirective } from './shared/custom-scroll.directive';
import { ErrorComponent } from './error/error.component';
import { DateDiffPipe } from './pipes/date-diff.pipe';
import { QrCodeDialogComponent } from './components/dialogs/qr-code-dialog/qr-code-dialog.component';
import { ProjectNew2Component } from './components/projects/project-new2/project-new2.component';
import { AwsS3bucketComponent } from './components/dialogs/aws-s3bucket/aws-s3bucket.component';
import { AwsS3Component } from './components/aws-s3/aws-s3.component';
import { AuthTestResponseDialogComponent } from './components/dialogs/auth-test-response-dialog/auth-test-response-dialog.component';
import { UpgradeComponent } from './components/dialogs/upgrade/upgrade.component';
// import { OrgListRowEditComponent } from './components/org/org-list/org-list-row-edit/org-list-row-edit.component';
import { SatPopoverModule } from '@ncstate/sat-popover';
import { CustomSnackbarComponent } from './components/custom-snackbar/custom-snackbar.component';
import { RouterModule } from '@angular/router';
import { AssertionPropagationDialogComponent } from './components/dialogs/assertion-propagation-dialog/assertion-propagation-dialog.component';
import { MaterialComponentsModule } from './shared/material-components.module';
import { GithubActionsIntegrationComponent } from './components/dialogs/github-actions-integration/github-actions-integration.component';
// import { SigninComponent } from './membership/components/signin/signin.component';
// import { SignupComponent } from './membership/components/signup/signup.component';
import { EndpointCommentsComponent } from './components/dialogs/endpoint-comments/endpoint-comments.component';
import { OktaLoginComponent } from './okta/okta-login/okta-login.component';
import { TrimValueDirective } from './shared/TrimValueDirective';
import { VulnerabilitySyncDialogComponent } from './components/dialogs/vulnerability-sync-dialog/vulnerability-sync-dialog.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { DeleteDialogComponent } from './components/dialogs/delete-dialog/delete-dialog.component';
import { UserAddComponent } from './components/users/user-add/user-add.component';
import { MatTableExporterModule } from 'mat-table-exporter';
import { AlertDialogComponent } from './components/dialogs/alert-dialog/alert-dialog.component';
import { BulkEndpointsComponent } from './components/dialogs/bulk-endpoints/bulk-endpoints.component';
import { ShimmerLoaderModule } from './shimmer-loader/shimmer-loader.module';
import { BulkEndpointsConfirmDialogComponent } from './components/dialogs/bulk-endpoints-confirm-dialog/bulk-endpoints-confirm-dialog.component';
import { DefaultIntegrationsService } from './services/default-integrations.service';
import { MfaCheckDialogComponent } from './components/dialogs/mfa-check-dialog/mfa-check-dialog.component';
import { SecurityCenterDashboardComponent } from './security-center-dashboard/security-center-dashboard.component';

import { PreScriptDialogComponent } from './components/dialogs/pre-script-dialog/pre-script-dialog.component';
import { ApiGatewayRespDialogComponent } from './components/dialogs/api-gateway-resp-dialog/api-gateway-resp-dialog.component';
import { GlobalSkipEndpointComponent } from './global-skip-endpoint/global-skip-endpoint.component';
import { ManageGlobalSkipEndpointComponent } from './components/dialogs/manage-global-skip-endpoint/manage-global-skip-endpoint.component';
import { TeamsListComponent } from './teams-list-component/teams-list.component';
import { TeamNewComponent } from './team-new/team-new.component';
import { TeamEditComponent } from './team-edit/team-edit.component';
import { ApiGroupListComponent } from './api-group-list/api-group-list.component';
import { ApiGroupNewComponent } from './api-group-new/api-group-new.component';
import { ApiGroupEditComponent } from './api-group-edit/api-group-edit.component';
import { ChatbotComponent } from './chatbot/chatbot.component';
import { ApiListDialogComponent } from './components/dialogs/api-list-dialog/api-list-dialog.component';
import { TeamsUserListDialogComponent } from './components/dialogs/teams-userslist-dialog/teams-userlist-dialog.component';
import { TeamsListDialogComponent } from './components/dialogs/teams-list-dialog/teams-list-dialog.component';
import { ApiGroupListDialogComponent } from './components/dialogs/api-groups-list-dialog/api-group-list-dialog.component';
import { OrgMonthlyReport } from './org-monthly-report/org-monthly-report.component';
// import { UserAddComponent } from './orgs-users/components/user-add/user-add.component';


@NgModule({
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: [
        SatPopoverModule,
        RouterModule.forRoot([]),
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        // NoopAnimationsModule,
        MaterialComponentsModule,
        ClipboardModule,
        AppRoutingModule,
        MatTableExporterModule,
        HttpClientModule,
        AceEditorModule,
        // Ng2OdometerModule,
        // ChartsModule,
        // Ng2SearchPipeModule,
        // NgxShimmerLoadingModule,
        ShimmerLoaderModule,
        // Sub modules
        LayoutModule,
        SharedModule

    ],
    declarations: [
        AppComponent,
        // Layout
        LayoutComponent,
        PreloaderDirective,
        // Header
        AppHeaderComponent,
        // Footer
        AppFooterComponent,
        // Pages
        // JobDetailsComponent,
        ProjectsListComponent,
        RunListComponent,
        ProjectsNewComponent,
        // ProjectsEditComponent,
        RunDetailComponent,
        NotificationListComponent,
        // NotificationEditComponent,
        // NotificationNewComponent,
        MsgDialogComponent,
        ErrorDialogComponent,
        ResponseDialogComponent,
        AdvRunComponent,
        IssueTrackerRegisterComponent,
        MstoDurationPipe,
        ByteFormatPipe,
        RunHistoryComponent,
        // DeleteDialogComponent,
        SuitDailogComponent,
        // ProjectsSyncComponent,
        RegisterComponent,
        TestSuiteListComponent,
        TimeAgoPipe,
        AutoSyncComponent,
        SlackRegisterComponent,
        // ApiCoverageComponent,
        BotCredentialsComponent,
        BotDialogComponent,
        //  TestsuiteRunComponent,
        BotSavingDialogComponent,
        MavenIntegrationComponent,
        GradleIntegrationComponent,
        JenkinsIntegrationComponent,
        TestsuitEditDialogComponent,
        // AutoSyncSaveConfigComponent,
        TestsuitNewDialogComponent,
        ProjectRecommendationsComponent,
        BambooDialogComponent,
        TeamcityDialogComponent,
        HudsonDialogComponent,
        ManageRolesComponent,
        // ProjectRatingsComponent,
        ManageSkippathDialogComponent,
        ManageAutocodeGenAuthComponent,
        ManageAbacDialogComponent,

        // HowToFixDialogComponent,
        // RatingAssesmentComponent,
        ManageE2eDialogComponent,
        ManageAbacType3DialogComponent,
        ManageAbacType2DialogComponent,
        UserAddComponent,
        ManageAbacL1PositiveComponent,
        ManageAbacL2PositiveComponent,
        ManageAbacL6DialogComponent,
        ManageAbacL7DialogComponent,
        RecommendationsLogsComponent,
        ApiListDialogComponent,
        AwsCodePipelineIntegrationComponent,
        GitlabIntegrationComponent,
        TfsIntegrationComponent,
        ManageDdosComponent,
        NestedResourceComponent,
        RecommendationsCommentsComponent,
        ManageHijack1Component,
        ManageAbacType2ResponseDialogComponent,
        RecommendationsIssuesComponent,
        XssFilterDialogComponent,
        OasDiffDialogComponent,
        // JobsSlackDialogComponent,
        JobsAutovulDialogComponent,
        // VulDetailsDialogComponent,
        DialogEntryComponent,
        PlaybookSampleDialogComponent,
        BotStatusDialogComponent,
        GitRepoComponent,
        BackButtonDirective,
        TrimValueDirective,
        //  PersonalizedCoverageDialogComponent,
        AssertionsListDialogComponent,
        ArchivedCategoriesDialogComponent,
        ShortNumberPipe,
        IssueTrackerPageRegistrationComponent,
        CiCdIntegrationComponent,
        ScannerDeleteDialogComponent,
        ActivityDialog,
        NotabugCommentsComponent,
        ReviewbugCommentsComponent,
        EndpointCommentsComponent,
        CircleCicdIntegrationComponent,
        MsAzureIntegrationComponent,
        SelectEndpointsDialogComponent,
        AgreementDialogComponent,
        CustomScrollDirective,
        ErrorComponent,
        DateDiffPipe,
        QrCodeDialogComponent,
        ProjectNew2Component,
        AwsS3bucketComponent,
        AwsS3Component,
        AuthTestResponseDialogComponent,
        UpgradeComponent,
        CustomSnackbarComponent,
        OktaLoginComponent,
        AssertionPropagationDialogComponent,
        GithubActionsIntegrationComponent,
        VulnerabilitySyncDialogComponent,
        DeleteDialogComponent,
        AlertDialogComponent,
        BulkEndpointsComponent,
        BulkEndpointsConfirmDialogComponent,
        MfaCheckDialogComponent,
        SecurityCenterDashboardComponent,
        PreScriptDialogComponent,
        GlobalSkipEndpointComponent,
        ApiGatewayRespDialogComponent,
        ManageGlobalSkipEndpointComponent,
        TeamsListComponent,
        TeamNewComponent,
        TeamEditComponent,
        ApiGroupListComponent,
        ApiGroupNewComponent,
        ApiGroupEditComponent,
        ChatbotComponent,
        TeamsUserListDialogComponent,
        TeamsListDialogComponent,
        ApiGroupListDialogComponent,
        OrgMonthlyReport
    ],
    bootstrap: [AppComponent],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
        DashboardService,
        TestSuiteService,
        AccountService,
        Handler,
        DatePipe,
        AutocodeGeneratorService,
        UserVariableService,
        DefaultIntegrationsService
    ],
    entryComponents: []
})
export class AppModule {
    constructor(public appRef: ApplicationRef) {
    }
}
