import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { Router } from "@angular/router";
import { ProjectService } from "../../../services/project.service";
import {
  Project,
  Noti,
  PersonalizedCoverage,
  Auth,
  Account,
} from "../../../models/project.model";
import { Handler } from "../../dialogs/handler/handler";
import { SnackbarService } from "../../../services/snackbar.service";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { AccountService } from "../../../services/account.service";
import { IssueTrackerRegisterComponent } from "./../../dialogs/issue-tracker-register/issue-tracker-register.component";
import { MatDialog } from "@angular/material/dialog";
// import { ShepherdService } from "angular-shepherd";
import {
  newProjectSteps2 as defaultSteps,
  defaultStepOptions,
} from "../../../shared/data";
import { SubscriptionTier } from "../../../models/subscription-tier";
import { DeleteDialogComponent } from "../../dialogs/delete-dialog/delete-dialog.component";
import { AlertDialogComponent } from "../../dialogs/alert-dialog/alert-dialog.component";
import {
  loggedInUserDetail,
  LoggedInUserOrgId,
} from "../../../shared/shared.data";
import { OrgService } from "../../../services/org.service";
import { OrgUser } from "../../../models/org.model";
import { Observable, of } from "rxjs";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger,
} from "@angular/material/autocomplete";
import { map, startWith } from "rxjs/operators";
import { MatChipInputEvent } from "@angular/material/chips";
import mixpanel from "mixpanel-browser";
import { MixpanelService } from "../../../services/mixpanel.service";

// import { Auth } from "../../../models/project-env.model";

@Component({
  selector: "app-projects-new",
  templateUrl: "./projects-new.component.html",
  styleUrls: ["./projects-new.component.scss"],
  providers: [ProjectService, SnackbarService, AccountService, OrgService],
})
export class ProjectsNewComponent implements OnInit {
  [x: string]: any;
  isFileLoadToggle: any;
  id: string;
  project: Project = new Project();
  @Output() emitData = new EventEmitter<string[]>();
  firstFormGroup: FormGroup;
  advance: boolean = false;
  apiText = "";
  showHide: boolean = false;
  bulkUserDetails;
  selectedTimeZone;
  selectedHour;
  commandCheck;
  selectedDatabases;
  subscriptionTier: SubscriptionTier = new SubscriptionTier();
  globalLoggedInUserOrgId = LoggedInUserOrgId;
  freeFlag: boolean = false;
  enterpriseFlag: boolean = false;
  subscriptions = ["FREE", "ENTERPRISE"];
  currentSection = "Basic";
  entry: OrgUser = new OrgUser();
  accountTypes: Type[] = [
    { value: "No_Issues", viewValue: "Vulnerability_Tracking_Off" },
    { value: "FX_Issues", viewValue: "Vulnerability_Tracking_On" },
    { value: "GitHub", viewValue: "GitHub" },
    { value: "Jira", viewValue: "Jira" },
    { value: "Bugzilla", viewValue: "Bugzilla" },
  ];
  itAccounts: Array<Account> = [];
  selected: "None";
  // tagsControl = new FormControl([]);
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  filteredTags: Observable<string[]>;
  // allTags: string[] = ["Prod", "Stg", "Dev", "QA", "Internal", "External", "Apigee", "Postman", "Ecommerce", "Partners"];
  resourceName;
  @ViewChild("auto", { static: false }) matAutocomplete: MatAutocomplete;
  loggedInUserDetail = loggedInUserDetail;
  // Multi select code start
  selectableTag = true;
  removableTag = true;
  separatorKeysCodesTag: number[] = [ENTER, COMMA];
  // tagsControl = new FormControl();
  allfilteredTags: Observable<string[]>;
  newtags: string[] = [];
  newallTags: string[] = [
    "Prod",
    "Stg",
    "Dev",
    "QA",
    "Internal",
    "External",
    "Apigee",
    "Postman",
    "Ecommerce",
    "Partners",
  ];
  @ViewChild("tInputTag") tInputTag: ElementRef<HTMLInputElement>;
  //   @ViewChild('auto') matAutocomplete: MatAutocomplete;
  @ViewChild("autocompleteTrigger") matACTrigger: MatAutocompleteTrigger;
  remainingEndpoints;
  constructor(
    private projectService: ProjectService,
    private router: Router,
    private handler: Handler,
    private snackbarService: SnackbarService,
    private _formBuilder: FormBuilder,
    private accountService: AccountService,
    public dialog: MatDialog,
    private orgService: OrgService,
    private mixpanelService: MixpanelService // private shepherdService: ShepherdService
  ) {
    // this.filteredTags = this.firstFormGroup.controls['tagsControl'].valueChanges.pipe(
    //     startWith(null),
    //     map((taging: string | null) => taging ? this._filter(taging) : this.allTags.slice()));
  }
  // loadBody(event, selectedResource) {
  //     if (event.source.selected) {
  //         this.resourceName = selectedResource;
  //         console.log(this.resourceName);
  //         this.firstFormGroup.controls['tagsControl'].patchValue(this.resourceName)
  //     }

  // }
  csvContent: string;
  // private _filter(value: string): string[] {
  //     const filterValue = value.toLowerCase();

  //     return this.allTags.filter(taging => taging.toLowerCase().indexOf(filterValue) === 0);
  // }

  onFileLoad(fileLoadedEvent) {
    const textFromFileLoaded = fileLoadedEvent.target.result;
    this.apiText = textFromFileLoaded;
    this.csvContent = textFromFileLoaded;
    var el = <HTMLInputElement>document.getElementById("fileText");
    el.value = textFromFileLoaded;
  }

  resetValidation() {
    var file1 = <HTMLInputElement>document.getElementById("file-field");
    file1.value = "";
    var k = this.firstFormGroup.value.isFileLoad;
    if (!k) {
      this.getTextIn();
      this.firstFormGroup = this._formBuilder.group({
        nameCtrl: [this.project.name, Validators.required],
        //       openAPISpec: [this.project.openAPISpec, Validators.required],
        isFileLoad: false,
        openText: [this.project.openText, Validators.nullValidator],
        tagsControl: [this.project.tags || []],
      });
      this.project.isFileLoad = false;
      this.project.openText = "";
      this.project.openAPISpec = "";
    }
    if (k) {
      this.getTextIn();
      this.firstFormGroup = this._formBuilder.group({
        nameCtrl: [this.project.name, Validators.required],
        //     openAPISpec: [this.project.openAPISpec, Validators.nullValidator],
        isFileLoad: true,
        openText: [this.project.openText, Validators.required],
        tagsControl: [this.project.tags || []],
      });
    }
  }

  onFileSelect(input: HTMLInputElement) {
    const files = input.files;
    var content = this.csvContent;
    if (files && files.length) {
      const fileToRead = files[0];
      const fileReader = new FileReader();
      fileReader.readAsText(fileToRead, "UTF-8");
      fileReader.onload = (event) => {
        var data = <FileReader>event.target;
        this.apiText = data.result.toString();

        var el = <HTMLInputElement>document.getElementById("fileText");
        el.value = this.apiText;
        this.project.openText = el.value;
        this.project.isFileLoad = true;
        this.project.openAPISpec = "none";
      };
    }
  }

  getTextIn() {
    var el = <HTMLInputElement>document.getElementById("fileText");
    if (el.value != "") this.project.openText = el.value;
    if (el.value.length != 0) {
      this.project.isFileLoad = true;
      this.project.openAPISpec = "none";
    }
  }

  regData: any = null;
  ngOnInit() {
    let regDetails = localStorage.getItem("regDetails");
    if (regDetails) {
      this.regData = JSON.parse(regDetails);
      if (this.regData["regName"]) this.project.name = this.regData["regName"];
      else this.project.name = "";
      if (this.regData["regUrl"])
        this.project.openAPISpec = this.regData["regUrl"];
      else this.project.openAPISpec = "";
      if (this.regData["openText"])
        this.project.openText = this.regData["openText"];
      else this.project.openText = "";
      if (this.regData["isFileLoadToggle"])
        this.isFileLoadToggle = this.regData["isFileLoadToggle"];
      else this.isFileLoadToggle = false;
      if (this.regData["tags"]) this.newtags = this.regData["tags"];
      else this.newtags = [];
      if (this.regData) this.bulkUserDetails = this.regData["bulkUserDetails"];
      else this.bulkUserDetails = "";
    }
    this.project.tags = [];
    this.firstFormGroup = this._formBuilder.group({
      nameCtrl: ["", Validators.required],
      //  openAPISpec: ["", Validators.nullValidator],
      isFileLoad: false,
      openText: [this.project.openText, Validators.nullValidator],
      tagsControl: [this.project.tags],
    });
    // this.getITAccountsByAccountType("");
    // this.filteredTags = this.firstFormGroup.controls['tagsControl'].valueChanges.pipe(
    //     startWith(null),
    //     map((taging: string | null) => taging ? this._filter(taging) : this.allTags.slice()));
    this.allfilteredTags = this.firstFormGroup.controls[
      "tagsControl"
    ].valueChanges.pipe(
      startWith(null),
      map((tag: string | null) =>
        tag ? this._filterr(tag) : this.newallTags.slice()
      )
    );
    if (this.globalLoggedInUserOrgId.orgId == "") this.getLoggedInUser();
    // console.log("Before call : ", this.globalLoggedInUserOrgId.orgId)
    else this.subscriptionTierCall(this.globalLoggedInUserOrgId.orgId);

    this.project.notifications[0] = new Noti();
    this.project.apispecType = "openAPISpec";
    // var showStepsFlag = localStorage.getItem("registerAPI2");
    // if (showStepsFlag != "Y" && window.screen.width > 1000) this.showTour();
  }
  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    // Add our tag
    if ((value || "").trim()) {
      this.newtags.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = "";
    }
    this.allfilteredTags = this.firstFormGroup.controls[
      "tagsControl"
    ].valueChanges.pipe(
      startWith(null),
      map((tag: string | null) =>
        tag ? this._filterr(tag) : this.newallTags.slice()
      )
    );
    // this.tagsControl.setValue(null);
  }

  remove(tag: string): void {
    const index = this.newtags.indexOf(tag);

    if (index >= 0) {
      this.newtags.splice(index, 1);
    }
  }

  selectedTag(event: MatAutocompleteSelectedEvent): void {
    const newValue = event.option.viewValue;
    if (this.newtags.includes(newValue)) {
      this.newtags = [...this.newtags.filter((tag) => tag !== newValue)];
    } else {
      this.newtags.push(event.option.viewValue);
    }
    this.tInputTag.nativeElement.value = "";
    // this.allfilteredTags =  of(this.newallTags.slice())
    // this.tagsControl.setValue(null);
    // this.firstFormGroup.controls['tagsControl'].patchValue(event);

    // keep the autocomplete opened after each item is picked.
    requestAnimationFrame(() => {
      this.openAuto(this.matACTrigger);
    });
  }

  private _filterr(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.newallTags.filter(
      (tag) => tag.toLowerCase().indexOf(filterValue) >= 0
    );
  }

  openAuto(trigger: MatAutocompleteTrigger) {
    trigger.openPanel();
    this.tInputTag.nativeElement.focus();
  }
  validateAndSave() {
    // if (this.subscriptionTier.availableEntLicense <= 0) {
    //     // window.alert("Please request for more licenses.")
    //     const dialogRef = this.dialog.open(AlertDialogComponent, {
    //         width: "580px",
    //         height: "225px",
    //         data: ["", "Please request for more licenses."]
    //     });
    // }
    // else
    if (this.project.openAPISpec == null || this.project.openAPISpec == "") {
      // var r = confirm("Are you sure you want to register project without API?");
      let dialogRef = this.dialog.open(DeleteDialogComponent, {
        width: "580px",
        height: "235px",
        data: [
          "Register",
          "Are you sure you want to register project without API?",
        ],
      });
      dialogRef.componentInstance.emitData.subscribe((data1) => {
        if (data1 == "Y") {
          this.project.noApi = true;
          this.save();
        }
      });
    } else this.save();
  }

  save() {
    let projectName = "";
    if (this.project.name && this.project.name.length >= 35)
      projectName = this.project.name.slice(0, 35) + "...";
    else projectName = this.project.name;

    //mixpanel code starts

    var obj = {
      projectName: projectName,
    };
    this.mixpanelService.trackMixpanel("API basic registration", obj);
    //mixpanel code ends

    localStorage.removeItem("regDetails");

    const tempAuth = [];
    this.bulkUserDetails = "";
    if (
      this.project.issueTracker == null ||
      this.project.issueTracker.accountType == null
    )
      this.project.issueTracker = null;
    this.project.bulkUser = this.bulkUserDetails;
    if (this.commandCheck != null) {
      this.project.personalizedCoverage.commandChecks =
        this.commandCheck.toString();
    }
    if (this.selectedDatabases != null) {
      this.project.personalizedCoverage.databases =
        this.selectedDatabases.toString();
    }
    this.project.personalizedCoverage.timeZone = this.selectedTimeZone;
    this.project.personalizedCoverage.cron = this.selectedHour;
    if (this.project.personalizedCoverage.cron != null)
      this.project.personalizedCoverage.cron =
        "0 0 " + this.selectedHour.substring(0, 2) + " * * ?";

    if (this.firstFormGroup.controls["isFileLoad"].value == false) {
      this.project.openText = null;
      this.project.source = "URL";
    } else if (this.firstFormGroup.controls["isFileLoad"].value == true) {
      this.project.source = "FILE";
      this.project.isFileLoad = true; /*Modification*/
    }

    this.handler.activateLoader();
    this.snackbarService.openSnackBar(
      "Project '" + projectName + "' creating...",
      ""
    );
    this.project.planType = "ENTERPRISE";
    // if(this.project.isFileLoad){
    //     this.project.openAPISpec='';
    // }else  this.project.openText='';

    // this.project.tags = Array.isArray(this.firstFormGroup.controls['tagsControl'].value) ?
    //     this.firstFormGroup.controls['tagsControl'].value : [this.firstFormGroup.controls['tagsControl'].value];
    this.project.tags = this.newtags;
    this.projectService.create(this.project).subscribe(
      (results) => {
        this.handler.hideLoader();
        if (this.handler.handle(results)) {
          return;
        }

        this.snackbarService.open(
          "Project '" +
            projectName +
            "' registered successfully. " +
            "The apisec is writing customized validations for the APIs. This step may take a few minutes to complete before you can run the first scan.",
          "OK",
          30000
        );
        this.project = results["data"];
        this.router.navigate(["/app/projects"]);
      },
      (error) => {
        this.handler.hideLoader();
        this.handler.error(error);
      }
    );
  }

  isValidInput(bulkUser, authType) {
    if (bulkUser.length < 3) {
      window.alert("Invalid User Authentication Format: " + bulkUser);
      return false;
    }
    // tslint:disable-next-line: triple-equals
    else if (authType == "Basic" && bulkUser.length != 4) {
      window.alert("Invalid User Authentication Format: " + bulkUser);
      return false;
    }

    // tslint:disable-next-line: triple-equals
    if (
      authType != "Basic" &&
      authType != "Token" &&
      authType != "Digest" &&
      authType != "HMAC"
    ) {
      window.alert("Invalid auth type : " + authType);
      return false;
    }

    return true;
  }

  // getAccountsForIssueTracker() {
  //     this.handler.activateLoader();
  //     this.accountService.getAccountByAccountType("ISSUE_TRACKER").subscribe(
  //         results => {
  //             this.handler.hideLoader();
  //             if (this.handler.handle(results)) {
  //                 return;
  //             }
  //             this.itAccounts = new Array();
  //         },
  //         error => {
  //             this.handler.hideLoader();
  //             this.handler.error(error);
  //         }
  //     );
  // }

  // getITAccountsByAccountType(type) {
  //     if (type != "") this.project.issueTracker.accountType = type;

  //     this.handler.activateLoader();
  //     this.itAccounts.splice(0);
  //     this.accountService.getAccountByAccountType("ISSUE_TRACKER").subscribe(
  //         results => {
  //             this.handler.hideLoader();
  //             if (this.handler.handle(results)) {
  //                 return;
  //             }
  //             this.itAccounts = new Array();
  //             if (results["data"][0]) {
  //                 this.subscriptionTierCall(results["data"][0]['org']['id']);
  //             }
  //             for (let entry of results["data"]) {
  //                 if (entry.accountType && entry.accountType == this.project.issueTracker.accountType) {
  //                     this.itAccounts.push(entry);
  //                 }
  //             }
  //         },
  //         error => {
  //             this.handler.hideLoader();
  //             this.handler.error(error);
  //         }
  //     );
  // }

  // openDialogITCredentials() {
  //     const dialogRef = this.dialog.open(IssueTrackerRegisterComponent, {
  //         width: "800px",
  //         data: this.accountTypes
  //     });
  //     dialogRef.afterClosed().subscribe(result => {
  //         this.getITAccountsByAccountType("");
  //     });
  // }

  // showTour() {
  //   this.shepherdService.defaultStepOptions = defaultStepOptions;
  //   this.shepherdService.disableScroll = true;
  //   this.shepherdService.modal = true;
  //   this.shepherdService.confirmCancel = false;
  //   this.shepherdService.addSteps(defaultSteps);
  //   this.shepherdService.start();
  //   localStorage.setItem("registerAPI2", "Y");
  // }

  subscriptionTierCall(orgId) {
    this.handler.activateLoader();
    this.projectService.getSubscriptionLicense(orgId).subscribe(
      (results) => {
        if (this.handler.handle(results)) {
          return;
        }
        this.handler.hideLoader();
        this.subscriptionTier = results["data"];
        if (
          this.subscriptionTier.orgEntPlan == "ENTERPRISE" &&
          this.subscriptionTier.availableEntLicense != 0
        ) {
          this.project.planType = "ENTERPRISE";
        }
        if (this.subscriptionTier.availableFreeLicense == 0) {
          this.freeFlag = true;
        }

        if (this.subscriptionTier.availableEntLicense == 0) {
          this.enterpriseFlag = true;
        }
        let utilized =
          this.subscriptionTier.utilizedEndpoints === null
            ? 0
            : this.subscriptionTier.utilizedEndpoints;
        this.remainingEndpoints =
          this.subscriptionTier.purchaseEntLicense - utilized;
      },
      (error) => {
        this.handler.hideLoader();
        this.handler.error(error);
      }
    );
  }

  goToAdvProject() {
    let regDetails = {
      regName: this.project.name,
      regUrl: this.project.openAPISpec,
      openText: this.project.openText,
      isFileLoadToggle: this.isFileLoadToggle,
      tags: this.newtags,
      bulkUserDetails: this.bulkUserDetails,
    };
    localStorage.setItem("regDetails", JSON.stringify(regDetails));
    this.router.navigate(["/app/projects/advRegistration"]);
  }

  getLoggedInUser() {
    this.handler.activateLoader();
    this.orgService.getLoggedInUser().subscribe(
      (results) => {
        this.handler.hideLoader();
        if (this.handler.handle(results)) {
          return;
        }

        this.entry = results["data"];
        this.subscriptionTierCall(this.entry.org.id);
      },
      (error) => {
        this.handler.hideLoader();
        this.handler.error(error);
      }
    );
  }

  // addNewTags(value: string) {
  //     this.tagsList.push(value);
  // }
}

export interface Type {
  value: string;
  viewValue: string;
}
