import { Component, ElementRef, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { JobsService } from "../../../services/jobs.service";
import { SnackbarService } from "../../../services/snackbar.service";
import { NavigationExtras, Router } from "@angular/router";
import { Handler } from "../handler/handler";
import { ProjectService } from "../../../services/project.service";
import { RunService } from "../../../services/run.service";
import { TestSuiteService } from "../../../services/test-suite.service";
import { TestCase, TestSuite } from "../../../models/test-suite.model";
import { Project } from "../../../models/project.model";
import { TestsuitNewDialogComponent } from "../testsuit-new-dialog/testsuit-new-dialog.component";
import { RegionsService } from '../../../services/regions.service';
import { GlobalPlaybookFlag, GlobalSelectedEnv, globalStorage } from './../../../shared/shared.data';
import { parse, stringify } from 'yaml';
import { AssertionPropagationDialogComponent } from '../assertion-propagation-dialog/assertion-propagation-dialog.component';
import { Env } from '../../../models/project-env.model';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';
// import YAML from 'yaml';
@Component({
    selector: 'app-testsuit-edit-dialog',
    templateUrl: './testsuit-edit-dialog.component.html',
    styleUrls: ['./testsuit-edit-dialog.component.scss'],
    providers: [JobsService, RunService, ProjectService, SnackbarService, RegionsService]

})
export class TestsuitEditDialogComponent implements OnInit {
    [x: string]: any;
    @ViewChild('logeditor') logeditor;
    @ViewChild('editor') editor;
    @ViewChild('h1')
    eleRef: ElementRef;
    @Output() emitData = new EventEmitter<string[]>();
    // logeditor:any;
    firstFormGroup: FormGroup;
    secondFormGroup: FormGroup;
    testSuite: TestSuite = new TestSuite();
    testSuite2: TestSuite = new TestSuite();
    project: Project = new Project();
    projectId: string;
    testSuiteId: string;
    testCases: FormArray;
    currentSuiteName: string = "";
    autoGenerated: boolean;
    testsuitesNames: any = [];
    processing: boolean = false;
    runResult;
    jobs;
    envIdTemp;
    i = 0;
    list = [];
    envs = [];
    region;
    regions: string;
    displayedColumns: string[] = ['sno', 'status', 'success', 'Status', 'time', 'data'];
    dataSource = null;
    runFlag: boolean = false;
    myCompleter: any;
    glob = globalStorage;
    env: Env = new Env();
    option = { name: "" };
    nextSuitName: string;
    suitePosition: number;
    disableSave: boolean;
    editorSelect: any = {
        rowText: '', validEvent: false, text: '', exec: false,
        row: 0, column: 0, prevExecuted: false
    };
    securityCoverage: string;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    originalYamlData = "";
    constructor(private testSuiteService: TestSuiteService, private runService: RunService, private dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: any, private _formBuilder: FormBuilder, public dialogRef: MatDialogRef<TestsuitEditDialogComponent>,
        private projectService: ProjectService, private handler: Handler, private snackbarService: SnackbarService,
        private router: Router, private regionService: RegionsService) {
        if (data != null) {
            this.projectId = data[0];
            this.testSuiteId = data[1];
            this.currentSuiteName = data[2];
            this.autoGenerated = data[3];
            this.securityCoverage = data[4];
            this.hideoptions = data[5]
        }
        if (!this.hideoptions) this.hideoptions = false;
    }

    globalSelectedEnv = GlobalSelectedEnv;
    ngOnInit() {
        const testsuitesNames = localStorage.getItem("testsuitesNames");
        if (testsuitesNames != null) this.testsuitesNames = testsuitesNames.split(",");
        this.getRegions();
        this.envlist(this.projectId);

        this.env.id = this.globalSelectedEnv.envId;
        this.option.name = this.glob.Scan_Global;
        // if (this.glob.Env_Global && this.glob.Scan_Global) {
        //     this.env.id = this.globalSelectedEnv.envId;
        //     this.option.name = this.glob.Scan_Global;
        // }
        // else if (this.glob.Env_Global == "" && this.glob.Scan_Global) {
        //     this.env.id = this.globalSelectedEnv.envId;
        //     this.option.name = this.glob.Scan_Global;
        // }
        // else if (this.glob.Env_Global && this.glob.Scan_Global == "") {
        //     this.env.id = this.globalSelectedEnv.envId;
        //     this.option.name = this.glob.FirstScan_Global;
        // }
        // else {
        //     this.env.id = this.globalSelectedEnv.envId;
        //     this.option.name = this.glob.FirstScan_Global;
        // }

        var myKeywords = (function () {
            var words = "FakerName,FakerPassword,FakerCity".split(",")
            var transformationWords = "trim,trimToNull,trimToEmpty,truncate,strip,indexOf,indexOfIgnoreCase,lastIndexOf,left,right,substringBefore,substringAfter,substringBeforeLast,substringAfterLast,substringBetween,removeStart,removeStartIgnoreCase,removeEnd,removeEndIgnoreCase,remove,removeIgnoreCase,removeAll,removeFirst,removePattern,chomp,chop,repeat,rightPad,leftPad,upperCase,lowerCase,capitalize,uncapitalize,reverse".split(",")
            var dataInjection = "@Random,@RandomAlphabetic,@RandomAlphanumeric,@RandomNumeric,@Date,@RandomUUID".split(",")
            var entityKeyWords = "@Request,@StatusCode,@ResponseHeaders,@Response,@NULL,@EMPTY,@ResponseTime,@ResponseSize".split(",")
            var array1 = [];
            for (var i = 0; i < words.length; i++)
                array1.push({
                    name: words[i],
                    value: words[i],
                    meta: "Faker-Words"
                });
            for (var i = 0; i < transformationWords.length; i++)
                array1.push({
                    name: transformationWords[i],
                    value: transformationWords[i],
                    meta: "Transformation"
                });
            for (var i = 0; i < dataInjection.length; i++)
                array1.push({
                    name: dataInjection[i],
                    value: dataInjection[i],
                    meta: "Data-Injection"
                })
            for (var i = 0; i < entityKeyWords.length; i++)
                array1.push({
                    name: entityKeyWords[i],
                    value: entityKeyWords[i],
                    meta: "Entity-Keyword"
                })
            return array1;
        })();

        this.myCompleter = {
            getCompletions: function (editor, session, pos, prefix, callback) {
                callback(null, myKeywords);
            }
        }
        if (this.editor) {
            this.editor.setOptions({
                enableLiveAutocompletion: [this.myCompleter],
                wrap: false
            });
        }

        this.loadTestSuite(this.projectId, this.currentSuiteName);
        this.firstFormGroup = this._formBuilder.group({
            nameCtrl: ['', Validators.required],
            testCases: this._formBuilder.array([])
        });

        this.secondFormGroup = this._formBuilder.group({
            baseUrlCtrl: ['', Validators.required],
            selectedScanner: ['', Validators.required],
        });

    }

    ngAfterContentChecked() {
        if (this.editor) {
            this.editor.setOptions({
                enableLiveAutocompletion: [this.myCompleter],
                wrap: false
            });
        }
    }

    saveTestSuite(playbookStatus: string) {
        // if (this.autoGenerated) {
        // var r = confirm("If AutoGenerated Playbook is modified, it will not be managed by AutoCode Framework. It will be considered as a Manual Playbook and needs to be managed manually. Are you sure you want to continue?");
        // if (r == true) {
        var groupVal = this.firstFormGroup.value;
        this.testSuite.testCases = groupVal.testCases;
        this.handler.activateLoader();
        this.testSuiteService.update(this.testSuite).subscribe(results => {
            this.disableSave = false;
            this.handler.hideLoader();
            if (this.handler.handle(results)) {
                return;
            }

            if (playbookStatus == "false") {
                this.snackbarService.openSnackBar("Playbook '" + this.testSuite.name.slice(0, 30) + "...' activated.", "");
                this.dialogRef.close();
            }
            else
                if (playbookStatus == "true") {
                    this.snackbarService.openSnackBar("Playbook '" + this.testSuite.name.slice(0, 30) + "...' deactivated.", "");
                    this.dialogRef.close();
                }
                else
                    this.snackbarService.openSnackBar("Playbook '" + this.testSuite.name.slice(0, 30) + "...' updated successfully!", "");
            // this.snackbarService.openBackgroundMsgBar("If AutoGenerated Playbook is modified, it will not be managed by AutoCode Framework. " +
            //     "It will be considered as a Manual Playbook and needs to be managed manually.")

            var yaml1 = parse(this.originalYamlData);
            var yaml2 = parse(this.testSuite.yaml);

            if (yaml1.assertions.toString() != yaml2.assertions.toString()) {
                this.propagateAssertion('Assertion');
            }
            if (yaml1.severity.toString() != yaml2.severity.toString()) {
                this.propagateSeverity('Severity');
            }
            if (yaml1.headers.toString() != yaml2.headers.toString()) {
                this.propagateHeaders('Headers');
            }
            if (yaml1.testCases[0].body.toString() != yaml2.testCases[0].body.toString()) {
                this.propagatePayload('Payload');
            }
            // else
            //     this.dialogRef.close();


        }, error => {
            this.disableSave = false;
            this.handler.hideLoader();
            this.handler.error(error);
        });
        // }
        // else {
        //     this.disableSave = false;

        // }
        // }
        // else {
        //     this.disableSave = true;
        //     var groupVal = this.firstFormGroup.value;
        //     this.testSuite.testCases = groupVal.testCases;
        //     this.handler.activateLoader();
        //     this.testSuiteService.update(this.testSuite).subscribe(results => {
        //         this.handler.hideLoader();
        //         if (this.handler.handle(results)) {
        //             return;
        //         }
        //         this.snackbarService.openSnackBar("Playbook '" + this.testSuite.name + "' updated successfully!", "");
        //         this.loadTestSuite(this.projectId, this.currentSuiteName);
        //         // if (saveclose == true) {
        //         //     this.dialogRef.close();
        //         // }
        //         this.disableSave = false;

        //     }, error => {
        //         this.handler.hideLoader();
        //         this.handler.error(error);
        //     });
        // }
    }

    selectedTab = 1;
    addClass(id: any) {
        // $event.target.scrollIntoView({behavior: 'smooth'});
        // el.scrollIntoView({ behavior: 'smooth' });
        this.selectedTab = id;
    }

    propagateAssertion(getClickValue) {
        var groupVal = this.firstFormGroup.value;
        this.testSuite.testCases = groupVal.testCases;

        const dialogRef = this.dialog.open(AssertionPropagationDialogComponent, {
            data: [this.testSuite, getClickValue]

        });

        dialogRef.componentInstance.emitData.subscribe(data1 => {
            if (data1 == 'Y') {
                localStorage.setItem('Propegation', "" + true);
                this.dialogRef.close();
                this.testSuiteService.propagateAssertion(this.testSuite).subscribe(results => {

                    this.handler.hideLoader();
                    if (this.handler.handle(results)) {
                        return;
                    }
                    // this.dialogRef.close();
                    this.snackbarService.openSnackBar("Assertions propagated to the category " + this.currtestSuite.category + " successfully!", "");

                }, error => {
                    this.handler.hideLoader();
                    this.handler.error(error);
                });
            }
        });



    }

    propagateSeverity(getClickValue) {
        var groupVal = this.firstFormGroup.value;
        this.testSuite.testCases = groupVal.testCases;

        const dialogRef = this.dialog.open(AssertionPropagationDialogComponent, {
            data: [this.testSuite, getClickValue]

        });

        dialogRef.componentInstance.emitData.subscribe(data1 => {
            if (data1 == 'Y') {
                this.dialogRef.close();
                localStorage.setItem('Propegation', "" + true);
                this.testSuiteService.propagateSeverity(this.testSuite).subscribe(results => {

                    this.handler.hideLoader();
                    if (this.handler.handle(results)) {
                        return;
                    }
                    // this.dialogRef.close();
                    this.snackbarService.openSnackBar("Severity propagated to the category " + this.currtestSuite.category + " successfully!", "");

                }, error => {
                    this.handler.hideLoader();
                    this.handler.error(error);
                });
            }
        });
    }

    propagateHeaders(getClickValue) {
        var groupVal = this.firstFormGroup.value;
        this.testSuite.testCases = groupVal.testCases;

        const dialogRef = this.dialog.open(AssertionPropagationDialogComponent, {
            data: [this.testSuite, getClickValue]

        });

        dialogRef.componentInstance.emitData.subscribe(data1 => {
            if (data1 == 'Y') {
                this.dialogRef.close();
                localStorage.setItem('Propegation', "" + true);
                this.testSuiteService.propagateHeaders(this.testSuite).subscribe(results => {

                    this.handler.hideLoader();
                    if (this.handler.handle(results)) {
                        return;
                    }
                    // this.dialogRef.close();
                    this.snackbarService.openSnackBar("Headers propagated to all the playbooks of the Endpoint successfully!", "");

                }, error => {
                    this.handler.hideLoader();
                    this.handler.error(error);
                });
            }
        });
    }

    propagatePayload(getClickValue) {
        var groupVal = this.firstFormGroup.value;
        this.testSuite.testCases = groupVal.testCases;

        const dialogRef = this.dialog.open(AssertionPropagationDialogComponent, {
            data: [this.testSuite, getClickValue]

        });

        dialogRef.componentInstance.emitData.subscribe(data1 => {
            if (data1 == 'Y') {
                this.dialogRef.close();
                localStorage.setItem('Propegation', "" + true);
                this.testSuiteService.propagatePayload(this.testSuite).subscribe(results => {

                    this.handler.hideLoader();
                    if (this.handler.handle(results)) {
                        return;
                    }
                    // this.dialogRef.close();
                    this.snackbarService.openSnackBar("Payload propagated to all (Default, Injection and Stored Injection) playbooks of the Endpoint successfully!", "");

                }, error => {
                    this.handler.hideLoader();
                    this.handler.error(error);
                });
            }
        });
    }

    currtestSuite: any;
    loadTestSuite(projectId: string, suiteName: string) {
        this.handler.activateLoader();
        this.testSuiteService.getByTestSuiteId(projectId, suiteName).subscribe(results => {
            this.handler.hideLoader();
            if (this.handler.handle(results)) {
                return;
            }
            this.testSuite = results['data'];
            this.currtestSuite = results['data']
            this.originalYamlData = this.testSuite.yaml;
            let k: TestCase = new TestCase();
            this.autoGenerated = this.testSuite['autoGenerated'];
            if (this.testSuite.testCases.length == 0) this.testSuite.testCases = [k];
            for (var i = 0; i < this.testSuite.testCases.length; i++) this.addItem1(this.testSuite.testCases[i]);


        }, error => {
            this.handler.hideLoader();
            this.handler.error(error);
        });
    }

    addItem1(obj): void {
        this.testCases = this.firstFormGroup.get('testCases') as FormArray;
        this.testCases.push(this.createItem1(obj));
    }

    createItem1(obj: TestCase): FormGroup {
        var k = this._formBuilder.group({
            id: obj.id,
            body: [obj.body, Validators.required]
        });
        return k;
    }

    deleteSuite() {
        // var r = confirm("Are you sure you want to delete this Playbook?");
        let dialogRef = this.dialog.open(DeleteDialogComponent, {
            width: "580px",
            height: "235px",
            data: ["Delete", "Are you sure you want to delete this Playbook?"]
        });
        dialogRef.componentInstance.emitData.subscribe(data1 => {
            if (data1 == 'Y') {
                this.snackbarService.openSnackBar("Deleting Playbook '" + this.testSuite.name.slice(0, 30) + "...'!", "");
                this.testSuiteService.delete(this.testSuite.id).subscribe(results => {
                    if (this.handler.handle(results)) {
                        return;
                    }
                    this.emitData.next([this.projectId]);
                    this.dialogRef.close();
                    this.snackbarService.openSnackBar("Playbook '" + this.testSuite.name.slice(0, 30) + "...' deleted successfully!", "");
                    // this.router.navigate(['/app/projects', this.projectId, 'Playbook']);
                }, error => {
                    this.handler.hideLoader();
                    this.handler.error(error);
                });
            }
        })
    }

    playbookCreatedFlag = GlobalPlaybookFlag;
    cloneTestSuite() {
        localStorage.removeItem('suitename');
        // this.emitData.next([this.projectId, this.testSuite.yaml]);
        if (this.router.url.split("/")[4] != 'playbooks') {

            const dialogRef2 = this.dialog.open(TestsuitNewDialogComponent, {
                width: '1200px',
                data: [this.projectId, this.testSuite.yaml]
            });


            dialogRef2.componentInstance.emitData.subscribe(result => {
                if (result[0] == this.projectId && result != null && result.length == 1) {
                    this.playbookCreatedFlag.playbookListFlag = '';
                    this.playbookCreatedFlag.playbookData = '';
                    this.dialog.closeAll();
                }
            });


        }
        else {
            this.emitData.next([this.projectId, this.testSuite.yaml]);
            this.dialogRef.close();
        }



        // this.router.navigate(['/app/projects', this.projectId, 'playbooks']);
        // const dialogRef = this.dialog.open(TestsuitNewDialogComponent, {
        //     width: '1200px',
        //     data: [this.projectId, this.testSuite.yaml]
        // });
    }


    //APIsec Playbook run
    run() {
        this.runFlag = true;
    }

    envlist(id: string) {
        this.handler.activateLoader();
        this.projectService.getEnvsByProjectId(id).subscribe(results => {
            this.handler.hideLoader();
            if (this.handler.handle(results)) {
                return;
            }
            this.envs = results['data'];
            if (this.envs && this.envs.length > 0 && this.globalSelectedEnv.envId == "") {
                this.env = this.envs[0];
                this.globalSelectedEnv.envId = this.env.id;
                this.envIdTemp = this.env.id;
            }
            else
                if (this.globalSelectedEnv.envId != "") {
                    this.envs.map(a => {
                        if (a.id == this.globalSelectedEnv.envId) {
                            this.env = a;
                            this.envIdTemp = this.env.id;
                            return;
                        }

                    })
                }

            setTimeout(() => {
                this.getSelectedEnv('');
            }, 500);


        }, error => {
            this.handler.hideLoader();
            this.handler.error(error);
        });
    }

    getSelectedEnv(evt) {

        if (evt) {
            this.envs.map(e => {
                if (e.id == evt) {
                    this.env = e;
                    return;
                }

            })
        }

        this.globalSelectedEnv.envId = this.env.id;

        if (this.env.baseUrl) {

            if (this.env.baseUrl == '127.0.0.1' || this.env.baseUrl.split("//")[1].split(":")[0] == '127.0.0.1') {
                this.secondFormGroup.controls.baseUrlCtrl.markAllAsTouched();
                this.secondFormGroup.controls.baseUrlCtrl.setErrors({ 'incorrect': true });

            }
            if (this.env.baseUrl.split("//")[1].split(".").length < 2 && this.env.baseUrl.split("//")[1].split(":").length < 2) {
                this.secondFormGroup.controls.baseUrlCtrl.markAllAsTouched();
                this.secondFormGroup.controls.baseUrlCtrl.setErrors({ 'incorrect': true });

            }
            if (this.env.baseUrl.indexOf('<host>') >= 0 || this.env.baseUrl.indexOf('<port>') >= 0 || this.env.baseUrl.split('//')[1].split('.')[1]?.length < 1) {
                this.secondFormGroup.controls.baseUrlCtrl.markAllAsTouched();
                this.secondFormGroup.controls.baseUrlCtrl.setErrors({ 'incorrect': true });

            }

            // if (this.env.baseUrl.split("//")[1].split(".").length < 2 && this.env.baseUrl.split("//")[1].split(":").length < 2) {
            //     this.secondFormGroup.controls.baseUrlCtrl.markAllAsTouched();
            //     this.secondFormGroup.controls.baseUrlCtrl.setErrors({ 'incorrect': true });

            // }
            // if (this.env.baseUrl.indexOf('<host>') >= 0 || this.env.baseUrl.indexOf('<port>') >= 0) {
            //     this.secondFormGroup.controls.baseUrlCtrl.markAllAsTouched();
            //     this.secondFormGroup.controls.baseUrlCtrl.setErrors({ 'incorrect': true });

            // }
        }

        // this.env.id = event; 
        // this.envs.map(a => {
        //     if (a.id == event) {
        //         this.env = a;
        //         return;
        //     }

        // })

    }

    getSelectedScan(event) {
        if (event != '') {
            this.option.name = event;
            this.glob.Scan_Global = this.option.name;
        }


        this.list.map(reg => {
            if (reg.name == this.option.name && reg.status == 'INACTIVE') {
                this.secondFormGroup.controls.selectedScanner.markAllAsTouched();
                this.secondFormGroup.controls.selectedScanner.setErrors({ 'incorrect': true });

            }
        })
    }

    env_id;
    regions_obj;

    testSuiterun() {
        this.selectedTab = 2;
        // this.addClass(this.selectedTab);
        // for (var i = 0; i < this.envs.length; i++) {
        //     if (this.envs[i].name == this.env.name) {
        //         this.env_id = this.envs[i].id;
        //     }
        // }
        for (var i = 0; i < this.list.length; i++) {
            if (this.list[i].name == this.option.name) {
                this.regions_obj = this.list[i];
            }
        }

        this.runFlag = true;
        this.processing = true;
        this.runResult = new Array();
        if (this.regions_obj) {
            this.region = this.regions_obj['org']['name'] + "/" + this.regions_obj['name'];
        }
        // this.runService.advTestSuiteRun(this.region, this.environment, this.data).subscribe(results => {
        this.runService.advTestSuiteRun(this.region, this.env.id, this.testSuite).subscribe(results => {
            this.handler.hideLoader();
            if (this.handler.handle(results)) {
                this.processing = false;
                return;
            }
            this.runResult = results['data'];

            this.processing = false;
        }, error => {
            this.processing = false;
            this.handler.error(error);

        });
    }

    getRegions() {
        this.handler.activateLoader();
        this.regionService.getEntitled(this.page, this.pageSize).subscribe(results => {
            this.handler.hideLoader();
            if (this.handler.handle(results)) {
                return;
            }
            this.list = results['data'];
            if (this.list && this.list.length > 0 && this.glob.Scan_Global == "") {
                this.option.name = this.list[0].name;
                this.glob.Scan_Global = this.option.name;
            }

            this.getSelectedScan('');
        }, error => {
            this.handler.hideLoader();
            this.handler.error(error);
        });
    }

    rowTemplateText(logeditor) {
        var row = logeditor.getEditor().session.getLine(logeditor.getEditor().selection.getCursor().row);
        if (row.charAt(27) != "[") return "";
        var tempText = row.substring(28, 125).trim();
        var trow = tempText.split("]");
        if (trow.length >= 1) tempText = trow[0];
        else tempText = "";
        if (tempText == "") return "";
        return tempText;
    }

    openFileDialog(temp) {

        if (temp.validEvent && !temp.prevExecuted) {
            this.editorSelect = temp;

            this.handler.activateLoader();

            this.testSuiteService.searchTestSuite(this.projectId, '', '', '', this.editorSelect.text, 0, 10).subscribe(results => {
                this.handler.hideLoader();
                if (this.handler.handle(results)) {
                    return;
                }

                for (var i = 0; i < results['data'].length; i++) {
                    var k = results['data'][i];
                    if (k.name == this.editorSelect.text) {
                        this.testSuite2 = k;
                        break;

                    }
                }
                if (this.testSuite2 != null)
                    this.showTSDialog(this.testSuite2.id, this.testSuite2.name, this.testSuite2['autoGenerated']);
                setTimeout(() => {
                    this.editorSelect.exec = false;
                }, 3000);

            }, error => {
                this.handler.hideLoader();
                this.handler.error(error);
            });
        }

    }

    editorRecursiveEvent() {
        if (this.logeditor != null) {
            var temp = { rowText: '', text: '', exec: false, row: 0, column: 0, validEvent: false, prevExecuted: true };
            temp.rowText = this.logeditor.getEditor().session.getLine(this.logeditor.getEditor().selection.getCursor().row);
            temp.row = this.logeditor.getEditor().selection.getCursor().row;
            temp.column = this.logeditor.getEditor().selection.getCursor().column;
            temp.text = this.rowTemplateText(this.logeditor);
            var tStartIndex = temp.rowText.indexOf(temp.text)
            var tLastIndex = tStartIndex + temp.text.length;
            if (temp.text != '' && tStartIndex > 0 && temp.column > tStartIndex && temp.column < tLastIndex) temp.validEvent = true;

            if (temp.validEvent && temp.row != this.editorSelect.row) temp.prevExecuted = false;
            this.openFileDialog(temp)
        }
    }

    showTSDialog(testSuiteID, testSuiteName, autoGenerated) {
        const dialogRef = this.dialog.open(TestsuitEditDialogComponent, {
            width: '1200px',
            height: '90%',
            data: [this.projectId, testSuiteID, testSuiteName, autoGenerated, this.hideoptions]
        });
    }

    markAsReviewed() {
        this.testSuite.reviewStatus = "MANUAL";
        this.saveTestSuite("");
    }

    disableNext: boolean = false;
    disablePrev: boolean = false;
    getNextPlaybook() {
        this.runFlag = false;
        if (this.testsuitesNames && this.testsuitesNames.length > 0) {
            this.suitePosition = this.testsuitesNames.indexOf(this.currentSuiteName);
            this.suitePosition = this.suitePosition + 1;
            if (this.suitePosition < this.testsuitesNames.length) {
                this.currentSuiteName = "";
                this.currentSuiteName = this.testsuitesNames[this.suitePosition];
                this.loadTestSuite(this.projectId, this.currentSuiteName);
            } else {
                window.alert('Reached end of the page');
            }
        }
    }

    getPrevPlaybook() {
        this.runFlag = false;
        if (this.testsuitesNames && this.testsuitesNames.length > 0) {
            this.suitePosition = this.testsuitesNames.indexOf(this.currentSuiteName);
            this.suitePosition = this.suitePosition - 1;
            if (this.suitePosition >= 0) {
                this.currentSuiteName = "";
                this.currentSuiteName = this.testsuitesNames[this.suitePosition];
                this.loadTestSuite(this.projectId, this.currentSuiteName);
            } else {
                window.alert('Reached beginning of the page');
            }
        }
    }

    changeStatus() {
        this.originalYamlData = this.testSuite.yaml
        this.testSuite.inactive = !this.testSuite.inactive;
        var yaml1 = parse(this.testSuite.yaml);
        yaml1.inactive = !yaml1.inactive;
        this.testSuite.yaml = stringify(yaml1);

        this.saveTestSuite(this.testSuite.inactive + "");
    }

    length = 0;
    page = 0;
    pageSize = 25;
    show = false;

}
