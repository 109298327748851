import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Routes, RouterModule, Router, ActivatedRoute } from "@angular/router";
import { AccountService } from '../../../services/account.service';
import { OrgService } from '../../../services/org.service';
import { SnackbarService } from '../../../services/snackbar.service';
import { Account } from '../../../models/account.model';
import { Handler } from '../../dialogs/handler/handler';
import { MixpanelService } from '../../../services/mixpanel.service';


@Component({
  selector: 'app-slack-register',
  templateUrl: './slack-register.component.html',
  styleUrls: ['./slack-register.component.scss'],
  providers: [AccountService, OrgService, SnackbarService]
})
export class SlackRegisterComponent implements OnInit {
  entry: Account = new Account();
  isValid: boolean = true;
  hide: boolean = true;
  // defaultCredentials: any = "Slack"
  constructor(private accountService: AccountService,
    private handler: Handler,
    private snackbarService: SnackbarService,
    private route: ActivatedRoute,
    private router: Router, private mixpanelService: MixpanelService,
    public dialogRef: MatDialogRef<SlackRegisterComponent>,
    private dialog: MatDialog) {
  }

  ngOnInit() {
  }

  create() {

    //mixpanel code starts
    var obj = {
      "notificationType": this.entry.accountType
    }
    this.mixpanelService.trackMixpanel("NewNotification", obj);
    //mixpanel code ends
    this.entry.accountType = 'Slack';
    this.handler.activateLoader();
    this.snackbarService.openSnackBar("'" + this.entry.name + "' registering...", "");
    this.accountService.create(this.entry).subscribe(results => {
      this.handler.hideLoader();
      if (this.handler.handle(results)) {
        return;
      }
      this.snackbarService.openSnackBar("'" + this.entry.name + "' registered successful", "");
      this.onClose();

    }, error => {
      this.handler.hideLoader();
      this.handler.error(error);
    });
  }

  onClose() {
    this.dialogRef.close();
  }

  changeValue(valid: boolean) {
    this.isValid = valid;
  }



}
