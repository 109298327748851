import { Component, OnInit, Inject, EventEmitter, Output } from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";

@Component({
  selector: "app-alert-dialog",
  templateUrl: "./alert-dialog.component.html",
  styleUrls: ["./alert-dialog.component.scss"],
})
export class AlertDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<AlertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
  @Output() emitData = new EventEmitter<string>();
  ngOnInit(): void {}
  cancel() {
    if (this.data[0] && this.data[0] != "Confirmation") {
      this.dialogRef.close();
    } else {
      this.emitData.next("Confirmation");
      this.dialogRef.close();
    }
  }
}
