import { Component, OnInit, Inject, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, NgModel } from '@angular/forms';
import { TestSuiteService } from '../../../services/test-suite.service';
import { RunService } from '../../../services/run.service';
import { ProjectService } from '../../../services/project.service';
import { Handler } from '../../dialogs/handler/handler';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { ProjectSync } from '../../../models/project-sync.model';
import { SnackbarService } from '../../../services/snackbar.service';
import { MixpanelService } from '../../../services/mixpanel.service';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';
@Component({
  selector: 'app-auto-sync',
  templateUrl: './auto-sync.component.html',
  styleUrls: ['./auto-sync.component.scss'],
  providers: [TestSuiteService, RunService, ProjectService, SnackbarService]
})
export class AutoSyncComponent implements OnInit {

  id; // project id
  checked = false;
  showCategories1 = false;
  showCategories2 = false;
  projectId: string = '';
  showSpinner: boolean = false;
  projectSync: ProjectSync = new ProjectSync();
  deleteAllChecked = false;
  indeterminateChecked = false;
  createIndeterminateFlag = false;
  deleteIndeterminateFlag = false;
  deleteOrCreate: boolean = true;
  checked2: boolean = false;
  checked3: boolean = false;
  project;
  autoCodeSyncObj;
  autoCodeSyncDeleteObj;
  createCategories = new FormControl();
  deleteCategories = new FormControl();
  generatorTypes = [];
  generatorTypesDelete = [];
  createAllFlag: boolean = false;
  deleteAllFlag: boolean = false;
  deleteCustomFlag: boolean = false;
  radioSelected = 'create';
  @Output() emitData = new EventEmitter<string>();

  categories = ['SimpleGET', 'Custom', 'SLA', 'Negative', 'Unsecured', 'ADoS', 'XSS_Injection', 'SQL_Injection', 'Log_Forging', 'RBAC', 'ABAC_Level1', 'ABAC_Level2', 'ABAC_Level3', 'ABAC_Level4', 'ABAC_Level5', 'ABAC_Level6', 'Hijack_Level1', 'NoSQL_Injection'];
  // if a new category is added to above array , please modify the this.projectSync.categories condition in selectionchange method.

  constructor(private testSuiteService: TestSuiteService, private runService: RunService, private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any, private mixpanelService: MixpanelService,
    private projectService: ProjectService, private route: ActivatedRoute, private router: Router, private handler: Handler, private snackbarService: SnackbarService, public dialogRef: MatDialogRef<AutoSyncComponent>) { }

  ngOnInit() {
    this.project = this.data[0];
    this.autoCodeSyncObj = this.data[1];
    this.autoCodeSyncDeleteObj = this.data[2];
    this.route.params.subscribe((params) => {
      this.id = params['id'];
    });

    for (const t of this.autoCodeSyncObj) {
      this.generatorTypes.push(t.type);
    }

    for (const t of this.autoCodeSyncDeleteObj) {
      this.generatorTypesDelete.push(t.type);
    }
  }

  // selectAll(select: NgModel, values, array) {
  //   if (this.checked){
  //      select.update.emit(values);
  //      this.projectSync.deleteAll = true;
  //      this.deleteAllChecked=true;
  //   }
  //   if (!this.checked){
  //       select.update.emit([]);
  //       this.projectSync.deleteAll = false;
  //       this.deleteAllChecked=false;
  //   }
  //
  // }

  selectDeleteAll(ev) {
    if (ev.checked) {
      this.deleteAllFlag = true;
      this.deleteCategories.setValue(this.generatorTypesDelete);
    } else {
      this.deleteAllFlag = false;
      this.deleteCategories.setValue([]);
    }
  }

  selectDeleteCustom(ev) {
    if (ev.checked) {
      this.deleteCustomFlag = true;
    } else {
      this.deleteCustomFlag = false;
    }
  }

  selectionChangeDelete() {
    if (this.projectSync.categories.length === 0) {
      this.deleteAllFlag = false;
      this.deleteIndeterminateFlag = false;
    } else
      if (this.projectSync.categories.length < this.autoCodeSyncDeleteObj.length) {
        this.deleteIndeterminateFlag = true;
        this.deleteAllFlag = false;
      } else
        if (this.projectSync.categories.length === this.autoCodeSyncDeleteObj.length) {
          this.deleteAllFlag = true;
          this.deleteIndeterminateFlag = false;
        }
  }
  // selectionChange(){
  //
  //   if (this.projectSync.categories.length == 0) {
  //     this.indeterminateChecked = false;
  //   }
  //   else
  //   if (this.projectSync.categories.length<11) {
  //       this.projectSync.deleteAll = false;
  //       this.indeterminateChecked=true;
  //   }
  //   else
  //   if (this.projectSync.categories.length >=11) {
  //     this.indeterminateChecked=false;
  //     this.deleteAllChecked = true;
  //   }
  //
  // }

  synchronization() {
    if (this.radioSelected === 'delete') {
      if (this.deleteCustomFlag == true) {
        let dialogRef = this.dialog.open(DeleteDialogComponent, {
          width: "580px",
          height: "235px",
          data: ["Delete", "Are you sure you want to delete Custom playbooks also?"]
        });
        dialogRef.componentInstance.emitData.subscribe(data1 => {
          if (data1 == 'Y') {
            this.projectSync.deleteAutoGenerated = true;
            if (this.deleteCustomFlag == true) {
              this.projectSync.deleteCustom = true;
            } else {
              this.projectSync.deleteCustom = false;
            }
            this.projectSync.recreate = false;
            this.snackbarService.openBackgroundMsgBar("Playbook Delete operation is in progress and  can take several seconds to complete… ");
            this.syncPlaybooks();
          }
        })
      }
      else {
        this.projectSync.deleteAutoGenerated = true;
        this.projectSync.recreate = false;
        this.snackbarService.openBackgroundMsgBar("Playbook Delete operation is in progress and  can take several seconds to complete… ");
        this.syncPlaybooks();
      }
    } else if (this.radioSelected === 'create') {
      this.projectSync.deleteAutoGenerated = false;
      this.projectSync.deleteCustom = false;
      this.projectSync.recreate = true;
      this.snackbarService.openBackgroundMsgBar("This operation can take several minutes to generate playbooks...");
      this.syncPlaybooks();
    }
  }

  syncPlaybooks() {
    this.showSpinner = true;
    this.projectSync.projectId = this.project.id;
    // if (this.projectSync.categories.length == 0 && this.checked2) {
    //     this.projectSync.deleteAll = this.checked2;
    // }

    //mixpanel code starts

    var obj = {
      "deleteAllPlaybooks": this.projectSync.createCategories.length != 0 ? false : this.projectSync.deleteAutoGenerated,
      "createAllPlaybooks": this.projectSync.categories.length != 0 ? false : this.projectSync.recreate,
      "createCategoryPlaybooks": this.projectSync.createCategories,
      "deleteCategoryPlaybooks": this.projectSync.categories

    }
    this.mixpanelService.trackMixpanel("PlaybookManagement", obj);
    //mixpanel code ends

    this.dialogRef.close();
    this.handler.activateLoader();
    this.projectService.projectSync(this.projectSync).subscribe((results) => {
      this.handler.hideLoader();
      let response = [];
      let key1;
      response = results["messages"];
      if (response.length > 0) {
        key1 = response[0]['key'];
      }
      if (key1 == "taskInProgress") {
        let con = confirm("A task is already running. Triggering the task again might result in invalid playbooks. Do you want to continue.");
        if (con) {
          this.projectSync.stopTask = true;
          return this.synchronization();
        }

      }
      else {
        if (this.handler.handle(results)) {
          return;
        }
      }

      this.emitData.next(this.radioSelected);


      //this.snackbarService.openSnackBar("'" + this.project.name + "' syncing.... ", '');
    }, (error) => {
      this.handler.hideLoader();
      this.handler.error(error);
    });
  }

  selectCreateAll(ev) {
    if (ev.checked) {
      this.createAllFlag = true;
      this.createCategories.setValue(this.generatorTypes);
    } else {
      this.createAllFlag = false;
      this.createCategories.setValue([]);
    }

  }

  selectionChangeCreate() {
    if (this.projectSync.createCategories.length === 0) {
      this.createAllFlag = false;
      this.createIndeterminateFlag = false;
    } else
      if (this.projectSync.createCategories.length < this.autoCodeSyncObj.length) {
        this.createIndeterminateFlag = true;
        this.createAllFlag = false;
      } else
        if (this.projectSync.createCategories.length === this.autoCodeSyncObj.length) {
          this.createAllFlag = true;
          this.createIndeterminateFlag = false;
        }
  }

}
