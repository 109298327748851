import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { Project } from '../../../models/project.model';
import { AbacResource } from '../../../models/project-autocode-config.model';
import { Observable } from 'rxjs';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Handler } from '../handler/handler';
import { ProjectService } from '../../../services/project.service';
import { SnackbarService } from '../../../services/snackbar.service';
import { map, startWith } from 'rxjs/operators';
import { AbacService } from "../../../services/abac.service";
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-manage-abac-type2-response-dialog',
  templateUrl: './manage-abac-type2-response-dialog.component.html',
  styleUrls: ['./manage-abac-type2-response-dialog.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  providers: [ProjectService, SnackbarService]
})

export class ManageAbacType2ResponseDialogComponent implements OnInit {
  resourceData: any = [];
  dataSource = null;

  columnsToDisplay: string[] = ['responseStatus', 'ref'];
  expandedElement: dataSource | null;
  EndpointData: any;
  createEndpoint: any;

  constructor(private dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any, private handler: Handler,
    public dialogRef: MatDialogRef<ManageAbacType2ResponseDialogComponent>, private projectService: ProjectService,
    private _formBuilder: FormBuilder, private abacService: AbacService, private snackbarService: SnackbarService) {
  }
  @Output() emitData = new EventEmitter<string[]>();
  expandStatus: boolean = false;
   ngOnInit() {
    var ArrayData = [];
    ArrayData = this.data.resourceData;

      if (ArrayData && ArrayData.length > 0) {
        for (var i = 0; i < ArrayData.length; i++) {
          if (ArrayData[i].responseStatus == "200" || ArrayData[i].responseStatus == "201") {
            this.resourceData.push(ArrayData[i]);
          }
        }
        this.dataSource = new MatTableDataSource(this.resourceData);
        var indexValue = this.resourceData.findIndex(x => x.responseStatus === "200");
        this.expandedElement = this.dataSource.data[indexValue];
     }
   }
}

export interface dataSource {
  responseStatus: string;
  ref: string;
}
