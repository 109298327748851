import { RegisterComponent } from "./../../dialogs/register/register.component";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { ProjectService } from "../../../services/project.service";
import { AccountService } from "../../../services/account.service";
import { Project, Noti } from "../../../models/project.model";
import { Handler } from "../../dialogs/handler/handler";
import { APPCONFIG } from "../../../config";
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
} from "@angular/material/autocomplete";
import { MatChipInputEvent } from "@angular/material/chips";
import { MatDialog } from "@angular/material/dialog";
import { SnackbarService } from "../../../services/snackbar.service";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ChangeDetectorRef, Input } from "@angular/core";
import { Observable } from "rxjs";
import { Location } from "@angular/common";
import { IssueTrackerRegisterComponent } from "./../../dialogs/issue-tracker-register/issue-tracker-register.component";
import { OrgService } from "../../../services/org.service";
import { OrgUser } from "../../../models/org.model";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { map, startWith } from "rxjs/operators";
import { UpgradeComponent } from "../../dialogs/upgrade/upgrade.component";
import { GlobalProjectDetails } from "../../../shared/shared.data";
import { Account } from "../../../models/account.model";
import { MixpanelService } from "../../../services/mixpanel.service";

@Component({
  selector: "app-git-repo",
  templateUrl: "./git-repo.component.html",
  styleUrls: ["./git-repo.component.scss"],
  providers: [ProjectService, SnackbarService, AccountService, OrgService],
})
export class GitRepoComponent implements OnInit {
  id: string;
  project: Project = new Project();
  @Input() data: Observable<any>;
  accounts;
  firstFormGroup: FormGroup;
  public AppConfig: any;
  visibilities = ["PRIVATE", "ORG_PUBLIC"];
  orgUsers: OrgUser[] = [];
  skipGitbackupTmp: boolean;
  cloudShow: boolean = true;
  accountTypes = ["No_Issues", "FX_Issues", "GitHub", "Jira"];
  itAccounts: Array<Account> = [];

  // user entitlement
  visible = true;
  selectable = true;
  removable = true;
  loggedInUserRole;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  userEntitlementCtrl = new FormControl();
  filteredUsers: Observable<any>;
  allowedUsers = [];
  allUsers = [];

  constructor(
    private projectService: ProjectService,
    private accountService: AccountService,
    private route: ActivatedRoute,
    private handler: Handler,
    private snackbarService: SnackbarService,
    public dialog: MatDialog,
    private mixpanelService: MixpanelService
  ) {}

  ngOnInit() {
    this.AppConfig = APPCONFIG;
    this.getAccountsForProjectPage();
    this.route.params.subscribe((params) => {
      this.id = params["id"];
      if (this.id) {
        this.loadProject(this.id);
        this.loggedInUserRole = localStorage.getItem("orgRole");
        if (!this.loggedInUserRole)
          this.loggedInUserRole = localStorage.getItem("userRole");
        if (this.loggedInUserRole == "BASIC") {
          this.freeAcc = true;
          this.upgradeSubscription();
        }
      }
    });
  }

  globalProjectDetails = GlobalProjectDetails;
  loadProject(id: string) {
    this.handler.activateLoader();
    this.projectService.getById(id).subscribe(
      (results) => {
        this.handler.hideLoader();
        if (this.handler.handle(results)) {
          return;
        }
        this.project = results["data"];
        if (!this.project.account) {
          this.project.account = new Account();
        }
        this.globalProjectDetails.name = this.project.name;
        if (!this.project.account.id) this.project.url = "";
        else this.redirectURL(this.project.url);
        if (this.project.url == null) {
          this.skipGitbackupTmp = true;
        } else this.skipGitbackupTmp = this.project.skipGitbackup;
      },
      (error) => {
        this.handler.hideLoader();
        this.handler.error(error);
      }
    );
  }

  redirectURLValue: any;
  redirectURL(value) {
    if (value.includes("git@github.com:")) {
      let result = value.replace("git@github.com:", "");
      this.redirectURLValue = "https://github.com/" + result;
    } else this.redirectURLValue = this.project.url;
  }

  accountChanged(obj) {
    for (var i = 0; i < this.accounts.length; i++)
      if (this.accounts[i].id == obj) this.project.account = this.accounts[i];
  }

  freeAcc: boolean = false;
  save() {
    if (this.freeAcc) this.upgradeSubscription();
    else this.saveData();
  }

  saveData() {
    //   var checkUrl = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/;
    //   if(this.project.account.isSSH){
    //     checkUrl = /^[\w-\.]+@([\w-]+\.)+([\w-]{2,15}):([\w-\.]{2,30})+[/]([\w-\.]{2,15})+.git$/;
    //     if (this.project.url && !checkUrl.test(this.project.url)) {
    //       alert("Please enter valid SSH url");
    //       return false;
    //     }
    //   }
    //   else{
    //   if (this.project.url && !checkUrl.test(this.project.url)) {
    //     alert("Please enter valid url");
    //     return false;
    //   }
    // }
    //mixpanel code starts
    if (this.skipGitbackupTmp != this.project.skipGitbackup) {
      var obj = {
        projectName: this.project.name,
        active: this.skipGitbackupTmp,
      };
      this.mixpanelService.trackMixpanel("GithubBackup", obj);
      //mixpanel code ends
    }

    if (
      (this.project && this.project.account.accountType == "GitHub") ||
      this.project.account.accountType == "GitLab" ||
      this.project.account.accountType == "Git"
    )
      this.project.skipGitbackup = this.skipGitbackupTmp;
    var accObj = JSON.parse(JSON.stringify(this.project));
    accObj.account.accountType = "Git";

    this.handler.activateLoader();
    this.snackbarService.openSnackBar("Saving GitHub Backup details ... ", "");
    this.projectService.update(accObj).subscribe(
      (results) => {
        this.handler.hideLoader();
        if (this.handler.handle(results)) {
          return;
        }
        this.snackbarService.openSnackBar(
          "GitHub Backup details added to Project '" + this.project.name + "'.",
          ""
        );
        this.project = results["data"];
        this.skipGitbackupTmp = this.project.skipGitbackup;
        if (!this.project.account) {
          this.project.account = new Account();
        }
        this.loadProject(this.project.id);
      },
      (error) => {
        this.handler.hideLoader();
        this.handler.error(error);
      }
    );
  }

  getAccountsForProjectPage() {
    this.handler.activateLoader();
    this.accountService.getAccountByAccountType("PROJECT").subscribe(
      (results) => {
        this.handler.hideLoader();
        if (this.handler.handle(results)) {
          return;
        }
        this.accounts = results["data"];
        this.accounts.map((a) => {
          const currentDate = new Date(a.createdDate);
          const november2023 = new Date("october 31, 2023");
          const CD = currentDate > november2023;
          // console.log(a.createdDate+" "+ CD +" "+ a['accountType'])
          if (CD && a["accountType"] == "Git") {
            a.accountType = "GitHub";
          }
        });
      },
      (error) => {
        this.handler.hideLoader();
        this.handler.error(error);
      }
    );
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(RegisterComponent, {
      width: "800px",
      data: ["gitbackup"],
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.getAccountsForProjectPage();
    });
  }

  upgradeSubscription(): void {
    const dialogRef = this.dialog.open(UpgradeComponent, {
      width: "350px",
      disableClose: true,
    });
  }

  githubmixpnl() {
    var obj = {
      projectName: this.project.name,
      githubRepoURL: this.project.url,
    };
    this.mixpanelService.trackMixpanel("GithubRepoURLClicked", obj);
    //mixpanel code ends
  }
}
