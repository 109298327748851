import { Component, OnInit, Inject } from "@angular/core";
import { Router } from "@angular/router";
import { JobsService } from "../../../services/jobs.service";
import { Project } from "../../../models/project.model";
import { Jobs, Noti, Cron } from "../../../models/jobs.model";
import { AccountService } from "../../../services/account.service";
import { Account } from "../../../models/account.model";
import { Handler } from "../../dialogs/handler/handler";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { SnackbarService } from "../../../services/snackbar.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { RegionsService } from "../../../services/regions.service";
import { IssueTrackerRegisterComponent } from "./../../dialogs/issue-tracker-register/issue-tracker-register.component";
import { TestSuiteService } from "../../../services/test-suite.service";
import { CATEGORIES } from "./../../../shared/shared.data";
@Component({
  selector: "app-jobs-autovul-dialog",
  templateUrl: "./jobs-autovul-dialog.component.html",
  styleUrls: ["./jobs-autovul-dialog.component.scss"],
  providers: [
    SnackbarService,
    JobsService,
    AccountService,
    RegionsService,
    TestSuiteService
  ]
})
export class JobsAutovulDialogComponent implements OnInit {
  id: string;
  jobId: string;
  project: Project = new Project();
  projects;
  job: Jobs = new Jobs();
  jobs;

  itAccounts: Array<Account> = [];
  httpAccounts: Array<Account> = [];
  list;
  cat = CATEGORIES;
  page = 0;
  pageSize = 100;
  notifyAccounts: Account[];
  crons: Cron[] = [];
  entry: Account = new Account();

  regions: string[] = [
    "FXLabs/US_WEST_1",
    "FXLabs/US_WEST_2",
    "FXLabs/US_EAST_1",
    "FXLabs/US_EAST_2",
    "FXLabs/EU_WEST_1",
    "FXLabs/EU_CENTRAL_1",
    "FXLabs/SA_EAST_1"
  ];

  accountTypes: Type[] = [
    { value: "Service_Now", viewValue: "Service Now" },
    { value: "Jira", viewValue: "Jira" },
    { value: "Bugzilla", viewValue: "Bugzilla" },
    { value: "Pivotal_Tracker", viewValue: "Pivotal Tracker" },
    { value: "GitHub", viewValue: "GitHub" },
    { value: "GitLab_Issues", viewValue: "GitLab Issues" },
    { value: "FX_Issues", viewValue: "APIsec Issues" },
    { value: "No_Issues", viewValue: "No Issues" }
  ];
  JiraType: string[] = ["Bug", "Task", "Epic"];
  selectedAccountType = [];
  context: string = "New";
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  categories: string[] = [];
  category: string[];
  selectedCategories: string[] = [];
  categoryCount = [];

  constructor(
    private jobsService: JobsService,
    private accountService: AccountService,
    private regionService: RegionsService,
    private router: Router,
    private handler: Handler,
    private snackbarService: SnackbarService,
    private _formBuilder: FormBuilder,
    public dialog: MatDialog,
    private testSuiteService: TestSuiteService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<JobsAutovulDialogComponent>
  ) { }

  ngOnInit() {
    this.secondFormGroup = this._formBuilder.group({});

    this.thirdFormGroup = this._formBuilder.group({});
    this.job.notifications[0] = new Noti();
    this.job.notifications[1] = new Noti();
    this.job.notifications[2] = new Noti();
    this.job.notifications[2].channel = "SPLUNK";
    // for (let j of this.data) {
    //   this.id = j.project.id;
    //   this.jobId = j.id;
    // }
    this.jobId = this.data.id;
    this.loadJob();
  }

  loadJob() {
    this.handler.activateLoader();
    this.jobsService.getById(this.jobId).subscribe(
      results => {
        this.handler.hideLoader();
        if (this.handler.handle(results)) {
          return;
        }
        this.job = results["data"];
        if (this.job.categories) {
          this.selectedCategories = this.job.categories
            .split(",")
            .map(function (item) {
              return item.trim();
            });
        }
        if (this.job.categories) {
          this.category = this.job.categories.split(",").map(function (item) {
            return item.trim();
          });
        }
        this.getITAccountsByAccountType();
      },
      error => {
        this.handler.hideLoader();
        this.handler.error(error);
      }
    );
  }

  changeIssueTracker(type) {
    if (type.value === "No_Issues") {
      this.job.issueTracker.accountType = "No_Issues";
    } else if (type.value === "FX_Issues") {
      this.job.issueTracker.accountType = "FX_Issues";
    }
    if (type.value === "GitHub") {
      this.job.issueTracker.accountType = "GitHub";
    } else if (type.value === "Jira") {
      this.job.issueTracker.accountType = "Jira";
    } else if (type.value === "Bugzilla") {
      this.job.issueTracker.accountType = "Bugzilla";
    } else if (type.value === "Pivotal_Tracker") {
      this.job.issueTracker.accountType = "Pivotal_Tracker";
    } else if (type.value === "Service_Now") {
      this.job.issueTracker.accountType = "Service_Now";
    } else if (type.value === "GitLab_Issues") {
      this.job.issueTracker.accountType = "GitLab_Issues";
    }
    this.getITAccountsByAccountType();
  }

  getITAccountsByAccountType() {
    this.handler.activateLoader();
    this.accountService.getAccountByAccountType("ISSUE_TRACKER").subscribe(
      results => {
        this.handler.hideLoader();
        if (this.handler.handle(results)) {
          return;
        }
        this.itAccounts = new Array();
        for (let entry of results["data"]) {
          if (entry.accountType == this.job.issueTracker.accountType) {
            this.itAccounts.push(entry);
          }
        }
      },
      error => {
        this.handler.hideLoader();
        this.handler.error(error);
      }
    );
  }

  openDialogITCredentials() {
    const dialogRef = this.dialog.open(IssueTrackerRegisterComponent, {
      width: "800px",
      data: this.accountTypes
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getITAccountsByAccountType();
    });
  }

  saveJob() {
    if(this.job.issueTracker.accountType === 'GitLab_Issues'){
      this.job.issueTracker.url="https://gitlab.com";
    }
    this.handler.activateLoader();
    this.snackbarService.openSnackBar(
      "Job '" + this.job.name + "' Saving...",
      ""
    );
    this.jobsService.update(this.job, this.category).subscribe(
      results => {
        this.handler.hideLoader();
        if (this.handler.handle(results)) {
          return;
        }
        this.snackbarService.openSnackBar(
          "Job '" + this.job.name + "' Saved.",
          ""
        );
        this.dialogRef.close();
      },
      error => {
        this.handler.hideLoader();
        this.handler.error(error);
      }
    );
  }
}
export interface Type {
  value: string;
  viewValue: string;
}
