import { Component, OnInit, Inject } from "@angular/core";
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SnackbarService } from "../../../services/snackbar.service";

@Component({
  selector: "app-jenkins-integration",
  templateUrl: "./jenkins-integration.component.html",
  styleUrls: ["./jenkins-integration.component.scss"],
  providers: [SnackbarService]
})
export class JenkinsIntegrationComponent implements OnInit {
  integration: string =
    //"wget https://raw.githubusercontent.com/intesar/FX-Scripts/master/fx_job_invoke_script.sh?token=AHILVH7YY7DVETVRLYXBYJK6FK7TC -O fx_job_invoke_script.sh;bash fx_job_invoke_script.sh {Username} {Password}";
    "wget https://raw.githubusercontent.com/apisec-inc/apisec-scripts/master/apisec_job_invoke_script.sh?token=AVOLEQ4FVVPJJ3DPAME4JELBS56SI -O apisec_job_invoke_script.sh;bash apisec_job_invoke_script.sh --username {Username} --password {Password}";
  integration2: string = "--emailReport true --reportType RUN_SUMMARY";
  pShell: string =
    "curl https://raw.githubusercontent.com/intesar/Fx-Docker-Script/master/fx_job_invoke_script.ps1?token=AHILVH2INCFKIZNSYLSYMA26FLBBM -o fx_job_invoke_script.ps1";
  pShell2: string =
    "powershell -File fx_job_invoke_script.ps1 {Username} {Password}";
  radioSelected = "shell";

  constructor(
    private snackbarService: SnackbarService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<JenkinsIntegrationComponent>
  ) { }

  ngOnInit() {

    // this.pShell2 = this.pShell2 + {{data.jobId}} {{data.regions}} {{data.envId}} {{data.projectId}}
    this.integration = this.integration + " --project " + this.data.projectName + " --profile " + this.data.name + " --scanner " + this.data.regions + " " + this.integration2;
  }

  // copyToClipboard(element) {
  //   var $temp = $("<input>");
  //   $("body").append($temp);
  //   $temp.val($(element).text()).select();
  //   document.execCommand("copy");
  //   this.snackbarService.openSnackBar("Copied text to clipboard ", "");
  //   $temp.remove();
  // }

  showMsg() {
    this.snackbarService.openSnackBar('Copied text to clipboard ', '');

  }
}
