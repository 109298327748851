import { Component, OnInit, Inject, ViewChild, Output, EventEmitter } from '@angular/core';
// import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// import { RegionsService } from '../../../services/regions.service';
import { Routes, RouterModule, Router, ActivatedRoute } from '@angular/router';
import { SnackbarService } from '../../../services/snackbar.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ProjectService } from '../../../services/project.service';
import { Handler } from '../handler/handler';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { ApiGroups } from '../../../services/api-groups.service';

@Component({
  selector: 'api-group-list-dialog',
  templateUrl: './api-group-list-dialog.component.html',
  styleUrls: ['./api-group-list-dialog.component.scss'],
  providers: [SnackbarService, ProjectService, ApiGroups]
})
export class ApiGroupListDialogComponent implements OnInit {


  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<ApiGroupListDialogComponent>, private handler: Handler, private snackbarService: SnackbarService, private projectService: ProjectService, private apiGroups: ApiGroups) { }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Output() emitData = new EventEmitter<string>();
  page = 0;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 25, 100];
  sortString: string = 'createdDate';
  sortType: string = 'DESC';
  tag: string = "";
  ApiGroupListData;
  // dataSource = null;
  projectId;
  projectLength1;
  apiData
  testId
  newId = [];
  selectedGroupName: any = [];
  test123
  NewArray = [];
  filteredData = [];
  length: number = 0;
  displayedColumns: string[] = ['add', 'name'];
  ngOnInit() {
    this.getAllAPIGroups()
  }

  close() {
    this.dialogRef.close();
  }

  keyword: any = "";
  // getObjectById(arr, id) {
  //   return arr.find(obj => obj.id === id);
  // }
  selected212

  apiGroupListData
  subApiGroupData

  getAllAPIGroups() {
    this.filteredData = []
    this.apiGroups.getGroups(this.page, 100, this.sortString, this.sortType).subscribe(results => {

      this.apiGroupListData = results["data"].content;
      // if (Array.isArray(this.data['subGroupId']) && this.data['subGroupId'].length > 0) {

      //for edit of an apigroup
      if (this.data) {
        this.apiGroupListData.forEach(obj1 => {
          this.data['subGroupId'].map(i => {
            if (i == obj1.id) {
              obj1['ischeck'] = true;
              this.filteredData.push(obj1)
            }

          })



          // this.filteredData = obj1.subApiGroups.map(newObj => {
          //   newObj.parentApiGroupId != null && newObj.parentApiGroupId === obj1.id
          //   //   (item.ParentApiGroupID != null && item.ParentApiGroupID != item.id));
          //   // this.filteredData.push(newObj);
          //   console.log('fileterd Data', this.filteredData);
          // })
          // this.filteredData.push(grp);

        });
        this.apiGroupListData.map(obj => {
          if (obj.parentApiGroupId == null)
            this.filteredData.push(obj);
        })
        // this.filteredData = this.apiGroupListData.sort((p1, p2) => (p1.ischeck < p2.ischeck) ? 1 : (p1.ischeck > p2.ischeck) ? -1 : 0);

      }



      //for new apigroup
      if (this.data == null) {
        this.apiGroupListData.map(grp => {
          if (grp.parentApiGroupId == null)
            this.filteredData.push(grp);
        })


      }



      // SubAPIGroup.ParentApiGroupID != null and SubAPIGroup.ParentApiGroupID != ApiGroupEditID
      // this.pageSize = results['data']['pageable'].pageSize;
      this.subApiGroupData = new MatTableDataSource(this.filteredData);
      this.subApiGroupData.paginator = this.paginator;
      this.length = this.filteredData.length;

      this.pageSize = results['data'].size;
    },

      error => {
        this.handler.hideLoader();
        this.handler.error(error);
      }
    )

  }

  change(evt) {
    this.page = evt['pageIndex'];
    this.pageSize = evt.pageSize;
    this.getAllAPIGroups();
  }
  applyFilter(filterValue: string) {

    filterValue = filterValue.trim().toLowerCase();
    const escapedFilterValue = filterValue.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&');
    const regex = new RegExp(escapedFilterValue, 'i');
    this.subApiGroupData.filter = filterValue;
    if (this.subApiGroupData.paginator) {
      this.subApiGroupData.paginator.firstPage();
    }

  }



  onSelectRow(item, event: any) {
    if (event.checked) {
      item.ischeck = event.checked

      this.selectedGroupName.push(item);
      this.apiGroupListData.forEach(element => {
        if (item.id == element.id) {
          element.ischeck = true;
        }
      })
    } else {
      this.selectedGroupName = this.selectedGroupName.filter((selected) => selected.ischeck == true);
      this.apiGroupListData.forEach(element => {
        if (item.id == element.id) {
          element.ischeck = false;
        }
      })
      const index = this.selectedGroupName.indexOf(item);
      if (index !== -1) {
        this.selectedGroupName.splice(index, 1);
      }
    }
  }
  onAddClick() {
    this.selectedGroupName = [];
    this.apiGroupListData.forEach(element => {
      if (element.ischeck == true) {
        this.selectedGroupName.push(element)
      }
    });


    this.emitData.next(this.selectedGroupName);
    this.dialogRef.close(this.selectedGroupName);

  }

}


