import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {map, startWith} from "rxjs/operators";
import {SnackbarService} from "../../../services/snackbar.service";
import {Project} from "../../../models/project.model";
import {Observable} from "rxjs/Observable";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {AbacService} from "../../../services/abac.service";
import {Handler} from "../handler/handler";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { AbacResource, AbacScripts } from "../../../models/project-autocode-config.model";
import {ProjectService} from "../../../services/project.service";
import {NestedResourceComponent} from "../nested-resource/nested-resource.component";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {ManageAbacType2ResponseDialogComponent} from "../manage-abac-type2-response-dialog/manage-abac-type2-response-dialog.component";

@Component({
  selector: 'app-manage-hijack1',
  templateUrl: './manage-hijack1.component.html',
  styleUrls: ['./manage-hijack1.component.scss'],
   animations: [
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0', display: 'none'})),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
   ],
   providers: [ProjectService, SnackbarService]
})


export class ManageHijack1Component implements OnInit {
    project: Project = new Project();
    abacResource: AbacResource = new AbacResource();
    projectId: string;
    resourceData:any;
    endpointData:any;
    resourceList: string[]=[];
    filteredOptions: Observable<string[]>;
    filteredOptionsEndpoint: Observable<string[]>;
    filteredOptionsEndpointR: Observable<string[]>;
    filteredOptionsEndpointD: Observable<string[]>;

    firstFormGroup:FormGroup;
    endpoint;
    body;
    bodyR;
    useridR = "";
    endpointR;
    endpointD;
    selectedEntryIndex;
    @Output() emitData = new EventEmitter<string[]>();

    endpointList: string[]=[];
    endpointListDelete: string[]=[];
    entryDataSource = null;
    expandedElement: entryDataSource | null;
    columnsToDisplay:string[] = ['userAuth','endpoint','inactive', 'deleteHeader'];
    userIdList = [];
    disallowTableData = [];
    cloneFlag:boolean = true;
    enumValuesArray = [];
    selectedEnum = '';
    selectedEnumType = '';
    enumList = '';
    enumObject = [];
    enumTypeInScriptName = [];
    enumTypeTemplateName='';
    resourceName = '';
    bodyKeys = [];
    bodyJSONObj;


    constructor(private dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any, private handler: Handler,
                public dialogRef: MatDialogRef<ManageHijack1Component>, private projectService: ProjectService,
                private _formBuilder: FormBuilder ,private abacService:AbacService, private snackbarService: SnackbarService) {

    }

    ngOnInit() {

        this.firstFormGroup = new FormGroup({
            myControlResource: new FormControl(),
            myControlEndpoint: new FormControl(),
            myControlEndpointR: new FormControl(),
            myControlEndpointD: new FormControl(),
            creatorCtrl: new FormControl(),
            useridRCtrl: new FormControl(),
            methodCtrl: new FormControl(),
            methodRCtrl: new FormControl(),
            methodDCtrl: new FormControl(),
            bodyCtrl: new FormControl(),
            bodyRCtrl: new FormControl(),
            enumValues: new FormControl()

        });

        this.projectId = this.data.projectId;
        this.abacResource.generatorId = this.data.genId;
        this.endpointData = this.data.endpointsData;
        if(this.data.resourceObj)
            this.abacResource = JSON.parse(this.data.resourceObj);
        if(this.abacResource.id != null)
            this.cloneFlag = true;
        else
            this.cloneFlag = false;

        this.resourceData = this.data.resourceData ;
        if(this.resourceData.length != 0)
            this.createResourceListFilter();
        else
            this.getResourceList();


        if(this.endpointData.length != 0)
            this.createEndpointFilters();
        else
            this.getEndpointList();

        if(this.abacResource.resourceName != ""){
            this.resourceName = this.abacResource.resourceName;
            this.endpointR = ""; //this.abacResource.createEndpoint;

            if(this.abacResource.validations != null && this.abacResource.validations.length > 0 ) {
                let tmpEndpoint = this.abacResource.validations[0].endpoint;
                this.useridR = this.abacResource.validations[0].userAuth;
                for(var i=1 ;i <this.abacResource.validations.length;i++)
                {
                    if(tmpEndpoint == this.abacResource.validations[i].endpoint)
                        this.useridR =this.useridR + "," + this.abacResource.validations[i].userAuth ;
                    else
                        break;
                }
            }
            this.entryDataSource = this.abacResource.validations;
            this.entryDataSource = new MatTableDataSource(this.entryDataSource);
            this.enumTypeTemplateName = this.abacResource.initScriptName.split(this.abacResource.resourceName)[1].split('Create')[0];
            this.bodyJSONObj= JSON.parse(this.abacResource.createBody);
            this.body = this.abacResource.createBody;
            //get keys
            this.bodyKeys= Object.keys(this.bodyJSONObj);
        }
        else
        {
            this.useridR = this.data.denyRoles;
            this.abacResource.createUserAuth = this.data.allowRoles;
            this.abacResource.lock = true;
        }
    }


    save(){

        this.abacResource.initScriptName = this.resourceName +  this.enumTypeTemplateName + "Create" + this.abacResource.createUserAuth + "InitHijack1";
        this.abacResource.resourceName = this.resourceName + this.enumTypeTemplateName;
        this.handler.activateLoader();
        this.snackbarService.openSnackBar("Resource '" + this.abacResource.resourceName + "' Saving...", "");
        this.abacService.saveAbacData(this.abacResource).subscribe(results => {
            this.handler.hideLoader();
            if (this.handler.handle(results)) {
                return;
            }
            this.snackbarService.openSnackBar("Resource '" + this.abacResource.resourceName + "' Saved.", "");
            this.emitData.next([this.data.projectId, ''])
            this.dialogRef.close();
        }, error => {
            this.handler.hideLoader();
            this.handler.error(error);
        });


    }

    getResourceList(){
        this.handler.activateLoader();
        this.projectService.getResourceList(this.projectId).subscribe(results => {
            this.handler.hideLoader();
            if (this.handler.handle(results)) {
                return;
            }
            this.resourceData = results['data'];
            this.createResourceListFilter();

        }, error => {
            this.handler.hideLoader();
            this.handler.error(error);
        });
    }

    createResourceListFilter(){

        this.filteredOptions = this.firstFormGroup.controls['myControlResource'].valueChanges.pipe(
            startWith(''),
            map(value => value? this._filter(value) : this.resourceData.slice())
        );
    }

    private _filter(value: string) {
        const filterValue = value.toLowerCase();
        return this.resourceData.filter(option => option.type.toLowerCase().indexOf(filterValue) === 0);
    }

    loadBody(event,selectedResource){
        if(event.source.selected) {
            this.abacResource.createBody = selectedResource.body;
            this.body = this.abacResource.createBody;
            this.abacResource.createEndpoint = selectedResource.method +':' + selectedResource.endpoint;
            this.abacResource.typeThreeCreateEndpoint = this.abacResource.createEndpoint;

            if(selectedResource.enums != null)
                this.loadEnumValuesArray(selectedResource.enums);
        }
    }
    createEndpointFilters(){
        for(let r1 of this.endpointData)
        {
            this.endpointList.push(r1['method'] + ":" + r1['endpoint'])
            if(r1['method'] == 'DELETE')
                this.endpointListDelete.push(r1['method'] + ":" + r1['endpoint'])
        }

        this.filteredOptionsEndpoint = this.firstFormGroup.controls['myControlEndpoint'].valueChanges
            .pipe(
                startWith(''),
                // map(value => value? this._filterEndpoint(value) : this.resourceData.slice())
                map(value => this._filterEndpoint(value))
            );

        this.filteredOptionsEndpointR = this.firstFormGroup.controls['myControlEndpointR'].valueChanges
            .pipe(
                startWith(''),
                map(value => this._filterEndpointR(value))
            );

        this.filteredOptionsEndpointD = this.firstFormGroup.controls['myControlEndpointD'].valueChanges
            .pipe(
                startWith(''),
                map(value => this._filterEndpointD(value))
            );
    }
    getEndpointList() {
        this.handler.activateLoader();
        this.projectService.getEndpoints(this.projectId).subscribe(results => {
            this.handler.hideLoader();
            if (this.handler.handle(results)) {
                return;
            }
            this.endpointData = results['data'];
            this.createEndpointFilters();
        }, error => {
            this.handler.hideLoader();
            this.handler.error(error);
        });
    }

    private _filterEndpoint(value: string): string[] {
        const filterValue = value.toLowerCase();

        return this.endpointList.filter(option => option.toLowerCase().includes(filterValue));
    }

    private _filterEndpointR(value: string): string[] {
        const filterValue = value.toLowerCase();

        return this.endpointList.filter(option => option.toLowerCase().includes(filterValue));
    }

    private _filterEndpointD(value: string): string[] {
        const filterValue = value.toLowerCase();

        return this.endpointListDelete.filter(option => option.toLowerCase().includes(filterValue));
    }

    addEntry(){
        if(this.entryDataSource != null)
            this.disallowTableData = this.entryDataSource.data;
        else
            this.disallowTableData = [];

        var tempObj;
        this.userIdList = this.useridR.split(",");
        for(let entry of this.userIdList)
        {
            tempObj = {
                userAuth : entry,
                endpoint: this.endpointR,
                path: this.endpointR,
                body:this.bodyR,
                inactive  : false,
                validationType: "Disallow"
            };

            this.disallowTableData.push(tempObj)
        }
        this.endpointR = "";
        this.entryDataSource = new MatTableDataSource(this.disallowTableData)
        this.abacResource.validations = this.entryDataSource.data;
    }

    deleteABACDisallow(deleteIndex){
        this.entryDataSource.data.splice(deleteIndex,1);
        this.entryDataSource = new MatTableDataSource(this.entryDataSource.data);
    }
    changeInactive(res){
        res.inactive = !res.inactive;
    }
    cloneAbacResource(){

        var originalScriptName = this.abacResource.initScriptName;
        var regex = new RegExp(originalScriptName,'gi');
        this.abacResource.resourceName = this.abacResource.resourceName + "_copy";
        this.abacResource.initScriptName = this.abacResource.resourceName + "Create" + this.abacResource.createUserAuth +"InitAbac";

        this.abacResource.deleteEndpoint = this.abacResource.deleteEndpoint.replace(regex,this.abacResource.initScriptName);
        this.abacResource.id = null;
        this.abacResource.lock = true;

        //prepare validation scripts as per clone
        for(var i=0;i<this.abacResource.validations.length;i++){
            this.abacResource.validations[i].endpoint = this.abacResource.validations[i].endpoint.replace(regex,this.abacResource.initScriptName);
            if(this.abacResource.validations[i].body != null)
                this.abacResource.validations[i].body = this.abacResource.validations[i].body.replace(regex,this.abacResource.initScriptName);
        }

        this.emitData.next([this.data.projectId,this.abacResource]);
        this.dialogRef.close();
    }


    loadEnumValuesArray(tempEnum) {
        this.enumTypeTemplateName = '';
        this.enumTypeInScriptName = [];
        this.selectedEnum = '';
        this.selectedEnumType = '';
        this.enumValuesArray = [];
        this.enumList = '';
        this.enumObject = [];
        var enumArray1 = tempEnum.split('\n');
        if (enumArray1 != "")
            for (var i = 0; i < enumArray1.length; i++) {
                this.enumList = enumArray1[i].split('[')[0];
                var tmpEnum = enumArray1[i].split('[')[1].split(']')[0].split('__');
                this.enumObject.push({
                    enum: this.enumList,
                    enumType: tmpEnum
                })
            }

        this.bodyJSONObj= JSON.parse(this.abacResource.createBody);
        this.bodyJSONObj[this.selectedEnum] = this.selectedEnumType;
        //get keys
        this.bodyKeys= Object.keys(this.bodyJSONObj);
        this.bodyKeys = this.bodyKeys.filter(k1 => k1 != '');
    }

    AddEnum() {

        this.enumTypeInScriptName[this.selectedEnum] = this.selectedEnumType ;
        var enumTypesTemp='';
        for(var key in this.enumTypeInScriptName) {
            enumTypesTemp = enumTypesTemp + key + this.enumTypeInScriptName[key];
        }
        this.enumTypeTemplateName = enumTypesTemp;
        this.abacResource.createBody = JSON.stringify(this.bodyJSONObj,null,2);
        this.abacResource.initScriptName = this.abacResource.resourceName + this.enumTypeTemplateName + "Create" + this.abacResource.createUserAuth + "InitAbact2";
    }

    EnumSelectedMethod() {
        this.enumValuesArray = [];
        this.selectedEnumType = '';
        for (var i = 0; i < this.enumObject.length; i++) {
            if (this.enumObject[i].enum == this.selectedEnum)
                this.enumValuesArray = this.enumObject[i].enumType
        }

    }

    resources;
    getSelectedValue(type){

        this.bodyR = null;
        if(this.endpointData && this.endpointData.length>0){
            if(type == 'create') {
                this.resources = '';
                for (let obj of this.endpointData) {
                    if (obj['method'] + ":" + obj['endpoint'] === this.abacResource.createEndpoint) {
                        this.resources=obj.responses;

                        for(let obj1 of obj.parameters){
                            if(obj1.in == 'body')
                                this.abacResource.createBody = obj1.body;
                        }
                        break;
                    }
                }
            }
            else
            if(type == 'read'){
                this.resources = '';
                for (let obj of this.endpointData) {
                    if (obj['method'] + ":" + obj['endpoint'] === this.endpointR) {
                        this.resources=obj.responses;

                        for(let obj1 of obj.parameters){
                            if(obj1.in == 'body')
                                this.bodyR = obj1.body;
                        }
                        break;
                    }
                }
            }

        }
    }

    viewEndPointResponses(){
        this.getSelectedValue('create');
        const dialogRef = this.dialog.open(ManageAbacType2ResponseDialogComponent, {
            width: '800px',
            data: this.resources
        });
    }

    addNestedResource(scriptType:boolean){
        const dialogRef = this.dialog.open(NestedResourceComponent, {
            width: '1000px',
            data:{resourceData:this.resourceData,endpointList:this.endpointList,createAuth:this.abacResource.createUserAuth,scriptType:scriptType,
                parentRes:this.abacResource.resourceName,genType:'Hijack1',bodyKeys:this.bodyKeys,endpointData: this.endpointData }
        });

        dialogRef.componentInstance.emitData.subscribe((nestedResData : AbacScripts) => {
            this.abacResource.scripts.push(nestedResData[0])
            //replace value with selected key in body
            this.bodyJSONObj= JSON.parse(this.abacResource.createBody);
            if(nestedResData[2] != '')
                this.bodyJSONObj[nestedResData[2]] =  nestedResData[0].nestedKeyScriptName ;

            this.abacResource.createBody = JSON.stringify(this.bodyJSONObj,null,2);
        })
    }

    editNestedResource(scriptType:boolean,nestedRes){
        const dialogRef = this.dialog.open(NestedResourceComponent, {
            width: '1000px',
            data:{resourceData:this.resourceData,endpointList:this.endpointList,createAuth:this.abacResource.createUserAuth,scriptType:scriptType,
                parentRes:this.abacResource.resourceName,nestedRes:nestedRes,genType:'Hijack1',bodyKeys:this.bodyKeys,endpointData: this.endpointData }
        });

        dialogRef.componentInstance.emitData.subscribe((nestedResData : AbacScripts) => {

            var index ;
            for(var i=0;i<this.abacResource.scripts.length;i++){
                if((this.abacResource.scripts[i].scriptName ==nestedResData[0].scriptName) &&(this.abacResource.scripts[i].validationScript ==nestedResData[0].validationScript ) ){
                    index = i;
                    break;
                }
            }
            if(nestedResData[1] == 'save'){
                if(index >= 0 )
                    this.abacResource.scripts[index] =nestedResData[0] ;
                else
                    this.abacResource.scripts.push(nestedResData[0])
            }
            else
            if(nestedResData[1] == 'del')
            {
                if(index >= 0 )
                    this.abacResource.scripts.splice(index,1);
            }
        })

    }
}

export interface entryDataSource {
    userAuth: string;
    endpoint: string;
    inactive: string;
}