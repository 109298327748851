import { Component, OnInit, Inject, ViewChild, Output, EventEmitter } from '@angular/core';
import { Routes, RouterModule, Router, ActivatedRoute } from '@angular/router';
import { SnackbarService } from '../../../services/snackbar.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ProjectService } from '../../../services/project.service';
import { Handler } from '../handler/handler';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { ApiGroups } from '../../../services/api-groups.service';
import { OrgService } from '../../../services/org.service';
import { event } from 'jquery';

@Component({
  selector: 'app-teams-list-dialog',
  templateUrl: './teams-list-dialog.component.html',
  styleUrls: ['./teams-list-dialog.component.scss'],
  providers: [SnackbarService, ProjectService, ApiGroups, OrgService]
})
export class TeamsListDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<TeamsListDialogComponent>, private handler: Handler, private snackbarService: SnackbarService, private projectService: ProjectService, private apiGroups: ApiGroups,private orgService: OrgService) { }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Output() emitData = new EventEmitter<string>();
  pageSize = 10;
  page = 0;
  sort = 'createdDate';
  sortString: string = 'createdDate';
  sortType: string = 'DESC';
  teamListData: any;
   teamListDataTable : any;
   displayedColumns: string[] = ['add', 'name'];
   length:number = 0;
   selectedTeams : any = [];

  ngOnInit(): void {
    this.getTeamsList()

  }
  change(evt) {
    this.page = evt['pageIndex'];
    this.pageSize = evt.pageSize;
    this.getTeamsList();
  }
  getTeamsList(){
    this.handler.activateLoader();
    this.orgService.getTeam(this.page, 100, this.sort, this.sortType).subscribe(result => {
      this.handler.hideLoader();
      if (this.handler.handle(result)) {
        return;
      }
      this.teamListData = result['data'].content;

      this.teamListData.forEach(obj1 => {
        if (this.data.userSelectedTeamId != null) {
           this.data.userSelectedTeamId.forEach(element => {
             if(element.id == obj1.id)
               obj1.isCheck = true;

           });
        }
        else
        {
          obj1['isCheck'] = false;
        }

      });
      this.selectedTeams = this.teamListData.filter((selected) => selected.isCheck == true);
      // Sort the array with isCheck property first
      const sortedData =  this.teamListData.sort((a, b) => {
           if (a.isCheck && !b.isCheck) {
              return -1;
           } else if (!a.isCheck && b.isCheck) {
             return 1;
           } else {
             return 0;
           }
          });


      this.teamListDataTable = new MatTableDataSource(this.teamListData);
      this.length = this.teamListData.length;
      // console.log('lengh', this.length);
      this.pageSize = result['data'].size;
      this.teamListDataTable.paginator = this.paginator;

    }, error => {
      this.handler.hideLoader();
      this.handler.error(error);
    })
  }

  applyFilter(filterValue: string) {

    filterValue = filterValue.trim().toLowerCase();
    const escapedFilterValue = filterValue.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&');
    const regex = new RegExp(escapedFilterValue, 'i');
    this.teamListDataTable.filterPredicate = (data: any, filter: string) => {
      const matchColumns = [
       data['name']
      ];
      return matchColumns.some(column => regex.test(column));

    };
    this.teamListDataTable.filter = filterValue;
    if (this.teamListDataTable.paginator) {
      this.teamListDataTable.paginator.firstPage();
    }

  }

  onSelectRow(item, event){
    if(event.checked){
      item.isCheck = event.checked;
      this.teamListData.forEach(element => {
        if (item.id == element.id) {
          element.isCheck = true;
        }
      })


    }
    else{

      this.teamListData.forEach(element => {
        if (item.id == element.id) {
          element.isCheck = false;
        }
      })


    }
          this.selectedTeams = this.teamListData.filter((selected) => selected.isCheck == true);


  }
  close() {
    this.dialogRef.close();
  }
  onAddClick() {
    this.emitData.next(this.selectedTeams);
    this.dialogRef.close(this.selectedTeams);

  }

}
