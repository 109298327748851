import { Component, OnInit, Inject, EventEmitter, Output, Input, ViewChild } from '@angular/core';
import { APPCONFIG } from '../../config';
import { Router, ActivatedRoute, NavigationExtras } from "@angular/router";
import { OrgService } from '../../services/org.service';
import { DataTransferService } from '../../services/data-transfer.service';
import { Observable, Subscription } from "rxjs";
import { Org, OrgUser } from "../../models/org.model";
import { Handler } from "../../components/dialogs/handler/handler";
import {
    Location,

} from "@angular/common";
import { GlobalProjectDetails, GlobalSecurityModelList, recentProfile, GlobalSelectedEnv, GlobalUserName, LoggedInUserOrgId, loggedInUserDetail, searchCatResult, selectedActPage, selectPageSize } from "../../shared/shared.data";
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MfaCheckDialogComponent } from '../../components/dialogs/mfa-check-dialog/mfa-check-dialog.component';
import { DefaultIntegrationsService } from '../.././services/default-integrations.service';
import { AlertDialogComponent } from '../../components/dialogs/alert-dialog/alert-dialog.component';


@Component({
    selector: "my-app-header",
    styles: [
        `.app-header .brand {
            padding-left:25px !important 
        }`
    ],
    templateUrl: "./header.component.html",
    providers: [OrgService]
})
export class AppHeaderComponent implements OnInit {
    @ViewChild(MatPaginator) paginator: MatPaginator;

    // @Output() parentData: ProjectRecommendationsComponent;
    public AppConfig: any;
    entry: OrgUser = new OrgUser();
    loggedInUser;
    recentProfile = recentProfile;
    loggedInUserType: any = "";
    version: String = ""; //"Enterprise";
    public currentTheme: string = APPCONFIG.theme;
    globalUserName = GlobalUserName;
    projectRecomData = searchCatResult;
    globalSelectedEnv = GlobalSelectedEnv;
    globalLoggedInUserOrgId = LoggedInUserOrgId;
    loggedInUserDetail = loggedInUserDetail;
    globActPageSize = selectedActPage;
    globalProjectDetails = GlobalProjectDetails;
    securityModelScope = GlobalSecurityModelList;
    globalPageSize = selectPageSize;
    dataSource: MatTableDataSource<notificationData>;
    assistant: any = null;
    totalNotifications = 0;
    obs: Observable<any>;
    showmessage: String = "";

    subscription: Subscription;
    inactiveIntegrationList: any = []
    constructor(
        private orgService: OrgService,
        private route: ActivatedRoute,
        private router: Router,
        private handler: Handler,
        private _location: Location,
        private defaultIntegrationsService: DefaultIntegrationsService,
        private dataTransfer: DataTransferService,
        private dialog: MatDialog,
    ) {
    }

    ngOnInit() {
        this.checkOrgMfa(true);
        this.AppConfig = APPCONFIG;
        this.getLoggedInUser();

    }

    getLoggedInUser() {
        this.handler.activateLoader();
        this.orgService.getLoggedInUser().subscribe(
            results => {
                this.handler.hideLoader();
                if (this.handler.handle(results)) {
                    return;
                }
                this.entry = results["data"];
                if (this.entry.orgRole == 'PROJECT_MANAGER' || this.entry.orgRole == 'ADMIN' || this.entry.orgRole == 'ENTERPRISE_ADMIN') {
                    this.getInactiveIntegrations()
                }
                this.loggedInUser = this.entry.users.email;
                this.loggedInUserType = this.entry.users['createdBy'];
                if (this.entry.org) {
                    this.globalLoggedInUserOrgId.orgId = this.entry.org.id;
                    this.loggedInUserDetail.orgName = this.entry.org.name;
                }
                this.loggedInUserDetail.userRole = this.entry.orgRole;
                this.loggedInUserDetail.userEmail = this.entry.users.email;
                localStorage.removeItem(this.loggedInUserType)
                localStorage.setItem('loggedInUserType', this.loggedInUserType);
                localStorage.removeItem(this.loggedInUser)
                localStorage.setItem('loggedInUser', this.loggedInUser);
                localStorage.setItem('userRole', this.entry.orgRole);
                
                this.globalUserName.userName = this.entry.users.name;
                if (this.entry.forceResetPwd == true) {
                    // if (
                    //     window.confirm(
                    //         "It is mandatory to reset password. Would you like to reset it now?"
                    //     )
                    // ) {
                    //     let navigationExtras: NavigationExtras = {
                    //         queryParams: { 'resetPwd': 'yes' }
                    //     }
                    //     this.router.navigate(["/app/profile/myProfile"], navigationExtras);
                    // } else {
                    //     this.router.navigate(["/app/projects"]);
                    // }
                    localStorage.setItem('resetPassword',JSON.stringify(this.entry.forceResetPwd));
                    const dialogRef = this.dialog.open(AlertDialogComponent, {
                        width: "580px",
                        height: "225px",
                        data: ["", "We recommend updating your temporary password to ensure account security."]
                    });

                    let navigationExtras: NavigationExtras = {
                        queryParams: { 'resetPwd': 'yes' }
                    }
                    this.router.navigate(["/app/profile/myProfile"], navigationExtras);
                }
            },
            error => {
                this.handler.hideLoader();
                this.handler.error(error);
            }
        );
    }

    changeTheme(t: any) {

        if (this.AppConfig.theme == 'light')
            this.AppConfig.theme = 'dark';
        else
            this.AppConfig.theme = 'light';

        localStorage.setItem("APISec-Theme", this.AppConfig.theme);
    }

    clearActivitiesGlobal() {
        // this.dataTransfer.getMessage();
        // this.getOrgMsg();
        if (this.entry.orgRole == 'PROJECT_MANAGER' || this.entry.orgRole == 'ADMIN' || this.entry.orgRole == 'ENTERPRISE_ADMIN') {
            this.getInactiveIntegrations()
        }
        this.checkOrgMfa(false);
        this.dataTransfer.sendMessage('Message from Header Component to Project List Component!');
        if (this.projectRecomData.catResult) {
            this.projectRecomData['catResult'].keyword = "";
            this.projectRecomData['catResult'].category = "All";
            this.projectRecomData['catResult'].toggleValue = "active";
            this.projectRecomData['catResult'].page = 0;
            this.projectRecomData['catResult'].pageSize = 25;
            this.projectRecomData['catResult'].severity = "all";
            this.projectRecomData['catResult'].activeCount = 0;
            this.projectRecomData.catResult = null
        }
        this.globalSelectedEnv.envId = '';
        this.globalPageSize.pageSize_Global = ""
        this.globActPageSize.actPageSize_Global = "";
        this.globalProjectDetails.name = '';
        this.globalProjectDetails.id = '';
        this.securityModelScope.scope = "PUBLIC";
        this.recentProfile.profileId = 'All';
    }

    logout() {
        // localStorage.removeItem('apisecT_1');
        // localStorage.removeItem('testsuitesNames');
        // localStorage.removeItem('projectName');
        // localStorage.removeItem('loggedInUser');
        // localStorage.removeItem('loggedInUserType');
        // localStorage.removeItem('projectId');
        // localStorage.removeItem('recentProject');
        // localStorage.removeItem('userRole');
        // localStorage.removeItem('regDetails');

        // localStorage.setItem('logoutButton', 'Y');
        this.orgService.logout().subscribe(res=>{
        localStorage.removeItem('apisecT_1');
        localStorage.removeItem('testsuitesNames');
        localStorage.removeItem('projectName');
        localStorage.removeItem('loggedInUser');
        localStorage.removeItem('loggedInUserType');
        localStorage.removeItem('projectId');
        localStorage.removeItem('recentProject');
        localStorage.removeItem('userRole');
        localStorage.removeItem('regDetails');
        localStorage.setItem('logoutButton', 'Y');
        window.location.reload();
        },error=>{
            this.handler.error(error);
            // window.location.reload();
        })

        //  this.router.navigate(["/login"], { queryParams: { logout: true } });
        //  window.location.reload();
    }

    ngOnDestroy() {
        if (this.subscription !== undefined) {
            this.subscription.unsubscribe();
        }
    }

    inactiveCounts: number = 0
    //Inactive Integrations
    getInactiveIntegrations() {
        this.inactiveCounts = 0;
        localStorage.removeItem('firstTimeLogin');
        this.handler.activateLoader();
        this.defaultIntegrationsService.getAllIntegrations().subscribe((results) => {
            this.handler.hideLoader();
            if (this.handler.handle(results)) {
                return;
            }
            this.inactiveIntegrationList = results['data'];
            this.inactiveIntegrationList.forEach(element => {
                if (element.inactive == true)
                    this.inactiveCounts = this.inactiveCounts + 1;
            });
        }, (error) => {
            this.handler.hideLoader();
            this.handler.error(error);
        });
    }
    // }

    // getOrgMsg() {
    //     this.assistant = [];
    //     this.assistantService.getMessagesOfOrg().subscribe(async results => {
    //         this.handler.hideLoader();
    //         if (this.handler.handle(results)) {
    //             return;
    //         }
    //         var tmpAssistant = null;
    //         tmpAssistant = results;
    //         this.totalNotifications = 0;

    //         tmpAssistant.map(a => {
    //             if (a.status == 'ACTIVE') {
    //                 this.assistant.push(a)
    //                 this.totalNotifications = this.totalNotifications + 1;

    //             }

    //         })

    //         this.dataSource = new MatTableDataSource(this.assistant);
    //         this.dataSource = new MatTableDataSource(this.assistant);
    //         // this.changeDetectorRef.detectChanges();
    //         this.dataSource.paginator = this.paginator;
    //         if (this.totalNotifications > 0) {
    //             this.obs = this.dataSource.connect();
    //             // this.assistant.paginator=this.paginator;
    //             // console.log(this.dataSource)
    //         }
    //         else if (this.totalNotifications == 0) {
    //             this.showmessage = "No notifications";
    //         }
    //     })


    // }

    page = 0;
    pageSize = 5;
    pageIndex = 0;
    pageSizeOptions = [10, 25, 50, 75, 100]
    change(evt) {
        this.page = evt['pageIndex'];
        this.pageIndex = this.page;
        if (this.pageSize != evt.pageSize)
            this.page = 0;
        this.pageSize = evt.pageSize;
        // this.getOrgMsg();s
    }

    unClosed(e) {
        e.stopPropagation();
    }

    checkOrgMfa(getConfirm) {
        this.handler.activateLoader();
        this.orgService.checkOrgMfa().subscribe(results => {
            this.handler.hideLoader();
            if (this.handler.handle(results)) {
                return;
            }


            if (getConfirm == true && results['data']['mfaOk'] == false && Date.parse(results['data']['lastDateToEnableUserMfa']) > Date.now()) {


                const dialogRef = this.dialog.open(MfaCheckDialogComponent, {
                    width: "650px",
                    disableClose: true,
                    data: [results['data']['lastDateToEnableUserMfa'], 'N']
                });

                // if (
                //     window.confirm(
                //         "It is mandatory enable 2FA. Would you like to enable it now?"
                //     )
                // ) {

                //     this.router.navigate(["/app/profile/myProfile"]);
                // }
            }


            if (results['data']['mfaOk'] == false && Date.parse(results['data']['lastDateToEnableUserMfa']) <= Date.now()) {

                const dialogRef = this.dialog.open(MfaCheckDialogComponent, {
                    width: "650px",
                    disableClose: true,
                    data: [results['data']['lastDateToEnableUserMfa'], 'Y']
                });

                // window.alert(
                //     "You should enable the MFA"
                // )


                // this.router.navigate(["/app/profile/myProfile"]);

            }



        }, error => {
            this.handler.hideLoader();
            this.handler.error(error);
        });

    }
}

export interface notificationData {
    projectName: string;
    message: string;
}