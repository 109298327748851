import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Handler } from '../components/dialogs/handler/handler';
import { ApiGroups } from '../services/api-groups.service';
import { OrgService } from '../services/org.service';
import { ProjectService } from "../services/project.service";
import { SnackbarService } from '../services/snackbar.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ApiListDialogComponent } from '../components/dialogs/api-list-dialog/api-list-dialog.component';
import { TeamsUserListDialogComponent } from '../components/dialogs/teams-userslist-dialog/teams-userlist-dialog.component';
import { TeamsListDialogComponent } from '../components/dialogs/teams-list-dialog/teams-list-dialog.component';
import { ApiGroupListDialogComponent } from '../components/dialogs/api-groups-list-dialog/api-group-list-dialog.component';

@Component({
  selector: 'app-api-group-new',
  templateUrl: './api-group-new.component.html',
  styleUrls: ['./api-group-new.component.scss'],
  providers: [SnackbarService, ProjectService, OrgService, ApiGroups]
})
export class ApiGroupNewComponent implements OnInit {
  firstFormGroup: FormGroup;
  @Output() emitData = new EventEmitter<string[]>();
  dialogRef: MatDialogRef<true, ''>;
  constructor(private _formBuilder: FormBuilder, private projectService: ProjectService, private snackbarService: SnackbarService, private handler: Handler, private orgService: OrgService, private apiGroups: ApiGroups, private router: Router, private dialog: MatDialog) { }


  projectLength1;
  projectListData1;
  test434;
  projectListData
  pageSize = 10;
  page = 0;
  sort = 'createdDate';
  sortString: string = 'createdDate';
  sortType: string = 'DESC';
  tag: string = "";
  projectId
  userSelectedprojectId = [];
  userSelectedTeamId = [];
  teamListData;
  orgId;
  teamListDatanew :any;
  teamListDatanewNames;
  ngOnInit(): void {

    this.firstFormGroup = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.maxLength(250),  Validators.pattern('[a-zA-Z0-9\. ]*')]),
      description: new FormControl('', [Validators.required, Validators.maxLength(250),  Validators.pattern('[a-zA-Z0-9\. ]*')]),
      projects: new FormControl('', Validators.required),
      teams: new FormControl(''),
      subApiGroups: new FormControl('')

    });
    this.getAllProjects();
    // this.getAllProjects();
    // this.teamList();
    this.getorgsId();
  }

  data

  getorgsId() {
    this.orgService.getLoggedInUser().subscribe(item => {
      this.data = item["data"];
      this.orgId = this.data.org.id;
    })
  }
  // getAllProjects() {
  //   this.projectService.getProjects(this.page, 100, this.sortString, this.sortType, this.tag).subscribe(results => {
  //     this.projectLength1 = results['totalElements'];
  //     this.projectListData = results["data"];
  //     this.projectId = this.projectListData[0].id


  //   },
  //     error => {
  //       this.handler.hideLoader();
  //       this.handler.error(error);
  //     }
  //   )

  // }
  getAllProjects() {
    this.apiGroups.getAllProjects().subscribe(results => {
      this.projectLength1 = results['totalElements'];
      this.projectListData = results["data"]; 
      this.projectId = this.projectListData[0].id
    },
      error => {
        this.handler.hideLoader();
        this.handler.error(error);
      }
    )

  }

  teamList() {
    this.handler.activateLoader();
    this.orgService.getTeam(this.page, this.pageSize, this.sort, this.sortType).subscribe(result => {
      this.handler.hideLoader();
      if (this.handler.handle(result)) {
        return;
      }
      this.teamListData = result['data'].content;
      this.pageSize = result['data'].size;

    }, error => {
      this.handler.hideLoader();
      this.handler.error(error);
    })

  }
  obj

  // getAllProjects1() {
  //   this.apiGroups.getAllProjects().subscribe(result => {
  //     let projectResult = result['data'];
  //     console.log('projectResult', projectResult);
  //   })
  // }
  subAPIGroupsID
  userSelectedAPIGroupIds = [];
  createGroup() {
    this.selectedProjects.map(t => {
      this.userSelectedprojectId.push({ id: t.id })
    })
    if (this.selectedAPIGroupsData && this.selectedAPIGroupsData.length > 0) {
    this.selectedAPIGroupsData.map(g => {
      this.userSelectedAPIGroupIds.push({ id: g.id })
    })
    }
    // this.firstFormGroup.value.teams.map(t => {
    //   this.userSelectedTeamId.push({ id: t.id })
    // })
    if (this.teamListDatanew && this.teamListDatanew.length > 0) {
      this.teamListDatanew.map(t => {
        this.userSelectedTeamId.push({ id: t.id })
      })
    }
    const obj = {
      name: this.firstFormGroup.get('name').value,
      description: this.firstFormGroup.get('description').value,
      org: {
        id: this.orgId
      },
      teams: this.userSelectedTeamId,
      projects: this.userSelectedprojectId,
      subApiGroups: this.userSelectedAPIGroupIds
    };
    this.handler.activateLoader();
    this.apiGroups.createAPIGroup(obj).subscribe(result => {
      this.handler.hideLoader();
      if (this.handler.handle(result)) {
        return;
      }
      let newResult = result['data'];
      this.router.navigate(['/app/api-groups']);
    }, error => {
      this.handler.hideLoader();
      this.handler.error(error);
    })

  }
  testId;
  selectedProjects: any = []
  testid;
  selectedProjectsNames: string;
  selectedAPIGroupsData
  selectedAPIGroupsName: string;
  openApiGroupListGroupDialog() {

    const dialogRef = this.dialog.open(ApiGroupListDialogComponent, {
      width: '600px'
    });

    dialogRef.componentInstance.emitData.subscribe(selectedGroups => {
      this.selectedAPIGroupsData = selectedGroups
      this.selectedAPIGroupsName = this.selectedAPIGroupsData.map(a => a.name)
    });
  }


  openApiListGroupDialog() {

    const dialogRef = this.dialog.open(ApiListDialogComponent, {
      width: '600px',
      data: {
        projectId: this.testId
      }
    });

    dialogRef.componentInstance.emitData.subscribe(data1 => {
      this.selectedProjects = data1
      this.selectedProjectsNames = this.selectedProjects.map(a => a.name)

    });
  }

  openTeamsListDialog(){
    const dialogRef = this.dialog.open(TeamsListDialogComponent, {
      width: '600px',
      data: {
        userSelectedTeamId: this.teamListDatanew
      },
      
    });
      dialogRef.componentInstance.emitData.subscribe(data1 => {
        this.teamListDatanew = data1
        this.teamListDatanewNames = this.teamListDatanew.map(a => a.name)
  
      });
  }

}
