import { Component, Inject, OnInit } from "@angular/core";
import { Handler } from "../../dialogs/handler/handler";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Cron, Jobs, Noti } from "../../../models/jobs.model";
// import {JobsSlackDialogComponent} from "../../dialogs/jobs-slack-dialog/jobs-slack-dialog.component";
import { TestSuiteService } from "../../../services/test-suite.service";
import { SlackRegisterComponent } from "../../dialogs/slack-register/slack-register.component";
import { Project } from "../../../models/project.model";
import { CATEGORIES } from "../../../shared/shared.data";
import { AccountService } from "../../../services/account.service";
import { JobsService } from "../../../services/jobs.service";
import { RegionsService } from "../../../services/regions.service";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { SnackbarService } from "../../../services/snackbar.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Account } from "../../../models/account.model";
import { ProjectService } from "../../../services/project.service";
import { UpgradeComponent } from "../../dialogs/upgrade/upgrade.component";
import { GlobalProjectDetails } from "../../../shared/shared.data";
import { MixpanelService } from "../../../services/mixpanel.service";
import { truncate } from "fs";

@Component({
  selector: "app-notification-list",
  templateUrl: "./notification-list.component.html",
  styleUrls: ["./notification-list.component.scss"],
  providers: [
    ProjectService,
    SnackbarService,
    JobsService,
    AccountService,
    RegionsService,
    TestSuiteService,
  ],
})
export class NotificationListComponent implements OnInit {
  id: string;
  jobId: string;
  job: Jobs = new Jobs();
  jobs = [];
  project: Project = new Project();
  projects;
  length = 0;
  page = 0;
  pageSize = 25;
  itAccounts: Array<Account> = [];
  httpAccounts: Array<Account> = [];
  list;
  cat = CATEGORIES;
  CredentialsType = [
    { value: "Slack", viewValue: "Slack" },
    { value: "MicrosoftTeams", viewValue: "Microsoft Teams" },
  ];
  defaultCredentials: any = "";
  // isSkipNotification=[{'key':true,'value':'ACTIVE'},{'key':false,'value':'INACTIVE'}];
  notifyAccounts: Account[];
  crons: Cron[] = [];
  selectedProfileAccount;
  selectedProfileChannel;
  regions: string[] = [
    "FXLabs/US_WEST_1",
    "FXLabs/US_WEST_2",
    "FXLabs/US_EAST_1",
    "FXLabs/US_EAST_2",
    "FXLabs/EU_WEST_1",
    "FXLabs/EU_CENTRAL_1",
    "FXLabs/SA_EAST_1",
  ];
  context: string = "New";
  slackFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  categories: string[] = [];
  category: string[];
  selectedCategories: string[] = [];
  categoryCount = [];
  loggedInUserRole;
  freeAcc: boolean = false;
  skipNotification: boolean = false;
  notificationActive: boolean = false;
  constructor(
    private jobsService: JobsService,
    private accountService: AccountService,
    private route: ActivatedRoute,
    private regionService: RegionsService,
    private router: Router,
    private handler: Handler,
    private mixpanelService: MixpanelService,
    private snackbarService: SnackbarService,
    private _formBuilder: FormBuilder,
    private projectService: ProjectService,
    private testSuiteService: TestSuiteService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.id = params["id"];
      this.jobId = params["jobId"];
      if (this.id) {
        this.loadProject();
        this.loggedInUserRole = localStorage.getItem("orgRole");
        if (!this.loggedInUserRole)
          this.loggedInUserRole = localStorage.getItem("userRole");
        if (this.loggedInUserRole == "BASIC") {
          this.upgradeSubscription();
          this.freeAcc = true;
        } else {
          // this.defaultIntegrations.notification[0] = new Notification()
          this.getJobs();
          this.getNotifyAccounts();
          // this.getDefaultDetails();
        }
      }
    });
    this.secondFormGroup = this._formBuilder.group({});
    this.thirdFormGroup = this._formBuilder.group({});
    this.slackFormGroup = this._formBuilder.group({
      account: ["", Validators.required],
      channel: ["", Validators.required],
      profile: [""],
      type: ["", Validators.required],
      notificationActive: [true],
    });
    this.job.notifications[0] = new Noti();
    this.job.notifications[1] = new Noti();
    this.job.notifications[2] = new Noti();
    this.job.notifications[2].channel = "SPLUNK";
    // this.jobId = '8a808044713e6d3601713fb413362479';
  }

  globalProjectDetails = GlobalProjectDetails;
  loadProject() {
    this.handler.activateLoader();
    this.projectService.getById(this.id).subscribe(
      (results) => {
        this.handler.hideLoader();
        if (this.handler.handle(results)) {
          return;
        }
        this.project = results["data"];
        this.globalProjectDetails.name = this.project.name;
      },
      (error) => {
        this.handler.hideLoader();
        this.handler.error(error);
      }
    );
  }

  allprofile: any = null;
  getJobs() {
    this.jobsService.getJobs(this.id, this.page, this.pageSize).subscribe(
      (results) => {
        // this.handler.hideLoader();
        if (this.handler.handle(results)) {
          return;
        }
        this.jobs = results["data"];

        var objJob = null;
        for (let j of this.jobs) {
          if (j.name == "Master") {
            this.jobId = j["id"];
            this.job = j;
          }
          if (j.name == "All Profiles") {
            objJob = j;
          }
        }

        if (!objJob) {
          this.allprofile = Object.assign({}, this.job);
          this.allprofile["name"] = "All Profiles";
          this.allprofile["id"] = "All";
          this.jobs.unshift(this.allprofile);
        }
        // if (this.defaultCredentials != "Slack")

        if (this.job.notifications.length == 0) {
          this.slackFormGroup.controls["account"].setValue("Microsoft Teams");
          this.notificationActive = false;
          this.job.notifications[0] = new Noti();
        }

        if (this.job["notifications"][0]["notificationType"]) {
          this.defaultCredentials =
            this.job["notifications"][0]["notificationType"];
          if (this.defaultCredentials != "Slack") {
            this.slackFormGroup.controls["account"].setValue(
              this.job.notifications[0]["account"]
            );
          }
          // else {
          //     this.selectedProfileAccount = "";
          //     this.slackFormGroup.controls['account'].setValue("");
          // }
        } else {
          this.job["notifications"][0]["skipNotification"] = true;
        }

        if (this.job["notifications"][0]["skipNotification"])
          this.notificationActive = false;
        else {
          this.notificationActive = true;
        }

        this.selectedProfileChannel = this.job["notifications"][0]["channel"];

        // if(this.job && this.job['notifications'].length>0){
        // this.skipNotification=this.job['notifications'][0]['skipNotification'];
        // this.job.notifications['skipNotification']=this.job['notifications'][0]['skipNotification'];
        // }
        // this.loadJob();
      },
      (error) => {
        this.handler.hideLoader();
        this.handler.error(error);
      }
    );
  }

  // loadJob() {
  //     this.handler.activateLoader();
  //     this.jobsService.getById(this.jobId).subscribe(results => {
  //         this.handler.hideLoader();
  //         if (this.handler.handle(results)) {
  //             return;
  //         }
  //         this.job = results['data'];
  //         if (this.job.categories) {
  //             this.selectedCategories = this.job.categories.split(",")
  //                 .map(function (item) {
  //                     return item.trim();
  //                 });
  //         }
  //         if (this.job.categories) {
  //             this.category = this.job.categories.split(",")
  //                 .map(function (item) {
  //                     return item.trim();
  //                 });
  //         }
  //         this.getNotifyAccounts();
  //     }, error => {
  //         this.handler.hideLoader();
  //         this.handler.error(error);
  //     });
  // }

  openDialogSlackRegister() {
    const dialogRef = this.dialog.open(SlackRegisterComponent, {
      width: "800px",
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.getNotifyAccounts();
    });
  }

  getNotifyAccounts() {
    this.handler.activateLoader();
    this.accountService.getAccountByAccountType("NOTIFICATION_HUB").subscribe(
      (results) => {
        this.handler.hideLoader();
        if (this.handler.handle(results)) {
          return;
        }
        this.notifyAccounts = results["data"];
      },
      (error) => {
        this.handler.hideLoader();
        this.handler.error(error);
      }
    );
  }

  saveJob() {
    if (this.freeAcc) this.upgradeSubscription();
    else this.saveData();
  }

  saveData() {
    //mixpanel code starts
    var obj = {
      projectName: this.project.name,
      notificationType: this.selectedProfileChannel,
    };
    this.mixpanelService.trackMixpanel("NotificationsSave", obj);
    //mixpanel code ends
    this.handler.activateLoader();

    this.job.notifications[0].account = this.selectedProfileAccount;
    this.job.notifications[0].channel = this.selectedProfileChannel;
    this.job.notifications[0].notificationType = this.defaultCredentials;

    if (this.notificationActive)
      this.job.notifications[0].skipNotification = false;
    else this.job.notifications[0].skipNotification = true;

    if (this.job.applyNotificationtoAll)
      this.snackbarService.openSnackBar(
        "Configurations Saving for all Profiles.",
        ""
      );
    else
      this.snackbarService.openSnackBar(
        "Configurations saved for '" + this.job.name + "'",
        ""
      );

    if (this.job["id"] == "All") {
      for (let j of this.jobs) {
        if (j.name == "Master") {
          this.job["id"] = j["id"];
          this.job["name"] = j.name;
        }
      }
    }

    this.jobsService.updateNotifications(this.job).subscribe(
      (results) => {
        this.handler.hideLoader();
        if (this.handler.handle(results)) {
          return;
        }
        if (this.job.applyNotificationtoAll)
          this.snackbarService.openSnackBar(
            "Configurations updated for all Profiles.",
            ""
          );
        else
          this.snackbarService.openSnackBar(
            "Configurations saved for '" + this.job.name + "'",
            ""
          );
        this.getJobs();
        // console.log("On save",this.job.notifications[0])
      },
      (error) => {
        this.handler.hideLoader();
        this.handler.error(error);
      }
    );
  }

  onChangeProfile(item) {
    this.jobs.map((j) => {
      if (j.id == this.jobId) {
        this.job = j;
        this.selectedProfileAccount = j.notifications[0].account;
        this.selectedProfileChannel = j.notifications[0].channel;
        this.defaultCredentials = j.notifications[0].notificationType;
        if (!this.defaultCredentials)
          this.job["notifications"][0]["skipNotification"] = true;
        if (item == "All") this.job.applyNotificationtoAll = true;
        else this.job.applyNotificationtoAll = false;
        this.notificationActive = !j.notifications[0].skipNotification;
        return;
      }
    });
  }

  upgradeSubscription(): void {
    const dialogRef = this.dialog.open(UpgradeComponent, {
      width: "350px",
      disableClose: true,
    });
  }

  showSlack: boolean = false;
  urlPattern =
    /^(?:(http(s)?)?(sftp)?(ftp)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
  onSelectionChange(item) {
    this.job.notifications[0].account = "";
    // const selectedAccount = this.notifyAccounts.find(account => account.id === item.value);
    if (item.value == "Slack") {
      this.defaultCredentials = "Slack";
      this.slackFormGroup.controls["account"].setValue("");
      this.selectedProfileChannel = "";
      this.notificationActive = false;
      this.selectedProfileAccount = "";
    } else {
      this.selectedProfileChannel = "";
      this.selectedProfileAccount = "Microsoft Teams";
      this.job.notifications[0].channel = "";
      this.defaultCredentials = "MicrosoftTeams";
      this.slackFormGroup.controls["account"].setValue("Microsoft Teams");
      this.notificationActive = false;
    }
  }
}

export interface Type {
  value: string;
  viewValue: string;
}
