import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Project } from '../../../models/project.model';
import { SnackbarService } from '../../../services/snackbar.service';
import { ProjectService } from './../../../services/project.service';
import {
  GlobalProjectDetails
} from './../../../shared/shared.data';

@Component({
  selector: 'app-github-actions-integration',
  templateUrl: './github-actions-integration.component.html',
  styleUrls: ['./github-actions-integration.component.scss'],
  providers: [ProjectService, SnackbarService]
})
export class GithubActionsIntegrationComponent implements OnInit {
  globalProjectDetails = GlobalProjectDetails;
  value =
    `  - name: Trigger APIsec scan \n` +
    `    id: scan \n` +
    `    uses: apisec-inc/apisec-run-scan@v1.0.3 \n` +
    `    with: \n` +
    `     apisec-username: $❴❴ secrets.apisec_username ❵❵ \n` +
    `     apisec-password: $❴❴ secrets.apisec_password ❵❵ \n` +
    `     apisec-project:  ` + this.globalProjectDetails.name + `\n` +
    `     apisec-profile:  ` + this.data.name + `\n` +
    `     apisec-region:   ` + this.data.regions + `\n`

  integration: string = 'wget https://raw.githubusercontent.com/intesar/FX-Scripts/master/fx_job_invoke_script.sh?token=AHILVH7YY7DVETVRLYXBYJK6FK7TC -O fx_job_invoke_script.sh;bash fx_job_invoke_script.sh {Username} {Password}';

  project: Project = new Project();
  projectname;
  projectId: any;
  constructor(private snackbarService: SnackbarService, private projectService: ProjectService, @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<GithubActionsIntegrationComponent>) { }

  ngOnInit(): void {

  }

  showMsg() {
    this.snackbarService.openSnackBar('Copied text to clipboard ', '');

  }
}
