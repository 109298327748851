import {Component, EventEmitter, Inject, OnInit, Output, ViewChild} from '@angular/core';
import {SnackbarService} from "../../../services/snackbar.service";
import {ProjectService} from "../../../services/project.service";
import {Handler} from "../handler/handler";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import {ManageRolesComponent} from "../manage-roles/manage-roles.component";
import {Project} from "../../../models/project.model";

@Component({
  selector: 'app-select-endpoints-dialog',
  templateUrl: './select-endpoints-dialog.component.html',
  styleUrls: ['./select-endpoints-dialog.component.scss'],
    providers: [ProjectService, SnackbarService]
})
export class SelectEndpointsDialogComponent implements OnInit {
    project: Project = new Project();
    projectId: string;
    keyword: string;
    displayedColumns = ['resource','endpoint','selected'];
    selectedEndpoints;
    dataSource = null;
    endpointList: any;
    endpointsData: any;
    endpointDataSource;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @Output() emitData = new EventEmitter<string>();
    constructor(private dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any, private handler: Handler,
                public dialogRef: MatDialogRef<ManageRolesComponent>, private projectService: ProjectService, private snackbarService: SnackbarService, public snackBar: MatSnackBar) {

    }

  ngOnInit() {
      this.endpointsData = JSON.parse(JSON.stringify(this.data[0]));
      this.selectedEndpoints = this.data[1].split(',');


      if(this.selectedEndpoints.length > 1){
          this.endpointsData.map(a=>{
              var temp = a.method+":"+a.endpoint;
              this.selectedEndpoints.map(b=>{
                  if(temp.trim() == b.trim())
                      a.selected = true
              })

          })
      }


      this.endpointDataSource = new MatTableDataSource(this.endpointsData)
      this.endpointDataSource.sort = this.sort;
  }


    copyEndpoints(){
        let modifiedData = "";
        this.endpointDataSource.data.map(a=>{
            if(a.selected){
                if(modifiedData == "")
                    modifiedData =  a.method+":"+a.endpoint
                else
                    modifiedData = modifiedData + ", " + a.method+":"+a.endpoint
            }


        })
        this.emitData.next(modifiedData.toString());
        this.dialogRef.close();
    }

    searchEndpoints(value){
        this.endpointDataSource.filter = value.trim().toLowerCase();
    }
}
