import { Component, Inject, OnInit, ViewChild, ViewContainerRef, ComponentFactoryResolver } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
//src/app/components/analytics/resources/resources.component.ts
//src/app/components/dialogs/activity-dialog/activity-dialog.component.ts
import { ResourcesComponent } from '../../../config-payloads/resources/resources.component';
import { MessageService } from '../../../services/message.service';
// declare function clipboardCopy(obj: string, attempt: number): void;
import { SnackbarService } from '../../../services/snackbar.service';
import { Handler } from '../handler/handler';
import { TestsuitEditDialogComponent } from '../testsuit-edit-dialog/testsuit-edit-dialog.component';

@Component({
  selector: 'activity-dialog',
  templateUrl: './activity-dialog.component.html',
  styleUrls: ['./activity-dialog.component.scss'],
  providers: [SnackbarService, MessageService]
})

export class ActivityDialog implements OnInit {
  @ViewChild('greettemp', { read: ViewContainerRef })
  private greetviewcontainerref: ViewContainerRef;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ActivityDialog>,
    public snackbarService: SnackbarService,
    public dialog: MatDialog,
    private vcref: ViewContainerRef,
    private cfr: ComponentFactoryResolver,
    private messageService: MessageService,
    private handler: Handler
  ) { }

  openEntity(obj) {
    var editOption = "";
    if (obj.activity == 'RESOURCE') editOption = 'RESOURCE_VIEW'
    else if (obj.activity == 'COVERAGE_ASSERTION_MODIFY' || obj.activity == 'ASSERTION') editOption = 'ASSERTION_VIEW'

    localStorage.setItem('projectId', obj.projectId);
    localStorage.setItem('activityId', obj.id);
    localStorage.setItem('autoCodeGeneratorId', obj.autoCodeGeneratorId);

    this.dialog.open(ActivityDialog, {
      width: "90vw",
      height: "90vh",
      data: {
        result: { activity: editOption, entityName: obj.entityName }
      }
    });
  }

  close() {
    this.dialogRef.close();
  }

  showMsg() {
    this.snackbarService.open('Copied to clipboard', '', 3000);

  }
  // copyclipBoard(obj) {
  //   clipboardCopy(obj, 3);
  //   this.snackbarService.open('Copied to clipboard', '', 3000);
  // }
  removeUTFSymbol(s) {
    s = s.split('␀').join('\n');
    s = s.split('␁').join('{');
    s = s.split('␂').join('}');
    s = s.split('␄').join('"');
    s = s.split('␅').join(',');
    s = s.split('␆').join('\n');
    s = s.split('␇').join(':');
    s = s.split('␃').join(':');
    s = s.split('␄').join('\\');
    return s;
  }

  // tslint:disable-next-line: no-empty
  ngOnInit() {
    if (this.data.result.activity == 'Sync') this.getById();
    // this.loadMessageDetailComponent();
    else if (this.data.result.activity == 'ASSERTION_VIEW') this.loadProjectsConfigComponent();
    else if (this.data.result.activity == 'RESOURCE_VIEW') this.loadResourcesComponent();
  }

  async loadMessageDetailComponent() {
    this.vcref.clear();
    const { MessageDetailComponent } = await import('../../../activities/components/message-detail/message-detail.component');
    let greetcomp = this.vcref.createComponent(
      this.cfr.resolveComponentFactory(MessageDetailComponent)
    );
  }

  async loadProjectsConfigComponent() {
    this.vcref.clear();
    const { ProjectsConfigComponent } = await import('../../../config-categories/projects-config/projects-config.component');
    let greetcomp = this.vcref.createComponent(
      this.cfr.resolveComponentFactory(ProjectsConfigComponent)
    );
  }

  async loadResourcesComponent() {
    this.vcref.clear();
    const { ResourcesComponent } = await import('../../../config-payloads/resources/resources.component');
    let greetcomp = this.vcref.createComponent(
      this.cfr.resolveComponentFactory(ResourcesComponent)
    );
  }

  logData;
  getById() {
    this.handler.activateLoader();
    this.messageService.getById(this.data.result.logId).subscribe(results => {
      this.handler.hideLoader();
      if (this.handler.handle(results)) {
        return;
      }
      this.logData = results['data']['message'];
    }, error => {
      this.handler.hideLoader();
      this.handler.error(error);
    });
  }

  hideOptions: boolean = false;
  showTSDialog() {
    console.log(this.data.result)
    if (this.dialog.openDialogs.length == 1) {
      this.hideOptions = true;
      const dialogRef = this.dialog.open(TestsuitEditDialogComponent, {
        width: '1400px',
        maxWidth: '90vw',
        data: [this.data.result.projectId, this.data.result.testSuiteId, this.data.result.entityName, true, this.hideOptions]
      });
    }
  }

}
