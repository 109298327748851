import { APPCONFIG } from '../../config';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationExtras, Router } from "@angular/router";
import { Assistant } from "../../models/assistant.model";
import { Handler } from "../../components/dialogs/handler/handler";
import { GlobalUserName } from "../../shared/shared.data";

@Component({
    selector: 'my-app-footer',
    styleUrls: ['./footer.component.scss'],
    templateUrl: './footer.component.html'
    //providers: [TasksService, ProjectService, JobsService]
})

export class AppFooterComponent implements OnInit {
    // subscription: Subscription;
    public AppConfig = APPCONFIG;
    projectId = '';
    assistant: Assistant = new Assistant();
    assistant1;
    globalUserName = GlobalUserName;
    routerLink = '';
    message = '';

    constructor(private route: ActivatedRoute, private router: Router, private handler: Handler,) {
    }

    ngOnInit() {
        //   this.router.events.subscribe((event) => {
        //       if(event instanceof NavigationEnd == true) {
        //           this.projectId = event['url'].split('/')[3]
        //           if(this.projectId != undefined){
        //               this.assistantService.getMessage(this.projectId).subscribe(results =>{
        //                   if (this.handler.handle(results)) {
        //                       return;
        //                   }
        //                   this.assistant1 = results;
        //                   this.routerLink = this.assistant1.message.split(' ')[0];
        //                   this.message = this.assistant1.message.substr(this.assistant1.message.indexOf(" ") + 1);
        //               })
        //           }
        //       }
        //   });
        //   var element = $('.floating-chat');
        //   setTimeout(function() {
        //       element.addClass('enter');
        //   }, 1000);
        //   element.click(openElement);
        //   function openElement() {
        //       element.find('>i').hide();
        //       element.addClass('expand');
        //       element.find('.chat').addClass('enter');

        //       element.off('click', openElement);
        //       element.find('.header button').click(closeElement);
        //   }
        //   function closeElement() {
        //       element.find('.chat').removeClass('enter').hide();
        //       element.find('>i').show();
        //       element.removeClass('expand');
        //       element.find('.header button').off('click', closeElement);
        //       setTimeout(function() {
        //           element.find('.chat').removeClass('enter').show()
        //           element.click(openElement);
        //       }, 500);
        //   }
    }

    //   dismissTodo(){
    //       if(this.projectId != "" && this.assistant1['todoId'] != undefined && this.assistant1['dismissed'] == false ){
    //           this.assistantService.dismissMessage(this.projectId,this.assistant1.todoId).subscribe(results =>{
    //           })
    //       }
    //        }

    showRed(link) {

        if (link.includes('red')) {
            let navigationExtras: NavigationExtras = {
                queryParams: { 'catType': 'red' }
            }
            this.router.navigate(['/app/projects', this.projectId, 'categories'], navigationExtras)
        }
        else
            if (link.includes('orange')) {
                let navigationExtras: NavigationExtras = {
                    queryParams: { 'catType': 'orange' }
                }
                this.router.navigate(['/app/projects', this.projectId, 'categories'], navigationExtras)
            }


    }
}
