import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { Auth, Project } from "../../../models/project.model";
import { SubscriptionTier } from "../../../models/subscription-tier";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Observable, Subscription, timer } from "rxjs/index";
import { MatTableDataSource } from "@angular/material/table";
import { Handler } from "../../dialogs/handler/handler";
import { RegionsService } from "../../../services/regions.service";
import { ProjectService } from "../../../services/project.service";
import { Router } from "@angular/router";
import { SnackbarService } from "../../../services/snackbar.service";
import { LoggedInUserOrgId } from "../../../shared/shared.data";
import { OrgService } from "../../../services/org.service";
import { OrgUser } from "../../../models/org.model";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { AlertDialogComponent } from "../../dialogs/alert-dialog/alert-dialog.component";
import { DeleteDialogComponent } from "../../dialogs/delete-dialog/delete-dialog.component";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger,
} from "@angular/material/autocomplete";
import { map, startWith } from "rxjs/operators";
import mixpanel from "mixpanel-browser";
import { MixpanelService } from "../../../services/mixpanel.service";
import { MatChipInputEvent } from "@angular/material/chips";

@Component({
  selector: "app-project-new2",
  templateUrl: "./project-new2.component.html",
  styleUrls: ["./project-new2.component.scss"],
  providers: [RegionsService, ProjectService, SnackbarService, OrgService],
})
export class ProjectNew2Component implements OnInit {
  project: Project = new Project();
  @Output() emitData = new EventEmitter<string[]>();
  id: string;
  firstFormGroup: FormGroup;
  advance: boolean = false;
  apiText = "";
  isFileLoadToggle: boolean = false;
  showHide: boolean = false;
  bulkUserDetails = "";
  selectedTimeZone;
  selectedHour;
  commandCheck;
  selectedScanner;
  databases = ["PostgreSQL", "MySQL"];
  disableSave: boolean = true;
  subscriptionTier: SubscriptionTier = new SubscriptionTier();
  freeFlag: boolean = false;
  enterpriseFlag: boolean = false;
  subscriptions = ["FREE", "ENTERPRISE"];
  globalLoggedInUserOrgId = LoggedInUserOrgId;
  progress1: boolean;
  progress2: boolean;
  progress3: boolean;
  success1: boolean = false;
  success2: boolean = false;
  success3: boolean = false;
  scanners = [];
  entry: OrgUser = new OrgUser();
  radioSelected = "advanced";
  tagsControl = new FormControl([]);
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  filteredTags: Observable<string[]>;
  // allTags: string[] = ["Prod", "Stg", "Dev", "QA", "Internal", "External", "Apigee", "Postman", "Ecommerce", "Partners"];
  resourceName;
  @ViewChild("auto", { static: false }) matAutocomplete: MatAutocomplete;

  // Multi select code start
  selectableTag = true;
  removableTag = true;
  separatorKeysCodesTag: number[] = [ENTER, COMMA];
  // tagsControl = new FormControl();
  allfilteredTags: Observable<string[]>;
  newtags: string[] = [];
  newallTags: string[] = [
    "Prod",
    "Stg",
    "Dev",
    "QA",
    "Internal",
    "External",
    "Apigee",
    "Postman",
    "Ecommerce",
    "Partners",
  ];
  @ViewChild("tInputTag") tInputTag: ElementRef<HTMLInputElement>;
  //   @ViewChild('auto') matAutocomplete: MatAutocomplete;
  @ViewChild("autocompleteTrigger") matACTrigger: MatAutocompleteTrigger;
  remainingEndpoints;

  constructor(
    private regionService: RegionsService,
    private handler: Handler,
    private projectService: ProjectService,
    private router: Router,
    private orgService: OrgService,
    private snackbarService: SnackbarService,
    private _formBuilder: FormBuilder,
    private dialog: MatDialog,
    private mixpanelService: MixpanelService
  ) {
    // this.filteredTags = this.tagsControl.valueChanges.pipe(
    //     startWith(null),
    //     map((taging: string | null) => taging ? this._filter(taging) : this.allTags.slice()));
  }

  private _clockSubscription: Subscription;
  regData: any = null;

  ngOnInit() {
    this.firstFormGroup = this._formBuilder.group({
      nameCtrl: ["", Validators.required],
      openAPISpec: ["", Validators.required],
      openText: ["", Validators.required],
      isFileLoad: false,
      tagsControl: [this.project.tags || []],
    });

    let regDetails = localStorage.getItem("regDetails");
    if (regDetails) {
      this.regData = JSON.parse(regDetails);
      if (this.regData["regName"]) this.project.name = this.regData["regName"];
      else this.project.name = "";
      if (this.regData["regUrl"]) {
        this.project.openAPISpec = this.regData["regUrl"];
        this.firstFormGroup.controls["openAPISpec"].setValue(
          this.project.openAPISpec
        );
      } else this.project.openAPISpec = "";
      if (this.regData["openText"])
        this.project.openText = this.regData["openText"];
      else this.project.openText = "";
      if (this.regData["isFileLoadToggle"]) {
        this.isFileLoadToggle = this.regData["isFileLoadToggle"];
      } else this.isFileLoadToggle = false;
      if (this.regData["tags"]) this.newtags = this.regData["tags"];
      else this.newtags = [];
      if (this.regData["bulkUserDetails"])
        this.bulkUserDetails = this.regData["bulkUserDetails"];
      else this.bulkUserDetails = "";
    }

    this.project.tags = [];

    // this.firstFormGroup.get('openText').setValidators(null);
    this.resetValidation();
    this.getScanners();
    if (this.globalLoggedInUserOrgId.orgId == "") this.getLoggedInUser();
    // console.log("Before call : ", this.globalLoggedInUserOrgId.orgId)
    else this.subscriptionTierCall(this.globalLoggedInUserOrgId.orgId);

    this.allfilteredTags = this.firstFormGroup.controls[
      "tagsControl"
    ].valueChanges.pipe(
      startWith(null),
      map((tag: string | null) =>
        tag ? this._filterr(tag) : this.newallTags.slice()
      )
    );
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    // Add our tag
    if ((value || "").trim()) {
      this.newtags.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = "";
    }
    this.allfilteredTags = this.firstFormGroup.controls[
      "tagsControl"
    ].valueChanges.pipe(
      startWith(null),
      map((tag: string | null) =>
        tag ? this._filterr(tag) : this.newallTags.slice()
      )
    );
    // this.tagsControl.setValue(null);
  }

  remove(tag: string): void {
    const index = this.newtags.indexOf(tag);

    if (index >= 0) {
      this.newtags.splice(index, 1);
    }
  }

  selectedTag(event: MatAutocompleteSelectedEvent): void {
    const newValue = event.option.viewValue;
    if (this.newtags.includes(newValue)) {
      this.newtags = [...this.newtags.filter((tag) => tag !== newValue)];
    } else {
      this.newtags.push(event.option.viewValue);
    }
    this.tInputTag.nativeElement.value = "";
    // this.allfilteredTags =  of(this.newallTags.slice())
    // this.tagsControl.setValue(null);
    // this.firstFormGroup.controls['tagsControl'].patchValue(event);

    // keep the autocomplete opened after each item is picked.
    requestAnimationFrame(() => {
      this.openAuto(this.matACTrigger);
    });
  }

  private _filterr(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.newallTags.filter(
      (tag) => tag.toLowerCase().indexOf(filterValue) >= 0
    );
  }

  openAuto(trigger: MatAutocompleteTrigger) {
    trigger.openPanel();
    this.tInputTag.nativeElement.focus();
  }

  // loadBody(event, selectedResource) {
  //     if (event.source.selected) {
  //         this.resourceName = selectedResource;
  //         console.log(this.resourceName);
  //         this.firstFormGroup.controls['tagsControl'].patchValue(this.resourceName)
  //     }

  // }
  csvContent: string;
  // private _filter(value: string): string[] {
  //     const filterValue = value.toLowerCase();

  //     return this.allTags.filter(taging => taging.toLowerCase().indexOf(filterValue) === 0);
  // }
  resetValidation() {
    var k = this.isFileLoadToggle;
    // console.log(k)
    if (!k) {
      if (this.project.openAPISpec == "none") this.project.openAPISpec = "";
      this.project.openText = "";
      // this.firstFormGroup.addControl('openAPISpec', this._formBuilder.control('', [Validators.required]));
      // this.firstFormGroup.removeControl('openText');
      this.firstFormGroup
        .get("openAPISpec")
        .setValidators([Validators.required]);
      this.firstFormGroup.get("openText").setValidators(null);
    }

    if (k) {
      this.project.openAPISpec = "none";
      this.firstFormGroup.controls["openAPISpec"].setValue("none");
      // this.firstFormGroup.removeControl('openAPISpec');
      this.firstFormGroup.get("openAPISpec").setValidators(null);

      this.firstFormGroup.get("openText").setValidators([Validators.required]);

      // this.firstFormGroup.addControl('openText', this._formBuilder.control('', [Validators.required]));
    }

    // this.firstFormGroup.markAsPristine();
  }

  getScanners() {
    this.handler.activateLoader();
    this.regionService.getEntitled(0, 50).subscribe(
      (results) => {
        this.handler.hideLoader();
        if (this.handler.handle(results)) {
          return;
        }
        this.scanners = results["data"];
        this.selectedScanner = this.scanners[0].name;
      },
      (error) => {
        this.handler.hideLoader();
        this.handler.error(error);
      }
    );
  }

  getLoggedInUser() {
    this.handler.activateLoader();
    this.orgService.getLoggedInUser().subscribe(
      (results) => {
        this.handler.hideLoader();
        if (this.handler.handle(results)) {
          return;
        }

        this.entry = results["data"];
        this.subscriptionTierCall(this.entry.org.id);
      },
      (error) => {
        this.handler.hideLoader();
        this.handler.error(error);
      }
    );
  }

  subscriptionTierCall(orgId) {
    this.handler.activateLoader();
    this.projectService.getSubscriptionLicense(orgId).subscribe(
      (results) => {
        if (this.handler.handle(results)) {
          return;
        }
        this.handler.hideLoader();
        this.subscriptionTier = results["data"];
        if (
          this.subscriptionTier.orgEntPlan == "ENTERPRISE" &&
          this.subscriptionTier.availableEntLicense != 0
        ) {
          this.project.planType = "ENTERPRISE";
        }
        if (this.subscriptionTier.availableFreeLicense == 0) {
          this.freeFlag = true;
        }

        if (this.subscriptionTier.availableEntLicense == 0) {
          this.enterpriseFlag = true;
        }
        let utilized =
          this.subscriptionTier.utilizedEndpoints === null
            ? 0
            : this.subscriptionTier.utilizedEndpoints;
        this.remainingEndpoints =
          this.subscriptionTier.purchaseEntLicense - utilized;
      },
      (error) => {
        this.handler.hideLoader();
        this.handler.error(error);
      }
    );
  }

  onFileSelect(files) {
    if (files && files.length) {
      const fileToRead = files[0];
      const fileReader = new FileReader();
      fileReader.readAsText(fileToRead, "UTF-8");
      fileReader.onload = (event) => {
        var data = <FileReader>event.target;
        this.apiText = data.result.toString();

        var el = <HTMLInputElement>document.getElementById("fileText");
        el.value = this.apiText;
        this.project.openText = el.value;
        this.project.isFileLoad = true;
        this.project.openAPISpec = "none";
      };
    }

    //below code is added to enable the register button after file upload.
    setTimeout(() => {
      this.firstFormGroup.markAsPristine();
    }, 600);
  }

  validate() {
    // if (this.subscriptionTier.availableEntLicense <= 0) {
    //   // window.alert("Please request for more licenses.")
    //   const dialogRef = this.dialog.open(AlertDialogComponent, {
    //     width: "580px",
    //     height: "225px",
    //     data: ["", "Please request for more licenses."],
    //   });
    // } else {
    this.validateAndSave();
    // }
  }

  changeSkipAbac() {
    this.project.skipAbac = !this.project.skipAbac;
  }

  validateAndSave() {
    let projectName = "";
    if (this.project.name && this.project.name.length >= 35)
      projectName = this.project.name.slice(0, 35) + "...";
    else projectName = this.project.name;

    //mixpanel code starts
    var obj = {
      projectName: projectName,
    };
    this.mixpanelService.trackMixpanel("API Adv registration", obj);
    //mixpanel code ends

    var tempAuth = [];
    localStorage.removeItem("regDetails");
    if (this.bulkUserDetails != null) {
      const bulkUserDetailsArray = this.bulkUserDetails.split("\n");
      for (const bulkUserData of bulkUserDetailsArray) {
        if (bulkUserData.trim() != "") {
          const bulkUser = bulkUserData.split("||");

          const auth = new Auth();
          auth.name = bulkUser[0].trim();
          auth.authType = bulkUser[1];
          if (!this.isValidInput(bulkUser, auth.authType)) {
            return;
          }

          if (auth.authType == "Basic") {
            auth.username = bulkUser[2].trim();
            auth.password = bulkUser[3].trim();
          } else if (auth.authType == "Token") {
            auth.header_1 = bulkUser[2].trim();
            if (bulkUser[3] != undefined) {
              auth.header_2 = bulkUser[3].trim();
            }
          } else if (auth.authType == "Digest") {
            auth.username = bulkUser[2].trim();
            auth.password = bulkUser[3].trim();
          } else if (auth.authType == "HMAC") {
            auth.header_1 = bulkUser[2].trim();
          }
          tempAuth.push(auth);
        }
      }

      this.project.personalizedCoverage.auths = tempAuth;
    }

    if (this.firstFormGroup.controls["isFileLoad"].value == false) {
      this.project.openText = null;
      this.project.source = "URL";
    }

    if (this.firstFormGroup.controls["isFileLoad"].value == true) {
      this.project.openAPISpec = "none";
      this.project.source = "FILE";
    }

    this.handler.activateLoader();
    this.snackbarService.openSnackBar(
      "Validating and registering the project '" + projectName + "...",
      ""
    );
    this.project.apispecType = "openAPISpec";
    this.project.validation = true;
    this.project.region = this.selectedScanner;
    this.project.planType = "ENTERPRISE";
    if (this.project.skipAbac) this.project.skipAbac = true;
    else this.project.skipAbac = false;

    //setting source as UI
    // this.project.source = "UI";
    // this.project.tags = Array.isArray(this.firstFormGroup.controls['tagsControl'].value) ?
    //     this.firstFormGroup.controls['tagsControl'].value : [this.firstFormGroup.controls['tagsControl'].value];
    this.project.tags = this.newtags;
    this.projectService.create(this.project).subscribe(
      (results) => {
        this.handler.hideLoader();
        if (this.handler.handleProjectRegistration(results)) {
          return;
        }

        this.snackbarService.open(
          "Project '" +
            projectName +
            "' registered successfully. " +
            "The apisec is writing customized validations for the APIs. This step may take a few minutes to complete before you can run the first scan.",
          "OK",
          30000
        );
        this.project = results["data"];

        this.router.navigate(["/app/projects"]);
      },
      (error) => {
        this.handler.hideLoader();
        this.handler.error(error);
      }
    );
  }

  isValidInput(bulkUser, authType) {
    if (bulkUser.length < 3) {
      window.alert("Invalid User Authentication Format: " + bulkUser);
      return false;
    }
    // tslint:disable-next-line: triple-equals
    else if (authType == "Basic" && bulkUser.length != 4) {
      window.alert("Invalid User Authentication Format: " + bulkUser);
      return false;
    }

    // tslint:disable-next-line: triple-equals
    if (
      authType != "Basic" &&
      authType != "Token" &&
      authType != "Digest" &&
      authType != "HMAC"
    ) {
      window.alert("Invalid auth type : " + authType);
      return false;
    }

    return true;
  }

  goToBasicProject() {
    let regDetails = {
      regName: this.project.name,
      regUrl: this.project.openAPISpec,
      openText: this.project.openText,
      isFileLoadToggle: this.isFileLoadToggle,
      tags: this.newtags,
      bulkUserDetails: this.bulkUserDetails,
    };
    localStorage.setItem("regDetails", JSON.stringify(regDetails));
    this.router.navigate(["/app/projects/new"]);
  }
}
