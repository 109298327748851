import { CHARTCONFIG } from './../../charts/charts.config';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { JobsService } from '../../services/jobs.service';
import { RunService } from '../../services/run.service';
import { ProjectService } from '../../services/project.service';
import { Base } from '../../models/base.model';
import { Handler } from '../dialogs/handler/handler';
import { Subscription } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DatePipe } from '@angular/common';
import { timer } from 'rxjs';
import { GlobalSelectedEnv, GlobalProjectDetails, recentProfile } from '../../shared/shared.data';

@Component({
    selector: 'app-run-list',
    templateUrl: './run-list.component.html',
    styleUrls: ['./run-list.component.scss'],
    providers: [JobsService, RunService, ProjectService]
})

export class RunListComponent implements OnInit {
    globalSelectedEnv = GlobalSelectedEnv;
    id;
    list;
    times;
    totalTimeSaved = 0;
    projectId;
    jobId: string = '';
    title: string = '';
    project: Base = new Base();
    job: Base = new Base();
    showSpinner: boolean = false;
    private _clockSubscription: Subscription;
    displayedColumns: string[] = [
        'regions',
        'environment',
        'job.name',
        'attributes.CATEGORIES',
        'createdDate',
        'task.totalTime',
        'stats.TestSuites_Failed',
        'task.issuesLogged',
        // "issuesClosed",
        'task.totalTestCompleted',
        // "status",
        'task.status',
        'runId'
    ];
    dataSource = null;
    autoSuggestColumns: string[] = [
        'testSuiteName',
        'category',
        'endPoint',
        'issueDesc',
        'suggestion'
    ];
    autoSuggestDS = null;
    autoSuggest: any;
    page = 0;
    pageSize = 25;
    pageIndex = 0;
    pageSizeOptions: number[] = [25, 50, 100];
    timeZone;

    jobPage = 0;
    jobPageSize = 100;
    sortString: string = 'createdDate';
    sortType: string = 'DESC';
    recentProfile = recentProfile;
    config = CHARTCONFIG;
    // graph1: Chart = []; // This will hold our chart info
    // graph2: Chart = []; // This will hold our chart info
    // graph3: Chart = []; // This will hold our chart info
    // graph4: Chart = []; // This will hold our chart info
    line1: any;

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    constructor(
        private jobsService: JobsService,
        private runService: RunService,
        private projectService: ProjectService,
        private route: ActivatedRoute,
        private handler: Handler,
        private datePipe: DatePipe,
        private router: Router,
    ) {
    }

    ngOnInit() {

        this.route.params.subscribe((params) => {
            if (params['projectId']) {
                this.projectId = params['projectId'];
                this.loadProject(this.projectId);
                this.getJobs();

            }
            if (params['jobId']) {
                this.jobId = params['jobId'];
                this.loadJob(this.jobId);
                this.getRunByJob(this.jobId);

                setTimeout((s) => {
                    if (this.list.find((x) => (x.task.status == 'PROCESSING' || x.task.status == 'WAITING'))) {
                        const timer1 = timer(10000, 15000);
                        this._clockSubscription = timer1.subscribe((t) => {
                            this.getRunByJob(this.jobId);
                        });
                    }
                }, 5000);

            } else {
                if (this.recentProfile.profileId == 'All') {
                    this.selectedProfileId = 'All';
                    this.fetchAllScans();
                    setTimeout((s) => {
                        if (this.list && this.list.find((x) => (x.task.status == 'PROCESSING' || x.task.status == 'WAITING'))) {
                            const timer1 = timer(10000, 15000);
                            this._clockSubscription = timer1.subscribe((t) => {
                                this.fetchAllScans();
                            });
                        }
                    }, 5000);
                }
            }
        });
        this.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    getProperty = (obj, path) => (
        path.split('.').reduce((o, p) => o && o[p], obj)
    )

    fetchAllScans() {
        this.handler.activateLoader();
        this.runService.fetchAllScansV2(this.projectId, this.page, this.pageSize, this.sortString, this.sortType).subscribe(
            (results) => {
                this.handler.hideLoader();
                if (this.handler.handle(results)) {
                    return;
                }
                this.list = results['data'];
                for (const i of this.list) {
                    if (i.attributes.CATEGORIES) {
                        i.attributes.CATEGORIES = i.attributes.CATEGORIES.replace(/,([^,]*)$/, '$1');
                    }
                }

                this.length = results['totalElements'];
                this.dataSource = new MatTableDataSource(this.list);
                this.dataSource.sortingDataAccessor = (obj, property) => this.getProperty(obj, property);
                // this.dataSource.sortingDataAccessor = (element, property) => {
                //     switch (property) {
                //         case 'task.totalBytes':
                //             return element.task.totalBytes;
                //         case 'task.totalTime':
                //             return element.task.totalTime;
                //         case 'task.failedTests':
                //             return element.task.failedTests;
                //         case 'task.issuesLogged':
                //             return element.task.issuesLogged;
                //         case 'task.totalTestCompleted':
                //             return element.task.totalTestCompleted;
                //         case 'task.status':
                //             return element.task.status;
                //         case 'attributes.TAGS':
                //             return element.attributes.TAGS;

                //         default:
                //             return element[property];
                //     }
                // };
                this.dataSource.sort = this.sort;

                if (this._clockSubscription && !(this.list.find((x) => (x.task.status == 'PROCESSING' || x.task.status == 'WAITING')))) {
                    this._clockSubscription.unsubscribe();
                }

                this.times = 0;
            },
            (error) => {
                if (this._clockSubscription != null) {
                    console.log('Inside error - unsubscribe');
                    this._clockSubscription.unsubscribe();
                }

                this.handler.hideLoader();
                this.handler.error(error);
            }
        );

    }

    ngOnDestroy(): void {
        if (this._clockSubscription) {
            this._clockSubscription.unsubscribe();
        } else {
            setTimeout((s) => {
                if (this._clockSubscription) {
                    this._clockSubscription.unsubscribe();
                }
            }, 5000);
        }
    }

    globalProjectDetails = GlobalProjectDetails;
    loadProject(id: string) {
        this.projectService.getById(id).subscribe((results) => {
            if (this.handler.handle(results)) {
                return;
            }
            this.project = results['data'];
            this.globalProjectDetails.name = this.project.name;
        });
    }

    loadJob(id: string) {
        this.jobsService.getById(id).subscribe((results) => {
            if (this.handler.handle(results)) {
                return;
            }
            this.job = results['data'];
        });
    }

    // loadSuggestions(id: string) {
    //     this.jobsService.getAutoSuggestions(id).subscribe(results => {
    //         if (this.handler.handle(results)) {
    //             return;
    //         }
    //         this.autoSuggestDS = new MatTableDataSource(results["data"]);
    //         this.autoSuggest = results["data"];
    //     });
    // }

    // openSkipDialog(obj) {
    //     var skip = confirm(
    //         "Are you sure you want to skip this endpoint from '" +
    //         obj.category +
    //         "' testing?"
    //     );
    //     if (skip == true) {
    //         // invoke skip service
    //         this.jobsService
    //             .skipAutoSuggestion(this.jobId, obj.testSuiteName, obj.testCaseNumber)
    //             .subscribe(results => {
    //                 if (this.handler.handle(results)) {
    //                     return;
    //                 }
    //                 this.loadSuggestions(this.jobId);
    //             });
    //     }
    // }

    //
    // dynamicColors(){
    //   let r = Math.floor(Math.random() * 255);
    //   let g = Math.floor(Math.random() * 255);
    //   let b = Math.floor(Math.random() * 255);
    //   return "rgba(" + r + "," + g + "," + b + ", 0.5)";
    // }
    //
    // poolColors(a) {
    //   var pool = [];
    //   for(let i = 0; i < a; i++){
    //       pool.push(this.dynamicColors());
    //     }
    //   return pool;
    // }

    getRunByJob(id: string) {
        this.handler.activateLoader();
        this.runService.get(id, this.page, this.pageSize).subscribe(
            (results) => {
                this.handler.hideLoader();
                if (this.handler.handle(results)) {
                    return;
                }
                this.list = results['data'];
                this.length = results['totalElements'];
                this.dataSource = new MatTableDataSource(this.list);
                this.dataSource.sortingDataAccessor = (element, property) => {
                    switch (property) {
                        case 'task.totalBytes':
                            return element.task.totalBytes;
                        case 'task.totalTime':
                            return element.task.totalTime;
                        case 'task.failedTests':
                            return element.task.failedTests;
                        case 'task.issuesLogged':
                            return element.task.issuesLogged;
                        case 'task.totalTestCompleted':
                            return element.task.totalTestCompleted;
                        case 'task.status':
                            return element.task.status;
                        case 'attributes.TAGS':
                            return element.attributes.TAGS;

                        default:
                            return element[property];
                    }
                };
                this.dataSource.sort = this.sort;

                if (this._clockSubscription && !(this.list.find((x) => (x.task.status == 'PROCESSING' || x.task.status == 'WAITING')))) {
                    this._clockSubscription.unsubscribe();
                }

                this.times = 0;
            },
            (error) => {
                if (this._clockSubscription != null) {
                    console.log('Inside error - unsubscribe');
                    this._clockSubscription.unsubscribe();
                }

                this.handler.hideLoader();
                this.handler.error(error);
            }
        );
    }

    length = 0;

    change(evt) {
        this.page = evt['pageIndex'];
        this.pageSize = evt.pageSize;
        this.lastEvent = evt;
        if (this.jobId) {
            this.getRunByJob(this.jobId);
        } else {
            this.fetchAllScans();
        }
    }

    jobs;
    jobsNoIssue;
    selectedProfileId;
    selectedProfile;

    getJobs() {
        this.jobsService.getJobs(this.projectId, this.jobPage, this.jobPageSize).subscribe(
            (results) => {
                // this.handler.hideLoader();
                if (this.handler.handle(results)) {
                    return;
                }
                this.jobs = results['data'];

                // for (let j of this.jobs) {
                //     if (j.name == 'Master') {
                //         this.selectedProfileId = j.id;
                //         this.jobId = j.id;
                //         this.selectedProfile = j;
                //     }
                // }
                this.getNoIssueJob();
            },
            (error) => {
                this.handler.hideLoader();
                this.handler.error(error);
            }
        );

    }

    getNoIssueJob() {
        this.handler.activateLoader();
        this.jobsService.getJobsNoIssues(this.projectId, this.jobPage, this.jobPageSize).subscribe(
            (results) => {
                this.handler.hideLoader();
                if (this.handler.handle(results)) {
                    return;
                }
                this.jobsNoIssue = results['data'];
                this.jobs = this.jobs.concat(this.jobsNoIssue);
                if (this.recentProfile.profileId != 'All') {
                    this.selectedProfileId = this.recentProfile.profileId;
                    this.getRunByJob(this.selectedProfileId);
                }
                // else {
                //     this.selectedProfileId = 'All';
                //     this.getRunByJob(this.selectedProfileId);
                // }
            },
            (error) => {
                this.handler.hideLoader();
                this.handler.error(error);
            }
        );
    }

    onChangeProfile(evt) {
        if (evt == 'All') {
            this.jobId = this.selectedProfileId;
            this.fetchAllScans();
        } else {
            this.jobId = this.selectedProfileId;
            this.getRunByJob(this.jobId);
        }
        this.recentProfile.profileId = this.selectedProfileId;
    }

    showRunDetails(element) {
        this.router.navigate(['/app/projects/', this.projectId, 'profiles', element.job.id, 'runs', element.id]);
    }

    goToProjectDashboard(element) {
        this.recentProfile.profileId = 'All';
        this.selectedProfileId = 'All';
        this.router.navigate(['/app/projects/', this.projectId, 'dashboard']);
    }

    goToEnvironment(element) {
        this.recentProfile.profileId = 'All';
        this.selectedProfileId = 'All';
        this.router.navigate(['/app/config-environments/projects', this.projectId, 'environmentList']);
    }

    goToReporting(element) {
        // [routerLink]="['/app/reports/projects', projectId, 'reporting']"
        this.recentProfile.profileId = 'All';
        this.selectedProfileId = 'All';
        this.router.navigate(['/app/reports/projects', this.projectId, 'reporting']);
    }

    lastEvent: any;
    changeSort(obj, event) {
        event.stopPropagation();
        this.sortString = obj;
        //  if (event.currentTarget.ariaSort == "ascending") this.sortType = "ASC"; else this.sortType = "DESC";
        if (this.sortType == "ASC") this.sortType = "DESC"; else this.sortType = "ASC";
        if (this.lastEvent != null) {
            if (this.jobId) {
                this.getRunByJob(this.jobId);
            } else {
                this.fetchAllScans();
            }
        } else this.fetchAllScans();
    }

    // Calculate the time difference in seconds
    calculateDiff(createDate, closedDate) {
        var date1: any = new Date(createDate);
        var date2: any = new Date(closedDate);
        const timeDifferenceInMillis = Math.abs(date1.getTime() - date2.getTime());
        const diffTime = Math.floor(timeDifferenceInMillis / 1000);
        return diffTime
    }

}
