import { Component, OnInit, Inject, ViewChild, Output, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-manage-ddos',
  templateUrl: './manage-ddos.component.html',
  styleUrls: ['./manage-ddos.component.scss']
})
export class ManageDdosComponent implements OnInit {

  displayedColumns: string[] = ['isSelected', 'name', 'type', 'defaultValue'];
  dataObj3 = [];
  selectedAllData = [];
  resourceSamples = [];
  allSelectedFlag: boolean = false;
  fewSelectedFlag: boolean = false;
  keyword: string = '';


  ddosParamsDataSource;
  @ViewChild(MatSort) sort: MatSort;
  @Output() emitData = new EventEmitter<string>();

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<ManageDdosComponent>) {
    if (data[1] != null && data[1] != "")
      this.resourceSamples = data[1].split(",");
    else
      this.resourceSamples = [];

  }

  ngOnInit() {

    this.dataObj3 = JSON.parse(JSON.stringify(this.data[0]));

    for (var a = 0; a < this.dataObj3.length; a++) {
      this.dataObj3[a]['defaultValue2'] = this.dataObj3[a].defaultValue
    }


    for (var i = 0; i < this.resourceSamples.length; i++) {
      for (var k = 0; k < this.dataObj3.length; k++) {
        var array1 = this.resourceSamples[i].split('=');
        if (this.dataObj3[k].name == array1[0]) {
          this.dataObj3[k].isSelected = true
          this.resourceSamples.splice(i, 1);
          k = this.dataObj3.length;
          i = i - 1;
        }
      }

    }

    //add remaining values to dataobj3
    for (var i = 0; i < this.resourceSamples.length; i++) {
      var temp = {
        name: this.resourceSamples[i].split('=')[0], defaultValue2: this.resourceSamples[i].split('=')[1],
        isSelected: true, custom: true
      };
      this.dataObj3.push(temp);
    }

    this.ddosParamsDataSource = this.dataObj3;
    this.ddosParamsDataSource = new MatTableDataSource(this.ddosParamsDataSource);
    this.ddosParamsDataSource.sort = this.sort;

    // this.removeDuplicate(JSON.parse(JSON.stringify(this.data[0])))


    this.ddosParamsDataSource.filterPredicate = function (data, filter: string): boolean {
      return data.name.toLowerCase().includes(filter);
    };
  }

  removeDuplicate(arr) {
    //initialize 
    this.selectedAllData = [];

    this.dataObj3 = arr;
    this.ddosParamsDataSource = this.dataObj3;
    this.ddosParamsDataSource = new MatTableDataSource(this.ddosParamsDataSource);
    this.ddosParamsDataSource.sort = this.sort;
    let processParams = new Map();
    for (var i = 0; i < this.resourceSamples.length; i++) {
      var array1 = this.resourceSamples[i].split('=');
      for (var j = 0; j < this.dataObj3.length; j++) {
        if (this.dataObj3[j].name == array1[0]) {
          this.dataObj3[j].defaultValue = array1[1];
          processParams.set(this.resourceSamples[i], true)
          this.selectedAllData.push(this.dataObj3[j]);
        }
      }
    }
    for (var i = 0; i < this.resourceSamples.length; i++) {
      var array = this.resourceSamples[i].split('=');
      //  for (var j = 0; j < this.dataObj3.length; j++) {
      if (!processParams.has(this.resourceSamples[i])) {
        var temp = { name: array[0], defaultValue: array[1], custom: true };
        this.selectedAllData.push(temp);
        this.dataObj3.push(temp);
        processParams.set(this.resourceSamples[i], true)
      }
      //    }
    }
    for (var k = 0; k < this.dataObj3.length; k++) {
      this.dataObj3[k]['defaultValue2'] = this.dataObj3[k].defaultValue
    }

    if (this.selectedAllData.length == this.dataObj3.length)
      this.allSelectedFlag = true;
    else if (this.selectedAllData.length != 0)
      this.fewSelectedFlag = true;
  }

  save() {
    let modifiedData = '';
    // take selected rows
    this.dataObj3.find(x => {
      if (x.isSelected) {
        modifiedData = modifiedData + x.name + '=' + x.defaultValue2 + ','
      }
    })

    if (modifiedData.endsWith(",")) {
      modifiedData = modifiedData.substring(0, modifiedData.length - 1);
    }

    //end

    // for (var i = 0; i < this.selectedAllData.length; i++) {
    //   modifiedData = modifiedData + this.selectedAllData[i].name + '=' + this.selectedAllData[i].defaultValue2 + ',';
    // }
    // if (modifiedData.endsWith(",")) {
    //   modifiedData = modifiedData.substring(0, modifiedData.length - 1);
    // }
    this.emitData.next(modifiedData);
    this.dialogRef.close();
  }
  isButtonDisable: boolean = true;
  addParam() {
    var newRow = { name: '', type: '', defaultValue2: '', custom: true, isSelected: true };
    this.dataObj3.push(newRow);
    this.ddosParamsDataSource = this.dataObj3;
    this.ddosParamsDataSource = new MatTableDataSource(this.ddosParamsDataSource);

    this.isButtonDisable = this.ddosParamsDataSource.data.find(x => x.name == "") == (undefined || null);
    this.ddosParamsDataSource.sort = this.sort;
    // this.removeDuplicate(this.dataObj3)
  }


  applyFilter(filterValue: string) {
    // if (filterValue.length == 0 || filterValue.length >= 3) {
    //   console.log(filterValue.length)
    this.ddosParamsDataSource.filter = filterValue.trim().toLowerCase();
    this.dataObj3 = this.ddosParamsDataSource.filteredData
    // }
  }

  isParamChecked(obj) {
    if ((this.allSelectedFlag || this.fewSelectedFlag) && this.ddosParamsDataSource.data.length > 0) { if (obj == 1) return true; else if (obj == 2) return false; }
    return false;
  }

  selectParam(selectName, selectValue, event) {

    this.dataObj3.find(x => {
      if (x.name == selectName) {
        x.isSelected = !x.isSelected;
      }
    })

    if (event.checked) {
      var tmp: any = {
        name: selectName,
        defaultValue2: selectValue
      };
      this.selectedAllData.push(tmp);

      if (this.selectedAllData.length == this.dataObj3.length) {
        this.allSelectedFlag = true;
        this.fewSelectedFlag = false;
      } else {
        this.allSelectedFlag = false;
        this.fewSelectedFlag = true;

      }
    }

    else {

      this.allSelectedFlag = false;
      this.fewSelectedFlag = true;

      for (var j = 0; j < this.selectedAllData.length; j++) {
        if (this.selectedAllData[j].name == selectName)
          if (this.selectedAllData[j].defaultValue == selectValue) {
            this.selectedAllData.splice(j, 1);
            break;
          }

      }

      if (this.selectedAllData.length == 0)
        this.fewSelectedFlag = false;

    }
  }

  selectedAllParams(event) {
    //All checked
    if (event.checked) {

      for (var i = 0; i < this.dataObj3.length; i++) {
        this.selectedAllData[i] = this.dataObj3[i];
        this.resourceSamples[i] = this.dataObj3[i].name + '=' + this.dataObj3[i].defaultValue;
      }

    }
    else {

      this.selectedAllData = [];
      this.resourceSamples = [];
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  validate() {
    this.isButtonDisable = this.ddosParamsDataSource.data.find(x => x.name == "") == (undefined || null);
    this.isButtonDisable = this.ddosParamsDataSource.data.find(x => x.defaultValue2 == "") == (undefined || null);
  }

  ngAfterViewInit() {
    // this.dataSource.paginator = this.paginator;
    this.ddosParamsDataSource.sort = this.sort;
  }
}
