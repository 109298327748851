import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { notDeepStrictEqual } from 'assert';
import { Handler } from '../components/dialogs/handler/handler';
// import { Org, Teams, Member } from '../models/org.model';
import { OrgService } from '../services/org.service';
import { SnackbarService } from '../services/snackbar.service';
import { Router } from "@angular/router";
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TeamsUserListDialogComponent } from '../components/dialogs/teams-userslist-dialog/teams-userlist-dialog.component';
@Component({
  selector: 'app-team-new',
  templateUrl: './team-new.component.html',
  styleUrls: ['./team-new.component.scss'],
  providers: [OrgService, SnackbarService]
})
export class TeamNewComponent implements OnInit {
  firstFormGroup: FormGroup;
  dialogRef: MatDialogRef<true, ''>;
  @Output() emitData = new EventEmitter<string[]>();

  selectedUserIDs = [];
  teamsControl = new FormControl([]);
  orgId: string;
  dataresult: any;
  newarray: any;
  users = [];
  name: any;
  NewUsers: any;
  topping;
  data;
  userId: any;
  description: any;
  page = 0;
  pageSize = 10;
  selectedUsers: string[] = [];
  selected
  selectedUsersData
  selectedUsersNames = []
  constructor(private _formBuilder: FormBuilder, private orgService: OrgService, private handler: Handler, private snackbarService: SnackbarService, private router: Router, private dialog: MatDialog) {


  }


  ngOnInit(): void {
    this.firstFormGroup = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.maxLength(250),  Validators.pattern('[a-zA-Z0-9 ]*')]),
      description: new FormControl('', [Validators.required, Validators.maxLength(250),  Validators.pattern('[a-zA-Z0-9 ]*')]),
      teams: new FormControl('', Validators.required)
    });
    this.getorgsId();
  }

  getorgsId() {

    this.orgService.getLoggedInUser().subscribe(item => {
      this.data = item["data"];
      this.orgId = this.data.org.id;
      this.getOrgUser();
    })

  }
  newData
  getOrgUser() {

    this.orgService.getOrgUsersByIdByPage(this.orgId, this.page, 100).subscribe(results => {
      this.dataresult = results['data'];
      for (let i = 0; i < this.dataresult.length; i++) {
        this.newData = this.dataresult[i].users
      }
      this.users = this.dataresult.map(newdt => newdt.users);
      console.log('this.users0', this.users);
      this.userId = this.dataresult.map(newdt =>
        this.userId = 
        newdt.users.id);
      // this.selectedid = this.team.usersId
    })
  }



  trackByFunction(index: number, item: string): string {
    return item; 
  }

  selectedUserIDs2;
  createTeam() {
    // this.selectedUserIDs2 = this.selectedUsersData.map(a =>
    //   a.id);

    // this.firstFormGroup.value.teams.map(t => {
    //   this.selectedUserIDs.push({ id: this.selectedUserIDs2 })
    // })

    const sendData = {
      name: this.firstFormGroup.get('name').value,
      description: this.firstFormGroup.get('description').value,
      org: {
        id: this.orgId
      },
      users: this.selectedUsersData
    };
    this.handler.activateLoader();
    this.snackbarService.openSnackBar("'" + this.name + "' creating...", "");
    this.orgService.createTeam(sendData).subscribe(result => {
      this.handler.hideLoader();
      if (this.handler.handle(result)) {
        return;
      }
      this.snackbarService.openSnackBar("'" + this.name + "' created successfully", "");
      this.router.navigate(['/app/teams']);
    }, error => {
      this.handler.hideLoader();
      this.handler.error(error);
    })
  }

  openUserListDialog() {
    const dialogRef = this.dialog.open(TeamsUserListDialogComponent, {
      width: '600px',
      data: {
        userId: this.selectedUserIDs
      }
    });

    dialogRef.componentInstance.emitData.subscribe(data1 => {
      this.selectedUsersData = data1

      this.selectedUsersNames = this.selectedUsersData.map(a => a.name);

    });
  }


}
