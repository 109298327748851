import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SnackbarService } from '../../../services/snackbar.service';
import { Handler } from '../handler/handler';

@Component({
  selector: 'app-api-gateway-resp-dialog',
  templateUrl: './api-gateway-resp-dialog.component.html',
  styleUrls: ['./api-gateway-resp-dialog.component.scss'],
  providers: [SnackbarService]

})
export class ApiGatewayRespDialogComponent implements OnInit {

  text;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private handler: Handler, private snackbarService: SnackbarService,
    public dialogRef: MatDialogRef<ApiGatewayRespDialogComponent>
  ) { }


  ngOnInit(): void {
    this.text = this.data.data.toString();
    this.text = this.text.replaceAll(',', '\n');

  }

  showMsg() {
    this.snackbarService.openSnackBar('Text copied to clipboard.', '');

  }
}