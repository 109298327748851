import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-chatbot',
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.scss']
})
export class ChatbotComponent {
  isChatbotOpen: boolean = false;
  isChatbotMinimized: boolean = false;
  userInput: string = '';
  chatMessages: any[] = [];
  sampleQuestions: string[] = ['How to register a project?', 'How to trigger a scan?', 'How to register jira issue tracker'];

  constructor(private http: HttpClient) {}

  toggleChatbot() {
    this.isChatbotOpen = !this.isChatbotOpen;
    this.isChatbotMinimized = false;
  }

  closeChatbot() {
    this.isChatbotOpen = false;
    this.isChatbotMinimized = false;
    this.chatMessages = [];
  }

  minimizeChatbot() {
    this.isChatbotOpen = false;
    this.isChatbotMinimized = true;
  }

  useSampleQuestion(question: string) {
    this.sendMessage(question);
  }

  sendMessage(message?: string) {
    const text = message || this.userInput; // Use provided message or user input
    if (text.trim() !== '') {
      this.chatMessages.push({ text, isBotResponse: false, isHuman: true });

      this.http.post('http://apps.apisec.ai:8000/chat', {
        question: text
      }).subscribe(
        (response: any) => {
          this.chatMessages.push({ text: response.Response, isBotResponse: true, isHuman: false });

          // Scroll the chat messages container to the bottom after a short delay
          setTimeout(() => {
            this.scrollToBottom();
          });
        },
        (error) => {
          console.error('Error fetching response:', error);
        }
      );

      if (!message) {
        this.userInput = ''; // Clear user input if sending manually
      }
    }
  }

  private scrollToBottom(): void {
    const chatContainer = document.querySelector('.chat-messages');
    if (chatContainer) {
      chatContainer.scrollTop = chatContainer.scrollHeight;
    }
  }
}