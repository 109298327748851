import { Component, OnInit, Inject, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, NgModel } from '@angular/forms';
import { TestSuiteService } from '../../../services/test-suite.service';
import { RunService } from '../../../services/run.service';
import { ProjectService } from '../../../services/project.service';
import { Handler } from '../../dialogs/handler/handler';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SnackbarService } from '../../../services/snackbar.service';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';
@Component({
  selector: 'app-archived-categories-dialog',
  templateUrl: './archived-categories-dialog.component.html',
  styleUrls: ['./archived-categories-dialog.component.scss'],
  providers: [TestSuiteService, RunService, ProjectService, SnackbarService]
})

export class ArchivedCategoriesDialogComponent implements OnInit {

  id; // project id
  checked = false;
  showCategories1 = false;
  projectId: string = '';
  showSpinner: boolean = false;
  indeterminateChecked = false;
  checked2: boolean = false;
  checked3: boolean = false;
  status: string;
  autoCodeSyncDeleteObj;
  archiveCategories = new FormControl();
  closeCategories = new FormControl();
  generatorTypes = [];
  archiveIndeterminateFlag = false;
  archiveAllFlag: boolean = false;
  closeIndeterminateFlag = false;
  closeAllFlag: boolean = false;
  catStatus: string = "";
  radioSelected = 'archive';
  autoCodeSyncObj: any = null;
  generatorTypesComp:any=null;
  generatorTypesCompList:any=[];
  @Output() emitData = new EventEmitter<string>();
  categories: any = [];
  compCategories:any=[];
  showMsg=false;
  constructor(private testSuiteService: TestSuiteService, private runService: RunService, private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private projectService: ProjectService, private route: ActivatedRoute,
    private router: Router, private handler: Handler, public dialogRef: MatDialogRef<ArchivedCategoriesDialogComponent>,
    private snackbarService: SnackbarService) {
    this.projectId = this.data[0];
    this.status = this.data[1];
    this.autoCodeSyncObj = this.data[2];
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.id = params['id'];
      this.showMsg=false;
      this.autoCodeSyncObj = this.autoCodeSyncObj.filter((item) => item.groupBy !== "All");
    });
    if(this.autoCodeSyncObj && this.autoCodeSyncObj.length>0){  
      this.generatorTypes=[];
    for (const t of this.autoCodeSyncObj) {
      this.generatorTypes.push(t.groupBy);
    } 
    this.generatorTypesComp = this.autoCodeSyncObj.filter(x => x.type =="C");
    if(this.generatorTypesComp && this.generatorTypesComp.length>0){
      this.showMsg=false;
      this.generatorTypesCompList=[];
      for (const ct of this.generatorTypesComp) {
        this.generatorTypesCompList.push(ct.groupBy);
      }
    }
    else
    this.showMsg=true;
  }
}

  disableBtnArchive: boolean = true;
  selectionChangeArchive() {
    if (this.categories.length === 0) {
      this.archiveAllFlag = false;
      this.archiveIndeterminateFlag = false;
    } else
      if (this.categories.length < this.autoCodeSyncObj.length) {
        this.archiveIndeterminateFlag = true;
        this.archiveAllFlag = false;
      } else
        if (this.categories.length === this.autoCodeSyncObj.length) {
          this.archiveAllFlag = true;
          this.archiveIndeterminateFlag = false;
        }
    if (this.categories.length <= 0)
      this.disableBtnArchive = true;
    else if (this.categories.length > 0)
      this.disableBtnArchive = false;
  }

  disableBtnClose: boolean = true;
  selectionChangeClose() {
    if (this.compCategories.length === 0) {
      this.closeAllFlag = false;
      this.closeIndeterminateFlag = false;
    } else
      if (this.compCategories.length < this.generatorTypesComp.length) {
        this.closeIndeterminateFlag = true;
        this.closeAllFlag = false;
      } else
        if (this.compCategories.length === this.generatorTypesComp.length) {
          this.closeAllFlag = true;
          this.closeIndeterminateFlag = false;
        }
    if (this.compCategories.length <= 0)
      this.disableBtnClose = true;
    else if (this.compCategories.length > 0)
      this.disableBtnClose = false;
  }

  archive() {
    // var skip = confirm(
    //   "Are you sure you want to Archive the vulnerabilities?"
    // );
    let dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: "580px",
      height: "225px",
      data: ["Archive", "Are you sure you want to Archive the vulnerabilities?"]
  });
  dialogRef.componentInstance.emitData.subscribe(data1 => {
    if (data1 == 'Y') {
      this.showSpinner = true;
      if (this.status == 'active') {
        this.catStatus = 'open'
      }
      if (this.status == 'archived') {
        this.catStatus = 'CLOSED'
      }
      this.snackbarService.openSnackBar("Archiving vulnerability...", "");
      this.handler.activateLoader();
      this.projectService.ArchivebyCategories(this.projectId, this.catStatus, this.categories,this.data[3]).subscribe((results) => {
        this.handler.hideLoader();
        if (this.handler.handle(results)) {
          return;
        }
        this.emitData.next(this.radioSelected);
        this.snackbarService.openSnackBar(
          this.categories + " archived successfully",
          ""
        );
        this.router.navigate(['/app/projects', this.projectId,'dashboard']);
        this.dialogRef.close();
      }, (error) => {
        this.handler.hideLoader();
        this.handler.error(error);
      });
    }
  })
  }

  close() {
    // var skip = confirm(
    //   "Are you sure you want to Close the vulnerabilities?"
    // );
    let dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: "580px",
      height: "225px",
      data: ["Close", "Are you sure you want to Close the vulnerabilities?"]
  });
  dialogRef.componentInstance.emitData.subscribe(data1 => {
    if (data1 == 'Y') {
      this.showSpinner = true;
      if (this.status == 'active') {
        this.catStatus = 'open'
      }
      this.snackbarService.openSnackBar("Closing vulnerabilities...", "");
      this.handler.activateLoader();

      this.projectService.ClosebyCategories(this.projectId, this.catStatus, this.compCategories,this.data[3]).subscribe((results) => {
        this.handler.hideLoader();
        if (this.handler.handle(results)) {
          return;
        }
        this.emitData.next(this.radioSelected);
        this.snackbarService.openSnackBar(
          this.compCategories + " closed successfully",
          ""
        );
        this.router.navigate(['/app/projects', this.projectId,'dashboard']);
        this.dialogRef.close();
      }, (error) => {
        this.handler.hideLoader();
        this.handler.error(error);
      });
    }
  })
  }

  selectArchiveAll(ev) {
    if (ev.checked) {
      this.disableBtnArchive=false;
      this.archiveAllFlag = true;
      this.archiveCategories.setValue(this.generatorTypes);
    } else {
      this.archiveAllFlag = false;
      this.disableBtnArchive=true;
      this.archiveCategories.setValue([]);
    }
  }

  selectCloseAll(ev) {
    if (ev.checked) {
      this.disableBtnClose=false;
      this.closeAllFlag = true;
      this.closeCategories.setValue(this.generatorTypesCompList);
    } else {
      this.closeAllFlag = false;
      this.disableBtnClose=true;
      this.closeCategories.setValue([]);
    }
  }

  //close all
  closeAll() {
    // var skip = confirm(
    //   "Are you sure you want to close all the vulnerabilities?"
    // );
    let dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: "580px",
      height: "225px",
      data: ["Close All", "Are you sure you want to close all the vulnerabilities?"]
  });
  dialogRef.componentInstance.emitData.subscribe(data1 => {
    if (data1 == 'Y') {
      this.handler.activateLoader();
      this.snackbarService.openBackgroundMsgBar(
        "apisec is closing all the vulnerabilities on the portal and in the registered issue-tracker. This operation can take several minutes to complete based on issue-tracker rate limits."
      );
      this.projectService.clearAllSuggestions(this.projectId).subscribe(
        results => {
          this.snackbarService.openSnackBar(
            "Vulnerabilities closed successfully",
            ""
          );
          this.router.navigate(['/app/project', this.projectId,'/dashboard']);
          this.dialogRef.close();
          this.handler.hideLoader();
          // this.loadSuggestions(this.id, this.toggleValue);
          // this.loadCategoriesCount(this.id, this.toggleValue);
          // this.get("count-archived-auto-suggestions", "archived");
          // this.loadBountyValue(this.id);
          // this.searchByCategory();
        },
        error => { }
      );
    }
  })
  }

}
