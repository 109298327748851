import { Component, OnInit, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
@Component({
  selector: 'app-custom-snackbar',
  templateUrl: './custom-snackbar.component.html',
  styleUrls: ['./custom-snackbar.component.scss']
})
export class CustomSnackbarComponent implements OnInit {

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: string, public snackBar: MatSnackBar,
    public router: Router, private route: ActivatedRoute,) { }
  paramsSubscription: Subscription;
  ngOnInit(): void {

    this.paramsSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd == true) {
        this.close()
      }
    })
  }

  close() {
    this.paramsSubscription.unsubscribe();
    this.snackBar.dismiss();
  }
}
