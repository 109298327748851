import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { UserVariable } from '../models/user-variable.model';
import { MixpanelService } from './mixpanel.service';

@Injectable({
    providedIn: 'root'
})
export class UserVariableService {

    private userVariableServiceUrl = '/api/v1/user-variable';

    constructor(private httpClient: HttpClient, private mixpanelService: MixpanelService) { }

    getUserVariablesByProjectId(projectId: string, page, pageSize) {
        let params = new HttpParams();
        params = params.append('page', page);
        params = params.append('pageSize', pageSize);
        return this.httpClient.get(this.userVariableServiceUrl + "/project/" + projectId, { params });
    }

    saveUserVariable(userVariable: UserVariable) {

        //mixpanel code starts

        var obj = {
            "varName": userVariable.name
        }
        this.mixpanelService.trackMixpanel("NewVariable", obj);
        //mixpanel code ends

        return this.httpClient.post(this.userVariableServiceUrl, userVariable);
    }

    createUserVariables(userVariables: UserVariable[]) {
        //mixpanel code starts
        this.mixpanelService.trackMixpanel("bulkVariables", "");
        //mixpanel code ends
        return this.httpClient.post(this.userVariableServiceUrl + "/create-variables", userVariables);
    }

    updateUserVariable(userVariable: UserVariable) {
        //mixpanel code starts

        var obj = {
            "varName": userVariable.name
        }
        this.mixpanelService.trackMixpanel("EditVariable", obj);
        //mixpanel code ends


        return this.httpClient.post(this.userVariableServiceUrl + "/" + userVariable.id, userVariable);
    }

    deleteUserVariable(userVariable: UserVariable) {
        return this.httpClient.delete(this.userVariableServiceUrl + "/" + userVariable.id);
    }

    getUserVariableById(userVariableId: string) {
        return this.httpClient.get(this.userVariableServiceUrl + "/" + userVariableId);
    }
    searchUserVariableByName(projectId: string, keyword: string, page, pageSize) {
        let params = new HttpParams();
        params = params.append('page', page);
        params = params.append('pageSize', pageSize);
        return this.httpClient.get(this.userVariableServiceUrl + "/project/" + projectId + "/search/" + keyword, { params });
    }

}