import {Component, Inject, OnInit} from '@angular/core';
import {SnackbarService} from "../../../services/snackbar.service";
import {BambooDialogComponent} from "../bamboo-dialog/bamboo-dialog.component";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {OrgService} from "../../../services/org.service";
import {Handler} from "../handler/handler";

@Component({
  selector: 'app-qr-code-dialog',
  templateUrl: './qr-code-dialog.component.html',
  styleUrls: ['./qr-code-dialog.component.scss'],
    providers: [OrgService, SnackbarService]
})
export class QrCodeDialogComponent implements OnInit {

    constructor( @Inject(MAT_DIALOG_DATA) public data: any,private orgService: OrgService,
        private handler: Handler, private snackbarService: SnackbarService, public dialogRef: MatDialogRef<QrCodeDialogComponent>) { }
    agreed = false;

    ngOnInit() {
  }

    save2Fa(mfaFlag){
        this.dialogRef.close();
        if(mfaFlag)
        {
            this.snackbarService.openSnackBar(" 2FA activated successfully.", "");

        }
        else
        {
            this.handler.activateLoader();
            this.orgService.updateMfa(mfaFlag).subscribe(results => {
                this.handler.hideLoader();
                if (this.handler.handle(results)) {
                    return;
                }


            }, error => {
                this.handler.hideLoader();
                this.handler.error(error);
            });
        }

    }
}
