import { AutoCodeConfig } from './../../../models/project-autocode-config.model';
import {Component, EventEmitter, Inject, OnInit, Output, ViewChild} from '@angular/core';
import {ELASTICSEARCHVALIDATOR} from "../../../shared/shared.data";
import {Handler} from "../handler/handler";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import {ProjectService} from "../../../services/project.service";
import {Project} from "../../../models/project.model";
import {MatDialogRef} from "@angular/material/dialog";
// import { element } from '@angular/core/src/render3';

@Component({
    selector: 'app-xss-filter-dialog',
    templateUrl: './xss-filter-dialog.component.html',
    styleUrls: ['./xss-filter-dialog.component.scss'],
    providers: [ProjectService]
})

export class XssFilterDialogComponent implements OnInit {
    project: Project = new Project();
    projectId: string;
    keyword: string;
    displayedColumns: string[] = ['resourceName', 'endpoint', 'filterFlag'];
    endPointDataSource = null;
    endpointsData: any;
    resourceSamples;
    allSelectedFlag: boolean = false;
    fewSelectedFlag: boolean = false;
    fewSelectedData = [];
    searchStringValidator = ELASTICSEARCHVALIDATOR;
    filterFlag;
    @ViewChild(MatSort, {static: true }) sort: MatSort;
    @Output() emitData = new EventEmitter<string>();
    totalEndpoints: number;
    filterType='';

    constructor(private dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any, private handler: Handler,
                public dialogRef: MatDialogRef<XssFilterDialogComponent>, private projectService: ProjectService) {

    }

    ngOnInit() {
        
        this.filterType = this.data.filterType;
        if (this.data.pathPatterns != null)
            this.resourceSamples = this.data.pathPatterns.split("\n");
        else
            this.resourceSamples = [];

        this.projectId = this.data.projectId;
        this.endpointsData = this.data.endpointsData;

        if(this.endpointsData.length == 0)
        this.getEndpointList();
        else
            this.processEndpointData();
        

    }

    processEndpointData() {
        for(let element of this.endpointsData){
            var index=  this.resourceSamples.findIndex(r1 => element.method == r1.split(':')[0] && element.endpoint == r1.split(':')[1])
            if(index >= 0){
                element['filterFlag'] = true
            }
            else
                element['filterFlag'] = false
        }

        this.endPointDataSource = this.endpointsData;

        this.totalEndpoints = this.endpointsData.length;
        this.endPointDataSource = new MatTableDataSource(this.endPointDataSource);
        this.endPointDataSource.sort = this.sort;
        let totalSelected = this.endpointsData.filter(i => i.filterFlag).length;
        if(totalSelected == 0)
        {
            this.allSelectedFlag = false;
            this.fewSelectedFlag = false;
        }
        else
        if(totalSelected > 0 && totalSelected < this.endpointsData.length)
        {
            this.allSelectedFlag = false;
            this.fewSelectedFlag = true;
        }
        else
        if(totalSelected > 0 && totalSelected == this.endpointsData.length)
        {
            this.allSelectedFlag = true;
            this.fewSelectedFlag = false;
        }
       
    }
    getEndpointList() {
        this.handler.activateLoader();
        this.projectService.getEndpoints(this.projectId).subscribe(results => {
            this.handler.hideLoader();
            if (this.handler.handle(results)) {
                return;
            }
            this.endpointsData = results['data'];

        }, error => {
            this.handler.hideLoader();
            this.handler.error(error);
        });
    }
    save() {
        let modifiedData = '';
        for(let element of this.endpointsData){
            if(element.filterFlag == true){
                modifiedData = modifiedData + element.method + ':' + element.endpoint + '\n';
            }
        }
        modifiedData = modifiedData.replace(/\n$/, "");
        this.emitData.next(modifiedData);
        this.dialogRef.close();
    }
    selectedEndPoint(f) {
        let totalSelected = this.endpointsData.filter(i => i.filterFlag).length;

    //     if(this.data.filterType != 'Sensitive Data Exposure'){
    //     if(totalSelected > 5 ){
    //         alert("Only sampling is allowed, select up to 5 endpoints.");
    //             if(f.filterFlag == true){
    //                 setTimeout(() => { f.filterFlag = false; }, );
    //     }
    // }
    // }

    if(totalSelected == 0)
    {
        this.allSelectedFlag = false;
        this.fewSelectedFlag = false;
    }
    else
    if(totalSelected > 0 && totalSelected < this.endpointsData.length)
    {
        this.allSelectedFlag = false;
        this.fewSelectedFlag = true;
    }
    else
    if(totalSelected > 0 && totalSelected == this.endpointsData.length)
    {
        this.allSelectedFlag = true;
        this.fewSelectedFlag = false;
    }
   }  

    applyFilter(filterValue: string) {
        if(filterValue.length==0 || filterValue.length>=3){
        this.endPointDataSource.filter = filterValue.trim().toLowerCase();
        }
    }

    selectedAllEndPoints(event) {
        //All checked
        if (event.checked) {

            for(let element of this.endpointsData){
                element['filterFlag'] = true
            }
        }
        //All unchecked
        else {

            for(let element of this.endpointsData){
                element['filterFlag'] = false
            }
        }

    }

}