import { Component, OnInit, Inject, ViewChild, Output, EventEmitter } from '@angular/core';
// import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// import { RegionsService } from '../../../services/regions.service';
import { Routes, RouterModule, Router, ActivatedRoute } from '@angular/router';
import { SnackbarService } from '../../../services/snackbar.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ProjectService } from '../../../services/project.service';
import { Handler } from '../handler/handler';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { ApiGroups } from '../../../services/api-groups.service';
import { OrgService } from '../../../services/org.service';


@Component({
  selector: 'teams-userlist-dialog',
  templateUrl: './teams-userlist-dialog.component.html',
  styleUrls: ['./teams-userlist-dialog.component.scss'],
  providers: [SnackbarService, ProjectService, ApiGroups, OrgService]
})
export class TeamsUserListDialogComponent implements OnInit {


  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<TeamsUserListDialogComponent>, private handler: Handler, private snackbarService: SnackbarService, private projectService: ProjectService, private apiGroups: ApiGroups, private orgService: OrgService) { }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Output() emitData = new EventEmitter<string>();
  page = 0;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 25];
  sortString: string = 'createdDate';
  sortType: string = 'DESC';
  tag: string = "";
  projectListData;
  // dataSource = null;
  projectId;
  projectLength1;

  testId
  dataresult
  newData;
  users
  userId
  usersData
  newId = [];
  selectedName: any = [];
  test123
  orgId
  getOrgsData
  NewArray = [];
  filteredData;
  dataUsersName = [];
  length: number = 0;
  displayedColumns: string[] = ['add', 'name'];
  ngOnInit() {

    this.getorgsId()

  }
  change(evt) {
    this.page = evt['pageIndex'];
    this.pageSize = evt.pageSize;
    this.getorgsId();
  }
  close() {
    this.dialogRef.close();
  }

  getorgsId() {
    this.orgService.getLoggedInUser().subscribe(item => {
      this.getOrgsData = item["data"];
      this.orgId = this.getOrgsData.org.id;
      this.getOrgUser();
    })

  }

  getOrgUser() {

    this.orgService.getOrgUsersByIdByPage(this.orgId, this.page, 100).subscribe(results => {
      this.dataresult = results['data'];






      this.users = this.dataresult.map(newdt => newdt.users);

      this.users.forEach(obj1 => {



        if (this.data['name'] != null && this.data['name'].includes(obj1.name)) {
          obj1['inactive'] = true;
        }
        else obj1['inactive'] = false;

      });
      this.filteredData = this.users.sort((p1, p2) => (p1.inactive < p2.inactive) ? 1 : (p1.inactive > p2.inactive) ? -1 : 0);
      this.selectedName = this.users.filter((selected) => selected.inactive == true)

      this.usersData = new MatTableDataSource(this.filteredData);
      this.usersData.paginator = this.paginator;
      this.length = results['totalElements'];
      // this.selectedid = this.team.usersId
    })
  }
  keyword: any = "";


  applyFilter(filterValue: string) {

    filterValue = filterValue.trim().toLowerCase();
    const escapedFilterValue = filterValue.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&');
    const regex = new RegExp(escapedFilterValue, 'i');
    this.usersData.filterPredicate = (data: any, filter: string) => {
      const matchColumns = [
       data['name']
      ];
      return matchColumns.some(column => regex.test(column));

    };
    this.usersData.filter = filterValue;
    if (this.usersData.paginator) {
      this.usersData.paginator.firstPage();
    }

  }
  onSelectRow(item, event: any) {
    if (event.checked) {
      item.inactive = event.checked
      this.selectedName.push(item);
      this.users.forEach(element => {
        if (item.id == element.id) {
          element.inactive = true;
        }
      })
    } else {
      this.selectedName = this.selectedName.filter((selected) => selected.inactive == true);
      this.users.forEach(element => {
        if (item.id == element.id) {
          element.inactive = false;
        }
      })
      const index = this.selectedName.indexOf(item);
      if (index !== -1) {
        this.selectedName.splice(index, 1);
      }
    }
  }
  onAddClick() {
    this.emitData.next(this.selectedName);
    this.dialogRef.close(this.selectedName);
  }

}


