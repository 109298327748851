import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Routes, RouterModule, Router, ActivatedRoute } from "@angular/router";
import { AccountService } from '../../../services/account.service';
import { OrgService } from '../../../services/org.service';
import { SnackbarService } from '../../../services/snackbar.service';
import { Account } from '../../../models/account.model';
import { Handler } from '../../dialogs/handler/handler';
import { MixpanelService } from '../../../services/mixpanel.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  providers: [AccountService, OrgService, SnackbarService]
})
export class RegisterComponent implements OnInit {
  entry: Account = new Account();
  accountTypes = ['GitHub'];
  AWSREGIONS = ['us-east-1', 'us-east-2', 'us-west-1', 'us-west-2', 'ca-central-1', 'eu-central-1', 'eu-west-1', 'eu-west-2', 'eu-west-3', 'ap-northeast-1', 'ap-northeast-2', 'ap-northeast-3', 'ap-southeast-1', 'ap-southeast-2', 'ap-southeast-1', 'sa-east-1'];

  storageAccountTypes = ['AWS_S3', 'AZURE_STORAGE', 'GOOGLE_CLOUD_STORAGE', 'Sftp'];

  isValid: boolean = true;
  hide: boolean = true;

  constructor(private accountService: AccountService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private handler: Handler,
    private snackbarService: SnackbarService,
    private route: ActivatedRoute,
    private router: Router,
    public dialogRef: MatDialogRef<RegisterComponent>,
    private mixpanelService: MixpanelService,
    private dialog: MatDialog) { 
      this.entry.accountType='GitHub';
    }

  ngOnInit() {
  }

  create() {
    this.handler.activateLoader();
    const accountType=this.entry.accountType;
    var accObj=JSON.parse(JSON.stringify(this.entry));      
    if(this.entry.accountType=='GitHub'||this.entry.accountType=='GitLab' || this.entry.accountType=='Git'){
      if(this.isValid==true) this.entry.accessKey='PAT';
      else this.entry.accessKey="";
    accObj.accountType='Git';   
    }
   //mixpanel code starts
    var obj1 = {
      "newCredName": this.entry.name,
      "accType": this.entry.accountType
    }
    if (this.data[0] == 'reportStore')
      this.mixpanelService.trackMixpanel("NewReportStorageCreds", obj1);
    else
      this.mixpanelService.trackMixpanel("NewGithubCreds", obj1);
          //mixpanel code ends

    this.snackbarService.openSnackBar("'" + accountType + "' configuring ...", "");
    this.accountService.create(accObj).subscribe(results => {
      this.handler.hideLoader();
      if (this.handler.handle(results)) {
        return;
      }
      this.snackbarService.openSnackBar("'" + accountType + "'  configurations saved successfully", "");
      this.onClose();
      // this.router.navigateByUrl('/app/projects', {skipLocationChange: true}).then(()=>
      //   this.router.navigate(['/app/projects/new']));

    }, error => {
      this.handler.hideLoader();
      this.handler.error(error);
    });
  }

  onClose() {
    this.dialogRef.close();
  }

  changeValue(valid: boolean) {
    this.isValid = valid;
  }

}
