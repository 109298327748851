import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
@Injectable({
  providedIn: "root",
})
export class AbacService {
  private serviceUrl = "/api/v1/abac";
  constructor(private http: HttpClient) {}

  regenerateAbacResourceList(id: string,page, pageSize) {
    let params = new HttpParams();
    params = params.append("page", page);
    params = params.append("pageSize", pageSize);
    return this.http.get(
      this.serviceUrl + "/project/" + id + "/add-abac-rules",
      { params }
    );
  }

  // regenerateAbac2ResourceList(id:string){
  //   return this.http.get(this.serviceUrl+"/project/"+id+"/add-abac2-rules");
  // }

  regenerateAbac2ResourceList(id: string, page, pageSize) {
    let params = new HttpParams();
    params = params.append("page", page);
    params = params.append("pageSize", pageSize);
    return this.http.get(
      this.serviceUrl + "/project/" + id + "/add-abac2-rules",
      { params }
    );
  }
  regenerateAbac1PositiveResourceList(id: string) {
    return this.http.get(
      this.serviceUrl + "/project/" + id + "/add-abac1positive-rules"
    );
  }

  regenerateAbac2PositiveResourceList(id: string) {
    return this.http.get(
      this.serviceUrl + "/project/" + id + "/add-abac2positive-rules"
    );
  }

  regenerateAbacType3ResourceList(id: string) {
    return this.http.get(
      this.serviceUrl + "/project/" + id + "/add-abact3-rules"
    );
  }

  regenerateAbacPositiveResourceList(id: string) {
    return this.http.get(
      this.serviceUrl + "/project/" + id + "/add-abacpositive-rules"
    );
  }

  regenerateAbacType3PositiveResourceList(id: string) {
    return this.http.get(
      this.serviceUrl + "/project/" + id + "/add-abact3positive-rules"
    );
  }

  regenerateAbacLevel7ResourceList(id: string) {
    return this.http.get(
      this.serviceUrl + "/project/" + id + "/add-abact7-rules"
    );
  }

  regenerateHijackLevel1ResourceList(id: string) {
    return this.http.get(
      this.serviceUrl + "/project/" + id + "/add-hijacklevel1-rules"
    );
  }
  getAbacData(genId: string, page, pageSize) {
    let params = new HttpParams();
    params = params.append("page", page);
    params = params.append("pageSize", pageSize);
    return this.http.get(this.serviceUrl + "/generator/" + genId, { params });
  }

  saveAbacData(abacResource) {
    if (abacResource.id) return this.http.put(this.serviceUrl, abacResource);
    else return this.http.post(this.serviceUrl, abacResource);
  }

  deleteAbacResource(resourceId) {
    return this.http.delete(this.serviceUrl + "/" + resourceId);
  }
}
