import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Routes, RouterModule, Router, ActivatedRoute } from "@angular/router";
import { AccountService } from '../../../services/account.service';
import { Account } from '../../../models/account.model';
import { Handler } from '../../../components/dialogs/handler/handler';
import { SnackbarService } from '../../../services/snackbar.service';

@Component({
  selector: 'app-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss'],
  providers: [AccountService, SnackbarService]
})

export class DeleteDialogComponent implements OnInit {

  constructor(private accountService: AccountService, private route: ActivatedRoute, @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DeleteDialogComponent>, private handler: Handler, private snackbarService: SnackbarService,
    private router: Router,) { }
  @Output() emitData = new EventEmitter<string>();

  ngOnInit() {
  }

  cancel() {
    this.emitData.next("N");
    this.dialogRef.close();
  }

  delete() {
    this.emitData.next("Y");
    this.dialogRef.close();
  }

}
