export const CHARTCONFIG = {
  primary: 'rgba(33,150,243,.85)', // #2196F3
  success: 'rgba(102,187,106,.85)', // #66BB6A
  info: 'rgba(0,188,212,.85)', // #00BCD4
  infoAlt: 'rgba(126,87,194,.85)', // #7E57C2
  warning: 'rgba(255,202,40,.85)', // #FFCA28
  orange: '#f7aaaa',
  danger: 'rgba(233,75,59,.85)', // #E94B3B
  gray: 'rgba(221,221,221,.3)',
  textColor: '#989898',
  whiteColor: '#ffffff',
  splitLineColor: 'rgba(0,0,0,.05)',
  splitAreaColor: ['rgba(250,250,250,0.035)', 'rgba(200,200,200,0.1)'],
  backgroundColor: [
    "#a2d6c4",
    "#36A2EB",
    "#3e8787",
    "#579aac",
    "#7dcfe8",
    "#b3dfe7",
    "#A4A4BF",
    "#aec1cf",
    "#f3d2b6",
    "#658aab",
    "#859abd",
    "#B5C1B4"
  ],
  lightBlue: '#bfe2cc',
  lightGreen: '#ace9af',
  lightYellow: '#f5ecab',
  lightBlack: '#414345',
  pointborderColor: "rgba(38, 185, 154, 0.7)",
  pointhoverBorderColor: "rgba(220,220,220,1)",
  multiColors: [
    '#FF3784',
    '#36A2EB',
    '#4BC0C0',
    '#F77825',
    '#9966FF',
    '#00A8C6',
    '#379F7A',
    '#CC2738',
    '#8B628A',
    '#8FBE00',
    '#606060'
  ],
  redBlue: [
    "#FFCA28",
    "#66BB6A"
  ],

  redGreen: [
    "#d35e60",
    "#66BB6A"
  ],
};
