import { AbacResource } from "./../models/project-autocode-config.model";
import { Component } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LayoutComponent } from "./layout.component";
import { RunListComponent } from "../components/run-list/run-list.component";
import { RunDetailComponent } from "../components/run-detail/run-detail.component";
import { RunHistoryComponent } from "../components/analytics/run-history/run-history.component";
import { ProjectsListComponent } from "../components/projects/projects-list/projects-list.component";
import { ProjectsNewComponent } from "../components/projects/projects-new/projects-new.component";
import { NotificationListComponent } from "../components/notify/notification-list/notification-list.component";
import {
    DialogEntryComponent,
    TestSuiteListComponent
} from "../components/testsuites/test-suite-list/test-suite-list.component";
import { AppFooterComponent } from "./footer/footer.component";
import { ProjectRecommendationsComponent } from "../components/projects/project-recommendations/project-recommendations.component";
import { GitRepoComponent } from "./../components/projects/git-repo/git-repo.component";
import { IssueTrackerPageRegistrationComponent } from "../components/issue-tracker-page-registration/issue-tracker-page-registration.component";
import { CiCdIntegrationComponent } from "../components/ci-cd-integration/ci-cd-integration.component";
import { AuthGuard } from "../services/auth/auth.guard";
import { ErrorComponent } from "../error/error.component";
import { ProjectNew2Component } from "../components/projects/project-new2/project-new2.component";
import { AwsS3Component } from "../components/aws-s3/aws-s3.component";
import { OktaLoginComponent } from "../okta/okta-login/okta-login.component";
import { UserAddComponent } from "../components/users/user-add/user-add.component";
import { SecurityCenterDashboardComponent } from "../security-center-dashboard/security-center-dashboard.component";

import { TeamNewComponent } from "../team-new/team-new.component";
import { TeamEditComponent } from "../team-edit/team-edit.component";
import { ApiGroupNewComponent } from "../api-group-new/api-group-new.component";
import { ApiGroupListComponent } from "../api-group-list/api-group-list.component";
import { TeamsListComponent } from "../teams-list-component/teams-list.component";
import { ApiGroupEditComponent } from "../api-group-edit/api-group-edit.component";
import { OrgMonthlyReport } from "../org-monthly-report/org-monthly-report.component";
const routes: Routes = [
    { path: '', redirectTo: '/app/projects', pathMatch: 'full' },
    { path: "/app/projects/vulnerabilities?severity=:sev", component: ProjectsListComponent },
    { path: "/app/projects/vulnerabilities?runId=:runId&testSuiteName=:testSuiteName", component: ProjectsListComponent },
    { path: "/app/projects/vulnerabilities", component: ProjectsListComponent },
    { path: "/app/projects?severity=:sev", component: ProjectsListComponent },
    { path: 'okta-login', component: OktaLoginComponent },
    { path: '', loadChildren: () => import('../membership/membership.module').then(m => m.MembershipModule) },
    {
        path: "app",
        component: LayoutComponent,
        canActivate: [AuthGuard],
        children: [
            { path: 'securityCategories', loadChildren: () => import('../security-categories/security-categories.module').then(m => m.SecurityCategoriesModule) },
            { path: 'vault', loadChildren: () => import('../vault/vault.module').then(m => m.VaultModule) },
            { path: 'scanners', loadChildren: () => import('../scanners/scanners.module').then(m => m.ScannersModule) },
            { path: 'apigateway', loadChildren: () => import('../apigateway/apigateway.module').then(m => m.ApigatewayModule) },
            { path: 'activities', loadChildren: () => import('../activities/activities.module').then(m => m.ActivitiesModule) },
            { path: 'orgs-users', loadChildren: () => import('../orgs-users/orgs-users.module').then(m => m.OrgsUsersModule) },
            { path: 'dashboard', loadChildren: () => import('../dashboard/dashboard.module').then(m => m.DashboardModule) },
            { path: 'security-center-dashboard', loadChildren: () => import('../security-center-dashboard/security-center-dashboard.module').then(m => m.SecurityCenterModule) },
            { path: "dashboard/list", redirectTo: "/security-center-dashboard/list", pathMatch: "full" },

            { path: 'account-details', loadChildren: () => import('../account-page/account-page.module').then(m => m.AccountPageModule) },
            { path: 'global-skip-endpoint', loadChildren: () => import('../global-skip-endpoint/global-skip-endpoint.module').then(m => m.GlobalSkipEndpointModule) },
            { path: 'account-details', loadChildren: () => import('../account-page/account-page.module').then(m => m.AccountPageModule) },
            { path: 'profile', loadChildren: () => import('../my-profile/my-profile.module').then(m => m.MyProfileModule) },
            { path: 'settings', loadChildren: () => import('../system-settings/system-settings.module').then(m => m.SystemSettingsModule) },
            { path: 'assessment-reports', loadChildren: () => import('../assessment-report/assessment-report.module').then(m => m.AssessmentReportModule) },
            // { path: 'marketplace', loadChildren: () => import('../marketplace/marketplace.module').then(m => m.MarketplaceModule) },
            { path: 'analytics', loadChildren: () => import('../job-analytics/job-analytics.module').then(m => m.JobAnalyticsModule) },
            { path: 'reports', loadChildren: () => import('../reports/reports.module').then(m => m.ReportsModule) },
            { path: 'endpoints-oas', loadChildren: () => import('../endpoints/endpoints.module').then(m => m.EndpointsModule) },
            { path: 'vulnerabilities', loadChildren: () => import('../vulnerabilities/vulnerabilities.module').then(m => m.VulnerabilitiesModule) },
            { path: 'config-environments', loadChildren: () => import('../config-environments/config-environments.module').then(m => m.ConfigEnvironmentsModule) },
            { path: 'config-categories', loadChildren: () => import('../config-categories/config-categories.module').then(m => m.ConfigCategoriesModule) },
            { path: 'config-details', loadChildren: () => import('../config-details/config-details.module').then(m => m.ConfigDetailsModule) },
            { path: 'config-payloads', loadChildren: () => import('../config-payloads/config-payloads.module').then(m => m.ConfigPayloadsModule) },
            { path: 'integrations', loadChildren: () => import('../integrations/integrations.module').then(m => m.IntegrationsModule)},

            { path: "", redirectTo: "/app/projects", pathMatch: "full" },

            { path: "projects", component: ProjectsListComponent },
            { path: "projects/vulnerabilities", component: ProjectsListComponent },
            { path: "projects/scans", component: ProjectsListComponent },
            { path: "projects/new", component: ProjectsNewComponent },
            { path: "projects/advRegistration", component: ProjectNew2Component },
            { path: "projects/:id/edit", component: ProjectsNewComponent },
            { path: "projects/:id/issueTrackers", component: IssueTrackerPageRegistrationComponent },
            { path: "projects/:id/ciCdIntegration", component: CiCdIntegrationComponent },
            { path: "projects/:id/notifications", component: NotificationListComponent },
            {
                path: "projects/:id/playbooks",
                component: TestSuiteListComponent,

                children: [
                    {
                        path: ":playbookName",
                        component: DialogEntryComponent
                    }
                ]
            },
            { path: "projects/:id/gitRepo", component: GitRepoComponent },
            { path: 'teams', component: TeamsListComponent },
            { path: 'org-monthly-report', component: OrgMonthlyReport },

            { path: 'team-new', component: TeamNewComponent },
            { path: 'team/:id', component: TeamEditComponent },
            { path: 'api-group-new', component: ApiGroupNewComponent },
            { path: 'api-groups', component: ApiGroupListComponent },
            { path: 'api-group/:id', component: ApiGroupEditComponent },
            { path: "projects/:entityId/playbooks", component: AppFooterComponent },
            {
                path: "projects/:id/dashboard",
                component: ProjectRecommendationsComponent
            },
            {
                path: "projects/:projectId/profiles/:jobId/runs",
                component: RunListComponent
            },
            {
                path: "projects/:projectId/allScans",
                component: RunListComponent
            },
            {
                path: "projects/:projectId/profiles/:jobId/runs/:runId",
                component: RunDetailComponent
            },
            {
                path: "projects/:projectId/profiles/:jobId/runs/:id/history/:suiteId",
                component: RunHistoryComponent
            },
            //{ path: 'autocode', component: StepOneComponent},

            // { path: "notification-accounts", component: NotificationListComponent },
            // {
            //   path: "notification-accounts/new",
            //   component: NotificationNewComponent
            // },
            // {
            //   path: "notification-accounts/:id",
            //   component: NotificationEditComponent
            // },

            { path: "orgs/:orgId/users/add", component: UserAddComponent },
            { path: "projects/:id/report-storage", component: AwsS3Component},

        ]
    },
    {
        path: '**',
        component: ErrorComponent
    }
];

export const LayoutRoutingModule = RouterModule.forChild(routes);
