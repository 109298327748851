import { EventEmitter, Inject, Output } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AccountService } from '../../../services/account.service';
import { Handler } from '../handler/handler';

@Component({
  selector: 'app-aws-s3bucket',
  templateUrl: './aws-s3bucket.component.html',
  styleUrls: ['./aws-s3bucket.component.scss']
})
export class AwsS3bucketComponent implements OnInit {
  @Output() emitData = new EventEmitter<string>();
  s3Accounts: Array<Account> = [];
  accountId: string = "false";
  uploadEvent: boolean = false;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private handler: Handler, private accountService: AccountService,
  public dialogRef: MatDialogRef<AwsS3bucketComponent>, private dialog: MatDialog) { }

  ngOnInit() {

    this.getAccountsForS3Bucket();
  }

  getAccountsForS3Bucket() {
         
    this.handler.activateLoader();
    this.accountService.getAccountByAccountType("REPORT_STORAGE").subscribe(
      results => {
        this.handler.hideLoader();
        if (this.handler.handle(results)) {
          return;
        }
        this.s3Accounts = results["data"];
      },
      error => {
        this.handler.hideLoader();
        this.handler.error(error);
      }
    );
  }

  dialogClose(){
    if(this.accountId != 'false'){
      this.emitData.next(this.accountId);
      this.dialogRef.close(); 
    }else{
      this.accountId = "";
      this.emitData.next(this.accountId);
      this.dialogRef.close(); 
    }
  
  }

  selectedUpload(event){
    if(event.checked){
      this.uploadEvent = event.checked;
    }else{
      this.accountId = '';
      this.uploadEvent = event.checked;
    }
  }
}
