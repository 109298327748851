import { Component, EventEmitter, Inject, OnInit, Output, } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Handler } from '../handler/handler';
import { ProjectService } from '../../../services/project.service';

@Component({
  selector: 'app-response-dialog',
  templateUrl: './response-dialog.component.html',
  styleUrls: ['./response-dialog.component.scss'],
  providers: [
    ProjectService]
})

export class ResponseDialogComponent implements OnInit {
  @Output() emitData = new EventEmitter<string>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private handler: Handler,
    private projectService: ProjectService,
    public dialogRef: MatDialogRef<ResponseDialogComponent>
  ) { }

  ngOnInit() {
  }

  reSync() {
    this.handler.activateLoader();
    this.projectService.getSyncData(this.data[1], this.data[2]).subscribe(
      results => {
        this.handler.hideLoader();
        if (this.handler.handle(results)) {
          return;
        }
        if (results['errors'] == false) {
          this.emitData.next(results['data']);
          this.dialogRef.close();
        }

      },
      error => {
        this.handler.hideLoader();
        this.handler.error(error);
      }
    );
  }

}
