import { Component, OnInit, Inject } from "@angular/core";
import { TestSuiteService } from "./../../../services/test-suite.service";
import { Handler } from "./../handler/handler";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-playbook-sample-dialog",
  templateUrl: "./playbook-sample-dialog.component.html",
  styleUrls: ["./playbook-sample-dialog.component.scss"],
  providers: [TestSuiteService]
})
export class PlaybookSampleDialogComponent implements OnInit {
  testSuitText: string = "";

  constructor(
    private testSuiteService: TestSuiteService,
    private handler: Handler,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PlaybookSampleDialogComponent>
  ) {}

  ngOnInit() {
    this.testSuiteService.getSample().subscribe(
      results => {
        this.testSuitText = results;
      },
      error => {
        this.handler.hideLoader();
        this.handler.error(error);
      }
    );
  }
}
