
function makeAppConfig() {
  const date = new Date();
  const year = date.getFullYear();
  const apiUrl = '/swagger-ui.html';
  const AppConfig = {
    brand: 'APIsec, Inc.',
    productName: 'apisec',
    cliLink: 'https://github.com/apisec-inc/apisec-cli',
    user: 'User',
    year,
    layoutBoxed: false,               // true, false
    navCollapsed: false,              // true, false
    navBehind: true,                 // true, false
    fixedHeader: true,                // true, false
    sidebarWidth: 'small',           // small, middle, large
    theme: 'light',                   // light, gray, dark
    colorOption: '32',                // 11,12,13,14,15,16; 21,22,23,24,25,26; 31,32,33,34,35,36
    AutoCloseMobileNav: true,         // true, false. Automatically close sidenav on route change (Mobile only)
    productLink: 'https://support.apisec.ai/',
    docLink: 'https://docs.apisec.ai/',
    copyright: 'https://www.apisec.ai/',
    maintenance: 'https://docs.apisec.ai/maintenance-window/',
    // cliLink: 'https://github.com/fxlabsinc/Fx-CLI',
    apiLink: apiUrl,
    fxSample: 'https://github.com/fxlabsinc/FX-Sample',
    training: 'https://www.apisec.ai/media',
    releaseNotes: 'https://releases.apisec.ai/',
    isLoadingResults: false
  };

  return AppConfig;
}

export const APPCONFIG = makeAppConfig();
