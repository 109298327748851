export class OrgUser {
  org: Org = new Org();
  orgRole: string;
  status: string;
  users: Dto = new Dto();
  forceResetPwd: boolean;
  userType: string;
  authFrom: string;
}

export class Org {
  public id: string;
  public name: string;
  public company: string;
  public createdDate: string;
  public billingEmail: string;
  public orgType: string;
  public orgPlan: string;
  public inactive: boolean;
  public freeLicense: string;
  public enterpriseLicense: string;
  public referrer: string;
  public suiteValidationLimit: string;
  public orgTag: string;
  public usedEntLicense: number;
  public issuer: string;
  public clientId: string;
  public url: string;
  public ssoEnabled: boolean;
  public mfaEnabled: boolean;
  public mfaEnabledDate: string;
  public ssoType: string;
  public industry: string;
  public compliances: string[];
}

export class Dto {
  public id: string;
  public name: string;
  public username: string;
  public email: string;
  public mfa: boolean;
  public qrCode: string;

}

export class Member {
  public orgId: string;
  public name: string;
  public username: string;
  public password: string;
  public confirmPassword: string;
  public email: string;
  public orgRole: string;
}
export class Team {
  public name: string;
  public descriptions: string;
  public users = [];
}
export class ApiGroupModel {
  public name: string;
  public description: string;
  public teams = [];
  public projects = [];
  public subApiGroups = [];
}