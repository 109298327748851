import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';


import { Account } from '../models/account.model';

@Injectable()
export class AccountService {

  private serviceUrl = '/api/v1/accounts'
  private gatewayURL = '/api/v1/api-gateway'
  constructor(private http: HttpClient) {
  }

  /**
   * Get observable from endpoint
   */
  get(page, pageSize) {
    let params = new HttpParams();
    params = params.append('page', page);
    params = params.append('pageSize', pageSize);
    return this.http.get(this.serviceUrl, { params });
  }

  getV2(page, pageSize, sort, sortType) {
    let params = new HttpParams();
    params = params.append('page', page);
    params = params.append('pageSize', pageSize);
    params = params.append("sort", sort);
    params = params.append("sortType", sortType);
    return this.http.get(this.serviceUrl, { params });
  }

  getById(id: string) {
    return this.http.get(this.serviceUrl + "/" + id);
  }

  create(obj: Account) {
    return this.http.post(this.serviceUrl, obj);
  }

  update(obj: Account) {
    return this.http.put(this.serviceUrl + "/" + obj['id'], obj);
  }

  delete(obj: Account) {
    return this.http.delete(this.serviceUrl + "/" + obj['id']);
  }

  getAccountByAccountType(accountType: string) {
    return this.http.get(this.serviceUrl + "/account-type/" + accountType);
  }

  searchAccount(keyword: string, page, pageSize, sort, sortType) {
    let params = new HttpParams();
    params = params.append('keyword', keyword);
    params = params.append('page', page);
    params = params.append('pageSize', pageSize);
    params = params.append("sort", sort);
    params = params.append("sortType", sortType);
    return this.http.get(this.serviceUrl + "/search", { params });

  }
  test(obj: Account) {
    return this.http.post(this.gatewayURL + "/test", obj);
  }

  getWrikeFolders(accountId) {
    let params = new HttpParams();
    params = params.append('accountId', accountId);
    return this.http.get(this.serviceUrl + "/wrike-folders", { params });
  }

  getWorkspacesbyId(accountId) {
    return this.http.get(this.gatewayURL + '/' + accountId + '/workspaces');
  }

  getAllWorkspaces(accountId, region, workspaceId, page, pageSize) {
    let params = new HttpParams();
    params = params.append('page', page);
    params = params.append('pageSize', pageSize);
    params = params.append('region', region);
    params = params.append('workspaceId', workspaceId);
    return this.http.get(this.gatewayURL + '/getall/' + accountId, { params });
  }
}
