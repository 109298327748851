import { Injectable } from '@angular/core';
import mixpanel from 'mixpanel-browser';
import { loggedInUserDetail } from '../shared/shared.data';

@Injectable({
  providedIn: 'root'
})
export class MixpanelService {
  loggedInUserDetail = loggedInUserDetail;

  constructor() { }

  trackMixpanel(component, obj) {

    var obj1 = {
      "orgName": this.loggedInUserDetail.orgName,
      "userEmail": this.loggedInUserDetail.userEmail
    }

    obj = { ...obj1, ...obj };

    mixpanel.init('8b76c0c3bfd545dea7aecf9f21f1b35b', { debug: false });

    mixpanel.track(component, obj)


  }
}
