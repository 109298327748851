import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Project } from "../../models/project.model";
import { AccountService } from "../../services/account.service";
import { Handler } from "../dialogs/handler/handler";
import { Account } from "../../models/account.model";
import { JobsService } from "../../services/jobs.service";
import { RegionsService } from "../../services/regions.service";
import { TestSuiteService } from "../../services/test-suite.service";
import { SnackbarService } from "../../services/snackbar.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ProjectService } from "../../services/project.service";
import { MatDialog } from "@angular/material/dialog";
import { timer } from "rxjs/index";
import { Jobs } from "../../models/jobs.model";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { IssueTrackerRegisterComponent } from "../dialogs/issue-tracker-register/issue-tracker-register.component";
import { UpgradeComponent } from '../dialogs/upgrade/upgrade.component';
import { GlobalProjectDetails } from "../../shared/shared.data";
import { GlobalSelectedEnv } from "../../shared/shared.data";
import { DeleteDialogComponent } from '../dialogs/delete-dialog/delete-dialog.component';
import { MixpanelService } from '../../services/mixpanel.service';


@Component({
    selector: 'app-issue-tracker-page-registration',
    templateUrl: './issue-tracker-page-registration.component.html',
    styleUrls: ['./issue-tracker-page-registration.component.scss'],
    providers: [ProjectService, SnackbarService, JobsService, AccountService, RegionsService, TestSuiteService]
})
export class IssueTrackerPageRegistrationComponent implements OnInit {
    id: string;
    jobId: string;
    job: Jobs = new Jobs();
    @Output() emitData = new EventEmitter<string[]>();
    jobs = [];
    project: Project = new Project();
    projects;
    length = 0;
    page = 0;
    itAccounts: Array<Account> = [];
    category: string[];
    pageSize = 100;
    secondFormGroup: FormGroup;
    thirdFormGroup: FormGroup;
    activeToggle: boolean = true;
    tmpPrevIT = "";
    invalidCreds = true;
    accountTypes: Type[] = [
        { value: "Jira", viewValue: "Jira" },
        { value: "GitHub", viewValue: "GitHub" },
        { value: "Bugzilla", viewValue: "Bugzilla" },
        { value: "Pivotal_Tracker", viewValue: "Pivotal Tracker" },
        { value: "Service_Now", viewValue: "Service Now" },
        { value: "GitLab_Issues", viewValue: "GitLab Issues" },
        { value: "AZURE_DevOps", viewValue: "Azure DevOps" },
        { value: "Trello", viewValue: "Trello" },
        { value: "JupiterOne", viewValue: "Jupiter One" },
        { value: "Linear", viewValue: "Linear" },
        { value: "Wrike", viewValue: "Wrike" },
        { value: "FX_Issues", viewValue: "APIsec Issues" },
        { value: "No_Issues", viewValue: "No Issues" },
    ];
    skipStatus = [{ 'key': true, 'value': 'ACTIVE' }, { 'key': false, 'value': 'INACTIVE' }];
    globalSelectedEnv = GlobalSelectedEnv;

    names: any = [];
    name: any = "Master";
    selectedAccountType = [];
    nextAccountFlag: boolean = false;
    prevAccountFlag: boolean = true;
    nextRegionFlag: boolean = false;
    prevRegionFlag: boolean = true;
    accountTypes1 = [];
    loggedInUserRole;
    i: number = 0;
    r: number = 0;
    assigneeAccId;
    itProjectId;
    freeAcc:boolean=false;
    urlReg: string = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
    emailReg = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
    constructor(private jobsService: JobsService, private accountService: AccountService, private route: ActivatedRoute,
        private regionService: RegionsService, private router: Router, private handler: Handler, private mixpanelService: MixpanelService,
        private snackbarService: SnackbarService, private _formBuilder: FormBuilder, private projectService: ProjectService,
        public dialog: MatDialog, private formBuilder: FormBuilder) {
    }

    ngOnInit() {
        //this.secondFormGroup = this._formBuilder.group({});

        this.secondFormGroup = this.formBuilder.group({
            account: ['', Validators.required],
            url: ['', [Validators.required, Validators.pattern(this.urlReg)]],
            assigneeAccountId: ['', []],
            projectKey: [this.job.issueTracker.projectKey, []],
            skipLogging: ['', []],
            wrikeFolder: []
        });
        this.UpdateIssueTrackerValidation('', '');
        this.thirdFormGroup = this._formBuilder.group({});
        this.route.params.subscribe(params => {
            this.id = params['id'];
            this.jobId = params['jobId'];
            if (this.id) {
                // this.loadProject();
                this.loggedInUserRole = localStorage.getItem('orgRole');
                if(!this.loggedInUserRole)  this.loggedInUserRole = localStorage.getItem('userRole'); 
                if (this.loggedInUserRole == 'BASIC') {
                    this.upgradeSubscription();
                    this.freeAcc=true;
                } else {
                    this.getJobs();
                }
            }

        });
        // this.getaccountTypes();
    }

    UpdateIssueTrackerValidation(type, action) {

        if (["FX_Issues", "No_Issues"].includes(type)) {
            this.secondFormGroup.get('account').setValidators(null);
            this.secondFormGroup.get('url').setValidators(null);
            if (type != this.job.issueTracker.accountType) this.secondFormGroup.markAsDirty();
        }

        // if (type.toUpperCase() != 'WRIKE') {
        //     this.secondFormGroup.get('wrikeFolder').setValidators(null);
        // }
        if (type.toUpperCase() == 'JIRA' || type.toUpperCase() == 'TRELLO' || type == 'AZURE_DevOps') {
            this.secondFormGroup.get('projectKey').setValidators([Validators.required]);
            this.secondFormGroup.get('assigneeAccountId').setValidators([]);
            this.secondFormGroup.addControl('labels', this.formBuilder.control('', []));
        }
        if (type.toUpperCase() == 'TRELLO' || type == 'AZURE_DevOps') {
            this.secondFormGroup.addControl('issueType', this.formBuilder.control('', []));
            this.secondFormGroup.get('url').setValidators(null);
        }
        if (type.toUpperCase() == 'JUPITERONE' || type.toUpperCase() == 'LINEAR') {
            this.secondFormGroup.get('url').setValidators(null);
            // this.secondFormGroup.addControl('issueType', this.formBuilder.control('', []));
        }
        if (type.toUpperCase() == 'WRIKE') {
            this.secondFormGroup.get('url').setValidators(null);
            this.secondFormGroup.addControl('wrikeFolder', this.formBuilder.control('', [Validators.required]));
        }
        else {
            if (type.toUpperCase() != 'JIRA' && type.toUpperCase() != 'TRELLO' && type != 'AZURE_DevOps') {
                this.secondFormGroup.removeControl('labels');
                this.secondFormGroup.removeControl('issueType');
                this.secondFormGroup.get('projectKey').setValidators(null);
                this.secondFormGroup.get('assigneeAccountId').setValidators(null);
                this.secondFormGroup.get('wrikeFolder').setValidators(null);

            }


        }
        if (action == 'change' && type == this.job.issueTracker.accountType) {
            var backendData = {
                account: this.job.issueTracker.account,
                url: this.job.issueTracker.url,
                assigneeAccountId: this.job.issueTracker.assigneeAccountId,
                projectKey: this.job.issueTracker.projectKey,
                labels: this.job.issueTracker.labels,
                skipLogging: this.job.issueTracker.skipLogging,
                wrikeFolder: this.job.issueTracker.projectKey,
                issueType: this.job.issueTracker.issueType
            }
            let res1: any = this.updateFormValues(backendData, this.secondFormGroup.value);
            this.secondFormGroup.setValue(res1);
            this.secondFormGroup.markAsPristine();
        }
        else if (action == 'change' && !["FX_Issues", "No_Issues"].includes(type)) {
            let resetVal: any = this.resetFormValues(this.secondFormGroup.value, []);
            this.secondFormGroup.setValue(resetVal);
        }
        // if (action == 'change' && type != this.tmpPrevIT)
        //     this.activeToggle = true;
        // else
        //     if (action == 'change' && type == this.tmpPrevIT)
        //         this.activeToggle = this.job.issueTracker.skipLogging;
        //     else
        //         if (action == 'load')
        //             this.activeToggle = this.job.issueTracker.skipLogging;
    }
    globalProjectDetails = GlobalProjectDetails;
    loadProject() {
        this.handler.activateLoader();
        this.projectService.getById(this.id).subscribe(results => {
            this.handler.hideLoader();
            if (this.handler.handle(results)) {
                return;
            }

            this.project = results['data'];
            this.globalProjectDetails.name = this.project.name;
        }, error => {
            this.handler.hideLoader();
            this.handler.error(error);
        });
    }


    openDialogITCredentials() {
        const dialogRef = this.dialog.open(IssueTrackerRegisterComponent, {
            width: "800px",
            data: this.accountTypes
        });
        dialogRef.afterClosed().subscribe(result => {
            this.getITAccountsByAccountType();
        });
    }

    getJobs() {
        this.jobsService.getJobs(this.id, this.page, this.pageSize).subscribe(
            results => {
                // this.handler.hideLoader();
                if (this.handler.handle(results)) {
                    return;
                }
                this.jobs = null;
                this.jobs = results["data"];

                //selected global selected env profile
                // if (this.globalSelectedEnv.envId != "") {
                //     this.jobs.map(j => {
                //         if (j.environment.id == this.globalSelectedEnv.envId) {
                //             this.selectedProfile = j;
                //             this.job = j;
                //             this.jobId = this.job.id;
                //             this.itType = this.job.issueTracker.accountType;
                //             this.itUrl = this.job.issueTracker.url;
                //             this.assigneeAccId = this.job.issueTracker.assigneeAccountId;
                //             this.itProjectId = this.job.issueTracker.assigneeAccountId;
                //             this.issueType = this.job.issueTracker.issueType;
                //             this.activeToggle = this.job.issueTracker.skipLogging;
                //             //save IT in a temp variable
                //             this.tmpPrevIT = this.job.issueTracker.accountType;
                //             return;
                //         }
                //     })
                // }

                if (!this.selectedProfile) {
                    for (let j of this.jobs) {
                        if (j.name == 'Master') {
                            this.jobId = j['id'];
                            this.selectedProfile = j;
                            this.job = j;
                            this.itType = this.job.issueTracker.accountType;
                            this.itUrl = this.job.issueTracker.url;
                            this.assigneeAccId = this.job.issueTracker.assigneeAccountId;
                            this.itProjectId = this.job.issueTracker.assigneeAccountId;
                            this.issueType = this.job.issueTracker.issueType;
                            this.activeToggle = !this.job.issueTracker.inactive;
                            //save IT in a temp variable
                            this.tmpPrevIT = this.job.issueTracker.accountType;
                        }
                    }
                }
                this.UpdateIssueTrackerValidation(this.job.issueTracker.accountType, 'load');
                var backendData = {
                    account: this.job.issueTracker.account,
                    url: this.job.issueTracker.url,
                    assigneeAccountId: this.job.issueTracker.assigneeAccountId,
                    projectKey: this.job.issueTracker.projectKey,
                    labels: this.job.issueTracker.labels,
                    skipLogging: this.job.issueTracker.skipLogging,
                    wrikeFolder: this.job.issueTracker.projectKey,
                    issueType: this.job.issueTracker.issueType
                }
                let res1: any = this.updateFormValues(backendData, this.secondFormGroup.value);
                this.secondFormGroup.setValue(res1);

                // if (this.jobs.length > 0) {
                //     this.names = this.jobs.map(function (val) {
                //         return val.name;
                //     });
                // }

                if (this.jobId) {
                    this.getITAccountsByAccountType();
                }

                if (this.itType == 'Wrike') {
                    this.getWrikeFolders();


                }



            },
            error => {
                this.handler.hideLoader();
                this.handler.error(error);
            });
    }

    selectedProfile;

    onChangeProfile(e) {
        // if (this.jobs.length > 0) {
        //     for (let j of this.jobs) {
        //         if (j.name == e) {
        this.jobId = this.selectedProfile['id'];
        this.job = JSON.parse(JSON.stringify(this.selectedProfile));
        this.itType = this.job.issueTracker.accountType;
        this.itUrl = this.job.issueTracker.url;
        this.assigneeAccId = this.job.issueTracker.assigneeAccountId;
        this.itProjectId = this.job.issueTracker.projectKey;
        this.issueType = this.job.issueTracker.issueType;

        this.itAccounts = new Array();
        for (let entry of this.issueTrackersAccounts) {
            if (entry.accountType == this.itType) {
                this.itAccounts.push(entry);
            }
        }
        this.UpdateIssueTrackerValidation(this.itType, 'change');
        // }
        // }
        // if (this.jobId) {
        //     this.loadJob(this.jobId);
        // }
        // }
    }

    itType;
    itUrl;
    issueType;
    updateFormValues(back, ui) {
        let res: any = {}
        for (var key in ui) res[key] = back[key]
        return res;
    }

    resetFormValues(ui, except) {
        let res: any = {}
        for (var key in ui) { if (!except.includes(key)) res[key] = ''; else res[key] = ui[key]; }
        return res;
    }
    resetUI() {
        if (this.secondFormGroup.value.account == this.job.issueTracker.account) {
            var backendData = {
                account: this.job.issueTracker.account,
                url: this.job.issueTracker.url,
                assigneeAccountId: this.job.issueTracker.assigneeAccountId,
                projectKey: this.job.issueTracker.projectKey,
                labels: this.job.issueTracker.labels,
                skipLogging: this.job.issueTracker.skipLogging,
                wrikeFolder: this.job.issueTracker.projectKey
            }
            let res1: any = this.updateFormValues(backendData, this.secondFormGroup.value);
            this.secondFormGroup.setValue(res1);
            this.secondFormGroup.markAsPristine();
        }
        else {
            let resetVal: any = this.resetFormValues(this.secondFormGroup.value, ['account']);
            this.secondFormGroup.setValue(resetVal);
        }

        if (this.itType == 'Wrike') {
            this.getWrikeFolders()
        }
    }


    changeIssueTracker(type) {

        /*  if (this.job.issueTracker.accountType != type.value) {
              this.job.issueTracker.url = "";
              this.job.issueTracker.assigneeAccountId = "";
              this.job.issueTracker.projectKey = "";
              this.job.issueTracker.issueType = "";
          }
  
          if (this.job.issueTracker.accountType == type.value) {
              this.job.issueTracker.url = this.itUrl;
              this.job.issueTracker.assigneeAccountId = this.assigneeAccId;
              this.job.issueTracker.projectKey = this.itProjectId;
              this.job.issueTracker.issueType = this.issueType;
          }
  */
        this.UpdateIssueTrackerValidation(type.value, 'change');
        this.itType = type.value;
        // if (type.value === "No_Issues") {
        //     this.job.issueTracker.accountType = "No_Issues";
        // } else if (type.value === "FX_Issues") {
        //     this.job.issueTracker.accountType = "FX_Issues";
        // }
        // if (type.value === "GitHub") {
        //     this.job.issueTracker.accountType = "GitHub";
        // } else if (type.value === "Jira") {
        //     this.job.issueTracker.accountType = "Jira";
        // } else if (type.value === "Bugzilla") {
        //     this.job.issueTracker.accountType = "Bugzilla";
        // } else if (type.value === "Pivotal_Tracker") {
        //     this.job.issueTracker.accountType = "Pivotal_Tracker";
        // } else if (type.value === "Service_Now") {
        //     this.job.issueTracker.accountType = "Service_Now";
        // } else if (type.value === "GitLab_Issues") {
        //     this.job.issueTracker.accountType = "GitLab_Issues";
        // }
        // else if (type.value === "AZURE_DevOps") {
        //     this.job.issueTracker.accountType = "AZURE_DevOps";
        // }
        //

        // this.getITAccountsByAccountType();
        if (type.value === "Jira")
            this.job.issueTracker.issueType = "Bug";


        this.itAccounts = new Array();
        for (let entry of this.issueTrackersAccounts) {
            if (entry.accountType == this.itType) {
                this.itAccounts.push(entry);
            }
        }
    }

    issueTrackersAccounts = [];
    process = false;
    getITAccountsByAccountType() {
        this.handler.activateLoader();
        this.accountService.getAccountByAccountType("ISSUE_TRACKER").subscribe(
            results => {
                this.handler.hideLoader();
                if (this.handler.handle(results)) {
                    return;
                }
                this.issueTrackersAccounts = results["data"];
                this.itAccounts = new Array();
                for (let entry of results["data"]) {
                    // if (entry.accountType == this.job.issueTracker.accountType) {
                    if (entry.accountType == this.itType) {
                        this.itAccounts.push(entry);
                    }
                }
            },
            error => {
                this.handler.hideLoader();
                this.handler.error(error);
            }
        );
    }

    saveJob() {
        if (this.freeAcc)
          this.upgradeSubscription();
        else
          this.saveData();
      }
    
      
    saveData() {
        //mixpanel code starts
        var obj = {
            "projectName": this.project.name,
            "issuetracker": this.job.issueTracker.accountType
        }
        if (this.job.issueTracker.syncAll) {

            this.mixpanelService.trackMixpanel("SyncVulnerabilities", obj);

        }
        else {
            this.mixpanelService.trackMixpanel("SaveIssuetracker", obj);
        }

        //mixpanel code ends
        this.process = true;
        this.job.issueTracker.account = this.secondFormGroup.value.account;
        this.job.issueTracker.url = this.secondFormGroup.value.url;
        this.job.issueTracker.assigneeAccountId = this.secondFormGroup.value.assigneeAccountId;
        this.job.issueTracker.projectKey = this.secondFormGroup.value.projectKey;
        this.job.issueTracker.labels = this.secondFormGroup.value.labels;
        this.job.issueTracker.inactive = !this.activeToggle;
        this.job.issueTracker.accountType = this.itType;
        // var accObj=JSON.parse(JSON.stringify(  this.job.issueTracker));      
        if( this.job.issueTracker && this.job.issueTracker.accountType=='GitHub'||  this.job.issueTracker.accountType=='GitLab' ||   this.job.issueTracker.accountType=='Git')
        this.job.issueTracker.accountType='Git';          
     if (this.job.issueTracker.accountType === 'Wrike') {

            this.wrikeFolders.map(f => {
                if (f.id == this.selectedWrikeFolderId)
                    this.job.issueTracker.folderName = f.title;
            })
            this.job.issueTracker.projectKey = this.selectedWrikeFolderId
        }

        if (this.job.issueTracker.accountType === 'GitLab_Issues') {
            this.job.issueTracker.url = "https://gitlab.com";
        }
        else if (this.job.issueTracker.accountType === "AZURE_DevOps") {
            this.job.issueTracker.url = "https://dev.azure.com";
            this.job.issueTracker.issueType = this.secondFormGroup.value.issueType;
        } else if (this.job.issueTracker.accountType === "Trello") {
            this.job.issueTracker.url = null;
            this.job.issueTracker.issueType = this.secondFormGroup.value.issueType;
        }
        this.handler.activateLoader();
        this.snackbarService.openSnackBar(
            " Saving...",
            ""
        );
        this.jobsService.update(this.job, this.category).subscribe(
            results => {
                this.process = false;
                this.handler.hideLoader();
                if (this.handler.handle(results)) {
                    return;
                }
                this.secondFormGroup.markAsPristine();
                var msg = this.job.issueTracker.accountType;
                if (this.job.issueTracker.accountType == 'FX_Issues')
                    msg = 'APIsec Issues'
                else
                    if (this.job.issueTracker.accountType == 'No_Issues')
                        msg = 'APIsec No_Issues'
                    else
                        if (this.job.issueTracker.accountType == 'Pivotal_Tracker')
                            msg = 'Pivotal Tracker '
                        else
                            if (this.job.issueTracker.accountType == 'Service_Now')
                                msg = 'ServiceNow '
                            else
                                if (this.job.issueTracker.accountType == 'GitLab_Issues')
                                    msg = 'GitLab Issues'
                                else
                                    if (this.job.issueTracker.accountType == 'AZURE_DevOps')
                                        msg = 'Azure DevOps'

                // this.snackbarService.openSnackBar(msg + " config saved.", "");
                if (this.job.issueTracker.syncAll)
                    this.snackbarService.openSnackBar("Vulnerabilities sync completed. " + msg + " is made default issue tracker. The vulnerabilities from now onwards will be logged in " + msg + ".", "")
                else
                    this.snackbarService.openSnackBar(msg + " issue tracker details saved.", "");


                this.tmpPrevIT = results['data'].issueTracker.accountType;
                this.job = results['data'];
                this.selectedProfile = this.job;

                for (var i = 0; i < this.jobs.length; i++) {
                    if (this.jobs[i].id == this.job.id)
                        this.jobs[i] = this.job
                }


            },
            error => {
                this.handler.hideLoader();
                this.handler.error(error);
            });
    }



    validateIssueTrackerCreds() {
        this.invalidCreds = true;
        this.process = true;
        this.job.issueTracker.account = this.secondFormGroup.value.account;
        this.job.issueTracker.url = this.secondFormGroup.value.url;
        this.job.issueTracker.assigneeAccountId = this.secondFormGroup.value.assigneeAccountId;
        this.job.issueTracker.projectKey = this.secondFormGroup.value.projectKey;
        this.job.issueTracker.labels = this.secondFormGroup.value.labels;
        this.job.issueTracker.skipLogging = this.secondFormGroup.value.skipLogging;
        this.job.issueTracker.accountType = this.itType;

        if (this.job.issueTracker.accountType === 'GitLab_Issues') {
            this.job.issueTracker.url = "https://gitlab.com";
        }
        else if (this.job.issueTracker.accountType === "AZURE_DevOps") {
            this.job.issueTracker.url = "https://dev.azure.com";
        } else if (this.job.issueTracker.accountType === "Trello") {
            this.job.issueTracker.url = null;
        }
        this.handler.activateLoader();
        this.snackbarService.openSnackBar(
            "Validating '" + this.itType + "' credentials...",
            ""
        );
        if( this.job.issueTracker && (this.job.issueTracker.accountType=='GitHub'||  this.job.issueTracker.accountType=='GitLab' ||   this.job.issueTracker.accountType=='Git'))
        this.job.issueTracker.accountType='Git';   
        this.jobsService.validateIssueTrackerCreds(this.job).subscribe(
            results => {
                this.process = false;
                this.handler.hideLoader();
                if (this.handler.handle(results)) {
                    return;
                }
                this.secondFormGroup.markAsPristine();
                this.snackbarService.openSnackBar(
                    "Valid Credentials.",
                    ""
                );
                this.invalidCreds = false;
            },
            error => {
                this.handler.hideLoader();
                this.handler.error(error);
            });
    }

    getaccountTypes() {
        for (this.i = 0; this.i && this.i < this.accountTypes.length; this.i++) {
            this.accountTypes1[this.i] = this.accountTypes[this.i]
        }

    }

    nextAccount() {
        this.accountTypes1 = [];
        if (this.i < 0) {
            for (this.i = 0; this.i < 5 && this.i < this.accountTypes.length;) {
                this.i++
            }
        }
        if (this.accountTypes.length > this.i) {
            for (var k = 0; k < 5 && k < this.accountTypes.length && this.i < this.accountTypes.length; k++) {
                this.accountTypes1[k] = this.accountTypes[this.i]
                this.i++
            }
        }
        if (this.accountTypes.length == this.i) {
            this.nextAccountFlag = true;

        }
        if (this.i > 5)
            this.prevAccountFlag = false;
    }

    previousAccount() {
        this.i = this.i - this.accountTypes1.length
        this.i--;
        this.accountTypes1 = [];
        for (var k = 4; k >= 0 && k < this.accountTypes.length && this.i >= 0; k--) {
            this.accountTypes1[k] = this.accountTypes[this.i]
            this.i--
        }
        if (this.accountTypes.length == this.i - 1)
            this.prevAccountFlag = true;
        if (this.i <= 0)
            this.prevAccountFlag = true;
        if (this.accountTypes.length > 5)
            this.nextAccountFlag = false;
        // this.i = this.i + 3
    }

    upgradeSubscription(): void {
        const dialogRef = this.dialog.open(UpgradeComponent, {
            width: '350px',
            disableClose: true

        });
    }

    syncVulnerabilities() {    
        this.job.issueTracker.syncAll = true;
        this.saveJob();
    }

    wrikeFolders = [];
    selectedWrikeFolderId;
    getWrikeFolders() {
        var accountId = this.secondFormGroup.value.account;
        this.wrikeFolders = [];
        this.handler.activateLoader();
        this.accountService.getWrikeFolders(accountId).subscribe(results => {
            this.handler.hideLoader();
            if (this.handler.handle(results)) {
                return;
            }
            var data = results["data"];
            this.wrikeFolders = data.wrikeFolders;

            this.selectedWrikeFolderId = this.job.issueTracker.projectKey

        },
            error => {
                this.handler.hideLoader();
                this.handler.error(error);
            });
    }
}

export interface Type {
    value: string;
    viewValue: string;
}
