import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { RegionsService } from "./../../../services/regions.service";
import { Handler } from "./../handler/handler";
@Component({
  selector: "app-bot-status-dialog",
  templateUrl: "./bot-status-dialog.component.html",
  styleUrls: ["./bot-status-dialog.component.scss"],
  providers: [RegionsService]
})
export class BotStatusDialogComponent implements OnInit {
  status;
  id;
  constructor(
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<BotStatusDialogComponent>,
    private regionService: RegionsService,
    private handler: Handler
  ) { }

  ngOnInit() {
    for (let st of this.data) this.id = st;
    this.ping();
  }

  ping() {
    this.handler.activateLoader();
    this.regionService.ping(this.id).subscribe(
      results => {
        this.handler.hideLoader();
        if (this.handler.handle(results)) {
          return;
        }
        if (results["data"])
          this.status = "Build Timestamp: " + results["data"].buildTimeStamp;
        else
          this.status = null;


      },
      error => {
        this.handler.hideLoader();
        this.handler.error(error);
      }
    );
  }
}
