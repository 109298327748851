import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProjectService } from "../../../services/project.service";
import { Handler } from "../../dialogs/handler/handler";
import { SnackbarService } from '../../../services/snackbar.service';

@Component({
  selector: 'app-reviewbug-comments',
  templateUrl: './reviewbug-comments.component.html',
  styleUrls: ['./reviewbug-comments.component.scss'],
  providers: [ProjectService,SnackbarService]

})


export class ReviewbugCommentsComponent implements OnInit {

  autoSuggestion: any;
  id: string;
  result: Array<boolean> = new Array();
  activateChecked: boolean;
  togglevalue;
  comments;
  reviewTagStatus;

  constructor(public dialogRef: MatDialogRef<ReviewbugCommentsComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    private projectService: ProjectService, private handler: Handler,private snackbarService: SnackbarService) {
    this.autoSuggestion = data[0];
    // this.falsePositiveChecked = data[1];
    this.id = data[1];
    this.togglevalue = data[2];
  }

  ngOnInit() {
    this.comments = this.autoSuggestion.comment;
    this.reviewTagStatus = this.data[3];
  }

  saveAndClose() {
    this.autoSuggestion.comment = this.comments ;
    this.autoSuggestion.reviewTag=!this.autoSuggestion.reviewTag;
    this.saveAutoSuggestion();
    this.result[0] = this.autoSuggestion.reviewTag;

  }

  saveAutoSuggestion() {
    this.handler.activateLoader();
    this.projectService.reviewAutoSuggestion(this.id, this.autoSuggestion).subscribe(results => {
      this.handler.hideLoader();
      if (this.handler.handle(results)) {
        return;
      }
      this.autoSuggestion = results['data'];
      if(this.autoSuggestion.reviewTag)
      // this.snackbarService.openSnackBar('Successfully marked Reviewed', '');
      this.snackbarService.openSnackBar('Comments Updated successfully', '');
      else 
      // this.snackbarService.openSnackBar('Successfully Uphold Review', '');
      this.snackbarService.openSnackBar('Comments Updated successfully', '');
      this.dialogRef.close(this.result);

    }, error => {
      this.handler.hideLoader();
      this.handler.error(error);
    });
  }

  closeWithoutSave() {
    this.result[0] = this.autoSuggestion.reviewTag;
    this.dialogRef.close(this.result);
  }

}
