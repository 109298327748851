import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable} from "rxjs/Rx";
import {AuthGuard} from "./auth.guard";
import {inject, Injectable} from "@angular/core";

@Injectable({
    providedIn:'root'
})

export class TokenInterceptor implements HttpInterceptor {

    constructor(private auth:AuthGuard){}
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if(this.auth.getToken()) {
            req = req.clone({
                setHeaders:{
                    Authorization:'Bearer ' +this.auth.getToken()
                }
            })
        }
        return next.handle(req);

    }

}