import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-okta-login',
  templateUrl: './okta-login.component.html',
  styleUrls: ['./okta-login.component.scss']
})
export class OktaLoginComponent implements OnInit {

  token: string;
  constructor(private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
  //  this.router.navigate(['/okta-login'], { queryParams: {}, replaceUrl: true });

    this.route.queryParams.subscribe(params => {
      console.log(params);
      this.token = params.token;

      localStorage.setItem('apisecT_1', this.token);
     this.router.navigate(["/app/projects"]);

    });
  }

}
