import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { AdvRunComponent } from "../adv-run/adv-run.component";
import { ProjectService } from "../../../services/project.service";
import { Handler } from "../handler/handler";
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
    selector: 'app-oas-diff-dialog',
    templateUrl: './oas-diff-dialog.component.html',
    styleUrls: ['./oas-diff-dialog.component.scss'],
    providers: [ProjectService]
})
export class OasDiffDialogComponent implements OnInit {

    differentFlag: boolean;
    additions = [];
    addition = "";
    differences = [];
    missings = [];
    missing = "";
    oldDifferences;
    newDifferences;
    dataSource = null;
    displayedColumns: string[] = ['field', 'oldVal', 'newVal'];

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<AdvRunComponent>, private projectService: ProjectService, private handler: Handler) {
    }

    ngOnInit() {

        this.handler.activateLoader();
        this.projectService.compareSpec(this.data.projectId).subscribe(results => {
            this.handler.hideLoader();
            if (this.handler.handle(results)) {
                return;
            }
            this.differentFlag = results['data']['different'];
            this.additions = results['data']['additions'];
            for (let add of this.additions) {
                this.addition = this.addition + add + "\n"
            }
            this.differences = results['data']['differences'];
            this.dataSource = new MatTableDataSource(this.differences);
            this.dataSource.sort = this.sort;
            this.length = results['totalElements'];
            this.missings = results['data']['missing'];
            for (let miss of this.missings) {
                this.missing = this.missing + miss + "\n"
            }
        }, error => {
            this.handler.hideLoader();
            this.handler.error(error);
        });
    }

    length = 0;
    page = 0;
    pageSize = 10;
    change(evt) {
      this.page = evt['pageIndex'];
    }

}
