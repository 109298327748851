import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateDiff'
})
export class DateDiffPipe implements PipeTransform {

  //custom pipe created to get difference between today's date and created date from database in days.
  transform(value: any, ...args: any[]): any {

      var now = Date.now();
      var createdTime = new Date(value).getTime();
      var dateDiff = (now - createdTime)/(1000*60*60*24);
      return dateDiff;

  }

}
