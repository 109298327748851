import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackbarService } from '../../../services/snackbar.service';

@Component({
  selector: 'app-hudson-dialog',
  templateUrl: './hudson-dialog.component.html',
  styleUrls: ['./hudson-dialog.component.scss'],
  providers: [SnackbarService]
})
export class HudsonDialogComponent implements OnInit {

  integration: string = 'wget https://raw.githubusercontent.com/intesar/FX-Scripts/master/fx_job_invoke_script.sh?token=AElo1orWSWln6w6y5uX0-1ylzPH8TX_3ks5bqJBywA%3D%3D -O fx_job_invoke_script.sh;bash fx_job_invoke_script.sh {Username} {Password}';

  constructor(private snackbarService: SnackbarService, @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<HudsonDialogComponent>) { }

  // tslint:disable-next-line: no-empty
  ngOnInit() {
    this.integration = this.integration + " " + this.data.jobId + " " + this.data.regions + " " + this.data.envId + " " + this.data.projectId;
  }
  // copyToClipboard(element) {
  //   const $temp = $('<input>');
  //   $('body').append($temp);
  //   $temp.val($(element).text()).select();
  //   document.execCommand('copy');
  //   this.snackbarService.openSnackBar('Copied text to clipboard ', '');
  //   $temp.remove();
  // }

  showMsg() {
    this.snackbarService.openSnackBar('Copied text to clipboard ', '');

  }
}
