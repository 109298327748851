import { AfterViewChecked, AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import 'brace';
import 'brace/mode/yaml';
import 'brace/theme/github';
import 'brace/ext/language_tools';
import { AceEditorComponent } from 'ng2-ace-editor';
import { Router } from '@angular/router';
import { RunService } from '../../../services/run.service';
import { Handler } from '../handler/handler';
import { JobsService } from '../../../services/jobs.service';
import { SnackbarService } from '../../../services/snackbar.service';
import { TestSuiteService } from '../../../services/test-suite.service';
import { TestsuitEditDialogComponent } from '../../dialogs/testsuit-edit-dialog/testsuit-edit-dialog.component';

@Component({
    selector: 'app-msg-dialog',
    templateUrl: './msg-dialog.component.html',
    styleUrls: ['./msg-dialog.component.scss'],
    providers: [JobsService, RunService, SnackbarService]
})
export class MsgDialogComponent implements OnInit {

    @ViewChild('editor', { static: true }) editor: AceEditorComponent;

    keyword: string = '';
    category: string = 'All';
    id: string = '';
    testsuite;
    editorSelect: any = { rowText: '', validEvent: false, text: '', exec: false, row: 0, column: 0, prevExecuted: false };
    currentSuiteName: string;
    suitNames: any;
    projectId: string;
    jobId: string;
    runId: string;
    passed: number;
    failed: number;
    hideOptions: boolean;
    // logDataTemp;
    logData;
    list;
    nextSuitName: any;
    suitePosition: number;

    methodGet = /\[GET]/gi;
    methodPut = /\[PUT]/gi;
    methodDelete = /\[DELETE]/gi;
    methodPost = /\[POST]/gi;
    nextLink = false;
    constructor(private testSuiteService: TestSuiteService, private dialog: MatDialog, @Inject(MAT_DIALOG_DATA)
    public data: any, public dialogRef: MatDialogRef<MsgDialogComponent>, private router: Router,
        private runService: RunService, private handler: Handler) {
        this.logData = data[0];
        this.currentSuiteName = data[1];
        this.suitNames = data[2];
        this.projectId = data[3];
        this.jobId = data[4];
        this.runId = data[5];
        this.passed = data[6];
        this.failed = data[7];
        this.hideOptions = data[8];
        this.activityProjectActivity();
        this.orgVulLogs();

    }

    activityProjectActivity() {
        var k = localStorage.getItem('activityId');
        localStorage.removeItem('activityId');
        localStorage.removeItem('logId');
        if (k) this.nextLink = true;

    }

    hideButtons: boolean = true;
    orgVulLogs() {

        if (this.data.length > 8) {
            if (this.data[8] == 'hide') {
                this.hideButtons = false
                this.nextLink = true;
            }

        }
        else
            this.hideButtons = true;
    }

    ngOnInit() {
        localStorage.setItem('suitename', this.currentSuiteName);
        if (this.logData.search(this.methodGet) > 0) {
            this.logData = this.logData.replace(this.methodGet, '["GET"]');
        }
        if (this.logData.search(this.methodPut) > 0) {
            this.logData = this.logData.replace(this.methodPut, '["PUT"]');
        }
        if (this.logData.search(this.methodDelete) > 0) {
            this.logData = this.logData.replace(this.methodDelete, '["DELETE"]');
        }
        if (this.logData.search(this.methodPost) > 0) {
            this.logData = this.logData.replace(this.methodPost, '["POST"]');
        }

        // this.suitePosition = this.suitNames.indexOf(this.currentSuiteName);
    }

    nextSuite() {
        if (this.currentSuiteName)
            this.suitePosition = this.suitNames.map(object => object.testsuitesName).indexOf(this.currentSuiteName);;
        this.suitePosition = this.suitePosition + 1;
        if (this.suitePosition < this.suitNames.length) {
            this.nextSuitName = this.suitNames[this.suitePosition];
            this.getTestSuiteResponseByName(this.nextSuitName);
        } else {
            window.alert('Reached end of the page');
        }
    }

    editorRecursiveEent() {
        const temp = this.rowTextEvent();
        if (temp.validEvent && !temp.prevExecuted) {
            this.editorSelect = temp;
            console.log('inside ');
            this.openFileDialog(this.projectId, this.editorSelect.text);
        }
    }

    previousSuite() {
        if (this.currentSuiteName)
            this.suitePosition = this.suitNames.map(object => object.testsuitesName).indexOf(this.currentSuiteName);;
        this.suitePosition = this.suitePosition - 1;

        if (this.suitePosition >= 0) {
            this.nextSuitName = this.suitNames[this.suitePosition];
            this.getTestSuiteResponseByName(this.nextSuitName);
        } else {
            window.alert('Reached beginning of the page');
        }
    }

    openFileDialog(projectId, suiteName) {
        this.id = projectId;
        this.keyword = suiteName;
        this.editorSelect.text = suiteName;
        this.searchByCategory(suiteName);
    }

    rowTextEvent() {
        const temp = { rowText: '', text: '', exec: false, row: 0, column: 0, validEvent: false, prevExecuted: true };
        temp.rowText = this.editor.getEditor().session.getLine(this.editor.getEditor().selection.getCursor().row);
        temp.row = this.editor.getEditor().selection.getCursor().row;
        temp.column = this.editor.getEditor().selection.getCursor().column;
        temp.text = this.rowTemplateText();
        const tStartIndex = temp.rowText.indexOf(temp.text);
        const tLastIndex = tStartIndex + temp.text.length;
        if (temp.text !== '' && tStartIndex > 0 && temp.column > tStartIndex && temp.column < tLastIndex) { temp.validEvent = true; }
        if (this.editor.getEditor().getSelectedText().length > 0) { temp.validEvent = false; }
        if (temp.validEvent && temp.row !== this.editorSelect.row) { temp.prevExecuted = false; }
        return temp;
    }

    rowTemplateText() {
        const row = this.editor.getEditor().session.getLine(this.editor.getEditor().selection.getCursor().row);
        if (row.charAt(27) !== '[') { return ''; }
        let tempText = row.substring(28, 100).trim();
        const trow = tempText.split(']');
        if (trow.length >= 1) { tempText = trow[0]; } else { tempText = ''; }
        if (tempText === '') { return ''; }
        return tempText;
    }

    searchByCategory(suiteName) {
        this.handler.activateLoader();
        if (this.keyword === '' && this.category === '') {
            return this.list(this.id);
        }
        let category_ = '';
        if (this.category === 'All') {
            category_ = '';
        } else {
            category_ = this.category;
        }

        this.testSuiteService.searchTestSuite(this.id, category_, '', '', this.keyword, 0, 10).subscribe((results) => {
            this.handler.hideLoader();
            if (this.handler.handle(results)) {
                return;
            }
            //  this.testsuites = results['data'];
            for (let i = 0; i < results['data'].length; i++) {
                const k = results['data'][i];
                if (k.name === this.editorSelect.text) {
                    this.testsuite = k;
                    break;

                }
            }
            if (this.testsuite != null) {
                this.testsuite.name = suiteName
                this.showTSDialog(this.testsuite.id, this.testsuite.name, this.testsuite.autoGenerated);
            }
            setTimeout(() => { this.editorSelect.exec = false; }, 3000);

        }, (error) => {
            this.handler.hideLoader();
            this.handler.error(error);
        });
    }

    showTSDialog(testSuiteID, testSuiteName, autoGenerated) {
        if (this.dialog.openDialogs.length == 1) {
            const dialogRef = this.dialog.open(TestsuitEditDialogComponent, {
                width: '1400px',
                maxWidth: '90vw',
                data: [this.id, testSuiteID, testSuiteName, autoGenerated, this.hideOptions]
            });
        }
    }

    getTestSuiteResponseByName(suiteDetail: any) {
        this.editorSelect.exec = true;
        this.currentSuiteName = suiteDetail.testsuitesName;
        this.handler.activateLoader();
        this.runService.getTestSuiteResponseByName(suiteDetail.runId, suiteDetail.testsuitesName).subscribe((results) => {
            this.handler.hideLoader();
            if (this.handler.handle(results)) {
                return;
            }
            this.list = results['data'];
            const arrayLength = this.list.length;
            let msg = '';
            for (let i = 0; i < arrayLength; i++) {
                msg += this.list[i].logs;
            }
            this.logData = msg;
            this.editorSelect.exec = false;
            if (this.list && this.list.length > 0) {
                this.passed = this.list[0].totalPassed;
                this.failed = this.list[0].totalFailed;
            }
            if (this.logData.search(this.methodGet) > 0) {
                this.logData = this.logData.replace(this.methodGet, '["GET"]');
            }
            if (this.logData.search(this.methodPut) > 0) {
                this.logData = this.logData.replace(this.methodPut, '["PUT"]');
            }
            if (this.logData.search(this.methodDelete) > 0) {
                this.logData = this.logData.replace(this.methodDelete, '["DELETE"]');
            }
            if (this.logData.search(this.methodPost) > 0) {
                this.logData = this.logData.replace(this.methodPost, '["POST"]');
            }
            localStorage.setItem('suitename', this.currentSuiteName);
        }, (error) => {
            this.handler.hideLoader();
            this.handler.error(error);
        });
    }

    ngAfterContentChecked() {
        const array2: any = [];
        const numberoflines = this.editor.getEditor().session.getLength();
        const words = this.editor.getEditor().session.getLines(0, numberoflines);
        for (let i = 0; i < words.length; i++) {
            const array1 = words[i].split(' ');
            for (let j = 0; j < array1.length; j++) {
                if (array1[j] === 'ERROR' || array1[j] === '[E]') {
                    j = array1.length;
                    array2.push({ row: i, text: 'Error', type: 'error' });
                }
            }
        }
        this.editor.getEditor().getSession().setAnnotations(array2);
    }

    reverseString(str) {
        let newString = '';
        if (str !== '') {
            for (let i = str.length - 1; i >= 0; i--) {
                newString += str[i]; // or newString = newString + str[i];
            }
        }
        return newString; // "olleh"
    }
}
