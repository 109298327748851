import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Handler } from '../components/dialogs/handler/handler';
import { ApiGroups } from '../services/api-groups.service';
import { OrgService } from '../services/org.service';
import { ProjectService } from "../services/project.service";
import { SnackbarService } from '../services/snackbar.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ApiListDialogComponent } from '../components/dialogs/api-list-dialog/api-list-dialog.component';
import { TeamsUserListDialogComponent } from '../components/dialogs/teams-userslist-dialog/teams-userlist-dialog.component';
import { TeamsListDialogComponent } from '../components/dialogs/teams-list-dialog/teams-list-dialog.component';
import { ApiGroupListDialogComponent } from '../components/dialogs/api-groups-list-dialog/api-group-list-dialog.component';

@Component({
  selector: 'app-example',
  templateUrl: './org-monthly-report.component.html',
  styleUrls: ['./org-monthly-report.component.scss'],
  providers: [SnackbarService, ProjectService, OrgService, ApiGroups]

})
export class OrgMonthlyReport implements OnInit {
  emailForm: FormGroup; 
  org_report_bcc_recipients="";
  
  constructor(private _formBuilder: FormBuilder, private projectService: ProjectService, private snackbarService: SnackbarService, private handler: Handler, private orgService: OrgService, private apiGroups: ApiGroups, private router: Router, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.emailForm =this._formBuilder.group({
      orgReportBccCtrl: ['']
    });
    this.getEmailListReports()
  }

emailList;
  getEmailListReports(){
     this.projectService.getReportsEmailList().subscribe(result => {
      if (result['data'] && result['data'].emailList) {
        this.emailList = result['data'].emailList;
        this.org_report_bcc_recipients = this.emailList;
      } else {
        console.log('No email list data found.');
      }
      this.handler.hideLoader();
      if (this.handler.handle(result)) {
        return;
      }
    }, error => {
      this.handler.hideLoader();
      this.handler.error(error);
    })
  }
  
  save(){
     const enteredValue = this.emailForm.get('orgReportBccCtrl').value;
     this.emailList = enteredValue
     this.projectService.saveAll(this.emailList).subscribe(results => {
      this.handler.hideLoader();
      if (this.handler.handle(results)) {
        return;
      }
    this.snackbarService.openSnackBar(" Saved Successfully.", "");
    }, error => {
      this.handler.hideLoader();
      this.handler.error(error);
    }); 
  }


}
