import { Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ProjectService } from "../../../services/project.service";
import { Handler } from "../handler/handler";

@Component({
    selector: 'app-manage-autocode-gen-auth',
    templateUrl: './manage-autocode-gen-auth.component.html',
    styleUrls: ['./manage-autocode-gen-auth.component.scss'],
    providers: [ProjectService]

})
export class ManageAutocodeGenAuthComponent implements OnInit {
    projectId: string;
    keyword: string;
    displayedColumns: string[] = ['resourceName', 'endpoint', 'coveredFlag'];
    manageRolesObj;
    endPointDataSource = null;
    endpointsData: any;
    resourceSamples;

    allSelectedFlag: boolean = false;
    fewSelectedFlag: boolean = false;

    @ViewChild(MatSort, {static: true}) sort: MatSort;
    @Output() emitData = new EventEmitter<string>();
    totalEndpoints: number;

    constructor(private dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any,
                public dialogRef: MatDialogRef<ManageAutocodeGenAuthComponent>, private projectService: ProjectService, private handler: Handler) {
    }

    ngOnInit() {

        this.projectId = this.data.projectId;
        if (this.data.pathPatterns != null)
            this.resourceSamples = this.data.pathPatterns.split("\n");
        else
            this.resourceSamples = [];

        this.endpointsData = this.data.endpointsData;
        if(this.endpointsData.length != 0)
            this.processEndpointData();
        else
            this.getEndpointList();

    }

    processEndpointData(){

        for(let element of this.endpointsData){
            var index=  this.resourceSamples.findIndex(r1 => element.method == r1.split(':')[0] && element.endpoint == r1.split(':')[1])
            if(index >= 0){
                element['coveredFlag'] = false
            }
            else
                element['coveredFlag'] = true

        }

        this.endPointDataSource = this.endpointsData;

        this.totalEndpoints = this.endpointsData.length;
        this.endPointDataSource = new MatTableDataSource(this.endPointDataSource);
        this.endPointDataSource.sort = this.sort;
        this.selectedEndPoint();
    }

    getEndpointList() {
        this.handler.activateLoader();
        this.projectService.getEndpoints(this.projectId).subscribe(results => {
            this.handler.hideLoader();
            if (this.handler.handle(results)) {
                return;
            }
            this.endpointsData = results['data'];

            this.processEndpointData();


        }, error => {
            this.handler.hideLoader();
            this.handler.error(error);
        });
    }


    save() {
        let modifiedData = '';
        for(let element of this.endpointsData){
            if(element.coveredFlag == false){
                modifiedData = modifiedData + element.method + ':' + element.endpoint + '\n';
            }
        }

        modifiedData = modifiedData.replace(/\n$/, "");
        this.emitData.next(modifiedData);
        this.dialogRef.close();
    }


    selectedEndPoint() {

        let totalSelected = this.endpointsData.filter(i => i.coveredFlag).length;

        if(totalSelected == 0)
        {
            this.allSelectedFlag = false;
            this.fewSelectedFlag = false;
        }
        else
        if(totalSelected > 0 && totalSelected < this.endpointsData.length)
        {
            this.allSelectedFlag = false;
            this.fewSelectedFlag = true;
        }
        else
        if(totalSelected > 0 && totalSelected == this.endpointsData.length)
        {
            this.allSelectedFlag = true;
            this.fewSelectedFlag = false;
        }
    }

    applyFilter(filterValue: string) {
        if(filterValue.length==0 || filterValue.length>=3){
        this.endPointDataSource.filter = filterValue.trim().toLowerCase();
        }
    }

    selectedAllEndPoints(event) {
        //All checked
        if (event.checked) {

            for(let element of this.endpointsData){
                element['coveredFlag'] = true
            }
        }

        //All unchecked
        else {

            for(let element of this.endpointsData){
                element['coveredFlag'] = false
            }
        }

    }

}
