import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ArchitectureViewService {
  private serviceUrl = '/api/v1/architecture-view';

  constructor(private httpClient: HttpClient) { }

  getCoverageMatrix(projectId:string,page,pageSize){
      let params = new HttpParams();
      params = params.append("page", page);
      params = params.append("pageSize", pageSize);
    return this.httpClient.get(this.serviceUrl + "/project/" + projectId + "/coverage",{params});
  }

    getCoverageMatrixFull(projectId:string){

        return this.httpClient.get(this.serviceUrl + "/project/" + projectId + "/coverage");
    }
}
