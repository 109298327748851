import { Component, EventEmitter, Inject, OnInit, Output, ViewChild, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Handler } from "../handler/handler";
import { ProjectService } from "../../../services/project.service";
import { Project } from "../../../models/project.model";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import * as XLSX from 'xlsx';
import downloadCsv from 'download-csv';

import { ELASTICSEARCHVALIDATOR } from './../../../shared/shared.data';
import { SnackbarService } from "../../../services/snackbar.service";
import { AutocodeGeneratorService } from '../../../services/autocode-generator.service';
@Component({
    selector: 'app-manage-roles',
    templateUrl: './manage-roles.component.html',
    styleUrls: ['./manage-roles.component.scss'],
    providers: [ProjectService, SnackbarService]

})
export class ManageRolesComponent implements OnInit {
    project: Project = new Project();
    projectId: string;
    keyword: string;
    displayedColumns;
    manageRolesObj;
    dataSource = null;
    endpointList: any;
    endPointDataSource = null;
    userRoles;
    resourceSamples;
    selectedAllData = [];
    allSelectedFlag: boolean = true;
    totalEndpoints: number = 0;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @Output() emitData = new EventEmitter<string>();
    assignedRoles = 0;
    searchStringValidator = ELASTICSEARCHVALIDATOR;
    endpointsData: any;
    fewSelectedFlag: boolean = false;
    categoryName = '';
    list = [];
    skipPath = [];
    isDirty: boolean;
    constructor(private dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any, private handler: Handler,
        public dialogRef: MatDialogRef<ManageRolesComponent>, private projectService: ProjectService, private snackbarService: SnackbarService, public snackBar: MatSnackBar,
        private autocodeGeneratorService: AutocodeGeneratorService) {
        this.projectId = data[0];
        this.userRoles = "resourceName,"  + "method," +  "endpoint," + "accessCount," + data[1];

        if (data[4] != null)
            this.skipPath = data[4].split("\n");
        if (data[2] != null)
            this.resourceSamples = data[2].split("\n");
        else
            this.resourceSamples = [];
        this.displayedColumns = this.userRoles.split(",");
        this.list = [];
        for (let i = 0; i < this.displayedColumns.length; i++) {
            const element = this.displayedColumns[i];
            let obj = { title: '', ischeck: false };
            obj.title = element;
            this.list.push(obj);
        }

        this.categoryName = data[5];
    }

    ngOnInit() {

        this.endpointsData = JSON.parse(JSON.stringify(this.data[3]));

        if (this.endpointsData.length != 0) {
            for (var i = 0; i < this.endpointsData.length; i++) {
                if (this.endpointsData[i].access)
                    this.endpointsData[i].accessCount = 0;
            }
            this.snackBar.open('"' + "Retrieving Endpoints Map..." + '"', "", {
                verticalPosition: 'top',
                horizontalPosition: 'center'
            });

            //SKIP ENDPOINTS FILTER BELOW
            for (var i = 0; i < this.skipPath.length; i++) {
                var array1 = this.skipPath[i].split(':');
                for (var j = 0; j < this.endpointsData.length; j++) {

                    if (this.endpointsData[j].method == array1[0] && this.endpointsData[j].endpoint == array1[1]) {
                        this.endpointsData.splice(j, 1)
                        j = this.endpointsData.length
                    }
                }
            }

            this.processEndpointData();
        }
        else {
            this.getEndpointList();

        }
    }

    arrSelectedValues: any = [];
    headers = {};

    applyPermissions() {
        let modifiedData = '';

        for (var i = 0; i < this.manageRolesObj.length; i++) {
            if (this.manageRolesObj[i].accessCount > 0)
                this.selectedAllData.push(this.manageRolesObj[i]);
        }

        for (var i = 0; i < this.selectedAllData.length; i++) {
            modifiedData = modifiedData + this.selectedAllData[i].method + ':' + this.selectedAllData[i].endpoint + ':Allowed[' + this.selectedAllData[i].access + "]\n";
        }
        if (modifiedData != this.data[2]) {
            //alert("Make sure to do " + '"' + "Save Changes or Commit & Rewrite playbooks;" + '"' + " otherwise" + "," + " your changes will be lost.");
        }
        this.emitData.next(modifiedData);
        this.dialogRef.close(true);
    }

    exportAsXLSX() {
        this.arrSelectedValues = [];
        if (this.displayedColumns && this.displayedColumns.length > 0) {
            this.headers['Resource'] = '';
            this.headers['Endpoint'] = '';
            this.headers['Overlapping Roles'] = '';
            for (let i = 3; i < this.displayedColumns.length; i++) {
                this.headers[this.displayedColumns[i]] = "";
            }
        }

        if (this.endPointDataSource.data && this.endPointDataSource.data.length > 0) {
            for (var i = 0; i < this.endPointDataSource.data.length; i++) {
                this.endPointDataSource.data[i]['Resource'] = this.endPointDataSource.data[i]['resourceName'];
                this.endPointDataSource.data[i]['Endpoint'] = this.endPointDataSource.data[i]['method'] + ":" + this.endPointDataSource.data[i]['endpoint'];
                this.endPointDataSource.data[i]['Overlapping Roles'] = this.endPointDataSource.data[i]['accessCount'];
                const element = this.endPointDataSource.data[i];
                let obj = JSON.parse(JSON.stringify(this.headers))

                Object.keys(obj).forEach(function (key) {
                    Object.keys(element).forEach(function (key2) {
                        if (key.toLowerCase() == key2.toLowerCase()) {
                            obj[key] = element[key2];
                        }
                    });
                });
                if (element.access && element.access.length > 0) {
                    for (let j = 0; j < element.access.length; j++) {
                        Object.keys(obj).forEach(function (key) {

                            if (key == element.access[j]) {
                                obj[key] = "Y";
                            } else if (obj[key] == "") {
                                obj[key] = "N";
                            }
                        });
                    }
                } else {
                    Object.keys(obj).forEach(function (key) {
                        if (obj[key] == "" && key.indexOf('Overlapping Roles') != 0) {
                            obj[key] = "N";
                        }
                    });
                }
                delete obj.AccessCount;
                this.arrSelectedValues.push(obj);
            }
        }

        let objectMaxLength = [];
            for (let i = 0; i < this.arrSelectedValues.length; i++) {
                let value = <any>Object.values(this.arrSelectedValues[i]);
                for (let j = 0; j < value.length; j++) {
                    if (typeof value[j] == "number") {
                        objectMaxLength[j] = 14;
                    } else {
                        if(value[j] && value[j].length){
                        objectMaxLength[j] =
                            objectMaxLength[j] >= value[j].length
                                ? objectMaxLength[j]
                                : value[j].length;
                    }
                }
                }
            }

        //     var wscols = [
        //         { width: objectMaxLength[0] = '15' },  // first column
        //         { width: objectMaxLength[1] ='30'},// second column
        //         { width: objectMaxLength[2] = '18'},
        //         { width: objectMaxLength[3] = '15'},
        //         { width: objectMaxLength[3]}
        //      ];
        //     const workBook = XLSX.utils.book_new(); // create a new blank book
        //     const workSheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.arrSelectedValues);
        //     workSheet["!cols"] = wscols;

        //     XLSX.utils.book_append_sheet(workBook, workSheet, 'data'); // add the worksheet to the book
        // XLSX.writeFile(workBook, 'RBAC_Mapping.xlsx'); // initiate a file download in browser
        downloadCsv(this.arrSelectedValues, '', 'RBAC_Mapping', '.csv');


    }

    processEndpointData() {

        for (var i = 0; i < this.endpointsData.length; i++) {
            if (this.endpointsData[i].accessCount == null)
                this.endpointsData[i].accessCount = 0;
            this.endpointsData[i].access = [];

        }

        this.snackBar.dismiss();
        this.endPointDataSource = this.endpointsData;
        this.endPointDataSource = new MatTableDataSource(this.endPointDataSource);
        this.manageRolesObj = this.endPointDataSource.data;
        this.totalEndpoints = this.endpointsData.length;

        //converting existing API and allowed roles in array format
        for (var i = 0; i < this.resourceSamples.length - 1; i++) {
            // var resourceMethod = this.resourceSamples[i].split(':')[0];
            var resourceMethodEndpoint = this.resourceSamples[i].split('Allowed')[0];
            // var accesVar1 = array1[2].split('[')[1];
            // var accesVar2 = accesVar1.split(']')[0];

            var methodPath0 = this.resourceSamples[i].split("[")[0]
            var methodPath1 = this.resourceSamples[i].split("[")[1]
            var methodPath2 = methodPath1.split("]")[0]

            var accessVar3 = methodPath2.split(',');
            for (var j = 0; j < this.manageRolesObj.length; j++) {
                if (this.manageRolesObj[j].method + ':' + this.manageRolesObj[j].endpoint + ':' == resourceMethodEndpoint) {
                    this.manageRolesObj[j].access = accessVar3;
                    this.manageRolesObj[j].accessCount = accessVar3.length;
                } else
                    this.allSelectedFlag = false;
            }
        }
        this.endPointDataSource.sort = this.sort;
        this.assignedRoles = this.manageRolesObj.map(t => t.accessCount).reduce((access, value) => access + value, 0);
        var map = {};

        for(var i=4;i<this.list.length;i++){
          map[this.list[i].title] = 0;
        }
        for(var i=0;i<this.endPointDataSource.data.length;i++){
          var temp=this.endPointDataSource.data[i].access
          for(var x=0;x<temp.length;x++){
          var a = map[temp[x]]
          map[temp[x]] +=1

        }
        }
        var len=this.endPointDataSource.data.length;
        for(var i=4;i<this.list.length;i++){
          var k = map[this.list[i].title];
          if (k==len)
            this.list[i].ischeck=true;
        }
      }

    getEndpointList() {
        this.handler.activateLoader();
        this.snackBar.open('"' + "Retrieving Endpoints Map..." + '"', "", {
            verticalPosition: 'top',
            horizontalPosition: 'center'
        });
        this.projectService.getEndpoints(this.projectId).subscribe(results => {
            this.handler.hideLoader();
            this.snackBar.dismiss();
            if (this.handler.handle(results)) {
                return;
            }
            this.endpointsData = results['data'];
            this.processEndpointData();
        }, error => {
            this.handler.hideLoader();
            this.handler.error(error);
        });
    }

    loadProject(id: string) {
        this.handler.activateLoader();
        this.projectService.getById(id).subscribe(results => {
            // this.handler.hideLoader();
            if (this.handler.handle(results)) {
                return;
            }
            this.project = results['data'];
        }, error => {
            this.handler.hideLoader();
            this.handler.error(error);
        });
    }

    //sorting endpoints
    sortEndpoints(a, b) {
        var ax = [], bx = [];
        a.replace(/(\d+)|(\D+)/g, function (_, $1, $2) {
            ax.push([$1 || Infinity, $2 || ""]);
        });
        b.replace(/(\d+)|(\D+)/g, function (_, $1, $2) {
            bx.push([$1 || Infinity, $2 || ""]);
        });
        while (ax.length && bx.length) {
            var an = ax.shift();
            var bn = bx.shift();
            var nn = (an[0] - bn[0]) || an[1].localeCompare(bn[1]);
            if (nn) return nn;
        }
        return ax.length - bx.length;
    }

    selectedEndPoint(selectedMethod, selectedEndPoint, selectedRole, event) {
        //checkbox checked
        this.isDirty = true;
        if (event.checked) {
            let isAllChecked = 0;
            for (var k = 0; k < this.manageRolesObj.length; k++) {
                if ((this.manageRolesObj[k].method == selectedMethod) && (this.manageRolesObj[k].endpoint == selectedEndPoint)) {
                    this.manageRolesObj[k].access.push(selectedRole);
                    this.manageRolesObj[k].accessCount++;
                }
                if (isAllChecked != 1 && this.manageRolesObj[k].access.indexOf(selectedRole) >= 0) {
                    isAllChecked = 2;
                } else {
                    isAllChecked = 1;

                }
            }
            for (let i = 0; i < this.list.length; i++) {
                const element = this.list[i];
                if (element.title == selectedRole) {
                    if (isAllChecked == 2) {
                        this.list[i].ischeck = true;
                        break;
                    }
                }
            }

        }
        //checkbox unchecked
        else {
            for (let i = 0; i < this.list.length; i++) {
                const element = this.list[i];
                if (element.title == selectedRole) {
                    if (event.checked == false) {
                        this.list[i].ischeck = false;
                        break;
                    }
                }
            }

            for (var j = 0; j < this.manageRolesObj.length; j++) {
                if ((this.manageRolesObj[j].method == selectedMethod) && (this.manageRolesObj[j].endpoint == selectedEndPoint)) {
                    var ind = this.manageRolesObj[j].access.indexOf(selectedRole);
                    if (ind >= 0) {
                        this.manageRolesObj[j].access.splice(ind, 1);
                    }
                    this.manageRolesObj[j].accessCount--;
                }
            }

        }
        this.assignedRoles = this.manageRolesObj.map(t => t.accessCount).reduce((access, value) => access + value, 0);
    }

    searchEndpoint() {
        //search endpoints
        if (this.keyword.length == 0 || this.keyword.length >= 3) {
            if (this.searchStringValidator.isValidSearchKeyCode(this.keyword)) {
                this.endPointDataSource = [];
                for (var j = 0; j < this.manageRolesObj.length; j++) {
                    if (this.manageRolesObj[j].endpoint.indexOf(this.keyword) >= 0) {
                        this.endPointDataSource.push(this.manageRolesObj[j]);
                    }
                }
                this.totalEndpoints = this.endPointDataSource.length;
                this.endPointDataSource = new MatTableDataSource(this.endPointDataSource);
                this.endPointDataSource.sort = this.sort;
            }
        }
    }

    selectedAllEndPoints(selectedColumn, event) {
        //All checked
        this.isDirty = true;
        if (event.checked) {
            for (var i = 0; i < this.manageRolesObj.length; i++) {
                if (this.manageRolesObj[i].access.indexOf(selectedColumn) < 0)
                    this.manageRolesObj[i].access.push(selectedColumn);

                this.manageRolesObj[i].accessCount = this.manageRolesObj[i].access.length;
            }
        }
        //All unchecked
        else {
            for (var i = 0; i < this.manageRolesObj.length; i++) {
                var ind = this.manageRolesObj[i].access.indexOf(selectedColumn);
                if (ind >= 0) {
                    this.manageRolesObj[i].access.splice(ind, 1);
                    this.manageRolesObj[i].accessCount--;
                }
            }
        }

        this.assignedRoles = this.manageRolesObj.map(t => t.accessCount).reduce((access, value) => access + value, 0);
    }
}
