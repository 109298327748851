import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';


import { Tasks } from './../models/tasks.model';

@Injectable()
export class TasksService {
  private serviceUrl = 'api/v1/events';
  //private serviceUrl = '/api/v1/events/register';
  constructor(private http: HttpClient) { }

  /**
   * Get observable from endpoint
   */

  get(page, pageSize) {
    let params = new HttpParams();
    params = params.append('page', page);
    params = params.append('pageSize', pageSize);
    return this.http.get(this.serviceUrl + "/orgevents", {params});
  }

  getById(id: string){
    return this.http.get(this.serviceUrl + "/orgevents/" + id)
  }

  getEventStatus(id,eventType){
      return this.http.get(this.serviceUrl + "/project/" + id + "/" + eventType)
  }


  getByUsername(page, pageSize, username, eventType, sort, sortType) {
    let params = new HttpParams();
    params = params.append('page', page);
    params = params.append('pageSize', pageSize);
    params = params.append("username", username);
    params = params.append("eventType", eventType);
    params = params.append("sort", sort);
    params = params.append("sortType", sortType);

    return this.http.get(this.serviceUrl +"/search-events", {params});
  }
}
