import { Member } from "./../models/org.model";
import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { MixpanelService } from "./mixpanel.service";
import { ProjectMinimalDto } from "../models/project-job.model";

@Injectable({
  providedIn: "root",
})
export class ResourceService {
  private serviceUrl = "/api/v1/resources";
  private endPointURL = "/api/v1/endpoint";
  constructor(
    private http: HttpClient,
    private mixpanelService: MixpanelService
  ) {}

  getResources(projectId) {
    return this.http.get(this.serviceUrl + "/project/" + projectId);
  }

  putResources(projectId, resourceObj) {
    return this.http.put(
      this.serviceUrl + "/project/" + projectId + "/batch",
      resourceObj
    );
  }

  getEndpoints(projectId) {
    return this.http.get(
      this.serviceUrl + "/project/" + projectId + "/endpoints"
    );
  }

  getEndpoint(projectId) {
    return this.http.get(
      this.serviceUrl + "/project/" + projectId + "/endpoint"
    );
  }

  getAbac3ResourceName(projectId) {
    return this.http.get(this.serviceUrl + "/project/" + projectId + "/abac3");
  }

  // Add Endpoints
  addEndPoints(projectId, bulkEndPoints, scanEndpoints) {
    //mixpanel code starts
    var obj1 = {
      endpoints: bulkEndPoints,
    };
    this.mixpanelService.trackMixpanel("ManualEPAdd", obj1);
    //mixpanel code ends
    let params = new HttpParams();
    params = params.append("scanEndpoints", scanEndpoints);

    return this.http.post(
      this.endPointURL + "/" + "add-to-spec-file/" + projectId,
      bulkEndPoints,
      { params }
    );
  }

  getAllProjectsEndpoints() {
    return this.http.get(this.serviceUrl + "/org/endpoint");
  }

  getOrgEndpointsRegex(regex, projectIds) {
    let params = new HttpParams();
    params = params.append("regex", regex);
    // params = params.append("projectIds", projectIds);
    return this.http.post(
      this.serviceUrl + "/" + "org" + "/" + "endpoint",
      projectIds,
      { params }
    );
  }

  skipEndpoint(selectedEndpoints) {
    return this.http.post(this.endPointURL + "/skip", selectedEndpoints);
  }

  skippedEdnpointList(projectIds) {
    return this.http.get(this.endPointURL + "/skippedendpoints", projectIds);
  }

  removeSkippedEndpoint(id) {
    return this.http.post(this.endPointURL + "/skippedendpoints", id);
  }

  //url  : Phttp://localhost:8080/api/v1/endpoint/delete-from-spec-file/<projectId>?scanEndpoints=false

  deleteEndpoint(id, payload) {
    let params = new HttpParams();
    params = params.append("scanEndpoints", "false");
    return this.http.post(
      this.endPointURL + "/delete-from-spec-file/" + id,
      payload,
      {
        params,
      }
    );
  }
}
