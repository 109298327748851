import { Component, HostBinding, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'apisec-shimmer-loading',
  template: `
    <div
  class="apisec-shimmer"
  [ngStyle]="{
    width: width,
    height: shimmerHeight,
    borderRadius: shimmerBorderRadius
  }"  
></div>
  `,
  styleUrls: ['./shimmer-loader.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ShimmerLoaderComponent implements OnInit {
  @HostBinding('class') class = 'shimmer-loading';

  @Input() width = '80%';
  @Input() height = '12px';
  @Input() shape: 'circle' | 'square' | 'rect' = 'rect';
  @Input() borderRadius = '5px';
  constructor() { }

  ngOnInit(): void {
  }
  get shimmerHeight(): string {
    switch (this.shape) {
      case 'circle':
        return this.width;
      case 'square':
        return this.width;
      case 'rect':
        return this.height;
      default:
        return this.height;
    }
  }

  get shimmerBorderRadius(): string {
    return this.shape === 'circle' ? '50%' : this.borderRadius;
  }
}
