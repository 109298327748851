import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Handler } from "../../dialogs/handler/handler";
import { ProjectService } from "../../../services/project.service";
import { JobsService } from '../../../services/jobs.service';
import { RecommendationsLogsComponent } from '../../dialogs/recommendations-logs/recommendations-logs.component';



@Component({
  selector: 'app-recommendations-issues',
  templateUrl: './recommendations-issues.component.html',
  styleUrls: ['./recommendations-issues.component.scss'],
  providers: [ProjectService,  JobsService]
})
export class RecommendationsIssuesComponent implements OnInit {

  object;
  displayedColumns: string[] = ['name', 'status', 'logs'];
  dataSource = null;
  passed; failed;response;
  job;

  @ViewChild(MatSort) sort: MatSort;
   constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,private handler: Handler,private projectService: ProjectService,private jobsService: JobsService,private dialog: MatDialog,
    public dialogRef: MatDialogRef<RecommendationsIssuesComponent>
  ) { }
  ngOnInit() {
    this.object = this.data;
    this.dataSource = new MatTableDataSource(this.object.issues);
      this.dataSource.sort = this.sort;
  }

  openLogs(issueId: string) {
    this.handler.activateLoader();
    this.projectService.getTestSuiteResponseByIssueId(this.object.projectId, issueId).subscribe(results => {
        this.handler.hideLoader();
        if (this.handler.handle(results)) {
            return;
        }
        this.response = results['data'];
        this.passed = this.response.totalPassed;
        this.failed = this.response.totalFailed;
        this.showDialog(this.response.logs, this.response.testSuite);
    }, error => {
        this.handler.hideLoader();
        this.handler.error(error);
    });
}

showDialog(msg, suitName) {
  let dialogRef = this.dialog.open(RecommendationsLogsComponent, {
      width: '1200px',
      data: [msg, suitName, this.object.id,this.passed, this.failed]
  });
  dialogRef.afterClosed().subscribe(result => {
      localStorage.removeItem("suitename");
  });
}

openIssueTracker(jobId: string,issueId: string) {
  this.loadJob(jobId,issueId);
}

loadJob(jobId: string,issueId: string) {
  this.handler.activateLoader();
  this.jobsService.getById(jobId).subscribe(results => {
      this.handler.hideLoader();
      if (this.handler.handle(results)) {
          return;
      }
      this.job = results['data'];
      if (this.job.issueTracker.accountType == "GitHub") {
          window.open(this.job.issueTracker.url+"/issues/"+issueId, "_blank");
      } else if(this.job.issueTracker.accountType == "Bugzilla"){
          window.open(this.job.issueTracker.url+"/show_bug.cgi?id="+issueId, "_blank");
      } else {

      }
  }, error => {
      this.handler.hideLoader();
      this.handler.error(error);
  });

}

}
