export class Env {
    id: string;
    projectId: string;
    name: string;
    url: string;
    refId: string;
    baseUrl: string;
    auths: Auth[] = [];
    region: string;

}

export class Auth {
    name: string;
    inactive: boolean;
    invalid: boolean;
    authType: string;

    username: string;
    password: string;

    header_1: string;
    header_2: string;
    header_3: string;


    clientId: string;
    clientSecret: string;
    id: string;
    accessTokenUri: string;

    authorizationScheme: string;

    clientAuthenticationScheme: string;

    tokenName: string;
    scope: string;
    grantType: string;

    preEstablishedRedirectUri: string;
    useCurrentUri: string;
    userAuthorizationUri: string;
    preScript: string;

    //OAUTH2 variables
    curlHeaders: string;
    curlUrl: string;
    curlVariables: string;
    k1: string;
    v1: string;
    k2: string;
    v2: string;
    k3: string;
    v3: string;
    k4: string;
    v4: string;
    endpointCtrl: any;
    endpoint: any;
    method: any;
    k5: string;
    v5: string;

    // Token vars
    contentType: string;
    cmdCache: string;
    methodToken: string;
    tokenAccess: string;
}
export class endpointCtrl {
    method: string;
    endpoint: string
}


