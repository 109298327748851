import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackbarService } from '../../../services/snackbar.service';

@Component({
  selector: 'app-gradle-integration',
  templateUrl: './gradle-integration.component.html',
  styleUrls: ['./gradle-integration.component.scss'],
  providers: [SnackbarService]
})
export class GradleIntegrationComponent implements OnInit {

  constructor(private snackbarService: SnackbarService, @Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<GradleIntegrationComponent>) { }

// tslint:disable-next-line: no-empty
  ngOnInit() {
  }
  copyToClipboard(element) {
    const $temp = $('<input>');
    $('body').append($temp);
    $temp.val($(element).text()).select();
    document.execCommand('copy');
    this.snackbarService.openSnackBar('Copied text to clipboard ', '');
    $temp.remove();
  }

}
