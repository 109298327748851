import { Project } from "./project.model";

export class Jobs {
  name: string;
  cron: string;
  environment: EnvDto = new EnvDto();
  tags: string[] = [];
  regions: string = "";
  issueTracker: Dto = new Dto();
  notifications: Noti[] = [];
  nextFire: string;
  project: Project = new Project();
  categories: string;
  id: string;
  timeZone: string;
  logPolicy: string[] = [];
  applyNotificationtoAll: boolean;

}

export class Dto {
  id: string;
  name: string;
  url: string;
  accountType: string;
  account: string;
  labels: string;
  issueType: string;
  assigneeAccountId: string;
  projectKey: string;
  skipLogging: boolean;
  syncAll: boolean;
  folderName: string;
  inactive: boolean;
}

export class EnvDto {
  id: string;
  name: string;
  auths: Auth[] = [];
  baseUrl = "";
}

export class Noti {
  id: string;
  name: string;
  to: string;
  channel: string;
  account: string;
  skipNotification: boolean;
  notificationType: string;
}

export class Cron {
  exp: string;
  desc: string;

  constructor(e: string, d: string) {
    this.exp = e;
    this.desc = d;
  }
}

export class Auth {
  name: string;
  inactive: boolean;
  invalid: boolean;
  authType: string;

  username: string;
  password: string;

  header_1: string;
  header_2: string;
  header_3: string;

  // OAuth 2.0 properties
  clientId: string;
  clientSecret: string;
  id: string;
  accessTokenUri: string;

  authorizationScheme: string;

  clientAuthenticationScheme: string;

  tokenName: string;
  scope: string;
  grantType: string;

  preEstablishedRedirectUri: string;
  useCurrentUri: string;
  userAuthorizationUri: string;

}
