import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';


import { Org, OrgUser, Member } from '../models/org.model';
import { catchError } from 'rxjs/operators';


@Injectable()
export class OrgService {
  private serviceUrl = '/api/v1/orgs';
  private usersServiceUrl = '/api/v1/users';
  private teamsUrl = '/api/v1/teams'
  constructor(private http: HttpClient) {
  }

  getByUser() {
    return this.http.get(this.serviceUrl + "/by-user");
  }

  logout(){
    return this.http.get(this.usersServiceUrl+'/logout')
  }

  /**
   * Get the jobs in observable from endpoint
   */
  get(page, pageSize) {
    let params = new HttpParams();
    params = params.append('page', page);
    params = params.append('pageSize', pageSize);
    return this.http.get(this.serviceUrl, { params });
  }

  getOrgsByTag(orgTag, keyword, page, pageSize) {
    let params = new HttpParams();
    params = params.append('keyword', keyword);
    params = params.append('page', page);
    params = params.append('pageSize', pageSize);
    return this.http.get(this.serviceUrl + "/org-tag/" + orgTag, { params });
  }


  getById(id: string) {
    return this.http.get(this.serviceUrl + "/" + id);
  }

  create(org: Org) {
    return this.http.post(this.serviceUrl, org);
  }

  update(org: Org) {
    return this.http.put(this.serviceUrl + "/" + org['id'], org);
  }

  delete(org: Org) {
    return this.http.delete(this.serviceUrl + "/" + org['id']);
  }

  getOrgUsersById(id: string) {
    return this.http.get(this.serviceUrl + "/" + id + "/users");
  }

  getActiveOrgUsersById(id: string) {
    return this.http.get(this.serviceUrl + "/" + id + "/activeUsers");
  }

  getOrgUsersByIdByPage(id: string, page, pageSize) {
    let params = new HttpParams();
    params = params.append('page', page);
    params = params.append('pageSize', pageSize);
    return this.http.get(this.serviceUrl + "/" + id + "/users", { params });
  }
  getOrgUsersByIdByPageV2(id: string, page, pageSize, sort, sortType) {
    let params = new HttpParams();
    params = params.append('page', page);
    params = params.append('pageSize', pageSize);
    params = params.append("sort", sort);
    params = params.append("sortType", sortType);
    return this.http.get(this.serviceUrl + "/" + id + "/users", { params });
  }
  getOrgUsers(orgId: string, id: string) {
    return this.http.get(this.serviceUrl + "/" + orgId + "/org-user/" + id);
  }

  getLoggedInUser() {
    return this.http.get(this.serviceUrl + "/login-status");
  }

  createOrgUser(orgUser: OrgUser) {
    return this.http.post(this.serviceUrl + "/org-user", orgUser);
  }

  updateOrgUser(orgId: string, id: string, orgUser: OrgUser) {
    return this.http.put(this.serviceUrl + "/" + orgId + "/users/" + id, orgUser);
  }

  addMember(orgId: string, member: Member) {
    return this.http.post(this.serviceUrl + "/" + orgId + "/users/add-member", member);
  }

  addMembers(orgId: string, members: Member[]) {
    return this.http.post(this.serviceUrl + "/" + orgId + "/users/add-members", members);
  }

  removeMember(email) {
    let params = new HttpParams();
    params = params.append('email', email);
    return this.http.delete(this.serviceUrl + "/users/removeMemberFromOrg", { params });
  }
  resetPassword(orgId: string, userId: string, member: Member) {
    return this.http.post(this.serviceUrl + "/" + orgId + "/users/" + userId + "/reset-password", member);
  }

  resetMyPassword(orgId: string, userId: string, member: Member) {
    return this.http.post(this.serviceUrl + "/" + orgId + "/users/" + userId + "/reset-my-password", member);
  }

  // searchOrg(orgTag: string, keyword: string, page, pageSize) {
  //   let params = new HttpParams();
  //   params = params.append('orgTag', orgTag);
  //   params = params.append('keyword', keyword);
  //   params = params.append('page', page);
  //   params = params.append('pageSize', pageSize);
  //   return this.http.get(this.serviceUrl + "/search", { params });
  // }

  searchOrgUsers(orgId: string, keyword: string, page, pageSize) {
    let params = new HttpParams();
    params = params.append('keyword', keyword);
    params = params.append('page', page);
    params = params.append('pageSize', pageSize);
    return this.http.get(this.serviceUrl + "/org-user/search", { params });
  }
  searchOrgUsersWithSort(orgId: string, keyword: string, page, pageSize, sort, sortType) {
    let params = new HttpParams();
    params = params.append('keyword', keyword);
    params = params.append('page', page);
    params = params.append('pageSize', pageSize);
    params = params.append("sort", sort);
    params = params.append("sortType", sortType);
    return this.http.get(this.serviceUrl + "/org-user/search", { params });
  }
  adduser(member: Member) {
    return this.http.post(this.serviceUrl + "/users/addMemberToOrg", member);
  }

  getbyUserId(page, pageSize, sort, sortType) {
    let params = new HttpParams();
    params = params.append('page', page);
    params = params.append('pageSize', pageSize);
    params = params.append("sort", sort);
    params = params.append("sortType", sortType);
    return this.http.get(this.serviceUrl + "/user-orgs/access", { params });
  }

  updateMfa(mfa) {
    return this.http.put(this.usersServiceUrl + "/update-mfa", { "mfa": mfa });
  }

  sendMail() {
    return this.http.get(this.serviceUrl + "/sendMail");
  }

  //Opportunities
  getCustomers(page, pageSize) {
    let params = new HttpParams();
    params = params.append('page', page);
    params = params.append('pageSize', pageSize);
    return this.http.get(this.serviceUrl + "/org-tag/CUSTOMER", { params });
  }

  //Opportunities
  getOpportunity(page, pageSize) {
    let params = new HttpParams();
    params = params.append('page', page);
    params = params.append('pageSize', pageSize);
    return this.http.get(this.serviceUrl + "/org-tag/OPPORTUNITY", { params });
  }

  //getpartners
  getPartner(page, pageSize) {
    let params = new HttpParams();
    params = params.append('page', page);
    params = params.append('pageSize', pageSize);
    return this.http.get(this.serviceUrl + "/org-tag/PARTNER", { params });
  }

  //churn
  getChurn(page, pageSize) {
    let params = new HttpParams();
    params = params.append('page', page);
    params = params.append('pageSize', pageSize);
    return this.http.get(this.serviceUrl + "/org-tag/CHURN", { params });
  }

  //getprospect
  getProspect(page, pageSize) {
    let params = new HttpParams();
    params = params.append('page', page);
    params = params.append('pageSize', pageSize);
    return this.http.get(this.serviceUrl + "/org-tag/PROSPECT", { params });
  }

  updateOrg(org: Org) {
    return this.http.put(this.serviceUrl + "/" + org['id'] + "/update", org);
  }

  checkOrgMfa() {
    return this.http.get(this.serviceUrl + "/mfa-status");
  }
  createTeam(obj) {
    return this.http.post(this.teamsUrl, obj);
  }
  getTeam(page, pageSize, sort, sortType) {
    let params = new HttpParams();
    params = params.append('page', page);
    params = params.append('pageSize', pageSize);
    params = params.append("sort", sort);
    params = params.append("sortType", sortType);
    return this.http.get(this.teamsUrl, { params })
  }
  editTeam(id: string, data) {
    return this.http.put(this.teamsUrl + "/" + id, data)
  }

  // getTeamById(id: string) {
  //   return this.http.get(this.serviceUrl + "/" + id);
  // }

  getTeamById(id: string) {
    return this.http.get(this.teamsUrl + "/" + id)
  }
  getTeamSearch(keyword, page, pageSize, sort, sortType) {
    let params = new HttpParams();
    params = params.append('keyword', keyword);
    params = params.append('page', page);
    params = params.append('pageSize', pageSize);
    params = params.append("sort", sort);
    params = params.append("sortType", sortType);
    return this.http.get(this.teamsUrl + "/search/", { params })
  }

  deleteTeam(id: any) {
    return this.http.post(this.teamsUrl + "/" + id, "")
  }
}
