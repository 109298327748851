export class ProjectSync {

    deleteAutoGenerated: boolean;
    deleteCustom: boolean;
    projectId: string;
    categories = new Array<string>();
    recreate:boolean;
    createCategories = new Array<string>();
    stopTask:boolean;

}
