import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Saving, Setting } from './../models/system-setting.model';


@Injectable()
export class SystemSettingService {
private serviceUrl = '/api/v1/system-settings';
constructor(private http: HttpClient) {
  }
  getAll() {
    return this.http.get(this.serviceUrl);
  }

//  update(Setting:any) {
//     return this.http.put(this.serviceUrl, Setting);
//   }

saveAll(Setting: any){
  return this.http.post(this.serviceUrl+ "/" + "save", Setting);
}

}
