import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProjectService } from "../../../services/project.service";
import { Handler } from "../../dialogs/handler/handler";

@Component({
  selector: 'app-recommendations-comments',
  templateUrl: './recommendations-comments.component.html',
  styleUrls: ['./recommendations-comments.component.scss'],
  providers: [ProjectService]
})

export class RecommendationsCommentsComponent implements OnInit {

  autoSuggestion: any;
  falsePositiveChecked: boolean;
  id: string;
  result: Array<boolean> = new Array();
  activateChecked: boolean;
  togglevalue;
  comments;

  constructor(public dialogRef: MatDialogRef<RecommendationsCommentsComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    private projectService: ProjectService, private handler: Handler) {
    this.autoSuggestion = data[0];
    // this.falsePositiveChecked = data[1];
    this.id = data[1];
    this.togglevalue = data[2];
  }

  ngOnInit() {
    this.comments = this.autoSuggestion.comment;
    this.falsePositiveChecked = !this.autoSuggestion['falsePositive']
  }

  saveAndClose() {
    if(this.falsePositiveChecked != undefined){
        this.autoSuggestion.falsePositive = this.falsePositiveChecked;
    }
    this.autoSuggestion.comment = this.comments ;
    this.saveAutoSuggestion();
    this.result[0] = true;
    this.result[1] = this.activateChecked;
    this.dialogRef.close(this.result);
  }

  saveAutoSuggestion() {
    this.handler.activateLoader();
    this.projectService.updateAutoSuggestion(this.id, this.autoSuggestion).subscribe(results => {
      this.handler.hideLoader();
      if (this.handler.handle(results)) {
        return;
      }
      this.autoSuggestion = results['data'];
      this.autoSuggestion.falsePositive = true;
    }, error => {
      this.handler.hideLoader();
      this.handler.error(error);
    });
  }

  closeWithoutSave() {
    if (this.togglevalue == "active") {
      this.autoSuggestion.falsePositive = false;
    }
    else {
      if (this.falsePositiveChecked == true) {
        this.autoSuggestion.falsePositive = !this.falsePositiveChecked;
      }
      if (this.falsePositiveChecked == false) {
        this.autoSuggestion.falsePositive = !this.falsePositiveChecked;
      }
    }
    this.result[0] = false;
    this.dialogRef.close(this.result);
  }


}
