export class SubscriptionTier {
  orgFreePlan: string;
  orgEntPlan: string;
  availableFreeLicense: number;
  purchaseFreeLicense: number;
  availableEntLicense: number;
  purchaseEntLicense: number;
  usedFreeLicense: number;
  usedEntLicense: number;
  utilizedEndpoints: number;
}
