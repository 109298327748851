export class AutoCodeConfig {
    openAPISpec: string;
    generators: Categories[] = [];
    totalEndpoints: number;
}

export class Categories {
    type: string;
    assertions: string[] = [];
    assertionsText: string;
    severity: string;
    cvssScoreType: string;
    cvssScore: string;
    inactive: boolean;
    authors: string;
    researchers: string;
    fixHours: string;
    displayHeaderLabel: string;
    displayHeaderDescription: string;
    assertionDescription: string;
    postfix: string;
    match: Matches[] = [];
    database: Database = new Database();
    currentScripts: number;
    expectedScripts: number;

}

export class Matches {
    name: string;
    pathPatterns: string;
    methods: string;
    denyRoles: string;
    allowRoles: string;
    resourceSamples: string;
    queryParams: string;
    bodyProperties: string;
}

export class Database {
    name: string;
    version: number;
}

export class AbacResource {
    generatorId: string;
    resourceName: string = "";
    initScriptName: string = "";
    createUserAuth: string = "";
    createEndpoint: string = "";
    createBody: string;
    deleteEndpoint: string = "";
    typeThreeCreateEndpoint: string;
    validations: AbacValidation[] = [];
    enumValues: string = "";
    lock: boolean;
    inactive: boolean;
    id: string;
    scripts: AbacScripts[] = [];

}

export class AbacValidation {

    userAuth: string = "";
    endpoint: string = "";
    path: string;
    body: string;
    validationType: string = ""; //(Allow/Disallow)
    inactive: boolean;

}

export class AbacScripts {
    body: string = "";
    deleteEndPoint: string = "";
    endpoint: string = "";
    resourceName: string = "";
    scriptName: string = "";
    scriptType: string = "";
    sequence: number;
    userAuth: string = "";
    validationScript: boolean;
}

export class FileData {
    host: string = "";
    swagger: string = "";
    license: string = "";
    info: Info = new Info();
}

export class Info {
    title: string = "";
    version: string = "";
    contact: Contact = new Contact();
}

export class Contact {
    name: string = "";
    email: string = "";
}