import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute} from "@angular/router";
import { OrgService } from '../../../services/org.service';
import { Org, OrgUser, Member } from '../../../models/org.model';
import { Handler } from '../../dialogs/handler/handler';
import {SnackbarService}from '../../../services/snackbar.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-user-add',
  templateUrl: './user-add.component.html',
  styleUrls: ['./user-add.component.scss'],
  providers: [OrgService, SnackbarService]

})
export class UserAddComponent implements OnInit {

  member: Member = new Member();
  org: Org = new Org();
  orgUser: OrgUser = new OrgUser();
  orgId;
  firstFormGroup: FormGroup;
  constructor(private orgService: OrgService, private route: ActivatedRoute, private router: Router,
     private handler: Handler, private snackbarService: SnackbarService, private _formBuilder: FormBuilder) { }

  ngOnInit() {
    this.firstFormGroup = this._formBuilder.group({
      roleCtrl:['', Validators.required],
      emailCtrl:['',  [Validators.required,  Validators.pattern("[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,4}$")]]
    });
    this.route.params.subscribe(params => {
      if (params['orgId']) {
        this.orgId = params['orgId']
        this.getOrgById(params['orgId']);
      }
    });
  }

  getOrgById(id: string) {
    this.handler.activateLoader();
    this.orgService.getById(id).subscribe(results => {
      this.handler.hideLoader();
      if (this.handler.handle(results)) {
        return;
      }
      this.org = results['data'];
    }, error => {
      this.handler.hideLoader();
      this.handler.error(error);
    });
  }

  create() {
    this.handler.activateLoader();
    this.snackbarService.openSnackBar("'" + this.member.email + "' adding...", "");
    this.member.orgId = this.org.id;
    this.orgService.adduser(this.member).subscribe(results => {
      this.handler.hideLoader();
      if (this.handler.handle(results)) {
        return;
      }
      this.snackbarService.openSnackBar("'" + this.member.email + "' added successfully", "");
      this.router.navigate(['/app/orgs-users/orgs', this.org.id, 'users']);
    }, error => {
      this.handler.hideLoader();
      this.handler.error(error);
    });
  }

  roles = ['BASIC','USER', 'PROJECT_MANAGER', 'ADMIN'];
}
