import {ActivatedRouteSnapshot, CanActivate, NavigationExtras, Router, RouterStateSnapshot, UrlTree} from "@angular/router";
import {Observable} from "rxjs/Rx";
import {Injectable} from "@angular/core";
import { AlertDialogComponent } from "../../components/dialogs/alert-dialog/alert-dialog.component";
import { UpgradeComponent } from "../../components/dialogs/upgrade/upgrade.component";
import { MatDialog } from "@angular/material/dialog";

@Injectable({
    providedIn:'root'
})
export class AuthGuard implements CanActivate{
    returnUrl: string="";
    constructor(private router:Router, private dialog: MatDialog){}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
   
        this.returnUrl=state.url
        // logged in so return true
        if(localStorage.getItem('apisecT_1'))
        return true;
        else{
           // not logged in so redirect to login page with the return url and return false
            this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
            return false;
        }

    }

    getToken(){
        return localStorage.getItem('apisecT_1');
    }

    canDeactivate() {
   
        // this.returnUrl=state.url
        const resetPwdValue=localStorage.getItem('resetPassword');
        if(resetPwdValue =='true'){
            const dialogRef = this.dialog.open(AlertDialogComponent, {
                width: "580px",
                height: "225px",
                data: ["", "We recommend updating your temporary password to ensure account security."]
            });
        
            return false;
        }else 
        return true;
        

    }

    

}