export const CATEGORIES = {
  categories: [
    "Unsecured",
    "InvalidAuth",
    "InvalidAuthEmpty",
    "InvalidAuthSQL",
    "RBAC",
    "ABAC_Level1",
    "ABAC_Level2",
    "ABAC_Level3",
    "ABAC_Level4",
    "ABAC_Level5",
    "ABAC_Level6",
    "ABAC_Level7",
    "Hijack_Level1",
    "Sensitive_Data_Exposure",
    "ADoS",
    "XSS_Injection",
    "Windows_Command_Injection_Filter",
    "Windows_Command_Injection",
    "Linux_Command_Injection_Filter",
    "Linux_Command_Injection",
    "SQL_Injection_Filter",
    "SQL_Injection",
    "SQL_Injection2",
    "NoSQL_Injection_Filter",
    "SLA",
    "Invalid_Data",
    "SimpleGET",
    "Special_Chars",
    "Null_Value",
    "Empty_Value",
    "Negative_Number"
  ]
};
export const CATEGORIES_TYPE_COUNT = {
  CATEGORIES: [
    {
      category: "Unsecured",
      label: "Unsecured Endpoint",
      count: "0"
    },
    {
      category: "InvalidAuth",
      label: "Authentication Exploit",
      count: "0"
    },
    {
      category: "InvalidAuthEmpty",
      label: "Authentication Exploit (Empty)",
      count: "0"
    },
    {
      category: "InvalidAuthSQL",
      label: "Authentication Exploit (SQL)",
      count: "0"
    },
    {
      category: "RBAC",
      label: "Role-based-access-control",
      count: "0"
    },
    {
      category: "ABAC_Level1",
      label: "Attribute-based-access-control 1",
      count: "0"
    },
    {
      category: "ABAC_Level2",
      label: "Attribute-based-access-control 2",
      count: "0"
    },
    {
      category: "ABAC_Level3",
      label: "Attribute-based-access-control 3",
      count: "0"
    },
    {
      category: "ABAC_Level4",
      label: "Attribute-based-access-control 4",
      count: "0"
    },
    {
      category: "ABAC_Level5",
      label: "Attribute-based-access-control 5",
      count: "0"
    },
    {
      category: "ABAC_Level6",
      label: "Attribute-based-access-control 6",
      count: "0"
    },
    {
      category: "ABAC_Level7",
      label: "Attribute-based-access-control 7",
      count: "0"
    },
    {
      category: "Hijack_Level1",
      label: "Hijack (Level1)",
      count: "0"
    },
    {
      category: "Sensitive_Data_Exposure",
      label: "Sensitive data exposure",
      count: "0"
    },
    { category: "ADoS", label: "ADoS", count: "0" },
    // {
    //   category: "XSS_Injection_Filter",
    //   label: "Stored XSS",
    //   count: "0"
    // },
    { "category": "XSS_Injection", "label": "Cross Site Scripting Injection (Level2)", "count": "0" },
    {
      category: "Windows_Command_Injection_Filter",
      label: "Stored Windows Injection",
      count: "0"
    },
    {
      category: "Windows_Command_Injection",
      label: "Windows Injection",
      count: "0"
    },
    {
      category: "Linux_Command_Injection_Filter",
      label: "Stored Linux Injection",
      count: "0"
    },
    {
      category: "Linux_Command_Injection",
      label: "Linux Injection",
      count: "0"
    },
    {
      category: "SQL_Injection_Filter",
      label: "Stored SQL Injection",
      count: "0"
    },
    {
      category: "SQL_Injection",
      label: "SQL Injection",
      count: "0"
    },
    {
      category: "SQL_Injection2",
      label: "SQL Injection2",
      count: "0"
    },
    {
      category: "NoSQL_Injection_Filter",
      label: "Stored NoSQL Injection",
      count: "0"
    },
    {
      category: "NoSQL_Injection",
      label: "NoSQL Injection",
      count: "0"
    },
    { category: "SLA", label: "Performance SLA", count: "0" },
    {
      category: "Invalid_Data",
      label: "Invalid Data Type (Fuzz)",
      count: "0"
    },
    {
      category: "SimpleGET",
      label: "Simple GET (Fuzz)",
      count: "0"
    },
    {
      category: "Special_Chars",
      label: "Special Characters (Fuzz)",
      count: "0"
    },
    {
      category: "Null_Value",
      label: "Null Value (Fuzz)",
      count: "0"
    },
    {
      category: "Empty_Value",
      label: "Empty Value (Fuzz)",
      count: "0"
    },
    {
      category: "Negative_Number",
      label: "Negative Number (Fuzz)",
      count: "0"
    },
    { category: "Custom", label: "Custom", count: "0" }
  ]
};
export const CATEGORIES_TYPE = {
  displayHeaderLabel: [
    "Unsecured Endpoint",
    "Authentication Exploit",
    "Authentication Exploit (Empty)",
    "Authentication Exploit (SQL)",
    "RBAC",
    "Attribute-based-access-control 1",
    "Attribute-based-access-control 2",
    "Attribute-based-access-control 3",
    "Attribute-based-access-control 4",
    "Attribute-based-access-control 5",
    "Attribute-based-access-control 6",
    "Attribute-based-access-control 7",
    "Hijack (Level1)",
    "Sensitive data exposure",
    "ADoS",
    "Stored XSS",
    //  "Cross Site Scripting Injection (Level2)",
    "Stored Windows Injection",
    "Windows Injection",
    "Stored Linux Injection",
    "Linux Injection",
    "Stored SQL Injection",
    "SQL Injection",
    "SQL Injection2",
    "Stored NoSQL Injection",
    "NoSQL Injection",
    "Performance SLA",
    "Invalid Data Type (Fuzz)",
    "Simple GET (Fuzz)",
    "Special Characters (Fuzz)",
    "Null Value (Fuzz)",
    "Empty Value (Fuzz)",
    "Negative Number (Fuzz)",
    "Custom"
  ],
  type: [
    "anonymous_invalid",
    "auth_invalid",
    "auth_invalid_empty",
    "auth_invalid_sql",
    "Role-based-access-control",
    "abac",
    "abact2",
    "abact3",
    "abact1positive",
    "abact2positive",
    "abact3positive",
    "abact7",
    "hijack1",
    "sensitive_data_exposure",
    "ADoS",
    "XSS_injection_filter",
    "XSS_Injection",
    "windows_command_injection_filter",
    "Windows_Command_Injection",
    "Linux_command_injection_filter",
    "Linux_Command_Injection",
    "sql_injection_timebound_filter",
    "sql_injection_timebound",
    "sql_injection2_timebound",
    "no_sql_injection_timebound_filter",
    "no_sql_injection_timebound",
    "sla",
    "invalid_datatype",
    "no_params",
    "special_chars",
    "null_value",
    "empty_value",
    "negative_number",
    "Custom"
  ]
};
export const globalStorage = {
  FirstEnv_Global: "",
  Env_Global: "",
  FirstScan_Global: "",
  Scan_Global: ""
};
export const globalStorageScan = { Scan_Global: "" };
export const selectPageSize = { pageSize_Global: "" };
export const selectedActPage = { actPageSize_Global: "" };
export const selectedPages: any = { selPages_Global: {} };
export const recentSearchCat: any = { recent_global: "", recentSrc_global: "" };
export const searchResult: any = { Result: {} };
export const GlobalSelectedEnv = { envId: "" };
export const searchCatResult: any = { catResult: {} };
export const ELASTICSEARCHVALIDATOR = {
  searchRegex: /[{(\s:\/\(\)\-\^)}]/gi,
  badKeyCodes: [
    9,
    16,
    17,
    18,
    20,
    33,
    34,
    35,
    36,
    37,
    38,
    39,
    40,
    91,
    191,
    220
  ],
  isValidSearchKeyCode(keycode: string) {
    if (this.badKeyCodes.indexOf(keycode) < 0) return true;
    else return false;
  },

  checkSearchString(searchString: string) {
    return searchString.replace(this.searchRegex, "");
  }
  // tab - 9
  // cntrl - 17
  // alt - 18
  // shft - 16
  // arrows - 37,38,39,40
  // home,end,pageup,pagedown - 33,34,35,36
  // caps lock - 20
  // cap -54
  // left windows key - 91
  // forwardslash - 191
  // backwardslash - 220
};

export const GlobalUserName = {
  userName: ''
};

export const GlobalPlaybookFlag = {
  playbookListFlag: '',
  playbookData: ''
};

export const LoggedInUserOrgId = {
  orgId: ''
};

export const GlobalProjectDetails = {
  name: '',
  id: ''
};

export const GlobalSecurityModelList = {
  scope: 'PUBLIC'
};

export const loggedInUserDetail = {
  orgName: '',
  userRole: '',
  userEmail: ''
}

export const viaProjectList = {
  route: ''
};

export const recentProfile = {
  profileId: ''
};
