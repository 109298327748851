import {
  Component,
  Inject,
  OnInit,
  Output,
  ViewChild,
  EventEmitter,
  ElementRef,
  HostListener,
} from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { ProjectService } from "../../../services/project.service";
import { Handler } from "../handler/handler";
import { Project } from "../../../models/project.model";
import { FormBuilder, FormControl } from "@angular/forms";
import { DataTransferService } from "../../../services/data-transfer.service";
import { ResourceService } from "../../../services/resource.service";
import { Observable } from "rxjs";
import { SnackbarService } from "../../../services/snackbar.service";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger,
} from "@angular/material/autocomplete";
import { map, startWith } from "rxjs/operators";
import mixpanel from "mixpanel-browser";
import { MixpanelService } from "../../../services/mixpanel.service";
import { MatChipInputEvent } from "@angular/material/chips";
import { MatSort } from "@angular/material/sort";
import { Console } from "console";
import { Subject } from 'rxjs';
import { ELASTICSEARCHVALIDATOR} from "../../../shared/shared.data";
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: "manage-global-skip-endpoint",
  templateUrl: "./manage-global-skip-endpoint.component.html",
  styleUrls: ["./manage-global-skip-endpoint.component.scss"],
  providers: [SnackbarService, ProjectService],
})
export class ManageGlobalSkipEndpointComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @Output() emitData = new EventEmitter<string>();
  project: Project = new Project();
  projectId: string;
  endpointDataArray: EndpointData[] = [];
  keyword: string;
  allSelectedFlag: boolean = false;
  fewSelectedFlag: boolean = false;
  // fewSelectedData = [];
  displayedColumns: string[] = [
    "skipFlag",
    "ProjectName",
    "method",
    "endpoint",
  ];
  pageSize = 10;
  page = 0;
  sortString: string = "createdDate";
  sortType: string = "DESC";
  tag: string = "";
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  allSelect: boolean = false;
  regexdata1;
  endpointId;
  generatorType = [];
  skipEndpointList;
  selectedEndpoint: any;
  skipEndpoint;
  userSelectedprojectId = [];
  totalEndpoints: number;
  projectData: [];
  projectListData: any;
  categoriesList: any;
  endpointView: any;
  apiPath: any;
  method: any;
  modifiedData: any;
  projectIds: any;
  projectLength;
  projectLength1;
  projectListData1 = null;
  projectname: any;
  ds: any;
  generatorData: any;
  selectedCategoryValue: any;
  selectedEndpoints: any[] = [];
  Selectedpage;
  SelectedEndpointpageSize: any;
  // length: number;
  setCategory: any;
  allComplete: boolean = false;
  intermediateFlag: boolean = false;
  selectedGeneratorType = [];
  selectedGeneratorTypesFromDropdown: string[] = [];
  projectList;
  regexdata: any;
  endpointData: any;
  dataSource: MatTableDataSource<any>;
  searchControl = new FormControl();
  // filteredProjects$: Observable<any[]>;
  tagsControl = new FormControl([]);
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  filteredTags: Observable<string[]>;
  resourceName;
  @ViewChild("auto", { static: false }) matAutocomplete: MatAutocomplete;

  // Multi select code start
  selectableTag = true;
  removableTag = true;
  separatorKeysCodesTag: number[] = [ENTER, COMMA];
  allfilteredTags: Observable<string[]>;
  newtags: string[] = [];
  newallTags: string[] = [];
  @ViewChild("tInputTag") tInputTag: ElementRef<HTMLInputElement>;
  //   @ViewChild('auto') matAutocomplete: MatAutocomplete;
  @ViewChild("autocompleteTrigger") matACTrigger: MatAutocompleteTrigger;
  firstFormGroup: any;
  searchStringValidator = ELASTICSEARCHVALIDATOR;

  constructor(
    private _formBuilder: FormBuilder,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private handler: Handler,
    public dialogRef: MatDialogRef<ManageGlobalSkipEndpointComponent>,
    private projectService: ProjectService,
    private resourcesService: ResourceService,
    private snackbarService: SnackbarService,
    public dataTransfer: DataTransferService
  ) {}

  ngOnInit() {
    this.getAllProjects();
    // this.regexSearch();
    this.firstFormGroup = this._formBuilder.group({
      tagsControl: [this.project.tags || []],
    });

    this.subjectKeyUp.pipe(debounceTime(700)).subscribe((a) => {
        this.regexSearch();
      });
  }
  // Select project service call
  getAllProjects() {
    this.projectService.getAllProjectsList().subscribe(
      (results) => {
        this.projectLength1 = results["totalElements"];
        this.projectListData = results["data"];
        this.projectListData.sort((a, b) => a.name.localeCompare(b.name));
        this.projectId = this.projectListData[0].id;
        this.newallTags = this.projectListData.map((e) => e.name);
        this.allfilteredTags = this.firstFormGroup.controls[
          "tagsControl"
        ].valueChanges.pipe(
          startWith(null),
          map((tag: string | null) =>
            tag ? this._filterr(tag) : this.newallTags.slice()
          )
        );
        this.projectListData1 = JSON.parse(
          JSON.stringify(this.projectListData)
        );
        // this.projectListData1 = new MatTableDataSource(this.projectListData);
        // this.projectListData1.sort = this.sort;
        // if (this.projectListData != null && this.projectListData.length > 0) {
        //     this.userSelectedprojectId = this.projectListData.map(item => item.id);
        // }
        // this.selectionChangeCreate();
        this.selectionCategoriesChange();
        this.selectCategories();
      },
      (error) => {
        this.handler.hideLoader();
        this.handler.error(error);
      }
    );
  }

  // Select project from dropdown
  selectPIds = [];
  selectionChangeCreate() {
    if (this.userSelectedprojectId.length == this.projectListData.length) {
      this.allComplete = true;
      this.intermediateFlag = false;
    } else if (
      this.userSelectedprojectId.length != 0 &&
      this.userSelectedprojectId.length < this.projectListData.length
    ) {
      this.intermediateFlag = true;
      this.allComplete = false;
    } else {
      this.allComplete = false;
      this.intermediateFlag = false;
    }
    this.regexSearch();
  }
  selectionCategoriesChange() {
    if (
      this.selectedGeneratorTypesFromDropdown.length ==
      this.projectListData.length
    ) {
      this.allCompleteCat = true;
      this.intermediateFlagCat = false;
    } else if (
      this.selectedGeneratorTypesFromDropdown.length != 0 &&
      this.userSelectedprojectId.length < this.ds.length
    ) {
      this.intermediateFlagCat = true;
      this.allCompleteCat = false;
    } else {
      this.allCompleteCat = false;
      this.intermediateFlagCat = false;
    }
    // this.regexSearch();
  }

  // this code is for pagination
  change(evt) {
    this.Selectedpage = evt["pageIndex"];
    this.SelectedEndpointpageSize = evt.pageSize;
    this.regexSearch();
  }

  flag = 0;
  disabledElements = [];
  subjectKeyUp = new Subject<any>();
  searchEndpointsTable($event){
    const key = $event.keyCode;
    if (this.searchStringValidator.isValidSearchKeyCode(key)) {
      this.keyword = $event.target.value;
      this.subjectKeyUp.next(this.keyword);
    }
  }
  // Search regular expression service call
  regexSearch() {
    this.flag = 0;
    if (!this.keyword || this.keyword.length == 0) {
      this.regexdata = [];
      this.modifiedData = [];
      this.regexdata1 = [];
      return;
    }
    if (this.keyword.length >= 3) {
      this.resourcesService
        .getOrgEndpointsRegex(this.keyword, this.userSelectedprojectId)
        .subscribe(
          (results) => {
            this.regexdata = results["data"];
            this.projectLength = results["totalElements"];
            // this.regexdata.forEach((obj1) => {
            //   const matchingObj = this.selectedEndpoints.find(
            //     (obj2) => obj1[6] === obj2.id
            //   );
            //   if (matchingObj) {
            //     obj1[8] = true;
            //   }
            //   console.log("matchingObj",matchingObj)
            // });
            this.selectedEndpoints =[];
            this.allSelect = false;
            this.regexdata.forEach((element)=>{
                if(element[8]==true)
                     element.isDisabled = true;
            });
            this.regexdata1 = new MatTableDataSource(this.regexdata);
            // this.modifiedData = this.regexdata.map((item) => {
            //   this.endpointView = item[0] + item[1];
            //   this.apiPath = item[0];
            //   this.method = item[1];
            // });
            // if (this.flag == 0) {
            //   for (let e of this.regexdata) {
            //     e.isDisabled = e[8];
            //     if (e.isDisabled == true) this.disabledElements.push(e);
            //   }
            //   this.flag++;
            // } else {
              // this.regexdata.forEach((obj1) => {
              //   const matchingObj = this.selectedEndpoints.find(
              //     (obj2) => obj1[6] === obj2.id
              //   );
                
              //   const ele2 = this.disabledElements.find(
              //     (obj2) => obj1[6] === obj2[6]
              //   );
              //   if (matchingObj) {
              //     obj1.isDisabled = false;
              //   }
              //   if (ele2) obj1.isDisabled = true;
              // });
            // }
          },
          (error) => {
            this.handler.hideLoader();
            this.handler.error(error);
          }
        );
    }
  }
  // select generator type or categories service call
  selectCategories() {
    this.projectService.getAutoCodeConfig(this.projectId).subscribe(
      (results) => {
        this.categoriesList = results["data"];
        this.ds = this.categoriesList.generators;
        // this.selectedCategoryValue = this.ds.value
        this.generatorType = this.ds.map((item) => {
          this.selectedGeneratorType.push(item.type);
          // this.selectedGeneratorType = item.type
        });
      },
      (error) => {
        this.handler.hideLoader();
        this.handler.error(error);
      }
    );
  }

  selectGeneratorType(event: any, generatorData: any) {
    if (event) {
      const generatorType = generatorData.type;
      if (!this.selectedGeneratorTypesFromDropdown.includes(generatorType)) {
        this.selectedGeneratorTypesFromDropdown.push(generatorType);
      } else {
        this.selectedGeneratorTypesFromDropdown =
          this.selectedGeneratorTypesFromDropdown.filter(
            (type) => type !== generatorType
          );
      }
    } else {
      this.selectedGeneratorTypesFromDropdown = [];
    }
  }
  allCompleteCat: boolean = false;
  intermediateFlagCat: boolean = false;
  art: boolean = false;
  // selectAllCat(checked: boolean) {
  //     if (checked) {
  //         this.selectedGeneratorTypesFromDropdown = this.ds.map((item) => item.type);
  //         this.allCompleteCat = true;
  //         console.log('Selected Generator Types:', this.selectedGeneratorTypesFromDropdown);
  //         this.art = true;
  //     } else {

  //         this.allCompleteCat = false;
  //         this.selectedGeneratorTypesFromDropdown = [];
  //         this.art = true;
  //         // this.selectCat = false;
  //     }
  // }
  selectAllCat(event: any) {
    this.selectedGeneratorTypesFromDropdown = [];
    if (event.checked) {
      this.allCompleteCat = true;
      this.intermediateFlagCat = false;
      this.selectedGeneratorTypesFromDropdown = this.ds.map(
        (item) => item.type
      );
    } else {
      this.allCompleteCat = false;
    }
  }

  // table select individual endpoint code begins here
  selectedEndPoint(event) {
    let totalSelected = this.projectListData.filter((i) => i.skipFlag).length;

    if (totalSelected == 0) {
      this.allSelect = false;
      this.fewSelectedFlag = false;
    } else if (
      totalSelected > 0 &&
      totalSelected < this.projectListData.length
    ) {
      this.allSelect = false;
      this.fewSelectedFlag = true;
    } else if (
      totalSelected > 0 &&
      totalSelected == this.projectListData.length
    ) {
      this.allSelect = true;
      this.fewSelectedFlag = false;
    }
  }

  // Select Single endpoints code begins here
  selectSingleEndPoint(endpoint: any, event) {
    this.endpointData = {
      projectId: endpoint[5],
      endpoint: endpoint[0],
      method: endpoint[1],
      generatorTypes: this.selectedGeneratorTypesFromDropdown.join(","),
      id: endpoint[6],
      // generatorTypesLabels: data.generatorTypesLabels.join(",")
    };

    if (event.checked) {
      this.selectedEndpoints.push(this.endpointData); // Add the selected endpoint to the list
      if (endpoint.isDisabled == false) endpoint[8] = true;
      for(let element of this.regexdata){
        if(element[6] == endpoint[6]){
           element[8] = true;
        }
      }
    } else {
      this.selectedEndpoints = this.selectedEndpoints.filter(
        (selected) =>
          selected.projectId !== this.endpointData.projectId ||
          selected.method !== this.endpointData.method ||
          selected.endpoint !== this.endpointData.endpoint
      ); // Remove the deselected endpoint from the list
      if (endpoint.isDisabled == true) endpoint[8] = false;

      for(let element of this.regexdata){
        if(element[6] == endpoint[6]){
           element[8] = false;
        }
      }
    }
    if(this.selectedEndpoints.length == this.regexdata.length){
      this.allSelect = true;
      this.fewSelectedFlag = false;
    }
    else if(this.selectedEndpoints.length>0 && this.selectedEndpoints.length < this.regexdata.length) {
       this.allSelect = false;
       this.fewSelectedFlag = true;
    }
    else if(this.selectedEndpoints.length == 0){
      this.allSelect = false;
      this.fewSelectedFlag = false;
    }
  }

  // table select all method is below
  fewSelectedData;
  newDatais;

  selectAllEndPoints(event) {
    this.selectedEndpoints = []; // Clear the selectedEndpoints array first
    // for(let e of this.regexdata){
    //     e.isDisabled = e[8]
    // }
    if (event.checked) {
      this.allSelect = true;
      this.fewSelectedFlag = false;
      for (let element of this.regexdata) {
        if (
          element.isDisabled == false ||
          !element.hasOwnProperty("isDisabled")
        )
          element[8] = true;
        this.selectedEndpoints.push({
          projectId: element[5],
          endpoint: element[0],
          method: element[1],
          generatorTypes: this.selectedGeneratorTypesFromDropdown.join(","),
          id: element[6],
        });
      }
    } else {
      this.allSelect = false;
      for (let element of this.regexdata) {
        if (
          element.isDisabled == false ||
          !element.hasOwnProperty("isDisabled")
        )
          element[8] = false;
      }
    }
  }

  // bgcolor for table rows code begins here
  getColorForTableRows(method: string): string {
    if (method === "GET") {
      return "callout-info";
    } else if (method === "DELETE") {
      return "callout-danger";
    } else if (method === "POST") {
      return "callout-success";
    } else if (method === "PUT") {
      return "callout-warning";
    } else {
      return "";
    }
  }

  // Apply changes to skip endpoint code begins here
  applyChanges() {
    if (this.selectedGeneratorTypesFromDropdown.length === 0) {
      alert("Please select a category before applying changes.");
      return;
    } else if (
      this.selectedGeneratorTypesFromDropdown &&
      this.selectedGeneratorTypesFromDropdown.length > 0
    ) {
      this.selectedEndpoints.map((e) => {
        e.generatorTypes = this.selectedGeneratorTypesFromDropdown.toString();
      });
      this.handler.activateLoader();
      this.snackbarService.openSnackBar(
        "' Skipping the selected Endpoints ..",
        ""
      );
      this.resourcesService
        .skipEndpoint(this.selectedEndpoints)
        .subscribe((result) => {
          this.handler.hideLoader();
          this.skipEndpointList = result["data"];

          this.snackbarService.openSnackBar(
            "Endpoints Successfully Skipped ",
            ""
          );
        });

      setTimeout(() => {
        this.dialogRef.close();
      }, 2000);
    }
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    // Add our tag
    if ((value || "").trim()) {
      this.newtags.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = "";
    }
    this.allfilteredTags = this.firstFormGroup.controls[
      "tagsControl"
    ].valueChanges.pipe(
      startWith(null),
      map((tag: string | null) =>
        tag ? this._filterr(tag) : this.newallTags.slice()
      )
    );
  }

  remove(tag: string): void {
    const index = this.newtags.indexOf(tag);
    const indexToRemove = this.userSelectedprojectId.indexOf(tag);
    if (index >= 0) {
      this.newtags.splice(index, 1);
    }
    this.userSelectedprojectId.splice(indexToRemove, 1);
    this.regexSearch();
  }
  selectedTag(event: MatAutocompleteSelectedEvent): void {
    const newValue = event.option.viewValue;
    if (this.newtags.includes(newValue)) {
      this.newtags = [...this.newtags.filter((tag) => tag !== newValue)];
    } else {
      this.newtags.push(event.option.viewValue);
    }
    this.tInputTag.nativeElement.value = "";
    requestAnimationFrame(() => {
      this.openAuto(this.matACTrigger);
    });
    this.userSelectedprojectId = [];
    this.projectListData.forEach((element) => {
      if (this.newtags.includes(element.name)) {
        this.userSelectedprojectId.push(element.id);
      }
    });

    this.regexSearch();
  }

  private _filterr(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.newallTags.filter(
      (tag) => tag.toLowerCase().indexOf(filterValue) >= 0
    );
  }

  openAuto(trigger: MatAutocompleteTrigger) {
    trigger.openPanel();
    this.tInputTag.nativeElement.focus();
  }

  // Select all project from dropdown
  selectAllProject(event) {
    if (event) {
      // this.regexSearch();
      this.userSelectedprojectId = this.projectListData.map((item) => {
        return item.id;
      });
      this.allComplete = true;
      this.tmpp = this.userSelectedprojectId;
    } else {
      this.allComplete = false;
      this.userSelectedprojectId = [];
      this.tmpp = this.userSelectedprojectId;
    }
  }

  filterValue: any = "";
  filterProjects(value) {
    this.filterValue = value.trim().toLowerCase();
    this.projectListData = this.projectListData1.filter((option) =>
      option.name.toLowerCase().includes(this.filterValue)
    );
    this.userSelectedprojectId = this.tmpp;
  }

  tmpp = [];
  searchTerm = "";
  onProjectSelection(e) {
    if (e.source.selected && e.isUserInput) {
      this.userSelectedprojectId.push(e.source.value);
      this.tmpp = this.userSelectedprojectId;
    }

    if (!e.source.selected && e.isUserInput) {
      this.userSelectedprojectId.splice(
        this.userSelectedprojectId.indexOf(e.source.value),
        1
      );
      if (this.tmpp.indexOf(e.source.value) >= 0)
        this.tmpp.splice(this.tmpp.indexOf(e.source.value), 1);
    }

    if (e.isUserInput) this.userSelectedprojectId = this.tmpp;

    if (this.userSelectedprojectId.length == this.projectListData.length) {
      this.allComplete = true;
      this.intermediateFlag = false;
    } else if (
      this.userSelectedprojectId.length != 0 &&
      this.userSelectedprojectId.length < this.projectListData.length
    ) {
      this.intermediateFlag = true;
      this.allComplete = false;
      //   this.userSelectedprojectId = [];
    } else {
      this.allComplete = false;
      this.intermediateFlag = false;
    }
  }
}
interface EndpointData {
  projectId: string;
  endpointId: string;
  generatorTypes: any;
}
