import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProjectService } from "../../../services/project.service";
import { Handler } from "../../dialogs/handler/handler";
import { SnackbarService } from '../../../services/snackbar.service';
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";

@Component({
  selector: 'app-endpoint-comments',
  templateUrl: './endpoint-comments.component.html',
  styleUrls: ['./endpoint-comments.component.scss'],
  providers: [ProjectService,SnackbarService]

})
export class EndpointCommentsComponent implements OnInit {
  autoSuggestion: any;
  id: string;
  result: Array<boolean> = new Array();
  activateChecked: boolean;
  togglevalue;
  comments;

  constructor(public dialogRef: MatDialogRef<EndpointCommentsComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
  private projectService: ProjectService, private router: Router, private handler: Handler,private snackbarService: SnackbarService) { }

  ngOnInit(): void {
    this.comments = this.data.comment;

  }
  saveAndClose() {
    if(this.comments)
    this.data.comment = this.comments;
    console.log(this.comments)
    if(this.data){
    this.saveAutoSuggestion(this.data[0], this.comments);
    this.result[0] = true;
    this.result[1] = this.activateChecked;
    // this.snackbarService.openSnackBar('Succesfully, Vulnerability mark as not a bug', '');
    this.dialogRef.close(this.result);
    }
  }

  saveAutoSuggestion(obj, comment) {
    this.handler.activateLoader();
    this.projectService.getOpenEndpoint(obj.id, obj.testSuiteName, comment).subscribe(results => {
      this.handler.hideLoader();
      if (this.handler.handle(results)) {
        return;
      }
      this.snackbarService.openSnackBar(
        "Successfully marked as unsecured, Please recreate the category.",
        ""
    );
    }, error => {
      this.handler.hideLoader();
      this.handler.error(error);
    });
  }

  closeWithoutSave() {
    this.result[0] = false;
    this.dialogRef.close(this.result);
  }
}
