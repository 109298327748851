import { AfterViewChecked, AfterViewInit, Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import 'brace';
import 'brace/mode/yaml';
import 'brace/theme/github';
import 'brace/ext/language_tools';
import { AceEditorComponent } from 'ng2-ace-editor';
import { Router } from "@angular/router";
import { RunService } from "../../../services/run.service";
import { Handler } from "../handler/handler";
import { JobsService } from "../../../services/jobs.service";
import { SnackbarService } from "../../../services/snackbar.service";
import { TestSuiteService } from '../../../services/test-suite.service';
import { TestsuitEditDialogComponent } from "../../dialogs/testsuit-edit-dialog/testsuit-edit-dialog.component";


@Component({
    selector: 'app-recommendations-logs',
    templateUrl: './recommendations-logs.component.html',
    styleUrls: ['./recommendations-logs.component.scss'],
    providers: [JobsService, RunService, SnackbarService]
})
export class RecommendationsLogsComponent implements OnInit {

    @ViewChild('editor', {static: true}) editor: AceEditorComponent;

    keyword: string = '';
    category: string = 'All';
    id:string='';
    testsuite;
    editorSelect:any = {rowText:'',validEvent:false,text:'',exec:false,row:0,column:0,prevExecuted:false};
    currentSuiteName: string;
    suitNames: any;
    projectId: string;
    jobId: string;
    runId: string;
    passed: number;
    failed: number;
    // logDataTemp;
    logData;
    list;
    nextSuitName: string;
    suitePosition: number;

    methodGet = /\[GET]/gi;
    methodPut = /\[PUT]/gi;
    methodDelete = /\[DELETE]/gi;
    methodPost = /\[POST]/gi;

    constructor(private testSuiteService: TestSuiteService, private dialog: MatDialog,@Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<RecommendationsLogsComponent>, private router: Router,
        private runService: RunService, private handler: Handler) {
        this.logData = data[0];
        this.currentSuiteName = data[1];
         this.projectId = data[2];
         this.passed = data[3];
         this.failed = data[4];
      }


    ngOnInit() {
        localStorage.setItem("suitename", this.currentSuiteName)
        if (this.logData.search(this.methodGet) > 0) {
            this.logData = this.logData.replace(this.methodGet, '["GET"]');
        }
        if (this.logData.search(this.methodPut) > 0) {
            this.logData = this.logData.replace(this.methodPut, '["PUT"]');
        }
        if (this.logData.search(this.methodDelete) > 0) {
            this.logData = this.logData.replace(this.methodDelete, '["DELETE"]');
        }
        if (this.logData.search(this.methodPost) > 0) {
            this.logData = this.logData.replace(this.methodPost, '["POST"]');
        }
     }

    
    editorRecursiveEent(){
      var temp=this.rowTextEvent();
      if(temp.validEvent && !temp.prevExecuted ){
      this.editorSelect=temp;
        console.log("inside ");
        this.openFileDialog(this.projectId,this.editorSelect.text);
      }

    }
  
    openFileDialog(projectId,suiteName){
      this.id=projectId;
      this.keyword=suiteName;
      this.editorSelect.text=suiteName;
      this.searchByCategory(suiteName);
    }
    rowTextEvent(){
      var temp = {rowText:'',text:'',exec:false,row:0,column:0,validEvent:false,prevExecuted:true};
      temp.rowText = this.editor.getEditor().session.getLine(this.editor.getEditor().selection.getCursor().row);
      temp.row=this.editor.getEditor().selection.getCursor().row;
      temp.column=this.editor.getEditor().selection.getCursor().column;
      temp.text=this.rowTemplateText();
      var tStartIndex= temp.rowText.indexOf(temp.text)
      var tLastIndex=tStartIndex+temp.text.length;
      if(temp.text!='' && tStartIndex>0 && temp.column>tStartIndex && temp.column<tLastIndex )temp.validEvent=true;
      if(this.editor.getEditor().getSelectedText().length>0)temp.validEvent=false;
      if( temp.validEvent && temp.row!=this.editorSelect.row) temp.prevExecuted=false;
      return temp;
    }
    rowTemplateText(){
var row = this.editor.getEditor().session.getLine(this.editor.getEditor().selection.getCursor().row);
var tempText= row.substring(28,100).trim();
//tempText=this.reverseString(tempText);

var trow=tempText.split("]");
if(trow.length>=1) tempText=trow[0];//this.reverseString(trow[1]);
else tempText="";
if(tempText=="") return "";
return tempText;
}
    searchByCategory(suiteName) {
        this.handler.activateLoader();
        if (this.keyword == '' && this.category == '') {
            return this.list(this.id);
        }
        var category_ = '';
        if (this.category == 'All') {
            category_ = '';
        } else {
            category_ = this.category;
        }

        this.testSuiteService.searchTestSuite(this.id, category_,'','',this.keyword, 0, 10).subscribe(results => {
            this.handler.hideLoader();
            if (this.handler.handle(results)) {
                return;
            }
          //  this.testsuites = results['data'];
            for(var i=0;i<results['data'].length;i++){
              var k=results['data'][i];
              if(k.name==this.editorSelect.text){
              this.testsuite=k;
              break;

            }
            }
            if(this.testsuite!=null)
            this.showTSDialog(this.testsuite.id,this.testsuite.name,this.testsuite.autoGenerated);
           setTimeout( () => { this.editorSelect.exec=false; }, 3000 );

        }, error => {
            this.handler.hideLoader();
            this.handler.error(error);
        });
    }
    showTSDialog(testSuiteID, testSuiteName, autoGenerated) {
        const dialogRef = this.dialog.open(TestsuitEditDialogComponent, {
            width: '1200px',
            data: [this.id, testSuiteID, testSuiteName, autoGenerated]
        });
    }
    getTestSuiteResponseByName(suiteName: string) {
      this.editorSelect.exec=true;
        this.currentSuiteName = suiteName;
        this.handler.activateLoader();
        this.runService.getTestSuiteResponseByName(this.runId, suiteName).subscribe(results => {
            this.handler.hideLoader();
            if (this.handler.handle(results)) {
                return;
            }
            this.list = results['data'];
            var arrayLength = this.list.length;
            var msg = '';
            for (var i = 0; i < arrayLength; i++) {
                msg += this.list[i].logs;
            }
            this.logData = msg;
            this.editorSelect.exec=false;
            
            if (this.logData.search(this.methodGet) > 0) {
                this.logData = this.logData.replace(this.methodGet, '["GET"]');
            }
            if (this.logData.search(this.methodPut) > 0) {
                this.logData = this.logData.replace(this.methodPut, '["PUT"]');
            }
            if (this.logData.search(this.methodDelete) > 0) {
                this.logData = this.logData.replace(this.methodDelete, '["DELETE"]');
            }
            if (this.logData.search(this.methodPost) > 0) {
                this.logData = this.logData.replace(this.methodPost, '["POST"]');
            }
            localStorage.setItem("suitename", this.currentSuiteName)
        }, error => {
            this.handler.hideLoader();
            this.handler.error(error);
        });
    }

    ngAfterContentChecked() {
        var array2: any = []
        var numberoflines = this.editor.getEditor().session.getLength()
        var words = this.editor.getEditor().session.getLines(0, numberoflines)
        for (var i = 0; i < words.length; i++) {
            var array1 = words[i].split(" ")
            for (var j = 0; j < array1.length; j++)
                if (array1[j] == "ERROR") {
                    j = array1.length
                    array2.push({ row: i, text: "Error", type: "error" })
                }
        }
        this.editor.getEditor().getSession().setAnnotations(array2)
    }

    reverseString(str) {
      var newString = "";
      if(str!='')
    for (var i = str.length - 1; i >= 0; i--) {
        newString += str[i]; // or newString = newString + str[i];
    }
    return newString; // "olleh"
}
}
