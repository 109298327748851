import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";

import { Categories } from "../models/project-autocode-config.model";
import { TestSuite, TestCase } from "../models/test-suite.model";
import { Project } from "../models/project.model";

@Injectable()
export class RunService {
  private serviceUrl = "/api/v1/runs";

  constructor(private http: HttpClient) { }

  getById(id: string) {
    return this.http.get(this.serviceUrl + "/" + id);
  }
  getAllRunsByStatus(page, pageSize, status) {
    let params = new HttpParams();
    params = params.append("page", page);
    params = params.append("pageSize", pageSize);
    return this.http.get(this.serviceUrl + "/job/mssp/" + status, { params });
    //return this.http.get("http://localhost:3000/posts", {params});
  }

  /**
   * Get the jobs in observable from endpoint
   */
  get(jobId: string, page, pageSize) {
    let params = new HttpParams();
    params = params.append("page", page);
    params = params.append("pageSize", pageSize);
    return this.http.get(this.serviceUrl + "/job/" + jobId, { params });
  }

  run(jobId: string) {
    //return this.http.post(
    return this.http.put(this.serviceUrl + "/job/" + jobId, null);
  }

  fetchAllScans(projectId: string, page, pageSize) {
    let params = new HttpParams();
    params = params.append("page", page);
    params = params.append("pageSize", pageSize);
    return this.http.get(this.serviceUrl + "/project/" + projectId, { params });
  }

  fetchAllScansV2(projectId: string, page, pageSize, sort, sortType) {
    let params = new HttpParams();
    params = params.append("page", page);
    params = params.append("pageSize", pageSize);
    params = params.append("sort", sort);
    params = params.append("sortType", sortType);
    return this.http.get(this.serviceUrl + "/project/" + projectId, { params });
  }

  advRun(
    jobId: string,
    region: string,
    tags: string,
    suites: string,
    categories: string[],
    emailReport: boolean,
    projectId: string,
    env: string,
    endpointsObj,
    endpointsList
  ) {
    var cat = "";
    if (categories) {
      for (let categorie of categories) {
        cat = cat + categorie + ", ";
      }
    }

    let params = new HttpParams();
    params = params.append("region", region);
    params = params.append("tags", tags);
    params = params.append("suites", suites);
    params = params.append("categories", cat);
    params = params.append("emailReport", emailReport.toString());
    params = params.append("projectId", projectId);
    params = params.append("env", env);
    params = params.append("endpoints", endpointsList);
    return this.http.post(this.serviceUrl + "/job/" + jobId, endpointsObj, { params });
  }

  stopRun(runId: string) {
    return this.http.get(this.serviceUrl + "/stoprun/" + runId);
  }

  advTestSuiteRun(region: string, env: string, testSuite: TestSuite) {
    let params = new HttpParams();
    params = params.append("region", region);
    params = params.append("env", env);
    return this.http.put(this.serviceUrl + "/testsuite", testSuite, { params });
  }

  getDetails(runId: string) {
    return this.http.get(this.serviceUrl + "/" + runId);
  }

  getTestSuiteResponses(runId: string) {
    return this.http.get(
      this.serviceUrl + "/" + runId + "/test-suite-responses"
    );
  }

  getSummary(runId: string, page, pageSize, sort) {
    let params = new HttpParams();
    params = params.append("page", page);
    params = params.append("pageSize", pageSize);
    params = params.append("sort", sort);
    params = params.append("sortString", "suiteName.keyword");
    params = params.append("sortType", "DESC");
    return this.http.get(
      this.serviceUrl + "/" + runId + "/test-suite-summary",
      { params }
    );
  }

  searchV2(
    runId: string,
    category: string,
    keyword: string,
    status: string,
    fpfn: string,
    page,
    pageSize, sort, sortType
  ) {
    let params = new HttpParams();
    params = params.append("category", category);
    params = params.append("keyword", keyword);
    params = params.append("status", status);
    params = params.append("fpfn", fpfn);
    params = params.append("page", page);
    params = params.append("pageSize", pageSize);
    params = params.append("sort", sort);
    params = params.append("sortType", sortType);
    return this.http.get(
      this.serviceUrl + "/" + runId + "/test-suite-summary/search",
      { params }
    );
  }
  getSummaryV2(runId: string, page, pageSize, sort, sortName, sortType) {
    let params = new HttpParams();
    params = params.append("page", page);
    params = params.append("pageSize", pageSize);
    params = params.append("sort", sort);
    params = params.append("sortName", sortName);
    params = params.append("sortType", sortType);
    return this.http.get(
      this.serviceUrl + "/" + runId + "/test-suite-summary",
      { params }
    );
  }

  search(
    runId: string,
    category: string,
    keyword: string,
    status: string,
    fpfn: string,
    page,
    pageSize
  ) {
    let params = new HttpParams();
    params = params.append("category", category);
    params = params.append("keyword", keyword);
    params = params.append("status", status);
    params = params.append("fpfn", fpfn);
    params = params.append("page", page);
    params = params.append("pageSize", pageSize);
    return this.http.get(
      this.serviceUrl + "/" + runId + "/test-suite-summary/search",
      { params }
    );
  }

  getTestSuiteResponseByName(id: string, name: string) {
    return this.http.get(
      this.serviceUrl + "/" + id + "/test-suite-response/" + name
    );
  }

  getTestSuiteResponseHistoryByName(
    jobId: string,
    name: string,
    page,
    pageSize
  ) {
    let params = new HttpParams();
    params = params.append("page", page);
    params = params.append("pageSize", pageSize);
    return this.http.get(
      this.serviceUrl + "/" + jobId + "/testSuite/test-suite-responses/" + name,
      { params }
    );
  }

  deleteByJobIdAndRunId(jobId: string, runId: string) {
    return this.http.delete(
      this.serviceUrl + "/job/" + jobId + "/run/" + runId
    );
  }

  reRunByJobIdAndRunId(jobId: string, runId: string) {
    let params = new HttpParams();
    return this.http.post(
      this.serviceUrl + "/job/" + jobId + "/rerun/" + runId,
      { params }
    );
  }

  getDetailsByJobIdRunNum(jobId: string, runNum: string, action: string) {
    return this.http.get(
      this.serviceUrl + "/job/" + jobId + "/runNo/" + runNum + "?nav=" + action
    );
  }

  getRunsCount(id: string) {
    return this.http.get(this.serviceUrl + "/" + id + "/count");
  }
  getTestsuiteCount(runId) {
    return this.http.get(this.serviceUrl + "/" + runId + "/test-suite-count");
  }

  getRunStats(runId) {
    return this.http.get(this.serviceUrl + "/" + runId + "/run-result-stats");

  }

  getLatestScanDetails(jobId) {
    return this.http.get(this.serviceUrl + "/job/" + jobId + "/latest");
  }

  getLatestScanData(projectId, envId) {
    return this.http.get(this.serviceUrl + "/project/" + projectId + "/env/" + envId + "/latestScanByEnvId");
  }

}
