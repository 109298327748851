import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Handler } from '../components/dialogs/handler/handler';
import { ApiGroups } from '../services/api-groups.service';
import { SnackbarService } from '../services/snackbar.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-api-group-list',
  templateUrl: './api-group-list.component.html',
  styleUrls: ['./api-group-list.component.scss'],
  providers: [SnackbarService, ApiGroups]
})
export class ApiGroupListComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = ['name', 'description', 'apis', 'teams', 'subGroups'];
  firstFormGroup: FormGroup;
  dataSource = null;
  page = 0;
  pageSize = 10;
  length = 0;
  keyword: string = '';
  sortString = 'createdDate';
  sortType = 'DESC'
  groupListData;
  searchList;
  teamsData ;
  constructor(private _formBuilder: FormBuilder, private snackbarService: SnackbarService, private handler: Handler, private apiGroups: ApiGroups) { }

  ngOnInit(): void {
    this.searchSubscription = this.searchSubject
      .pipe(
        debounceTime(700), 
        distinctUntilChanged() 
      )
      .subscribe((keyword) => {
        this.searchSubject.next(this.keyword)
        this.searchGroups();
      });
    this.apiGroupList();
  }


  apiGroupList() {
    this.handler.activateLoader();
    this.apiGroups.getGroups(this.page, this.pageSize, this.sortString, this.sortType).subscribe(result => {
      this.handler.hideLoader();
      if (this.handler.handle(result)) {
        return;
      }
      this.groupListData = result['data'].content;
      this.dataSource = new MatTableDataSource(this.groupListData);
      this.dataSource.sort = this.sort;
      // this.length = result['totalElements'];
      this.length = result['data'].totalElements
      this.pageSize = result['data']['pageable'].pageSize;
    }, error => {
      this.handler.hideLoader();
      this.handler.error(error);

    })
    // this.searchGroups()
  }
  change(evt) {
    this.page = evt['pageIndex'];
    this.pageSize = evt.pageSize;
    this.apiGroupList();
  }
  private searchSubject = new Subject<string>();
  private searchSubscription: Subscription;

  onSearchInput(event) {
    const inputElement = event.target as HTMLInputElement;
    this.searchSubject.next(inputElement.value); 
  }

  clearSearch() {
    this.keyword = '';
    this.onSearchInput({ target: { value: '' } } as any);
  }  

  resultOfGroups :boolean = true;
  searchGroups() {
    this.resultOfGroups = false
    this.page = 0;
    this.handler.activateLoader();
    this.apiGroups.getGroupSearch(this.keyword, this.page, this.pageSize, this.sortString, this.sortType).subscribe(results => {
      this.handler.hideLoader();
      if (this.handler.handle(results)) {
        return;
      }
      this.searchList = results['data'].content;
      this.length = results['data'].totalElements;
      this.dataSource = new MatTableDataSource(this.searchList);
      this.resultOfGroups = true;
    }, error => {
      this.handler.hideLoader();
      this.handler.error(error);
    });
  }
  changeSort(obj, event) {
    event.stopPropagation();
    this.sortString = obj;
    if (this.sortType == "ASC") this.sortType = "DESC"; else this.sortType = "ASC";
    this.apiGroupList();
  }

  getTooltipContent(element){
      return element.map(item => item.name).join(', ');
  }
  ngOnDestroy() {
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
  }
}