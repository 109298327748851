import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SnackbarService } from '../../../services/snackbar.service';

@Component({
  selector: 'app-pre-script-dialog',
  templateUrl: './pre-script-dialog.component.html',
  styleUrls: ['./pre-script-dialog.component.scss'],
  providers: [SnackbarService]
})
export class PreScriptDialogComponent implements OnInit {
  preScript;
  constructor(
    private router: Router, public dialogRef: MatDialogRef<PreScriptDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private snackbarService: SnackbarService) { }


  ngOnInit(): void {

    this.preScript = this.data[0];
  }
  showMsg() {
    this.snackbarService.openSnackBar('Text copied to clipboard.', '');

  }
}
