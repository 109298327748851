import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackbarService } from '../../../services/snackbar.service';

@Component({
  selector: 'app-maven-integration',
  templateUrl: './maven-integration.component.html',
  styleUrls: ['./maven-integration.component.scss'],
  providers: [SnackbarService]

})
export class MavenIntegrationComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<MavenIntegrationComponent>,  private snackbarService: SnackbarService) { }

  ngOnInit() {
  }

  copyToClipboard(element) {
    var $temp = $("<input>");
    $("body").append($temp);
    $temp.val($(element).text()).select();
    document.execCommand("copy");
    this.snackbarService.openSnackBar("Copied text to clipboard ", "");
    $temp.remove();
  }
}
