import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { Project } from '../../../models/project.model';
import { AbacResource } from '../../../models/project-autocode-config.model';
import { Observable } from 'rxjs/Observable';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Handler } from '../handler/handler';
import { ProjectService } from '../../../services/project.service';
import { map, startWith } from 'rxjs/operators';
import {AbacService} from "../../../services/abac.service";
import {animate, state, style, transition, trigger} from '@angular/animations';


@Component({
  selector: 'app-manage-e2e-dialog',
  templateUrl: './manage-e2e-dialog.component.html',
  styleUrls: ['./manage-e2e-dialog.component.scss'],
  animations: [
    trigger('detailExpand', [
        state('collapsed', style({height: '0px', minHeight: '0', display: 'none'})),
        state('expanded', style({height: '*'})),
        transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
],
  providers: [ProjectService]
})
export class ManageE2eDialogComponent implements OnInit {
  project: Project = new Project();
  abacResource: AbacResource = new AbacResource();
  projectId: string;
  resourceData:any;
  endpointData:any;
  resourceList: string[]=[];
  filteredOptions: Observable<string[]>;
  filteredOptionsEndpoint: Observable<string[]>;
  filteredOptionsEndpointR: Observable<string[]>;
  filteredOptionsEndpointD: Observable<string[]>;

  firstFormGroup:FormGroup;
  endpoint;
  body;
  bodyR;
  useridR = "";
  endpointR;
  endpointD;
  selectedEntryIndex;
  @Output() emitData = new EventEmitter<string[]>();

  endpointList: string[]=[];
  endpointListDelete: string[]=[];
  entryDataSource = null;
  expandedElement: entryDataSource | null;
  columnsToDisplay:string[] = ['userAuth','endpoint','inactive','deleteHeader'];
  userIdList = [];
  disallowTableData = [];

  constructor(private dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any, private handler: Handler,
              public dialogRef: MatDialogRef<ManageE2eDialogComponent>, private projectService: ProjectService,
              private _formBuilder: FormBuilder ,private abacService:AbacService) {
    
  }

  ngOnInit() {

    // this.scriptName = this.resource + "Create" + this.userId + "InitAbac";
    this.projectId = this.data.projectId;
    this.abacResource.generatorId = this.data.genId;
    this.loadProject(this.projectId);
    this.getResourceList();
    this.getEndpointList();
    if(this.data.resourceObj)
    this.abacResource = JSON.parse(this.data.resourceObj);
    if(this.abacResource.resourceName != ""){
      this.bodyR = this.abacResource.createBody;
      // this.endpointD = this.abacResource.deleteEndpoint;

      //get list of disallow users
      
      this.endpointR = this.abacResource.createEndpoint;

      if(this.abacResource.validations != null && this.abacResource.validations.length > 0 ) {
        let tmpEndpoint = this.abacResource.validations[0].endpoint;
        this.useridR = this.abacResource.validations[0].userAuth;
      for(var i=1 ;i <this.abacResource.validations.length;i++)
      {
        if(tmpEndpoint == this.abacResource.validations[i].endpoint)
          this.useridR =this.useridR + "," + this.abacResource.validations[i].userAuth ;
        else
          break;
      }
      }
      this.entryDataSource = this.abacResource.validations;
      this.entryDataSource = new MatTableDataSource(this.entryDataSource);
    }
    else
    {
      this.useridR = this.data.denyRoles;
      this.abacResource.createUserAuth = this.data.allowRoles;
    }

    this.firstFormGroup = new FormGroup({
      myControlResource: new FormControl(),
      myControlEndpoint: new FormControl(),
      myControlEndpointR: new FormControl(),
      myControlEndpointD: new FormControl(),
      creatorCtrl: new FormControl(),
      useridRCtrl: new FormControl(),
      methodCtrl: new FormControl(),
      methodRCtrl: new FormControl(),
      methodDCtrl: new FormControl(),
      bodyCtrl: new FormControl(),
      bodyRCtrl: new FormControl()
    });

  }


  save(){

    this.abacResource.initScriptName = this.abacResource.resourceName + "Create" + this.abacResource.createUserAuth +"InitE2E";
    
    //call save resource endpoint
    this.handler.activateLoader();
    this.abacService.saveAbacData(this.abacResource).subscribe(results => {
      this.handler.hideLoader();
      if (this.handler.handle(results)) {
        return;
      }
      this.emitData.next(this.data.projectId)
      this.dialogRef.close(); 
    }, error => {
      this.handler.hideLoader();
      this.handler.error(error);
    });

  
  }

  loadProject(id: string) {
    this.handler.activateLoader();
    this.projectService.getById(id).subscribe(results => {
      this.handler.hideLoader();
      if (this.handler.handle(results)) {
        return;
      }
      this.project = results['data'];
    }, error => {
      this.handler.hideLoader();
      this.handler.error(error);
    });
  }

  getResourceList(){
    this.handler.activateLoader();
    this.projectService.getResourceList(this.projectId).subscribe(results => {
      this.handler.hideLoader();
      if (this.handler.handle(results)) {
        return;
      }
      this.resourceData = results['data'];
      for(let r1 of this.resourceData)
        this.resourceList.push(r1['resourceName']);

      // this.filteredOptions = this.myControlResource.valueChanges
      this.filteredOptions = this.firstFormGroup.controls['myControlResource'].valueChanges.pipe(
          startWith(''),
          map(value => this._filter(value))
      );


    }, error => {
      this.handler.hideLoader();
      this.handler.error(error);
    });
  }

  private _filter(value: string): string[] {
    // this.selectedResource = value;
    const filterValue = value.toLowerCase();

    return this.resourceList.filter(option => option.toLowerCase().includes(filterValue));
  }

  loadBody(selectedResource){
    var resIndex =  this.resourceList.indexOf(selectedResource);
    this.abacResource.createBody = this.resourceData[resIndex]['definition'];
    this.body = this.resourceData[resIndex]['definition'];
    this.bodyR = this.resourceData[resIndex]['definition']
  }

  getEndpointList() {
    this.handler.activateLoader();
    this.projectService.getEndpoints(this.projectId).subscribe(results => {
      this.handler.hideLoader();
      if (this.handler.handle(results)) {
        return;
      }
      this.endpointData = results['data'];

      for(let r1 of this.endpointData)
      {
        this.endpointList.push(r1['method'] + ":" + r1['endpoint'])
        if(r1['method'] == 'DELETE')
          this.endpointListDelete.push(r1['method'] + ":" + r1['endpoint'])
      }

      this.filteredOptionsEndpoint = this.firstFormGroup.controls['myControlEndpoint'].valueChanges
          .pipe(
              startWith(''),
              map(value => this._filterEndpoint(value))
          );

      this.filteredOptionsEndpointR = this.firstFormGroup.controls['myControlEndpointR'].valueChanges
          .pipe(
              startWith(''),
              map(value => this._filterEndpointR(value))
          );

      this.filteredOptionsEndpointD = this.firstFormGroup.controls['myControlEndpointD'].valueChanges
          .pipe(
              startWith(''),
              map(value => this._filterEndpointD(value))
          );

    }, error => {
      this.handler.hideLoader();
      this.handler.error(error);
    });
  }

  private _filterEndpoint(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.endpointList.filter(option => option.toLowerCase().includes(filterValue));
  }

  private _filterEndpointR(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.endpointList.filter(option => option.toLowerCase().includes(filterValue));
  }

  private _filterEndpointD(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.endpointListDelete.filter(option => option.toLowerCase().includes(filterValue));
  }

  addEntry(){
    if(this.entryDataSource != null)
      this.disallowTableData = this.entryDataSource.data;
    else
      this.disallowTableData = [];

    var tempObj;
    this.userIdList = this.useridR.split(",");
    for(let entry of this.userIdList)
    {
      tempObj = {
        userAuth : entry,
        endpoint: this.endpointR,
        path: this.endpointR,
        body:this.bodyR,
        inactive  : true,
        validationType: "Disallow"
      };

      this.disallowTableData.push(tempObj)
    }

    this.entryDataSource = new MatTableDataSource(this.disallowTableData)
    this.abacResource.validations = this.entryDataSource.data;
  }

  deleteABACAllow(deleteIndex){
    this.entryDataSource.data.splice(deleteIndex,1);
    this.entryDataSource = new MatTableDataSource(this.entryDataSource.data);
  }
  // endpointActive(index,event){

  //   if(event.checked){
  //     this.entryDataSource.data[index].inactive = true;
  //   }
  //   else
  //   {
  //     this.entryDataSource.data[index].inactive = false;

  //   }
  // }
  changeInactive(res){
    res.inactive = !res.inactive;
  }
}

export interface entryDataSource {
  userAuth: string;
  endpoint: string;
  inactive: string;
}
