import { Component, OnInit, ElementRef, ViewChild} from '@angular/core';

@Component({
  selector: 'app-agreement-dialog',
  templateUrl: './agreement-dialog.component.html',
  styleUrls: ['./agreement-dialog.component.scss']
})
export class AgreementDialogComponent implements OnInit {

  @ViewChild('scroll', { read: ElementRef, static: true }) public scroll: ElementRef<any>;
  agreeResult: any = "disagree";
  agree(agreeResult:any){
      // this.agreeRes=agreeRes;
    localStorage.removeItem(agreeResult);
          localStorage.setItem('agreeResult', agreeResult);
               console.log('result:', agreeResult);
    }

    showAgree(agreeResult: any) {
      localStorage.removeItem(agreeResult);
      localStorage.setItem('agreeResult', agreeResult);
  }
  

  constructor() {
    
   }

  ngOnInit() {
  }

}
