import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SnackbarService } from '../../../services/snackbar.service';
import { TestSuiteService } from '../../../services/test-suite.service';
import { Handler } from '../handler/handler';

@Component({
  selector: 'app-assertion-propagation-dialog',
  templateUrl: './assertion-propagation-dialog.component.html',
  styleUrls: ['./assertion-propagation-dialog.component.scss'],
  providers: [SnackbarService]

})
export class AssertionPropagationDialogComponent implements OnInit {

  constructor(private testSuiteService: TestSuiteService, private snackbarService: SnackbarService,
    private router: Router, private handler: Handler,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AssertionPropagationDialogComponent>, private dialog: MatDialog,
  ) {
  }
  @Output() emitData = new EventEmitter<string>();
  ngOnInit(): void {
  }

  propagateAssertion() {
    this.emitData.next("Y");
    this.dialogRef.close();
    this.handler.activateLoader();
    //   if(this.data[1]=="Assertion"){
    //   this.testSuiteService.propagateAssertion(this.data[0]).subscribe(results => {

    //     this.handler.hideLoader();
    //     if (this.handler.handle(results)) {
    //       return;
    //     }
    //     this.snackbarService.openSnackBar("Assertions propagated to the category " + this.data[0].category + " successfully!", "");

    //     // this.router.navigate(['/app/projects', this.data.project.id, 'playbooks']);
    //   }, error => {
    //     // this.disableSave = false;
    //     this.handler.hideLoader();
    //     this.handler.error(error);
    //   });
    // }
    // if (this.data[1] == "Severity") {
    //   this.testSuiteService.propagateSeverity(this.data[0]).subscribe(results => {

    //     this.handler.hideLoader();
    //     if (this.handler.handle(results)) {
    //       return;
    //     }
    //     this.snackbarService.openSnackBar("Severity propagated to the category " + this.data[0].category + " successfully!", "");

    //    }, error => {
    //      this.handler.hideLoader();
    //     this.handler.error(error);
    //   });
    // }
  }


  skip() {
    this.emitData.next("N");
    this.dialogRef.close();
  }

}
