import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Project } from '../../models/project.model';
import { ReportSetting } from '../../models/report-setting';
import { AccountService } from '../../services/account.service';
import { OrgService } from '../../services/org.service';
import { ProjectService } from '../../services/project.service';
import { SnackbarService } from '../../services/snackbar.service';
import { Handler } from '../dialogs/handler/handler';
import { Location } from "@angular/common";
import { RegisterComponent } from '../dialogs/register/register.component';
import { UpgradeComponent } from '../dialogs/upgrade/upgrade.component';
import { GlobalProjectDetails } from "../../shared/shared.data";
import { MixpanelService } from '../../services/mixpanel.service';

@Component({
  selector: 'app-aws-s3',
  templateUrl: './aws-s3.component.html',
  styleUrls: ['./aws-s3.component.scss'],
  providers: [ProjectService, SnackbarService, AccountService, OrgService]

})
export class AwsS3Component implements OnInit {
  id: string;
  project: Project = new Project();
  selectedAccountId = null;
  reportSetting: ReportSetting = new ReportSetting();
  firstFormGroup: FormGroup;
  loggedInUserRole;
  freeAcc: boolean = false;
  storageInactive: boolean;
  // accounts: Array<Account> = [];
  accounts = [];

  constructor(private projectService: ProjectService,
    private accountService: AccountService,
    private route: ActivatedRoute,
    private router: Router,
    private handler: Handler,
    private snackbarService: SnackbarService,
    public dialog: MatDialog,
    private mixpanelService: MixpanelService,
    private _location: Location) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.id = params["id"];
      if (this.id) {
        this.loadProject(this.id);
        this.getReportSetting();
        this.getAccountsAwsS3();
      }
    });
  }

  // below code returns the credential/account for report
  getAccountsAwsS3() {
    this.handler.activateLoader();
    this.accountService.getAccountByAccountType("REPORT_STORAGE").subscribe(
      results => {
        this.handler.hideLoader();
        if (this.handler.handle(results)) {
          return;
        }
        this.accounts = results["data"];
        // console.log(this.accounts)
      },
      error => {
        this.handler.hideLoader();
        this.handler.error(error);
      }
    );
  }

  globalProjectDetails = GlobalProjectDetails;
  loadProject(id: string) {
    this.handler.activateLoader();
    this.projectService.getById(id).subscribe(
      results => {
        this.handler.hideLoader();
        if (this.handler.handle(results)) {
          return;
        }
        this.project = results["data"];
        this.globalProjectDetails.name = this.project.name;
      },
      error => {
        this.handler.hideLoader();
        this.handler.error(error);
      }
    );
  }

  validateAndSave() {
    if (this.freeAcc)
      this.upgradeSubscription();
    else
      this.save();
  }

  save() {
    // if(this.reportSetting.inactive==true)
    // this.reportSetting.inactive=false;

    if (this.storageInactive != this.reportSetting.inactive) {
      //mixpanel code starts
      var obj1 = {
        "projectName": this.project.name,
        "active": this.storageInactive
      }
      this.mixpanelService.trackMixpanel("ReportToggle", obj1);
      //mixpanel code ends
    }

    this.snackbarService.openSnackBar("Saving Report-storage details... ", "");
    this.reportSetting.projectId = this.project.id;
    this.accounts.map(acc => {
      if (this.selectedAccountId == acc.id) {
        this.reportSetting.account = acc
        return
      }

    })

    if (this.reportSetting.account) {
      this.reportSetting.inactive = !this.storageInactive;
    }

    this.handler.activateLoader();
    this.projectService.saveReportSetting(this.reportSetting).subscribe(results => {
      this.handler.hideLoader();
      if (this.handler.handle(results)) {
        return;
      }
      this.reportSetting = results['data'];
      this.snackbarService.openSnackBar(
        "Report-storage added to Project '" + this.project.name + "'.", "");
    }, error => {
      this.handler.hideLoader();
      this.handler.error(error);
    });
  }

  //below code return saved setting of reports
  getReportSetting() {
    this.projectService.getReportSetting(this.id).subscribe(results => {
      this.handler.hideLoader();
      if (!results['errors']) {
        this.reportSetting = results['data'];
        if (this.reportSetting.account) {
          this.selectedAccountId = this.reportSetting.account.id;
          this.storageInactive = !this.reportSetting.inactive;
        }
        else
          this.storageInactive = true;
      }
      // else if (results['errors']) 
      //     window.alert("Please upgrade your subscription plan.");
    },
      error => {
        // var r = confirm("Please Upgrade to Professional License.");
        // if (r == true) {
        //   window.location.href = 'https://www.apisec.ai/sign-up';
        // }
        this.handler.hideLoader();
        if (error['status'] == '403') {
          this.freeAcc = true;
          this.upgradeSubscription();
        }
        else {
          this.freeAcc = false;
          this.handler.error(error);
        }
      })
  }

  showUpgrade(event) {
    this.freeAcc = true;
    this.upgradeSubscription();
}

  upgradeSubscription(): void {
    const dialogRef = this.dialog.open(UpgradeComponent, {
      width: '450px',
      disableClose: true
    });
  }

  cancelClicked() {
    this._location.back();
  }


  openCredsDialog(): void {
    const dialogRef = this.dialog.open(RegisterComponent, {
      width: "800px",
      data: ['reportStore']

    });
    dialogRef.afterClosed().subscribe(result => {
      this.getAccountsAwsS3();
    });
  }
}
