import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';


import { Jobs, Noti, Cron } from '../models/jobs.model';
import { MixpanelService } from './mixpanel.service';

@Injectable()
export class JobsService {
  private serviceUrl = '/api/v1/jobs';
  constructor(private http: HttpClient, private mixpanelService: MixpanelService) {
  }

  get(page, pageSize) {
    let params = new HttpParams();
    params = params.append('page', page);
    params = params.append('pageSize', pageSize);
    return this.http.get(this.serviceUrl, { params });
  }

  getById(id: string) {
    return this.http.get(this.serviceUrl + "/" + id);
  }

  /**
   * Get the jobs in observable from endpoint
   */
  getJobs(id: string, page, pageSize) {
    let params = new HttpParams();
    params = params.append('page', page);
    params = params.append('pageSize', pageSize);
    return this.http.get(this.serviceUrl + "/project-id/" + id, { params });
  }
  getJobsV2(id: string, page, pageSize, sort, sortType) {
    let params = new HttpParams();
    params = params.append('page', page);
    params = params.append('pageSize', pageSize);
    params = params.append("sort", sort);
    params = params.append("sortType", sortType);
    return this.http.get(this.serviceUrl + "/project-id/" + id, { params });
  }
  getJobsNoIssues(id: string, page, pageSize) {
    let params = new HttpParams();
    params = params.append('page', page);
    params = params.append('pageSize', pageSize);
    return this.http.get(this.serviceUrl + "/project-id/" + id + "/no-issues", { params });
  }

  getCountJobs() {
    return this.http.get(this.serviceUrl + "/count");
  }

  getCountTests() {
    return this.http.get(this.serviceUrl + "/count-tests");
  }

  create(obj: Jobs, categories: string[]) {


    //mixpanel code starts
    var obj1 = {
      "profileName": obj.name
    }
    this.mixpanelService.trackMixpanel("NewProfile", obj1);
    //mixpanel code ends
    obj.categories = categories ? categories.join() : '';
    return this.http.post(this.serviceUrl, obj);
  }

  update(obj: Jobs, categories: string[]) {
    //mixpanel code starts
    var obj1 = {
      "profileName": obj.name
    }
    this.mixpanelService.trackMixpanel("EditProfile", obj1);
    //mixpanel code ends

    obj.categories = categories ? categories.join() : '';
    return this.http.put(this.serviceUrl, obj);
  }

  validateIssueTrackerCreds(obj: Jobs) {
    return this.http.post(this.serviceUrl + "/validate-creds", obj);
  }

  updateNotifications(obj: Jobs) {
    return this.http.put(this.serviceUrl, obj);
  }

  delete(obj: Jobs) {
    return this.http.delete(this.serviceUrl + "/" + obj['id']);
  }

  getAutoSuggestions(id: string) {
    return this.http.get(this.serviceUrl + "/" + id + "/auto-suggestions");
  }

  skipAutoSuggestion(jobId: string, suiteName: string, tcNumber: string) {
    return this.http.get(this.serviceUrl + "/" + jobId + "/auto-suggestions/skip/" + suiteName + "/" + tcNumber);
  }
  clearAllJobSuggestions(id: string) {
    return this.http.get(this.serviceUrl + "/" + id + "/clear-all-suggestions");
  }

  getPlayRunsGraph(id: string) {
    return this.http.get(this.serviceUrl + "/playbooks-graph/" + id);
  }
}
