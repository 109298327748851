import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'app-assertions-list-dialog',
  templateUrl: './assertions-list-dialog.component.html',
  styleUrls: ['./assertions-list-dialog.component.scss']
})
export class AssertionsListDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<AssertionsListDialogComponent>) { }
  displayedColumns: string[] = ['text'];

  assertionDatasource = [
    "@StatusCode == 200",
    "@ResponseTime <= 1000",
    "@ResponseSize <= 1024",
    "@Response.errors == false || @Response.data.errors == false",
    "@Response.data.name == @Request.name",
    "@Response.data.url == @Request.url",
    "@Response.data.account.id == @Request.account.id",
    "@Response.data.email =~ ***work@**.com"
  ];
  ngOnInit() {

  }

}
