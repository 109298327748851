import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DefaultIntegrationsService {

  private serviceUrl = '/api/v1/default-integration';
  constructor(private http: HttpClient) {
  }

  getByAccountFor(accountFor) {
    return this.http.get(this.serviceUrl + "/get-by-account-for/" + accountFor);
  }

  saveIntegrations(defaultIntegrations) {
    return this.http.put(this.serviceUrl, defaultIntegrations);
  }


  getAllIntegrations() {
    //This API call is required in be backend to check if the org has a valid entry for default settings.
    //If there is no data , the java code will create default entries for that org.
    return this.http.get(this.serviceUrl + "/all");
  }
}
