import { Component, Injectable, Directive } from '@angular/core';
import { VERSION } from '@angular/material/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ResponseDialogComponent } from '../response-dialog/response-dialog.component';
import { AuthTestResponseDialogComponent } from '../auth-test-response-dialog/auth-test-response-dialog.component';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';
import { APPCONFIG } from '../../../config';
import { MatSnackBar } from '@angular/material/snack-bar';
import { filter } from 'rxjs/operators';
import { Router } from "@angular/router";

@Injectable()
export class Handler {

    AppConfig;

    constructor(private dialog: MatDialog, private router: Router, public snackBar: MatSnackBar) {
        this.AppConfig = APPCONFIG;
    }

    ngOnInit() {

    }

    activateLoader() {
        this.AppConfig['isLoadingResults'] = true;
    }

    hideLoader() {
        this.AppConfig['isLoadingResults'] = false;
    }

    showError: boolean = false;
    showResp: boolean = false;

    error(error) {
        var msg = "";
        // if (error['status'] != '401')
        //     console.error(error);

        this.snackBar.dismiss();

        if (error['status'] == '400') {
            error['error']['type'] = 'ERROR';
            const dialogRef = this.dialog.open(ResponseDialogComponent, {
                width: "500px",
                data: error['error']
            });
            dialogRef.afterClosed().subscribe(result => {
                this.showResp = false;

            });
        }

        else if (this.showError == false) {
            this.showError = true;
            if (error['status'] == '401') {
                msg = "Your session has expired. You will be redirected to Login page."
                const dialogRef = this.dialog.open(ErrorDialogComponent, {
                    data: [msg, error['status']],
                    width: "350px"
                });
                dialogRef.afterClosed().subscribe(result => {
                    this.showError = false;
                    if (error['status'] == '401') {
                        localStorage.removeItem('apisecT_1');
                        localStorage.removeItem('userRole');
                        // location.reload(true);
                        this.router.navigate(['/login']);
                    }
                });

            }
            else {
                if (error['status'] == '403')
                    msg = "You are not authorized to perform this activity. Please contact org admin."
                else
                    msg = error['message'];
                const dialogRef = this.dialog.open(ErrorDialogComponent, {
                    data: [msg, error['status']],
                    width: "600px"
                });
                dialogRef.afterClosed().subscribe(result => {
                    this.showError = false;
                });
            }
        }

    }

    handle(response) {
        if (!response['errors']) {
            return false;
        }
        // console.error(response);
        if (this.showResp == false) {
            this.showResp = true;
            this.snackBar.dismiss();
            const dialogRef = this.dialog.open(ResponseDialogComponent, {
                width: "500px",
                data: response['messages']
            });
            dialogRef.afterClosed().subscribe(result => {
                this.showResp = false;

            });
        }
        return true;
    }


    handleForgotPwd(response) {
        if (!response['errors']) {
            return false;
        }
        console.error(response);
        if (this.showResp == false) {
            this.showResp = true;
            this.snackBar.dismiss();
            const dialogRef = this.dialog.open(ResponseDialogComponent, {
                data: response['messages']
            });
            dialogRef.afterClosed().subscribe(result => {
                this.showResp = false;
                this.router.navigate(['/login']);

            });
        }
        return true;
    }

    handleProjectRegistration(response) {
        if (!response['errors']) {
            return false;
        }
        // console.error(response);
        var msg = [];
        // msg.push(response['messages'][0]);

        if (response['data']) {
            var obj = response['data'];
            Object.keys(obj).forEach(function (key) {
                if (obj[key] == "UNAUTHORIZED")
                    msg.push({ value: key });
            });
            msg.sort((a, b) => (a.value > b.value) ? 1 : -1)
        }
        if (msg && msg.length > 0) {
            const dialogRef = this.dialog.open(AuthTestResponseDialogComponent, {
                width: '450px',
                data: { authStatus: msg, valid: 'inValid' }
            });
        }
        else {
            const dialogRef = this.dialog.open(ResponseDialogComponent, {
                data: response['messages']
            });
        }

        // if (msg && msg.length > 0) {
        //     const dialogRef = this.dialog.open(AuthTestResponseDialogComponent, {
        //         width: '450px',
        //         data: { authStatus: msg, valid: 'inValid' }
        //       });
        // }
        // else {
        //     const dialogRef = this.dialog.open(AuthTestResponseDialogComponent, {
        //         width: '450px',
        //         data: { authStatus: response['messages'], valid: 'inValid' }
        //       });
        // }
        return true;
    }
}
